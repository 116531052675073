import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import CircleFilledIcon from '@assets/icons/dashboard/circle-filled-orange.svg';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { ICreateAdjustmentDefinitionData } from '@models/interfaces/additional/ICreateAdjustmentDefinitionData';
import { IProject } from '@models/interfaces/entities/IProject';
import { IUpdateAdjustmentDefinitionData } from '@models/interfaces/additional/IUpdateAdjustmentDefinitionData';
import { IgnoredTypeCodesTab } from './components/IgnoredTypeCodesTab';
import { NewTypeCodesTab } from './components/NewTypeCodesTab';
import { UsedTypeCodesTab } from './components/UsedTypeCodesTab';
import useStyles from './styles';

export enum DetailsTabs {
  newTypeCodes = 0,
  usedTypeCodes = 1,
  ignoredTypeCodes = 2,
}

interface IProps {
  project: IProject;
  accounts: IAccount[];
  type: number;
  hasNewTypeCodes: boolean;
  onNewTypeCodesChanged: (type: number) => void;
  onAccountsChanged: (account?: IAccount) => void;
  onAdjustmentsChanged: () => void;
  manageAdjustmentDefinitions: (
    create?: { url: string; data: ICreateAdjustmentDefinitionData[] },
    update?: { url: string; data: IUpdateAdjustmentDefinitionData }[],
    callback?: () => void,
  ) => void;
}

export const SourceBalanceDetails = ({
  project,
  accounts,
  type,
  hasNewTypeCodes,
  onNewTypeCodesChanged,
  onAccountsChanged,
  onAdjustmentsChanged,
  manageAdjustmentDefinitions,
}: IProps) => {
  const { classes } = useStyles();

  const [activeTab, setActiveTab] = useState<DetailsTabs>(
    hasNewTypeCodes ? DetailsTabs.newTypeCodes : DetailsTabs.usedTypeCodes,
  );

  const onTabChanged = (tab: DetailsTabs) => {
    setActiveTab(tab);
  };

  const onNewTypeCodeUpdated = useCallback(() => {
    onNewTypeCodesChanged(type);
  }, [type, onNewTypeCodesChanged]);

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.content}>
          <Tabs value={activeTab} onChange={(e, t) => onTabChanged(t)} aria-label='tabs'>
            <Tab
              id='new-type-codes-tab'
              aria-controls='new-type-codes-tab-panel'
              label={
                <Box className={classes.tabTitle}>
                  {hasNewTypeCodes && <img src={CircleFilledIcon} alt='circle' />}
                  <Typography variant='subtitle2'>New Type Codes</Typography>
                </Box>
              }
              value={DetailsTabs.newTypeCodes}
            />
            <Tab
              id='used-type-codes-tab'
              aria-controls='used-type-codes-tab-panel'
              label={<Typography variant='subtitle2'>Used Type Codes</Typography>}
              value={DetailsTabs.usedTypeCodes}
            />
            <Tab
              id='ignored-type-codes-tab'
              aria-controls='ignored-type-codes-tab-panel'
              label={<Typography variant='subtitle2'>Ignored Type Codes</Typography>}
              value={DetailsTabs.ignoredTypeCodes}
            />
          </Tabs>
          <Box
            role='tabpanel'
            hidden={activeTab !== DetailsTabs.newTypeCodes}
            id='new-type-codes-tab-panel'
            aria-labelledby='new-type-codes-tab'
          >
            {activeTab === DetailsTabs.newTypeCodes && (
              <NewTypeCodesTab
                project={project}
                accounts={accounts}
                type={type}
                onTypeCodesUpdated={onNewTypeCodeUpdated}
                onAccountsUpdated={onAccountsChanged}
                onAdjustmentsUpdated={onAdjustmentsChanged}
                manageAdjustmentDefinitions={manageAdjustmentDefinitions}
              />
            )}
          </Box>
          <Box
            role='tabpanel'
            hidden={activeTab !== DetailsTabs.usedTypeCodes}
            id='used-type-codes-tab-panel'
            aria-labelledby='used-type-codes-tab'
          >
            {activeTab === DetailsTabs.usedTypeCodes && (
              <UsedTypeCodesTab project={project} type={type} />
            )}
          </Box>
          <Box
            role='tabpanel'
            hidden={activeTab !== DetailsTabs.ignoredTypeCodes}
            id='ignored-type-codes-tab-panel'
            aria-labelledby='ignored-type-codes-tab'
          >
            {activeTab === DetailsTabs.ignoredTypeCodes && (
              <IgnoredTypeCodesTab project={project} type={type} />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
