import { AccountsDialog, IAccountOption } from './AccountsDialog';
import { Box, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { CustomAutocomplete, IAutocompleteOption } from '@components/CustomAutocomplete';
import { DataGridPremium, GridActionsCellItem, GridColDef } from '@mui/x-data-grid-premium';
import {
  createAccount,
  getAdjustmentDefinitions,
  getTypeCodes,
  updateNewTypeCode,
} from '@services/api';
import { formatBalance, useTableExpand } from '../../../../utils';
import { useApi, useLoader, useUpdateEffect } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { AdjustmentOperation } from '../../../../common/AdjustmentOperation';
import CheckIcon from '@assets/icons/dashboard/check-circle.svg';
import { Dropdown } from '@components/Dropdown';
import EditIcon from '@assets/icons/dashboard/edit.svg';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustmentDefinition } from '@models/interfaces/entities/IAdjustmentDefinition';
import { ICreateAdjustmentDefinitionData } from '@models/interfaces/additional/ICreateAdjustmentDefinitionData';
import { IProject } from '@models/interfaces/entities/IProject';
import { ITypeCode } from '@models/interfaces/entities/ITypeCode';
import { IUpdateAdjustmentDefinitionData } from '@models/interfaces/additional/IUpdateAdjustmentDefinitionData';
import InfoIcon from '@assets/icons/dashboard/info.svg';
import { Loader } from '@components/Loader';
import { NewTypeCodeStatuses } from '@models/enums/NewTypeCodeStatuses';
import PlusIcon from '@assets/icons/dashboard/plus-filled-green.svg';
import { PreviewSamplesDialog } from '@pages/dashboard/project/components/PreviewSamplesDialog';
import { StandardTableFooter } from '../../../StandardTableFooter';
import XCircleRedIcon from '@assets/icons/dashboard/x-circle-red.svg';
import XIcon from '@assets/icons/dashboard/x.svg';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import useStyles from './styles';

interface ITypeCodeAdditionalData {
  code: string;
  status: NewTypeCodeStatuses;
  description: string;
  additional: string;
  accountId?: string;
}

interface INewTypeCode {
  code: string;
  balance: number;
  status: NewTypeCodeStatuses;
  description: string;
  additional: string;
  accountId?: string;
}

interface IProps {
  project: IProject;
  type: number;
  accounts: IAccount[];
  onTypeCodesUpdated: (typeCode?: ITypeCode) => void;
  onAccountsUpdated: (account?: IAccount) => void;
  onAdjustmentsUpdated: () => void;
  manageAdjustmentDefinitions: (
    create?: { url: string; data: ICreateAdjustmentDefinitionData[] },
    update?: { url: string; data: IUpdateAdjustmentDefinitionData }[],
    callback?: () => void,
  ) => void;
}

export const NewTypeCodesTab = ({
  project,
  type,
  accounts,
  onTypeCodesUpdated,
  onAccountsUpdated,
  onAdjustmentsUpdated,
  manageAdjustmentDefinitions,
}: IProps) => {
  const { classes } = useStyles();

  const [typeCodes, setTypeCodes] = useState<ITypeCode[]>([]);
  const [initialAdjustmentDefinitions, setInitialAdjustmentDefinitions] = useState<
    IAdjustmentDefinition[]
  >([]);

  const [additionalDataRecords, setAdditionalDataRecords] = useState<ITypeCodeAdditionalData[]>([]);
  const [invalidRecords, setInvalidRecords] = useState<string[]>([]);

  const [selectedCode, setSelectedCode] = useState<string>();
  const [selectedExistingAccount, setSelectedExistingAccount] = useState<string | null>(null);
  const [openSamplesDialog, setOpenSamplesDialog] = useState(false);
  const [openAccountsDialog, setOpenAccountsDialog] = useState(false);

  const { isTableExpanded, onToggleTableExpand } = useTableExpand();

  const reloadAdjustmentDefinitions = useCallback((link: string | undefined, type: number) => {
    if (link) {
      getAdjustmentDefinitionsRequest(link, 'initial', undefined, type);
    }
  }, []);

  const {
    request: getTypeCodesRequest,
    data: getTypeCodesData,
    loading: getTypeCodesLoading,
  } = useApi(getTypeCodes, null, { handleErrors: true });

  const { request: mapTypeCodeToNewAccountRequest, loading: mapTypeCodeToNewAccountLoading } =
    useApi(
      async (
        updateTypeCodeLink: string,
        createAccountLink: string,
        createAdjustmentDefinitionLink: string,
        code: string,
        description: string,
        accountName: string,
        balance: number,
      ) => {
        const typeCode = await updateNewTypeCode(updateTypeCodeLink, {
          code,
          description,
          ignoreReason: '',
          isUsed: false,
        });

        const newAccount = await createAccount(createAccountLink, {
          name: accountName,
          category: '',
          tierGroup: '',
          type,
        });

        if (manageAdjustmentDefinitions && typeCode.id)
          manageAdjustmentDefinitions(
            {
              url: createAdjustmentDefinitionLink,
              data: [
                {
                  reverseBalance: false,
                  source: {
                    type: 'typeCode',
                    id: typeCode.id || undefined,
                  },
                  targets: [newAccount.id],
                  operation: AdjustmentOperation.Initial,
                  baseline: [],
                  tierLimits: [],
                  description: '',
                },
              ],
            },
            undefined,
            () => {
              const updateNewTypeCodeData = {
                ...typeCode,
                isUsed: true,
                additional: accountName,
                accountId: newAccount.id,
                balance,
              };
              toast.info('Successfully updated the type code.');
              onTypeCodesUpdated(updateNewTypeCodeData);
              onAccountsUpdated(newAccount);
              setTypeCodes((prev) =>
                prev.map((x) =>
                  x.typeCode !== updateNewTypeCodeData.typeCode ? x : updateNewTypeCodeData,
                ),
              );
              setAdditionalDataRecords((prev) =>
                prev.filter((x) => x.code !== updateNewTypeCodeData.typeCode),
              );
              reloadAdjustmentDefinitions(getAdjustmentDefinitionsLink, type);
            },
          );
      },
      null,
      {
        handleErrors: true,
      },
    );

  const {
    request: mapTypeCodeToExistingAccountRequest,
    loading: mapTypeCodeToExistingAccountLoading,
  } = useApi(
    async (
      updateTypeCodeLink: string,
      createAdjustmentDefinitionLink: string,
      code: string,
      description: string,
      account: IAccount,
      balance: number,
    ) => {
      const typeCode = await updateNewTypeCode(updateTypeCodeLink, {
        code,
        description,
        ignoreReason: '',
        isUsed: false,
      });

      if (manageAdjustmentDefinitions && typeCode.id)
        manageAdjustmentDefinitions(
          {
            url: createAdjustmentDefinitionLink,
            data: [
              {
                reverseBalance: false,
                source: {
                  type: 'typeCode',
                  id: typeCode.id || undefined,
                },
                targets: [account.id],
                operation: AdjustmentOperation.Initial,
                baseline: [],
                tierLimits: [],
                description: '',
              },
            ],
          },
          undefined,
          () => {
            const updateNewTypeCodeData = {
              ...typeCode,
              isUsed: true,
              additional: account.accountName,
              balance,
            };
            toast.info('Successfully updated the type code.');
            onTypeCodesUpdated(updateNewTypeCodeData);
            setTypeCodes((prev) =>
              prev.map((x) =>
                x.typeCode !== updateNewTypeCodeData.typeCode ? x : updateNewTypeCodeData,
              ),
            );
            setAdditionalDataRecords((prev) =>
              prev.filter((x) => x.code !== updateNewTypeCodeData.typeCode),
            );
            reloadAdjustmentDefinitions(getAdjustmentDefinitionsLink, type);
          },
        );
    },
    null,
    {
      handleErrors: true,
    },
  );

  const { request: ignoreTypeCodeRequest, loading: ignoreTypeCodeLoading } = useApi(
    async (
      updateTypeCodeLink: string,
      code: string,
      description: string,
      ignoreReason: string,
      balance: number,
    ) => {
      const typeCode = await updateNewTypeCode(updateTypeCodeLink, {
        code,
        description,
        ignoreReason,
        isUsed: false,
      });

      const updateNewTypeCodeData = { ...typeCode, balance };

      toast.info('Successfully updated the type code.');
      onTypeCodesUpdated(updateNewTypeCodeData);
      onAdjustmentsUpdated();
      setTypeCodes((prev) =>
        prev.map((x) =>
          x.typeCode !== updateNewTypeCodeData.typeCode ? x : updateNewTypeCodeData,
        ),
      );
      setAdditionalDataRecords((prev) =>
        prev.filter((x) => x.code !== updateNewTypeCodeData.typeCode),
      );
    },
    null,
    {
      handleErrors: true,
    },
  );

  const {
    request: getAdjustmentDefinitionsRequest,
    data: getAdjustmentDefinitionsData,
    loading: getAdjustmentDefinitionsLoading,
  } = useApi(getAdjustmentDefinitions, null, { handleErrors: true });

  const getSamplesLink = useMemo(() => project.links[Actions.getTypeCodeSamples]?.href, [project]);
  const updateTypeCodeLink = useMemo(
    () => getTypeCodesData?.links[Actions.updateNewTypeCode]?.href,
    [getTypeCodesData],
  );
  const getAdjustmentDefinitionsLink = useMemo(
    () => project.links[Actions.getAdjustmentDefinitions]?.href,
    [project],
  );
  const getTypeCodesLink = useMemo(() => project.links[Actions.getTypeCodes]?.href, [project]);
  const createAccountLink = useMemo(() => project.links[Actions.createAccount]?.href, [project]);
  const createAdjustmentDefinitionLink = useMemo(
    () => project.links[Actions.createAdjustmentDefinition]?.href,
    [project],
  );

  const getCommonDescription = useCallback((descriptions: string[]) => {
    if (!descriptions.length) return '';
    const first = descriptions[0];
    let commonPart = '';

    for (let i = 0; i < first.length; i++) {
      const char = first[i];
      if (descriptions.every((desc) => desc[i] === char)) {
        commonPart += char;
      } else {
        break;
      }
    }

    return commonPart;
  }, []);

  const groupedInitialAdjustmentDefinitions = useMemo(() => {
    const uniqueDefinitions: Array<{
      code: string;
      name: string;
      descriptions: string[];
      accountIds: string[];
    }> = [];

    initialAdjustmentDefinitions.forEach((x) => {
      if (x.targets.length) {
        const code = x.source.value.title || '';
        const name = x.tierLimits.length
          ? accounts.find((a) => a.id === x.targets[0].id)?.tierGroup || x.targets[0].accountName
          : x.targets[0].accountName;
        const accountId = x.targets[0].id;

        const existing = uniqueDefinitions.find((def) => def.code === code && def.name === name);

        if (existing) {
          existing.descriptions.push(x.description);
          existing.accountIds.push(accountId);
        } else {
          uniqueDefinitions.push({
            code,
            name,
            descriptions: [x.description],
            accountIds: [accountId],
          });
        }
      }
    });

    const result = uniqueDefinitions.map((d) =>
      Object.freeze({
        code: d.code,
        name: d.name,
        description: getCommonDescription(d.descriptions),
        accountIds: d.accountIds,
      }),
    );

    return result;
  }, [initialAdjustmentDefinitions, accounts]);

  const newTypeCodeItems = useMemo(
    () =>
      typeCodes.map((x) => {
        let status = NewTypeCodeStatuses.none;
        let accountId: string | undefined = undefined;
        const description = x.description;
        let additional = '';

        if (x.isKnown) {
          if (x.isUsed) {
            const initAdjustmentDefinition = groupedInitialAdjustmentDefinitions.find(
              (ad) => ad.code === x.typeCode,
            );

            if (initAdjustmentDefinition) {
              accountId = initAdjustmentDefinition.accountIds[0];
              additional = initAdjustmentDefinition.name;

              const account = accounts.find((a) => a.id === accountId);
              status = account?.isNew
                ? NewTypeCodeStatuses.mappedToNewAccount
                : NewTypeCodeStatuses.mappedToExistingAccount;
            }
          } else {
            status = NewTypeCodeStatuses.ignored;
            additional = x.ignoreReason || '';
          }
        }

        return {
          code: x.typeCode,
          balance: x.balance,
          status,
          description,
          additional,
          accountId,
          accounts,
        };
      }),

    [typeCodes, groupedInitialAdjustmentDefinitions],
  );

  const onCloseSamplesDialog = () => {
    setOpenSamplesDialog(false);
    setSelectedCode(undefined);
  };

  const onCloseAccountsDialog = (data?: IAccountOption) => {
    if (data && selectedCode) {
      onChangeAdditional(selectedCode, data.title);
      onChangeAccountId(selectedCode, data.id);
    }
    setOpenAccountsDialog(false);
    setSelectedCode(undefined);
    setSelectedExistingAccount(null);
  };

  const onShowSamples = (code: string) => {
    setSelectedCode(code);
    setOpenSamplesDialog(true);
  };

  const onStatusChanged = (code: string, status: NewTypeCodeStatuses) => {
    const typeCode = newTypeCodeItems.find((x) => x.code === code);
    const record = additionalDataRecords.find((x) => x.code === code);
    if (!record && (!typeCode || status === typeCode.status)) return;
    if (status === NewTypeCodeStatuses.none && record) {
      setAdditionalDataRecords((prev) => prev.filter((x) => x.code !== code));
    } else if (status !== NewTypeCodeStatuses.none) {
      if (!record) {
        setAdditionalDataRecords((prev) => [
          ...prev,
          {
            code,
            status,
            description: typeCode?.description || '',
            additional: '',
            accountId: undefined,
          },
        ]);
      } else {
        if (status === record.status) return;
        setAdditionalDataRecords((prev) =>
          prev.map((x) =>
            x.code === code
              ? { code, status, description: x.description, additional: '', accountId: undefined }
              : x,
          ),
        );
      }
    }
    setInvalidRecords((prev) => prev.filter((x) => x !== code));
  };

  const onSave =
    ({ code, status, description, additional, accountId }: ITypeCodeAdditionalData) =>
    () => {
      if (!updateTypeCodeLink) return;
      if (!description || !additional) {
        setInvalidRecords((prev) => [...prev, code]);
      } else {
        if (status === NewTypeCodeStatuses.ignored) {
          const typeCodeBalance = typeCodes.find((x) => x.typeCode === code)?.balance || 0;
          ignoreTypeCodeRequest(updateTypeCodeLink, code, description, additional, typeCodeBalance);
        } else if (status === NewTypeCodeStatuses.mappedToNewAccount) {
          const typeCodeBalance = typeCodes.find((x) => x.typeCode === code)?.balance || 0;
          const existingAccount = accountId ? accounts.find((x) => x.id === accountId) : undefined;
          if (existingAccount) {
            mapTypeCodeToExistingAccountRequest(
              updateTypeCodeLink,
              createAdjustmentDefinitionLink,
              code,
              description,
              existingAccount,
              typeCodeBalance,
            );
          } else {
            mapTypeCodeToNewAccountRequest(
              updateTypeCodeLink,
              createAccountLink,
              createAdjustmentDefinitionLink,
              code,
              description,
              additional,
              typeCodeBalance,
            );
          }
        } else if (status === NewTypeCodeStatuses.mappedToExistingAccount) {
          const typeCodeBalance = typeCodes.find((x) => x.typeCode === code)?.balance || 0;
          const existingAccount = accountId ? accounts.find((x) => x.id === accountId) : undefined;
          if (existingAccount) {
            mapTypeCodeToExistingAccountRequest(
              updateTypeCodeLink,
              createAdjustmentDefinitionLink,
              code,
              description,
              existingAccount,
              typeCodeBalance,
            );
          } else {
            toast.error('Account has been not selected');
          }
        } else {
          toast.error('This action is not supported yet');
        }
      }
    };

  const onCancel = (record: ITypeCodeAdditionalData) => () => {
    setAdditionalDataRecords((prev) => prev.filter((x) => x.code !== record.code));
  };

  const onEdit =
    ({ code, status, description, additional, accountId }: INewTypeCode) =>
    () => {
      setAdditionalDataRecords((prev) => [
        ...prev,
        { code, status, description, additional, accountId },
      ]);
    };

  const onChangeDescription = (code: string, value: string) => {
    setInvalidRecords((prev) => prev.filter((x) => x !== code));
    setAdditionalDataRecords((prev) =>
      prev.map((x) => (x.code === code ? { ...x, description: value } : x)),
    );
  };

  const onChangeAdditional = (code: string, value: string) => {
    setInvalidRecords((prev) => prev.filter((x) => x !== code));
    setAdditionalDataRecords((prev) =>
      prev.map((x) => (x.code === code ? { ...x, additional: value } : x)),
    );
  };

  const onChangeAccountId = (code: string, value?: string) => {
    setInvalidRecords((prev) => prev.filter((x) => x !== code));
    setAdditionalDataRecords((prev) =>
      prev.map((x) => (x.code === code ? { ...x, accountId: value } : x)),
    );
  };

  const onEditAccount = (code: string, accountId: string | null) => {
    setInvalidRecords((prev) => prev.filter((x) => x !== code));
    setSelectedCode(code);
    setSelectedExistingAccount(accountId);
    setOpenAccountsDialog(true);
  };

  const statusOptionsWithoutNone = useMemo(
    () => [
      {
        value: NewTypeCodeStatuses.ignored,
        label: 'Do Not Use in Simulation',
      },
      {
        value: NewTypeCodeStatuses.mappedToNewAccount,
        label: 'Create New Account',
      },
      {
        value: NewTypeCodeStatuses.mappedToExistingAccount,
        label: 'Map to Existing Account',
      },
    ],
    [],
  );

  const statusOptionsWithNone = useMemo(
    () => [
      {
        value: NewTypeCodeStatuses.none,
        label: 'None',
      },
      ...statusOptionsWithoutNone,
    ],
    [statusOptionsWithoutNone],
  );

  const existingAccountOptions = useMemo<IAccountOption[]>(() => {
    const accountsWithoutTierGroup = accounts
      .filter((x) => x.accountType.type === type)
      .filter((x) => !x.isNew && !x.tierGroup)
      .map((x) => ({
        id: x.id,
        title: x.accountName,
      }));

    const tierGroupsArray: IAccount[] = accounts
      .filter((x) => x.accountType.type === type)
      .filter((x) => !x.isNew && x.tierGroup);

    const accountsByTierGroup = tierGroupsArray.reduce<Record<string, IAccount[]>>(
      (acc, currentAccount) => {
        const groupName = currentAccount.tierGroup;
        if (!acc[groupName]) {
          acc[groupName] = [];
        }
        acc[groupName].push(currentAccount);
        return acc;
      },
      {},
    );

    const tierGroupOptions = Object.entries(accountsByTierGroup).map(
      ([groupName, groupedAccounts]) => ({
        id: groupedAccounts[0].id,
        title: groupName,
        accounts: groupedAccounts.map((acc) => ({
          id: acc.id,
          title: acc.accountName,
        })),
      }),
    );
    const options = [...accountsWithoutTierGroup, ...tierGroupOptions];
    options.sort((a, b) => a.title.localeCompare(b.title, undefined, { sensitivity: 'base' }));
    return options;
  }, [accounts, type]);

  const newAccountOptions = useMemo<IAutocompleteOption[]>(() => {
    const options = accounts
      .filter((x) => x.accountType.type === type)
      .filter((x) => x.isNew)
      .map((x) => ({
        value: x.id,
        title: x.accountName,
      }));
    options.sort((a, b) => a.title.localeCompare(b.title, undefined, { sensitivity: 'base' }));
    return options;
  }, [accounts]);

  const getTypeCodeDescription = useCallback(
    (code: string) => {
      const record = additionalDataRecords.find((x) => x.code === code);
      return record?.description;
    },
    [additionalDataRecords],
  );

  const columns = useMemo<GridColDef[]>(() => {
    return [
      {
        field: 'code',
        headerName: 'Type Code',
        type: 'string',
        flex: 2,
        renderCell: (params) => {
          if (params.rowNode.type === 'pinnedRow') return params.value;
          const value = params.value;
          return (
            <Box className={classes.flexCell}>
              {getSamplesLink && (
                <Tooltip title='Show data samples'>
                  <IconButton onClick={() => onShowSamples(value)}>
                    <img src={InfoIcon} alt='info' />
                  </IconButton>
                </Tooltip>
              )}
              {value}
            </Box>
          );
        },
      },
      {
        field: 'status',
        headerName: 'Action',
        type: 'string',
        flex: 2,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          const record = additionalDataRecords.find((x) => x.code === params.id);
          const storedStatus = params.value;
          if (!record && storedStatus !== NewTypeCodeStatuses.none) {
            return statusOptionsWithoutNone.find((x) => x.value === params.value)?.label;
          }

          const status = record?.status || NewTypeCodeStatuses.none;
          return (
            <Dropdown
              value={status}
              onChanged={(value) =>
                onStatusChanged(params.id.toString(), value as NewTypeCodeStatuses)
              }
              options={
                storedStatus !== NewTypeCodeStatuses.none
                  ? statusOptionsWithoutNone
                  : statusOptionsWithNone
              }
            />
          );
        },
      },
      {
        field: 'description',
        headerName: 'Type Code Description',
        type: 'string',
        flex: 2,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          const record = additionalDataRecords.find((x) => x.code === params.id);
          if (!record) return params.value;

          const status = record.status || NewTypeCodeStatuses.none;
          const value = record.description || '';
          const invalid = !value && invalidRecords.includes(record.code);

          if (!record) return null;
          if (status === NewTypeCodeStatuses.none) return value;
          if (
            status === NewTypeCodeStatuses.ignored ||
            status === NewTypeCodeStatuses.mappedToNewAccount ||
            status === NewTypeCodeStatuses.mappedToExistingAccount
          ) {
            return (
              <TextField
                value={value}
                size='small'
                variant='outlined'
                placeholder={invalid ? '' : 'Description'}
                error={invalid}
                helperText={invalid ? 'Description is required' : ''}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                onChange={(evt) => onChangeDescription(record.code, evt.target.value)}
                onKeyDown={(evt) => {
                  evt.stopPropagation();
                }}
              />
            );
          }
        },
      },
      {
        field: 'additional',
        headerName: 'Additional Information',
        type: 'string',
        flex: 3,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          const record = additionalDataRecords.find((x) => x.code === params.id);
          if (!record) return params.value;

          const status = record.status || NewTypeCodeStatuses.none;
          const additional = record.additional || '';
          const accountId = record.accountId;
          const invalid = !additional && invalidRecords.includes(record.code);

          if (status === NewTypeCodeStatuses.none) return additional;
          if (status === NewTypeCodeStatuses.ignored) {
            return (
              <TextField
                value={additional}
                size='small'
                variant='outlined'
                placeholder={invalid ? '' : 'Reason for ignoring'}
                error={invalid}
                helperText={invalid ? 'Reason is required' : ''}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                onChange={(evt) => onChangeAdditional(record.code, evt.target.value)}
                onKeyDown={(evt) => {
                  evt.stopPropagation();
                }}
              />
            );
          } else if (status === NewTypeCodeStatuses.mappedToNewAccount) {
            const selectedAccountOption =
              newAccountOptions.find((x) => x.value === accountId) || null;

            return (
              <CustomAutocomplete
                key={`new-account-${record.code}`}
                allowCustomOptions
                fullWidth
                value={selectedAccountOption}
                size='small'
                variant='outlined'
                placeholder={invalid ? '' : 'New account name'}
                error={invalid}
                helperText={invalid ? 'Account name is required' : ''}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                onChange={(value) => {
                  onChangeAdditional(record.code, value?.title || '');
                  onChangeAccountId(
                    record.code,
                    value ? (value.custom ? undefined : value.value) : undefined,
                  );
                }}
                onKeyDown={(evt) => {
                  evt.stopPropagation();
                }}
                options={newAccountOptions}
                clearIcon={<img src={XIcon} alt='Clear' />}
              />
            );
          } else if (status === NewTypeCodeStatuses.mappedToExistingAccount) {
            const selectedAccountOption =
              existingAccountOptions.find(
                (x) => x.id === accountId || x.accounts?.some((a) => a.id === accountId),
              ) || null;

            if (selectedAccountOption) {
              return (
                <Box className={classes.flexCell}>
                  <IconButton
                    onClick={() => onEditAccount(record.code, selectedAccountOption?.id || null)}
                  >
                    <img src={EditIcon} alt='edit' />
                  </IconButton>
                  <Box>{selectedAccountOption.title}</Box>
                </Box>
              );
            } else {
              return (
                <Box
                  className={clsx([classes.flexCell, invalid && classes.invalidCell])}
                  onClick={() => onEditAccount(record.code, null)}
                >
                  <IconButton>
                    <img src={PlusIcon} alt='+' />
                  </IconButton>
                  <Typography variant='subtitle1'>Select Account</Typography>
                </Box>
              );
            }
          }
        },
      },
      {
        field: 'balance',
        headerName: 'Balance',
        type: 'number',
        width: 120,
        renderCell: (params) => formatBalance(params.value || 0),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'string',
        width: 70,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          if (params.rowNode.type === 'pinnedRow') return null;

          const record = additionalDataRecords.find((x) => x.code === params.id);
          const status = record?.status || NewTypeCodeStatuses.none;
          const storedStatus = params.row.status;
          if (!record || status === NewTypeCodeStatuses.none) {
            if (storedStatus === NewTypeCodeStatuses.none) {
              return null;
            } else {
              return [
                <GridActionsCellItem
                  key={`${params.row.code}-edit`}
                  icon={<img src={EditIcon} alt='Edit' />}
                  label='Edit'
                  onClick={onEdit(params.row)}
                />,
              ];
            }
          }
          return [
            <GridActionsCellItem
              key={`${record.code}-save`}
              icon={<img src={CheckIcon} alt='Check' />}
              label='Accept changes'
              onClick={onSave(record)}
            />,
            <GridActionsCellItem
              key={`${record.code}-cancel`}
              icon={<img src={XCircleRedIcon} alt='Cancel' />}
              label='Cancel'
              onClick={onCancel(record)}
            />,
          ];
        },
      },
    ] as GridColDef<INewTypeCode>[];
  }, [additionalDataRecords, invalidRecords, typeCodes, getSamplesLink, existingAccountOptions]);

  useEffect(() => {
    if (getTypeCodesLink) {
      getTypeCodesRequest(getTypeCodesLink, undefined, type, true);
    }
  }, [getTypeCodesLink, type]);

  useEffect(() => {
    reloadAdjustmentDefinitions(getAdjustmentDefinitionsLink, type);
  }, [getAdjustmentDefinitionsLink, type]);

  useUpdateEffect(() => {
    if (getAdjustmentDefinitionsData) {
      setInitialAdjustmentDefinitions(getAdjustmentDefinitionsData.items);
    }
  }, [getAdjustmentDefinitionsData]);

  useUpdateEffect(() => {
    if (getTypeCodesData) {
      setTypeCodes(getTypeCodesData.items);
    }
  }, [getTypeCodesData]);

  const showLoader = useLoader(
    getTypeCodesLoading,
    getAdjustmentDefinitionsLoading,
    ignoreTypeCodeLoading,
    mapTypeCodeToNewAccountLoading,
    mapTypeCodeToExistingAccountLoading,
  );

  return (
    <>
      <Box className={classes.root}>
        <DataGridPremium
          rows={newTypeCodeItems}
          density='compact'
          columns={columns}
          className={clsx([classes.table, !isTableExpanded && classes.limitedHeightTable])}
          initialState={{
            sorting: {
              sortModel: [{ field: 'code', sort: 'asc' }],
            },
            aggregation: {
              model: {
                balance: 'sum',
              },
            },
          }}
          getRowId={(row) => row.code}
          slots={{
            footer: () => (
              <StandardTableFooter
                showTableExpandSwitch={newTypeCodeItems.length > 10}
                isTableExpanded={isTableExpanded}
                onToggleTableExpand={onToggleTableExpand}
              />
            ),
          }}
        />
      </Box>
      <Loader show={showLoader} fixed={false} />

      {getSamplesLink && selectedCode && (
        <PreviewSamplesDialog
          open={openSamplesDialog}
          onClose={onCloseSamplesDialog}
          type={type}
          getSamplesLink={getSamplesLink}
          code={selectedCode}
        />
      )}

      {selectedCode && (
        <AccountsDialog
          open={openAccountsDialog}
          onClose={onCloseAccountsDialog}
          title='Select Account'
          subtitle={selectedCode}
          secondSubtitle={getTypeCodeDescription(selectedCode)}
          accounts={existingAccountOptions}
          selectedAccountId={selectedExistingAccount}
        />
      )}
    </>
  );
};
