import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    position: 'relative',
  },
  table: {
    backgroundColor: t.palette.custom.white[100],

    '& .MuiDataGrid-aggregationColumnHeaderLabel': {
      display: 'none',
    },

    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600,
    },

    '& .MuiDataGrid-pinnedRows': {
      fontWeight: 600,
      '& div[data-field="accountName"]': {
        '&::after': {
          content: '"Filtered Total"',
          display: 'block',
        },
      },
    },
  },
  limitedHeightTable: {
    maxHeight: 480,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
}));

export default useStyles;
