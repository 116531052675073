import {
  IUpdateSubAccountsData,
  IUpdateSubAccountsDataDMAccount,
  IUpdateSubAccountsDataDMAccountAdjustment,
  IUpdateSubAccountsDataGLAccount,
  IUpdateSubAccountsDataSubAccount,
  IUpdateSubAccountsDataSubAccountTierLimit,
} from '@models/interfaces/additional/IUpdateSubAccountsData';
import {
  IUpdateSubAccountsRequestDMAccount,
  IUpdateSubAccountsRequestDMAccountAdjustment,
  IUpdateSubAccountsRequestGLAccount,
  IUpdateSubAccountsRequestSubAccount,
  IUpdateSubAccountsRequestSubAccountTierLimit,
} from '@models/interfaces/requests/IUpdateSubAccountsRequest';
import { Mapper, createMap, forMember, mapFrom } from '@automapper/core';

import { IJsonDMAccountAdjustmentData } from '@models/interfaces/additional/IJsonDMAccountAdjustmentData';
import { IJsonDMAccountData } from '@models/interfaces/additional/IJsonDMAccountData';
import { IJsonGLAccountData } from '@models/interfaces/additional/IJsonGLAccountData';
import { IJsonGLSubAccountsData } from '@models/interfaces/additional/IJsonGLSubAccountsData';
import { PojosMetadataMap } from '@automapper/pojos';

export const createImportSubAccountsMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IJsonGLAccountData>('IJsonGLAccountData', {
    sSubAcctID: String,
    sSubAcctDesc: String,
    sSubAcctIDFormat: String,
    sDownloadID: String,
    sAccountName: String,
    nAccountType: Number,
    bDownloaded: Boolean,
    sFilename: String,
    dBalance: Number,
    dAvgBalance: Number,
    dIncome: Number,
    bCalcYield: Boolean,
    nOperation: Number,
    dBalAdjust: Number,
    dRatio: Number,
    bReverseBalance: Boolean,
    sAcctsCompBalance: [String],
    sAcctsPushBalancing: [String],
    sAcctsPushDeferred: [String],
    sAcctsPushYield: [String],
  });

  PojosMetadataMap.create<IUpdateSubAccountsDataGLAccount>('IUpdateSubAccountsDataGLAccount', {
    subAcctId: String,
    subAcctDesc: String,
    subAcctIdFormat: String,
    downloadId: String,
    ignored: Boolean,
    accountName: String,
    accountType: Number,
    downloaded: Boolean,
    filename: String,
    balance: Number,
    avgBalance: Number,
    income: Number,
    calcYield: Boolean,
    operation: Number,
    balAdjust: Number,
    ratio: Number,
    reverseBalance: Boolean,
    acctsCompBalance: [String],
    acctsPushBalancing: [String],
    acctsPushDeferred: [String],
    acctsPushYield: [String],
  });

  PojosMetadataMap.create<IUpdateSubAccountsRequestGLAccount>(
    'IUpdateSubAccountsRequestGLAccount',
    {
      subAcctId: String,
      subAcctDesc: String,
      subAcctIdFormat: String,
      downloadId: String,
      ignored: Boolean,
      accountName: String,
      accountType: Number,
      downloaded: Boolean,
      filename: String,
      balance: Number,
      avgBalance: Number,
      income: Number,
      calcYield: Boolean,
      operation: Number,
      balAdjust: Number,
      ratio: Number,
      reverseBalance: Boolean,
      acctsCompBalance: [String],
      acctsPushBalancing: [String],
      acctsPushDeferred: [String],
      acctsPushYield: [String],
    },
  );

  createMap<IJsonGLAccountData, IUpdateSubAccountsDataGLAccount>(
    mapper,
    'IJsonGLAccountData',
    'IUpdateSubAccountsDataGLAccount',
    forMember(
      (dest) => dest.subAcctId,
      mapFrom((src) => src.sSubAcctID),
    ),
    forMember(
      (dest) => dest.subAcctDesc,
      mapFrom((src) => src.sSubAcctDesc),
    ),
    forMember(
      (dest) => dest.subAcctIdFormat,
      mapFrom((src) => src.sSubAcctIDFormat),
    ),
    forMember(
      (dest) => dest.downloadId,
      mapFrom((src) => (src.sDownloadID.toUpperCase() === 'IGNORE' ? '' : src.sDownloadID)),
    ),
    forMember(
      (dest) => dest.ignored,
      mapFrom((src) => src.sDownloadID.toUpperCase() === 'IGNORE'),
    ),
    forMember(
      (dest) => dest.accountName,
      mapFrom((src) => src.sAccountName),
    ),
    forMember(
      (dest) => dest.accountType,
      mapFrom((src) => src.nAccountType),
    ),
    forMember(
      (dest) => dest.downloaded,
      mapFrom((src) => src.bDownloaded),
    ),
    forMember(
      (dest) => dest.filename,
      mapFrom((src) => src.sFilename),
    ),
    forMember(
      (dest) => dest.balance,
      mapFrom((src) => src.dBalance),
    ),
    forMember(
      (dest) => dest.avgBalance,
      mapFrom((src) => src.dAvgBalance),
    ),
    forMember(
      (dest) => dest.income,
      mapFrom((src) => src.dIncome),
    ),
    forMember(
      (dest) => dest.calcYield,
      mapFrom((src) => src.bCalcYield),
    ),
    forMember(
      (dest) => dest.operation,
      mapFrom((src) => src.nOperation),
    ),
    forMember(
      (dest) => dest.balAdjust,
      mapFrom((src) => src.dBalAdjust),
    ),
    forMember(
      (dest) => dest.ratio,
      mapFrom((src) => src.dRatio),
    ),
    forMember(
      (dest) => dest.reverseBalance,
      mapFrom((src) => src.bReverseBalance || false),
    ),
    forMember(
      (dest) => dest.acctsCompBalance,
      mapFrom((src) => src.sAcctsCompBalance),
    ),
    forMember(
      (dest) => dest.acctsPushBalancing,
      mapFrom((src) => src.sAcctsPushBalancing),
    ),
    forMember(
      (dest) => dest.acctsPushDeferred,
      mapFrom((src) => src.sAcctsPushDeferred),
    ),
    forMember(
      (dest) => dest.acctsPushYield,
      mapFrom((src) => src.sAcctsPushYield),
    ),
  );

  createMap<IUpdateSubAccountsDataGLAccount, IUpdateSubAccountsRequestGLAccount>(
    mapper,
    'IUpdateSubAccountsDataGLAccount',
    'IUpdateSubAccountsRequestGLAccount',
  );

  PojosMetadataMap.create<IJsonDMAccountAdjustmentData>('IJsonDMAccountAdjustmentData', {
    sSource: String,
    sID: String,
    sDescription: String,
    dBalance: Number,
    dFees: Number,
  });

  PojosMetadataMap.create<IUpdateSubAccountsDataDMAccountAdjustment>(
    'IUpdateSubAccountsDataDMAccountAdjustment',
    {
      source: String,
      id: String,
      description: String,
      balance: Number,
      fees: Number,
    },
  );

  PojosMetadataMap.create<IUpdateSubAccountsRequestDMAccountAdjustment>(
    'IUpdateSubAccountsRequestDMAccountAdjustment',
    {
      source: String,
      id: String,
      description: String,
      balance: Number,
      fees: Number,
    },
  );

  createMap<IJsonDMAccountAdjustmentData, IUpdateSubAccountsDataDMAccountAdjustment>(
    mapper,
    'IJsonDMAccountAdjustmentData',
    'IUpdateSubAccountsDataDMAccountAdjustment',
    forMember(
      (dest) => dest.source,
      mapFrom((src) => src.sSource),
    ),
    forMember(
      (dest) => dest.id,
      mapFrom((src) => src.sID),
    ),
    forMember(
      (dest) => dest.description,
      mapFrom((src) => src.sDescription),
    ),
    forMember(
      (dest) => dest.balance,
      mapFrom((src) => src.dBalance),
    ),
    forMember(
      (dest) => dest.fees,
      mapFrom((src) => src.dFees),
    ),
  );

  createMap<
    IUpdateSubAccountsDataDMAccountAdjustment,
    IUpdateSubAccountsRequestDMAccountAdjustment
  >(
    mapper,
    'IUpdateSubAccountsDataDMAccountAdjustment',
    'IUpdateSubAccountsRequestDMAccountAdjustment',
  );

  PojosMetadataMap.create<IJsonDMAccountData>('IJsonDMAccountData', {
    sFileID: String,
    nVersion: Number,
    sAcctName: String,
    dInitBalance: Number,
    dManualAdj: Number,
    dAdjBal: Number,
    dFees: Number,
    dTotal: Number,
    dGLBal: Number,
    dYld: Number,
    nType: Number,
    dPushBal: Number,
    dRatio: Number,
    // eslint-disable-next-line camelcase
    m_Adjustments: ['IJsonDMAccountAdjustmentData'],
  });

  PojosMetadataMap.create<IUpdateSubAccountsDataDMAccount>('IUpdateSubAccountsDataDMAccount', {
    fileId: String,
    version: Number,
    acctName: String,
    initBalance: Number,
    manualAdj: Number,
    adjBal: Number,
    fees: Number,
    total: Number,
    glBal: Number,
    yld: Number,
    type: Number,
    pushBal: Number,
    ratio: Number,
    adjustments: ['IUpdateSubAccountsDataDMAccountAdjustment'],
  });

  PojosMetadataMap.create<IUpdateSubAccountsRequestDMAccount>(
    'IUpdateSubAccountsRequestDMAccount',
    {
      fileId: String,
      version: Number,
      acctName: String,
      initBalance: Number,
      manualAdj: Number,
      adjBal: Number,
      fees: Number,
      total: Number,
      glBal: Number,
      yld: Number,
      type: Number,
      pushBal: Number,
      ratio: Number,
      adjustments: ['IUpdateSubAccountsRequestDMAccountAdjustment'],
    },
  );

  createMap<IJsonDMAccountData, IUpdateSubAccountsDataDMAccount>(
    mapper,
    'IJsonDMAccountData',
    'IUpdateSubAccountsDataDMAccount',
    forMember(
      (dest) => dest.fileId,
      mapFrom((src) => src.sFileID),
    ),
    forMember(
      (dest) => dest.version,
      mapFrom((src) => src.nVersion),
    ),
    forMember(
      (dest) => dest.acctName,
      mapFrom((src) => src.sAcctName),
    ),
    forMember(
      (dest) => dest.initBalance,
      mapFrom((src) => src.dInitBalance),
    ),
    forMember(
      (dest) => dest.manualAdj,
      mapFrom((src) => src.dManualAdj),
    ),
    forMember(
      (dest) => dest.adjBal,
      mapFrom((src) => src.dAdjBal),
    ),
    forMember(
      (dest) => dest.fees,
      mapFrom((src) => src.dFees),
    ),
    forMember(
      (dest) => dest.total,
      mapFrom((src) => src.dTotal),
    ),
    forMember(
      (dest) => dest.glBal,
      mapFrom((src) => src.dGLBal),
    ),
    forMember(
      (dest) => dest.yld,
      mapFrom((src) => src.dYld),
    ),
    forMember(
      (dest) => dest.type,
      mapFrom((src) => src.nType),
    ),
    forMember(
      (dest) => dest.pushBal,
      mapFrom((src) => src.dPushBal),
    ),
    forMember(
      (dest) => dest.ratio,
      mapFrom((src) => src.dRatio),
    ),
    forMember(
      (dest) => dest.adjustments,
      mapFrom((src) =>
        src.m_Adjustments.map((adjustment) =>
          mapper.map(
            adjustment,
            'IJsonDMAccountAdjustmentData',
            'IUpdateSubAccountsDataDMAccountAdjustment',
          ),
        ),
      ),
    ),
  );

  createMap<IUpdateSubAccountsDataDMAccount, IUpdateSubAccountsRequestDMAccount>(
    mapper,
    'IUpdateSubAccountsDataDMAccount',
    'IUpdateSubAccountsRequestDMAccount',
    forMember(
      (dest) => dest.adjustments,
      mapFrom((src) =>
        src.adjustments.map((adjustment) =>
          mapper.map(
            adjustment,
            'IUpdateSubAccountsDataDMAccountAdjustment',
            'IUpdateSubAccountsRequestDMAccountAdjustment',
          ),
        ),
      ),
    ),
  );

  PojosMetadataMap.create<IUpdateSubAccountsDataSubAccount>('IUpdateSubAccountsDataSubAccount', {
    token: String,
    subAcctId: String,
    accountType: Number,
    tierLimits: ['IUpdateSubAccountsDataSubAccountTierLimit'],
    subAcctDesc: String,
    downloadId: String,
    subAccountIdFormat: String,
    accountName: String,
    ignored: Boolean,
    category: String,
  });

  PojosMetadataMap.create<IUpdateSubAccountsRequestSubAccount>(
    'IUpdateSubAccountsRequestSubAccount',
    {
      token: String,
      subAcctId: String,
      accountType: Number,
      tierLimits: ['IUpdateSubAccountsRequestSubAccountTierLimit'],
      subAcctDesc: String,
      downloadId: String,
      subAccountIdFormat: String,
      accountName: String,
      ignored: Boolean,
      category: String,
    },
  );

  createMap<IUpdateSubAccountsDataSubAccount, IUpdateSubAccountsRequestSubAccount>(
    mapper,
    'IUpdateSubAccountsDataSubAccount',
    'IUpdateSubAccountsRequestSubAccount',
    forMember(
      (dest) => dest.tierLimits,
      mapFrom((src) =>
        src.tierLimits.map((limit) =>
          mapper.map(
            limit,
            'IUpdateSubAccountsDataSubAccountTierLimit',
            'IUpdateSubAccountsRequestSubAccountTierLimit',
          ),
        ),
      ),
    ),
  );

  PojosMetadataMap.create<IUpdateSubAccountsDataSubAccountTierLimit>(
    'IUpdateSubAccountsDataSubAccountTierLimit',
    {
      name: String,
      lower: Number,
      upper: Number,
    },
  );

  PojosMetadataMap.create<IUpdateSubAccountsRequestSubAccountTierLimit>(
    'IUpdateSubAccountsRequestSubAccountTierLimit',
    {
      name: String,
      lower: Number,
      upper: Number,
    },
  );

  createMap<
    IUpdateSubAccountsDataSubAccountTierLimit,
    IUpdateSubAccountsRequestSubAccountTierLimit
  >(
    mapper,
    'IUpdateSubAccountsDataSubAccountTierLimit',
    'IUpdateSubAccountsRequestSubAccountTierLimit',
  );

  PojosMetadataMap.create<IJsonGLSubAccountsData>('IJsonGLSubAccountsData', {
    Accounts: ['IJsonGLAccountData'],
    GLTotalAccounts: ['IJsonGLAccountData'],
    DMAccounts: ['IJsonDMAccountData'],
    TotalAccounts: ['IJsonDMAccountData'],
    sLoanPushBalancing: [String],
    sInvestmentPushBalancing: [String],
    sAssetPushBalancing: [String],
    sSharePushBalancing: [String],
    sCDPushBalancing: [String],
    sDepositsPushBalancing: [String],
    sBorrowingPushBalancing: [String],
    sLiabilityPushBalancing: [String],
  });

  createMap<IJsonGLSubAccountsData, IUpdateSubAccountsData>(
    mapper,
    'IJsonGLSubAccountsData',
    'IUpdateSubAccountsData',
    forMember(
      (dest) => dest.GLAccounts,
      mapFrom((src) =>
        src.Accounts.map((account) =>
          mapper.map(account, 'IJsonGLAccountData', 'IUpdateSubAccountsDataGLAccount'),
        ),
      ),
    ),
    forMember(
      (dest) => dest.GLTotalAccounts,
      mapFrom((src) =>
        src.GLTotalAccounts.map((account) =>
          mapper.map(account, 'IJsonGLAccountData', 'IUpdateSubAccountsDataGLAccount'),
        ),
      ),
    ),
    forMember(
      (dest) => dest.DMAccounts,
      mapFrom((src) =>
        src.DMAccounts.map((account) =>
          mapper.map(account, 'IJsonDMAccountData', 'IUpdateSubAccountsDataDMAccount'),
        ),
      ),
    ),
    forMember(
      (dest) => dest.DMTotalAccounts,
      mapFrom((src) =>
        src.TotalAccounts.map((account) =>
          mapper.map(account, 'IJsonDMAccountData', 'IUpdateSubAccountsDataDMAccount'),
        ),
      ),
    ),
    forMember(
      (dest) => dest.LoanPushBalancing,
      mapFrom((src) => src.sLoanPushBalancing),
    ),
    forMember(
      (dest) => dest.InvestmentPushBalancing,
      mapFrom((src) => src.sInvestmentPushBalancing),
    ),
    forMember(
      (dest) => dest.AssetPushBalancing,
      mapFrom((src) => src.sAssetPushBalancing),
    ),
    forMember(
      (dest) => dest.SharePushBalancing,
      mapFrom((src) => src.sSharePushBalancing),
    ),
    forMember(
      (dest) => dest.CDPushBalancing,
      mapFrom((src) => src.sCDPushBalancing),
    ),
    forMember(
      (dest) => dest.DepositsPushBalancing,
      mapFrom((src) => src.sDepositsPushBalancing),
    ),
    forMember(
      (dest) => dest.BorrowingPushBalancing,
      mapFrom((src) => src.sBorrowingPushBalancing),
    ),
    forMember(
      (dest) => dest.LiabilityPushBalancing,
      mapFrom((src) => src.sLiabilityPushBalancing),
    ),
    forMember(
      (dest) => dest.SubAccounts,
      mapFrom(() => []),
    ),
  );
};
