import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Pagination,
  TextField,
  Typography,
} from '@mui/material';
import { SearchInput, SearchInputColors } from '@components/SearchInput';
import {
  closeProject,
  createProject,
  deleteProject,
  getClient,
  getClientFeatures,
  getClientProduct,
  getProjects,
  reopenProject,
  restartProject,
  startProject,
  updateProject,
} from '@services/api';
import { selectAuthFeatures, selectAuthProducts } from '@reducers/authSlice';
import { useApi, useAppSelector, useConfirm, useInput, useLoader, useUpdateEffect } from '@hooks';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Actions } from '@models/enums/Actions';
import CalendarIcon from '@assets/icons/date-picker/calendar.svg';
import { CreateProjectDialog } from './components/CreateProjectDialog';
import { CustomDateRangePicker } from '@components/CustomDateRangePicker';
import { DashboardBreadcrumbs } from '@components/DashboardBreadcrumbs';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dropdown } from '@components/Dropdown';
import { EditProjectDialog } from './components/EditProjectDialog';
import { ExternalResources } from '@components/ExternalResources';
import { Features } from '@models/enums/Features';
import { GuideTour } from '@components/GuideTour';
import { IClient } from '@models/interfaces/entities/IClient';
import { IClientFeature } from '@models/interfaces/entities/IClientFeature';
import { ICreateProjectData } from '@models/interfaces/additional/ICreateProjectData';
import { IProject } from '@models/interfaces/entities/IProject';
import { IUpdateProjectData } from '@models/interfaces/additional/IUpdateProjectData';
import { IssuesAlert } from '@components/IssuesAlert';
import { Loader } from '@components/Loader';
import PlusFilledIcon from '@assets/icons/item-view/plus-filled.svg';
import { ProjectCard } from './components/ProjectCard';
import { ProjectStatuses } from '@models/enums/ProjectStatuses';
import { Step } from 'react-joyride';
import XCircleIcon from '@assets/icons/date-picker/x-circle.svg';
import { clsx } from 'clsx';
import moment from 'moment';
import { toast } from 'react-toastify';
import useStyles from './styles';

export const statusOrder: { [key in ProjectStatuses]: number } = {
  [ProjectStatuses.open]: 0,
  [ProjectStatuses.inProgress]: 1,
  [ProjectStatuses.closed]: 2,
  [ProjectStatuses.inactive]: 3,
};

export const ProjectsPage = () => {
  const { classes } = useStyles();
  const { clientId, productId } = useParams();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const searchInput = useInput<string>('');
  const [client, setClient] = useState<IClient>();
  const features = useAppSelector(selectAuthFeatures);
  const products = useAppSelector(selectAuthProducts);
  const [clientFeatures, setClientFeatures] = useState<IClientFeature[]>([]);
  const [selectedProject, setSelectedProject] = useState<IProject | null>(null);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [dateRange, setDateRange] = useState<DateRange<Date | null>>([null, null]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(12);

  const {
    request: getClientFeaturesRequest,
    data: getClientFeaturesData,
    loading: getClientFeaturesLoading,
  } = useApi(getClientFeatures, null, { handleErrors: true });

  const {
    request: getClientProductRequest,
    data: getClientProductData,
    loading: getClientProductLoading,
  } = useApi(getClientProduct, null, { handleErrors: true });

  const {
    request: getClientRequest,
    data: getClientData,
    loading: getClientLoading,
  } = useApi(getClient, null, { handleErrors: true });

  const {
    request: getProjectsRequest,
    data: getProjectsData,
    loading: getProjectsLoading,
  } = useApi(getProjects, null, { handleErrors: true });

  const {
    request: startProjectRequest,
    data: startProjectData,
    loading: startProjectLoading,
  } = useApi(startProject, null, { handleErrors: true });

  const {
    request: closeProjectRequest,
    data: closeProjectData,
    loading: closeProjectLoading,
  } = useApi(closeProject, null, { handleErrors: true });

  const {
    request: restartProjectRequest,
    data: restartProjectData,
    loading: restartProjectLoading,
  } = useApi(restartProject, null, { handleErrors: true });

  const {
    request: reopenProjectRequest,
    data: reopenProjectData,
    loading: reopenProjectLoading,
  } = useApi(reopenProject, null, { handleErrors: true });

  const { request: updateProjectRequest, loading: updateProjectLoading } = useApi(
    async (
      url: string,
      data: IUpdateProjectData,
      callback?: (project: IProject) => Promise<void>,
    ) => {
      const result = await updateProject(url, data);

      setProjects((projects) => projects.map((x) => (x.id !== result.id ? x : result)));
      setSelectedProject(null);

      if (callback) {
        await callback(result);
      }
    },
    null,
    { handleErrors: true },
  );

  const { request: deleteProjectRequest, loading: deleteProjectLoading } = useApi(
    deleteProject,
    null,
    {
      handleErrors: true,
      onCallback: () => {
        toast.info('Successfully deleted the project');
        if (getClientProductData?.links[Actions.getProjects]?.href) {
          getProjectsRequest(getClientProductData.links[Actions.getProjects].href);
        }
      },
    },
  );

  const { request: createProjectRequest, loading: createProjectLoading } = useApi(
    async (
      url: string,
      data: ICreateProjectData,
      callback?: (project: IProject) => Promise<void>,
    ) => {
      const result = await createProject(url, data);

      toast.info('Successfully added the project');
      setOpenCreateDialog(false);
      if (getClientProductData?.links[Actions.getProjects]?.href) {
        getProjectsRequest(getClientProductData.links[Actions.getProjects].href);
      }

      if (callback) {
        await callback(result);
      }
    },
    null,
    {
      handleErrors: true,
    },
  );

  useEffect(() => {
    if (clientId) {
      getClientRequest(`/clients/${clientId}`);
    }
  }, [clientId]);

  useUpdateEffect(() => {
    if (getClientData) {
      setClient(getClientData);
      if (getClientData.links[Actions.getProducts]) {
        getClientProductRequest(`${getClientData.links[Actions.getProducts].href}/${productId}`);
      }
      if (getClientData.links[Actions.getFeatures]) {
        getClientFeaturesRequest(getClientData.links[Actions.getFeatures].href);
      }
    }
  }, [getClientData]);

  useUpdateEffect(() => {
    if (getClientProductData?.links[Actions.getProjects]?.href) {
      getProjectsRequest(getClientProductData.links[Actions.getProjects].href);
    }
  }, [getClientProductData]);

  useUpdateEffect(() => {
    if (startProjectData) {
      setProjects((projects) =>
        projects.map((x) => (x.id !== startProjectData.id ? x : startProjectData)),
      );
    }
  }, [startProjectData]);

  useUpdateEffect(() => {
    if (closeProjectData) {
      setProjects((projects) =>
        projects.map((x) => (x.id !== closeProjectData.id ? x : closeProjectData)),
      );
    }
  }, [closeProjectData]);

  useUpdateEffect(() => {
    if (restartProjectData) {
      setProjects((projects) =>
        projects.map((x) => (x.id !== restartProjectData.id ? x : restartProjectData)),
      );
    }
  }, [restartProjectData]);

  useUpdateEffect(() => {
    if (reopenProjectData) {
      setProjects((projects) =>
        projects.map((x) => (x.id !== reopenProjectData.id ? x : reopenProjectData)),
      );
    }
  }, [reopenProjectData]);

  useUpdateEffect(() => {
    if (getProjectsData) {
      setProjects(getProjectsData.items);
    }
  }, [getProjectsData]);

  useUpdateEffect(() => {
    if (getClientFeaturesData) {
      setClientFeatures(getClientFeaturesData);
    }
  }, [getClientFeaturesData]);

  const onAddProject = () => {
    setOpenCreateDialog(true);
  };

  const onProjectSelected = (item: IProject) => {
    navigate(`/clients/${clientId}/products/${productId}/projects/${item.id}`);
  };

  const onStartProject = async (item: IProject) => {
    if (item.links[Actions.start]?.href) {
      await confirm({
        title: 'Start project',
        description: 'Are you sure that you want to start this project?',
        confirmationText: 'Confirm',
      });
      startProjectRequest(item.links[Actions.start].href);
    }
  };

  const onCloseProject = async (item: IProject) => {
    if (item.links[Actions.close]?.href) {
      await confirm({
        title: 'Close project',
        description: 'Are you sure that you want to close this project?',
        confirmationText: 'Confirm',
      });
      closeProjectRequest(item.links[Actions.close].href);
    }
  };

  const onRestartProject = async (item: IProject) => {
    if (item.links[Actions.restart]?.href) {
      await confirm({
        title: 'Restart project',
        description: 'Are you sure that you want to restart this project?',
        confirmationText: 'Confirm',
      });
      restartProjectRequest(item.links[Actions.restart].href);
    }
  };

  const onReopenProject = async (item: IProject) => {
    if (item.links[Actions.reopen]?.href) {
      await confirm({
        title: 'Re-open project',
        description: 'Are you sure that you want to re-open this project?',
        confirmationText: 'Confirm',
      });
      reopenProjectRequest(item.links[Actions.reopen].href);
    }
  };

  const onDeleteProject = async (item: IProject) => {
    if (item.links[Actions.delete]?.href) {
      await confirm({
        title: 'Delete project',
        description: 'Are you sure that you want to delete this project?',
        confirmationText: 'Confirm',
      });
      deleteProjectRequest(item.links[Actions.delete].href);
    }
  };

  const onEditProject = async (item: IProject) => {
    if (item.links[Actions.update]?.href) {
      setSelectedProject(item);
    }
  };

  const onCloseCreateDialog = async (
    data?: ICreateProjectData,
    callback?: (project: IProject) => Promise<void>,
  ) => {
    if (!data) {
      setOpenCreateDialog(false);
    } else {
      const url = getProjectsData?.links[Actions.createProject]?.href;
      if (url) {
        createProjectRequest(url, data, callback);
      }
    }
  };

  const onCloseEditDialog = async (
    data?: IUpdateProjectData,
    callback?: (project: IProject) => Promise<void>,
  ) => {
    if (!data) {
      setSelectedProject(null);
    } else {
      const url = selectedProject?.links[Actions.update]?.href;
      if (url) {
        updateProjectRequest(url, data, callback);
      }
    }
  };

  const onPageChanged = (page: number) => {
    setPage(page);
  };

  const filteredItems = useMemo(() => {
    const regexp = new RegExp(searchInput.value, 'i');
    let filtered = regexp ? projects.filter((x) => regexp.test(x.name)) : projects;
    if (dateRange[0] && dateRange[1]) {
      filtered = projects.filter(
        (x) =>
          dateRange[0] &&
          dateRange[1] &&
          x.startDate >= dateRange[0] &&
          x.startDate <= dateRange[1],
      );
    }

    return filtered.sort((a, b) => {
      if (a.status === b.status) {
        return b.startDate.getTime() - a.startDate.getTime();
      } else {
        return statusOrder[a.status] - statusOrder[b.status];
      }
    });
  }, [projects, searchInput.value, dateRange]);

  const pages = useMemo(
    () => Math.ceil(filteredItems.length / perPage),
    [filteredItems.length, perPage],
  );

  useUpdateEffect(() => {
    setPage(1);
  }, [filteredItems]);

  const pageItems = useMemo(() => {
    const startIndex = (page - 1) * perPage;
    const endIndex = page * perPage - 1;
    return filteredItems.filter((_, i) => i >= startIndex && i <= endIndex);
  }, [filteredItems, page, perPage]);

  const showLoader = useLoader(
    getClientFeaturesLoading,
    getClientProductLoading,
    getProjectsLoading,
    getClientLoading,
    createProjectLoading,
    reopenProjectLoading,
    startProjectLoading,
    closeProjectLoading,
    restartProjectLoading,
    updateProjectLoading,
    deleteProjectLoading,
  );

  const product = useMemo(
    () =>
      getClientProductData ? products.find((x) => x.id === getClientProductData.productId) : null,
    [products, getClientProductData],
  );

  const perPageOptions = useMemo(
    () => [12, 24, 48].map((x) => ({ value: x.toString(), label: x.toString() })),
    [],
  );

  const subAccountMatchingEnabled = useMemo(() => {
    const feature = features.find((f) => f.name === Features.subAccountMatching);
    if (!feature) return false;
    return clientFeatures.findIndex((x) => x.featureId === feature.id) !== -1;
  }, [features, clientFeatures]);

  const tourSteps = useMemo<Step[]>(() => {
    const firstOpenProject = pageItems.find((x) => x.status === ProjectStatuses.open);
    const firstInactiveProject = pageItems.find((x) => x.status === ProjectStatuses.inactive);
    const firstInProgressProject = pageItems.find((x) => x.status === ProjectStatuses.inProgress);
    const firstClosedProject = pageItems.find((x) => x.status === ProjectStatuses.closed);
    return [
      firstInactiveProject
        ? {
            target: `#project-${firstInactiveProject.id}`,
            content:
              'Inactive projects are upcoming and will not be accessible until the Start date indicated.',
          }
        : null,
      firstOpenProject
        ? {
            target: `#project-${firstOpenProject.id}`,
            content:
              'Open projects are currently available. Click on an Open project to upload the required data.',
          }
        : null,
      firstInProgressProject
        ? {
            target: `#project-${firstInProgressProject.id}`,
            content:
              ' In Progress projects are actively being handled by c. myers and do not currently require any action. Click on an In Progress project to view what has been provided to c. myers.',
          }
        : null,
      firstClosedProject
        ? {
            target: `#project-${firstClosedProject.id}`,
            content: 'Click on a Completed project to view the results for that project.',
          }
        : null,
      {
        target: '#project-search',
        content:
          'To search for a specific project by name, enter a few characters from the project name.',
      },
      {
        target: '#project-date-range-filter',
        content: 'To search for a specific project by start date, select the desired date range.',
      },
    ].filter((x) => x !== null) as Step[];
  }, [pageItems]);

  return (
    <Box className={clsx([classes.root, product && 'loaded'])}>
      {product && (
        <>
          <GuideTour steps={tourSteps} name='projects-selection' />
          <Box className={classes.hiddenSection} />

          <Box className={classes.container}>
            <Box className={classes.topSection}>
              <Container maxWidth='xl'>
                <Box className={classes.topSectionContainer}>
                  <DashboardBreadcrumbs clientId={clientId} productId={productId} />
                  <IssuesAlert clientId={clientId} productId={productId} />
                  <Box className={clsx([classes.flexRow, classes.flexGap60])}>
                    <ExternalResources />
                    <SearchInput
                      id='project-search'
                      onChange={(value) => searchInput.onChange({ target: { value } })}
                      color={SearchInputColors.white}
                      maxWidth={120}
                    />
                  </Box>
                </Box>
              </Container>
            </Box>
            <Box className={classes.mainSection}>
              <Box className={classes.title}>
                <Container maxWidth='xl' className={classes.h100}>
                  <Box
                    className={clsx([
                      classes.flexRow,
                      classes.justifyContentSpaceBetween,
                      classes.h100,
                    ])}
                  >
                    <Typography variant='h6'>{product.name}</Typography>
                    <Box className={classes.flexRow}>
                      <CustomDateRangePicker
                        value={dateRange}
                        onChange={(newValue) =>
                          setDateRange([
                            newValue[0]
                              ? new Date(moment(newValue[0]).startOf('day').toString())
                              : null,
                            newValue[1]
                              ? new Date(moment(newValue[1]).endOf('day').toString())
                              : null,
                          ])
                        }
                        renderInput={(startProps) => (
                          <TextField
                            {...startProps}
                            id='project-date-range-filter'
                            className={classes.dateRangePickerInput}
                            label={null}
                            value={
                              dateRange[0] || dateRange[1]
                                ? `${
                                    (dateRange[0] && moment(dateRange[0]).format('MM.DD.YYYY')) ||
                                    'none'
                                  } - ${
                                    (dateRange[1] && moment(dateRange[1]).format('MM.DD.YYYY')) ||
                                    'none'
                                  }`
                                : ''
                            }
                            variant='standard'
                            autoComplete='off'
                            inputProps={{
                              placeholder: 'Pick date range',
                            }}
                            InputProps={{
                              startAdornment: (
                                <IconButton>
                                  <img src={CalendarIcon} alt='Calendar' />
                                </IconButton>
                              ),
                              endAdornment: (
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setDateRange([null, null]);
                                  }}
                                >
                                  <img src={XCircleIcon} alt='Clear' />
                                </IconButton>
                              ),
                            }}
                          />
                        )}
                      />
                      {getProjectsData?.links[Actions.createProject]?.href && (
                        <Button
                          className='narrow'
                          variant='outlined'
                          color='info'
                          startIcon={<img alt='add' src={PlusFilledIcon} />}
                          onClick={onAddProject}
                        >
                          Add Project
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Container>
              </Box>

              <Container maxWidth='lg' className={classes.overflowHidden}>
                <Box className={classes.projectsSection}>
                  <Box className={classes.projectsList}>
                    {pageItems.map((item) => (
                      <ProjectCard
                        id={`project-${item.id}`}
                        key={item.id}
                        project={item}
                        onSelected={() => onProjectSelected(item)}
                        onStart={() => onStartProject(item)}
                        onClose={() => onCloseProject(item)}
                        onRestart={() => onRestartProject(item)}
                        onReopen={() => onReopenProject(item)}
                        onEdit={() => onEditProject(item)}
                        onDelete={() => onDeleteProject(item)}
                      />
                    ))}
                  </Box>
                </Box>
              </Container>
            </Box>
          </Box>
          <Box className={classes.paginationSection}>
            <Container maxWidth='lg'>
              <Divider className={classes.paginationDivider} />
              <Box className={clsx([classes.flexRow, classes.justifyContentSpaceBetween])}>
                <Box className={clsx([classes.flexRow, classes.flexGap8])}>
                  <Typography variant='subtitle2' className={classes.textGray}>
                    Total projects:
                  </Typography>
                  <Typography variant='subtitle2'>{filteredItems.length}</Typography>
                </Box>
                <Pagination
                  className={classes.pagination}
                  color='primary'
                  page={page}
                  count={pages}
                  onChange={(_, n) => onPageChanged(n)}
                  siblingCount={1}
                  hideNextButton
                  hidePrevButton
                />
                <Box className={clsx([classes.flexRow, classes.flexGap4])}>
                  <Typography variant='subtitle2' className={classes.textGray}>
                    Show per page:
                  </Typography>
                  <Dropdown
                    value={perPage.toString()}
                    onChanged={(value) => setPerPage(+value)}
                    options={perPageOptions}
                    variant='pagination'
                  />
                </Box>
              </Box>
            </Container>
          </Box>
        </>
      )}
      {client && getProjectsData?.links[Actions.createProject]?.href && (
        <CreateProjectDialog
          client={client}
          open={openCreateDialog}
          onClose={onCloseCreateDialog}
          subAccountMatchingEnabled={subAccountMatchingEnabled}
        />
      )}
      {client && selectedProject && (
        <EditProjectDialog
          project={selectedProject}
          client={client}
          open={true}
          onClose={onCloseEditDialog}
          subAccountMatchingEnabled={subAccountMatchingEnabled}
        />
      )}
      <Loader show={showLoader} />
    </Box>
  );
};
