import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: { display: 'flex', flexDirection: 'column', gap: 24 },
  table: {
    backgroundColor: t.palette.custom.white[100],

    '&>div': {
      height: 300,
    },

    '& .MuiDataGrid-virtualScroller': {
      minHeight: 39,
    },

    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600,
    },

    '& .MuiDataGrid-pinnedRows': {
      fontWeight: 600,
      backgroundColor: t.palette.custom.white[100],

      '& > div': {
        backgroundColor: t.palette.custom.info[50],
      },
    },
  },
  flex: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    gap: 16,
  },
  gap24: {
    gap: 24,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  menuItem: {
    '&.Mui-selected': {
      backgroundColor: t.palette.custom.info[50],
    },
    '&.Mui-selected:hover': {
      backgroundColor: t.palette.custom.info[60],
    },
  },
}));

export default useStyles;
