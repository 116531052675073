import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';

import { Box } from '@mui/material';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { OperationMap } from '../../../../common/AdjustmentOperation';
import { formatBalance } from '../../../../utils';
import { useMemo } from 'react';
import useStyles from './styles';

interface IProps {
  adjustments: IAdjustment[];
  showOperation?: boolean;
}

export const AdjustmentsTab = ({ adjustments, showOperation }: IProps) => {
  const { classes } = useStyles();

  const columns = useMemo(() => {
    const baseColumns: GridColDef<IAdjustment>[] = [
      {
        field: 'sourceSubAccountId',
        headerName: 'Balance Sheet Line Item',
        type: 'string',
        flex: 2,
      },
      {
        field: 'sourceSubAccountDescription',
        headerName: 'Description',
        type: 'string',
        flex: 2,
      },
      {
        field: 'sourceTotal',
        headerName: 'Line Item Balance',
        type: 'number',
        flex: 2,
        renderCell: (params) => (params.value !== undefined ? formatBalance(params.value) : ''),
      },
      {
        field: 'sourceAdjustment',
        headerName: 'Total Adjustment',
        type: 'number',
        flex: 2,
        renderCell: (params) => (params.value !== undefined ? formatBalance(params.value) : ''),
      },
      {
        field: 'amount',
        headerName: 'Adjustment to Account',
        type: 'number',
        flex: 2,
        renderCell: (params) => formatBalance(params.value || 0),
      },
    ];

    if (showOperation) {
      baseColumns.splice(2, 0, {
        field: 'operation',
        headerName: 'Operation',
        type: 'string',
        flex: 1,
        renderCell: (params) => OperationMap[params.value] || params.value,
      });
    }

    return baseColumns;
  }, []);

  return (
    <>
      <Box className={classes.root}>
        <DataGridPremium
          rows={adjustments}
          density='compact'
          columns={columns}
          className={classes.table}
          hideFooter
          initialState={{
            sorting: {
              sortModel: [{ field: 'sourceSubAccountId', sort: 'asc' }],
            },
            aggregation: {
              model: {
                amount: 'sum',
              },
            },
          }}
        />
      </Box>
    </>
  );
};
