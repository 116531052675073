import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 16,
    paddingLeft: 16,
    backgroundColor: '#f3f3f3',
    color: t.palette.custom.dark[100],
    position: 'relative',
    zIndex: 1,

    '&.shadowed': {
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.2)',
      borderRadius: 4,
    },
  },
  header: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    padding: 16,
    position: 'relative',
  },
  balancesContainer: {
    gap: 30,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
  },
  balanceRecord: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  balanceCount: {
    backgroundColor: t.palette.custom.white[100],
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 2,
    paddingRight: 15,
    paddingLeft: 15,
    height: 28,
    width: 180,
    borderRadius: 20,
    textAlign: 'center',
    cursor: 'pointer',

    '&.green': {
      backgroundColor: t.palette.custom.success[50],
    },
    '&.blue': {
      backgroundColor: t.palette.custom.info[50],
    },
    '&.orange': {
      backgroundColor: t.palette.custom.warning[50],
    },
    '&.gray': {
      backgroundColor: t.palette.custom.dark[10],
      color: t.palette.custom.dark[50],
    },
    '&.active': {
      borderColor: t.palette.custom.dark[100],
    },
    '&.disabled': {
      cursor: 'not-allowed',
    },
  },
  flex: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    gap: 4,
  },
  expandIcon: {
    position: 'absolute',
    right: 16,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  itemsListContent: {
    backgroundColor: t.palette.custom.white[100],
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  itemContent: {
    padding: 16,
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
    marginLeft: -16,
    marginRight: -16,
    marginBottom: -12,
  },
  adjustmentsDetails: {
    marginLeft: -24,
    marginRight: -24,
    marginTop: -24,
  },
  gap8: {
    gap: 8,
  },
}));

export default useStyles;
