import { Mapper, createMap } from '@automapper/core';

import { ISimulationAbbreviation } from '@models/interfaces/entities/ISimulationAbbreviation';
import { ISimulationAbbreviationDto } from '@models/interfaces/dtos/ISimulationAbbreviationDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createSimulationAbbreviationMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<ISimulationAbbreviationDto>('ISimulationAbbreviationDto', {
    id: String,
    clientId: String,
    balanceSheetMonth: Number,
    balanceSheetYear: Number,
    scenario: String,
    name: String,
    additionalInfo: String,
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<ISimulationAbbreviation>('ISimulationAbbreviation', {
    id: String,
    clientId: String,
    balanceSheetMonth: Number,
    balanceSheetYear: Number,
    scenario: String,
    name: String,
    additionalInfo: String,
    links: 'ILinks',
  });

  createMap<ISimulationAbbreviationDto, ISimulationAbbreviation>(
    mapper,
    'ISimulationAbbreviationDto',
    'ISimulationAbbreviation',
  );
};
