import ClientFeaturesProvider from '@providers/ClientFeaturesProvider';
import { IClientFeature } from '@models/interfaces/entities/IClientFeature';
import { IClientFeatureDto } from '@models/interfaces/dtos/IClientFeatureDto';
import { ICreateClientFeatureData } from '@models/interfaces/additional/ICreateClientFeatureData';
import { ICreateClientFeatureRequest } from '@models/interfaces/requests/ICreateClientFeatureRequest';
import mapper from '@models/mapper';

const clientFeaturesProvider = new ClientFeaturesProvider();

export const getClientFeature = async (url: string) => {
  const result = await clientFeaturesProvider.getClientFeature(url);
  return mapper.map<IClientFeatureDto, IClientFeature>(
    result,
    'IClientFeatureDto',
    'IClientFeature',
  );
};

export const getClientFeatures = async (url: string, manage?: boolean) => {
  const result = await clientFeaturesProvider.getClientFeatures(url, manage);
  return mapper.mapArray<IClientFeatureDto, IClientFeature>(
    result.items,
    'IClientFeatureDto',
    'IClientFeature',
  );
};

export const createClientFeature = async (url: string, data: ICreateClientFeatureData) => {
  const requestData = mapper.map<ICreateClientFeatureData, ICreateClientFeatureRequest>(
    data,
    'ICreateClientFeatureData',
    'ICreateClientFeatureRequest',
  );
  const result = await clientFeaturesProvider.createClientFeature(url, requestData);
  return mapper.mapArray<IClientFeatureDto, IClientFeature>(
    result.items,
    'IClientFeatureDto',
    'IClientFeature',
  );
};

export const deleteClientFeature = async (url: string) => {
  await clientFeaturesProvider.deleteClientFeature(url);
  return true;
};
