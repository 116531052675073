import { Box, Button, Typography } from '@mui/material';
import { IBalancingNode, rootBalancingNodes } from '../../common/BalancingNodes';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ButtonsSelector } from '@components/ButtonsSelector';
import { CustomDialog } from '@components/CustomDialog';
import { IAccount } from '@models/interfaces/entities/IAccount';
import clsx from 'clsx';
import useStyles from './styles';

export interface IProps {
  open: boolean;
  title: string;
  category: string;
  accounts: IAccount[];
  selectedAccountIds: string[];
  onClose: (accountIds?: string[]) => void;
}

export const AccountsDialog = ({
  open,
  title,
  category,
  accounts,
  selectedAccountIds,
  onClose,
}: IProps) => {
  const { classes } = useStyles();
  const [accountIds, setAccountIds] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState(category);

  const onAccountToggled = useCallback((accountId: string, value: boolean) => {
    if (value) {
      setAccountIds((prev) => [...prev, accountId]);
    } else {
      setAccountIds((prev) => prev.filter((x) => x !== accountId));
    }
  }, []);

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    if (accountIds.length) {
      onClose([...selectedAccountIds, ...accountIds]);
    }
  };

  const onCategoryChanged = useCallback((category: string | string[]) => {
    setSelectedCategory(Array.isArray(category) ? category[0] : category);
    setAccountIds([]);
  }, []);

  const rootBalancingNode = useMemo(() => {
    return rootBalancingNodes.find((node) => {
      if (node.title === category) return true;

      const allChildren = node.items?.flatMap((child) => [child, ...(child.items || [])]) || [];

      return allChildren.some((item) => item.title === category);
    });
  }, [category, rootBalancingNodes]);

  const accountCategories = useMemo(() => {
    const extractCategories = (
      node: IBalancingNode,
    ): { category: string; accounts: typeof accounts }[] => {
      if (!node.items || node.items.length === 0) {
        return [
          {
            category: node.title,
            accounts: accounts
              .filter(
                (x) =>
                  x.accountType.type === node.accountsType &&
                  x.summaryCode !== 1 &&
                  !x.isBalancing &&
                  !x.isTemp,
              )
              .filter((x) => !selectedAccountIds.includes(x.id))
              .sort((a, b) => a.accountName.localeCompare(b.accountName)),
          },
        ];
      }

      return node.items.flatMap(extractCategories);
    };

    return rootBalancingNode ? extractCategories(rootBalancingNode) : [];
  }, [rootBalancingNode, accounts, selectedAccountIds]);

  const categoryOptions = useMemo(
    () => accountCategories.map((x) => ({ value: x.category, label: x.category })),
    [accountCategories, category],
  );

  const selectedCategoryAccounts = useMemo(
    () => accountCategories.find((x) => x.category === selectedCategory)?.accounts || [],
    [selectedCategory, accountCategories],
  );

  useEffect(() => {
    if (open) {
      const selectedOption = categoryOptions.find((x) => x.value === category);
      setSelectedCategory(selectedOption?.value || categoryOptions[0].value);
    }
  }, [open, category, categoryOptions]);

  useEffect(() => {
    setAccountIds([]);
  }, [open]);

  if (!open) return null;

  return (
    <CustomDialog
      title={title}
      onClose={onCancel}
      open={open}
      maxWidth='sm'
      fullWidth
      actions={
        <>
          <div />
          <Button
            size='medium'
            variant='contained'
            color='primary'
            onClick={onSave}
            disabled={!accountIds.length}
          >
            Ok
          </Button>
        </>
      }
    >
      <Box className={classes.root}>
        <Box className={classes.categoryControls}>
          <Typography variant='subtitle1' className={classes.mt4}>
            Account&nbsp;type:
          </Typography>
          <ButtonsSelector
            options={categoryOptions}
            value={selectedCategory}
            onChanged={(value) => onCategoryChanged(value)}
            wrap
          />
        </Box>

        <Box className={classes.accountsList}>
          {selectedCategoryAccounts.map((x) => (
            <Box
              key={x.id}
              className={clsx([classes.accountItem, accountIds.includes(x.id) && classes.selected])}
              onClick={() => onAccountToggled(x.id, !accountIds.includes(x.id))}
            >
              <Typography>{x.accountName}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </CustomDialog>
  );
};
