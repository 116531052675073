import {
  ReportSectionLineValueAlignment,
  ReportSectionLineValueColor,
  ReportSectionLineValueMode,
} from '.';

import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const getAlignments = (t: Theme, alignment: ReportSectionLineValueAlignment) => {
  switch (alignment) {
    case ReportSectionLineValueAlignment.left:
      return { justifyContent: 'flex-start', textAlign: 'left' as const };
    case ReportSectionLineValueAlignment.center:
      return { justifyContent: 'center', textAlign: 'center' as const };
    case ReportSectionLineValueAlignment.right:
      return { justifyContent: 'flex-end', textAlign: 'right' as const };
  }
};

const getFilledColors = (
  t: Theme,
  color: ReportSectionLineValueColor | undefined,
  highlighted: boolean,
) => {
  switch (color) {
    case ReportSectionLineValueColor.blue:
      return { color: t.palette.custom.white[100], backgroundColor: t.palette.custom.info[100] };
    case ReportSectionLineValueColor.yellow:
      return { backgroundColor: t.palette.custom.yellow[100] };
    case ReportSectionLineValueColor.orange:
      return { color: t.palette.custom.white[100], backgroundColor: t.palette.custom.orange[100] };
    case ReportSectionLineValueColor.red:
      return { color: t.palette.custom.white[100], backgroundColor: t.palette.custom.error[100] };
    default: {
      if (highlighted) return { backgroundColor: t.palette.custom.primary[5] };
      else return { backgroundColor: t.palette.custom.white[100] };
    }
  }
};

const getUnderlinedColors = (
  t: Theme,
  color: ReportSectionLineValueColor | undefined,
  highlighted: boolean,
) => {
  switch (color) {
    case ReportSectionLineValueColor.blue:
      return {
        color: t.palette.custom.info[100],
        borderBottomColor: t.palette.custom.info[100],
        borderBottomStyle: 'dashed',
        backgroundColor: highlighted ? t.palette.custom.primary[5] : t.palette.custom.white[100],
      };
    case ReportSectionLineValueColor.yellow:
      return {
        color: t.palette.custom.yellow[100],
        borderBottomStyle: 'dashed',
        borderBottomColor: t.palette.custom.yellow[100],
        backgroundColor: highlighted ? t.palette.custom.primary[5] : t.palette.custom.white[100],
      };
    case ReportSectionLineValueColor.orange:
      return {
        color: t.palette.custom.orange[100],
        borderBottomStyle: 'dashed',
        borderBottomColor: t.palette.custom.orange[100],
        backgroundColor: highlighted ? t.palette.custom.primary[5] : t.palette.custom.white[100],
      };
    case ReportSectionLineValueColor.red:
      return {
        color: t.palette.custom.error[100],
        borderBottomColor: t.palette.custom.error[100],
        backgroundColor: highlighted ? t.palette.custom.primary[5] : t.palette.custom.white[100],
        borderBottomStyle: 'dashed',
      };
    default: {
      if (highlighted) return { backgroundColor: t.palette.custom.primary[5] };
      else return { backgroundColor: t.palette.custom.white[100] };
    }
  }
};

const useStyles = makeStyles<{
  mode: ReportSectionLineValueMode | undefined;
  color: ReportSectionLineValueColor | undefined;
  highlighted: boolean;
  bolded: boolean;
  alignment: ReportSectionLineValueAlignment;
  columnPositions?: [number, number];
  rowPositions?: [number, number];
  isEditing: boolean;
}>()(
  (
    t,
    { mode, color, highlighted, bolded, alignment, columnPositions, rowPositions, isEditing },
  ) => {
    const colors = isEditing
      ? { backgroundColor: t.palette.custom.primary[10] }
      : mode === ReportSectionLineValueMode.filled
      ? getFilledColors(t, color, highlighted)
      : mode === ReportSectionLineValueMode.underlined
      ? getUnderlinedColors(t, color, highlighted)
      : highlighted
      ? { backgroundColor: t.palette.custom.primary[5] }
      : {};

    const alignments = getAlignments(t, alignment);

    return {
      root: {
        padding: 2,
        whiteSpace: 'pre-line',
        minHeight: 30,
        display: 'flex',
        alignItems: 'center',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'transparent',
        fontWeight: bolded ? 'bold' : 'normal',

        gridColumnStart: columnPositions ? columnPositions[0] : undefined,
        gridColumnEnd: columnPositions ? columnPositions[0] + columnPositions[1] : undefined,
        gridRowStart: rowPositions ? rowPositions[0] : undefined,
        gridRowEnd: rowPositions ? rowPositions[0] + rowPositions[1] : undefined,

        ...alignments,
        ...colors,
      },
      input: {
        '&>input': {
          padding: 0,
          height: 'auto',

          fontWeight: t.typography.body2.fontWeight,
          fontSize: t.typography.body2.fontSize,
          lineHeight: t.typography.body2.lineHeight,
          letterSpacing: t.typography.body2.letterSpacing,

          ...alignments,
        },
      },
    };
  },
);

export default useStyles;
