import { Box } from '@mui/material';
import useStyles from './styles';

interface IProps {
  value?: string;
  columnPositions?: [number, number];
  rowPositions?: [number, number];
}

export const ReportSectionLineDivider = ({ value, columnPositions, rowPositions }: IProps) => {
  const { classes } = useStyles({
    columnPositions,
    rowPositions,
  });

  return <Box className={classes.root}>{value}</Box>;
};
