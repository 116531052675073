import { IReportSectionHeader, ReportSectionHeader } from './components/ReportSectionHeader';
import { IReportSectionLine, ReportSectionLine } from './components/ReportSectionLine';

import { Box } from '@mui/material';
import useStyles from './styles';

interface IProps {
  header: IReportSectionHeader;
  lines: IReportSectionLine[];
  dataColumnsCount: number;
  hideAdditional?: boolean;
}

export const ReportSection = ({ header, lines, dataColumnsCount, hideAdditional }: IProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <ReportSectionHeader
        item={header}
        dataColumnsCount={dataColumnsCount}
        hideAdditional={hideAdditional}
      />
      {lines.map((item, i) => (
        <ReportSectionLine
          key={i}
          item={item}
          highlighted={i % 2 === 0}
          dataColumnsCount={dataColumnsCount}
          hideAdditional={hideAdditional}
        />
      ))}
    </Box>
  );
};
