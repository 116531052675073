import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    display: 'flex',
    gap: 1,
    flexDirection: 'column',

    fontWeight: t.typography.body2.fontWeight,
    fontSize: t.typography.body2.fontSize,
    lineHeight: t.typography.body2.lineHeight,
    letterSpacing: t.typography.body2.letterSpacing,

    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: t.palette.custom.dark[30],
  },
}));

export default useStyles;
