import BaseProvider from './BaseProvider';
import { ICreateAdjustmentDefinitionRequest } from '@models/interfaces/requests/ICreateAdjustmentDefinitionRequest';
import { ICreateAdjustmentDefinitionResponse } from '@models/interfaces/responses/ICreateAdjustmentDefinitionResponse';
import { IGetAdjustmentDefinitionResponse } from '@models/interfaces/responses/IGetAdjustmentDefinitionResponse';
import { IGetAdjustmentDefinitionsResponse } from '@models/interfaces/responses/IGetAdjustmentDefinitionsResponse';
import { IUpdateAdjustmentDefinitionRequest } from '@models/interfaces/requests/IUpdateAdjustmentDefinitionRequest';
import { IUpdateAdjustmentDefinitionResponse } from '@models/interfaces/responses/IUpdateAdjustmentDefinitionResponse';

export default class AdjustmentDefinitionsProvider extends BaseProvider {
  public getAdjustmentDefinitions = async (
    url: string,
    operation: string,
    categoryId?: string,
    type?: number,
    targetId?: string,
    sourceName?: string,
  ) => {
    return this.get<IGetAdjustmentDefinitionsResponse>(url, {
      params: {
        operation,
        categoryId,
        type,
        targetId,
        sourceName,
      },
    });
  };

  public getAdjustmentDefinition = async (url: string) => {
    return this.get<IGetAdjustmentDefinitionResponse>(url);
  };

  public updateAdjustmentDefinition = (url: string, data: IUpdateAdjustmentDefinitionRequest) => {
    return this.put<IUpdateAdjustmentDefinitionRequest, IUpdateAdjustmentDefinitionResponse>(
      url,
      data,
    );
  };

  public createAdjustmentDefinition = (url: string, data: ICreateAdjustmentDefinitionRequest) => {
    return this.post<ICreateAdjustmentDefinitionRequest, ICreateAdjustmentDefinitionResponse>(
      url,
      data,
    );
  };

  public deleteAdjustmentDefinition = (url: string) => {
    return this.delete<void>(url);
  };
}
