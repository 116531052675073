import { Box } from '@mui/system';
import { Button } from '@mui/material';
import clsx from 'clsx';
import { useCallback } from 'react';
import useStyles from './styles';

export interface IButtonsSelectorOption {
  value: string;
  label: string;
}

export interface IProps {
  value: string | string[];
  options: IButtonsSelectorOption[];
  onChanged: (value: string | string[]) => void;
  buttonSize?: 'small' | 'medium' | 'large';
  buttonsGap: number;
  buttonsWidth?: number;
  disabled?: boolean;
  multiselect?: boolean;
  wrap?: boolean;
}

export const ButtonsSelector = ({
  value,
  options,
  onChanged,
  buttonSize,
  buttonsGap,
  buttonsWidth,
  disabled,
  multiselect,
  wrap,
}: IProps) => {
  const { classes } = useStyles({ buttonsGap, buttonsWidth, wrap });

  const onClick = useCallback(
    (itemValue: string) => {
      if (multiselect) {
        if (Array.isArray(value)) {
          onChanged(
            value.includes(itemValue)
              ? value.filter((v) => v !== itemValue)
              : [...value, itemValue],
          );
        } else {
          onChanged(value === itemValue ? [] : [value, itemValue]);
        }
      } else {
        onChanged(itemValue);
      }
    },
    [value, multiselect, onChanged],
  );

  return (
    <Box className={classes.root}>
      {options.map((item) => {
        const selected = Array.isArray(value) ? value.includes(item.value) : value === item.value;

        return (
          <Button
            key={item.value}
            size={buttonSize}
            variant={selected ? 'contained' : 'outlined'}
            color={selected ? 'info' : 'secondary'}
            disabled={disabled}
            onClick={() => onClick(item.value)}
            className={clsx([classes.button, selected && classes.transparentBorderButton])}
          >
            {item.label}
          </Button>
        );
      })}
    </Box>
  );
};

ButtonsSelector.defaultProps = {
  buttonsGap: 10,
  disabled: false,
  buttonSize: 'small',
  wrap: false,
};
