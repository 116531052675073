import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { CustomAutocomplete, IAutocompleteOption } from '@components/CustomAutocomplete';
import { useEffect, useMemo } from 'react';

import { ClientRoles } from '@models/enums/Roles';
import { CustomDialog } from '@components/CustomDialog';
import { IGroup } from '@models/interfaces/entities/IGroup';
import { IRoleAssignment } from '@models/interfaces/entities/IRoleAssignment';
import { IUpdateClientRolesData } from '@models/interfaces/additional/IUpdateClientRolesData';
import PlusFilledIcon from '@assets/icons/dialog/plus-filled.svg';
import useStyles from './styles';

interface IProps {
  groups: IGroup[];
  roleAssignments: IRoleAssignment[];
  open: boolean;
  onClose: (data?: IUpdateClientRolesData) => void;
}

type IFormData = {
  group: IAutocompleteOption | null;
  role: ClientRoles.clientManager | ClientRoles.clientUser | ClientRoles.projectManager;
};

export const AddGroupDialog = ({ roleAssignments, groups, open, onClose }: IProps) => {
  const { classes } = useStyles();
  const formDefaultData = useMemo(
    () => ({
      group: null,
      role: ClientRoles.clientManager,
    }),
    [],
  );

  const form = useForm<IFormData>({
    defaultValues: formDefaultData,
  });

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    const data = form.getValues();

    if (!data.group) return;

    onClose([
      ...roleAssignments
        .filter((x) => x.groupId != null)
        .map((x) => ({
          role: x.role,
          adGroup: x.groupId as string,
        })),
      { role: data.role, adGroup: data.group.value },
    ]);
  };

  useEffect(() => {
    form.reset(formDefaultData);
  }, [open]);

  const groupOptions = useMemo<IAutocompleteOption[]>(() => {
    return groups.map((x) => ({
      value: x.id,
      title: x.name,
    }));
  }, [groups]);

  return (
    <>
      <CustomDialog
        title='Add Group'
        onClose={onCancel}
        open={open}
        maxWidth='xs'
        fullWidth
        actions={
          <>
            <div />
            <Button
              type='submit'
              form='form'
              variant='contained'
              color='secondary'
              size='large'
              startIcon={<img alt='add' src={PlusFilledIcon} />}
            >
              Add Group
            </Button>
            <div />
          </>
        }
      >
        <form
          id='form'
          onSubmit={form.handleSubmit(() => {
            onSave();
          })}
          noValidate
        >
          <Box>
            <Controller
              name={'group'}
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: 'Please select the group',
                },
              }}
              render={({ field: { onChange, value }, formState }) => (
                <CustomAutocomplete
                  value={value}
                  label='Group'
                  placeholder='Select Group'
                  onChange={onChange}
                  error={!!formState.errors.group}
                  helperText={formState.errors.group?.message}
                  options={groupOptions}
                />
              )}
            />
          </Box>
          <Box className={classes.mt24}>
            <Controller
              name={'role'}
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <FormControl>
                  <RadioGroup row value={value} onChange={onChange}>
                    <FormControlLabel
                      value={ClientRoles.clientManager}
                      control={<Radio />}
                      label='Client Manager'
                    />
                    <FormControlLabel
                      value={ClientRoles.clientUser}
                      control={<Radio />}
                      label='Client User'
                    />
                    <FormControlLabel
                      value={ClientRoles.projectManager}
                      control={<Radio />}
                      label='Project Manager'
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Box>
        </form>
      </CustomDialog>
    </>
  );
};
