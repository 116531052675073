import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{
  columnPositions?: [number, number];
  rowPositions?: [number, number];
}>()((t, { columnPositions, rowPositions }) => {
  return {
    root: {
      padding: 2,
      whiteSpace: 'pre-line',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',

      fontWeight: t.typography.caption.fontWeight,
      fontSize: t.typography.caption.fontSize,
      lineHeight: t.typography.caption.lineHeight,
      letterSpacing: t.typography.caption.letterSpacing,

      gridColumnStart: columnPositions ? columnPositions[0] : undefined,
      gridColumnEnd: columnPositions ? columnPositions[0] + columnPositions[1] : undefined,
      gridRowStart: rowPositions ? rowPositions[0] : undefined,
      gridRowEnd: rowPositions ? rowPositions[0] + rowPositions[1] : undefined,
    },
  };
});

export default useStyles;
