import { Mapper, createMap } from '@automapper/core';

import { IAdjustmentDefinition } from '@models/interfaces/entities/IAdjustmentDefinition';
import { IAdjustmentDefinitionDto } from '@models/interfaces/dtos/IAdjustmentDefinitionDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createAdjustmentDefinitionMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IAdjustmentDefinitionDto>('IAdjustmentDefinitionDto', {
    id: String,
    description: String,
    operation: String,
    groupId: String,
    source: Object,
    tierLimits: [Object],
    targets: [Object],
    baseline: [Object],
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<IAdjustmentDefinition>('IAdjustmentDefinition', {
    id: String,
    description: String,
    operation: String,
    groupId: String,
    source: Object,
    tierLimits: [Object],
    targets: [Object],
    baseline: [Object],
    links: 'ILinks',
  });

  createMap<IAdjustmentDefinitionDto, IAdjustmentDefinition>(
    mapper,
    'IAdjustmentDefinitionDto',
    'IAdjustmentDefinition',
  );
};
