import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{
  dataColumnsCount: number;
  hideAdditional?: boolean;
}>()((t, { dataColumnsCount, hideAdditional }) => {
  return {
    root: {
      display: 'grid',
      gridTemplateColumns: `1fr 1px 200px 1px 1fr 1px 1fr 1px 1fr 16px repeat(${dataColumnsCount}, 1fr)${
        !hideAdditional ? ' 16px 1fr' : ''
      }`,
    },
    colSpan9: {
      gridColumnStart: 1,
      gridColumnEnd: 10,
    },
    textBold: {
      fontWeight: 'bold',
    },
  };
});

export default useStyles;
