import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import { ITypeCode } from '@models/interfaces/entities/ITypeCode';
import { ITypeCodeDto } from '@models/interfaces/dtos/ITypeCodeDto';
import { IUpdateNewTypeCodeData } from '@models/interfaces/additional/IUpdateNewTypeCodeData';
import { IUpdateNewTypeCodeRequest } from '@models/interfaces/requests/IUpdateNewTypeCodeRequest';
import TypeCodesProvider from '@providers/TypeCodesProvider';
import mapper from '@models/mapper';

const typeCodesProvider = new TypeCodesProvider();

export const getTypeCodes = async (
  url: string,
  categoryId?: string,
  type?: number,
  isNew?: boolean,
  isKnown?: boolean,
  isUsed?: boolean,
) => {
  const result = await typeCodesProvider.getTypeCodes(
    url,
    categoryId,
    type,
    isNew,
    isKnown,
    isUsed,
  );
  return {
    isNew,
    isKnown,
    isUsed,
    items: mapper.mapArray<ITypeCodeDto, ITypeCode>(result.items, 'ITypeCodeDto', 'ITypeCode'),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const getTypeCodesCount = async (
  url: string,
  categoryId?: string,
  type?: number,
  isNew?: boolean,
  isKnown?: boolean,
  isUsed?: boolean,
) => {
  const result = await typeCodesProvider.getTypeCodesCount(
    url,
    categoryId,
    type,
    isNew,
    isKnown,
    isUsed,
  );
  return {
    isNew,
    isKnown,
    isUsed,
    type,
    count: result.count,
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const updateNewTypeCode = async (url: string, data: IUpdateNewTypeCodeData) => {
  const requestData = mapper.map<IUpdateNewTypeCodeData, IUpdateNewTypeCodeRequest>(
    data,
    'IUpdateNewTypeCodeData',
    'IUpdateNewTypeCodeRequest',
  );
  const result = await typeCodesProvider.updateNewTypeCode(url, requestData);
  return mapper.map<ITypeCodeDto, ITypeCode>(result, 'ITypeCodeDto', 'ITypeCode');
};
