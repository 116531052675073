import { toast } from 'react-toastify';
import { useState } from 'react';

interface IOptions<T> {
  handleErrors?: boolean;
  onCallback?: (data: T) => void;
  onError?: (
    error: string,
    details: object & {
      [key: string]: string[];
    },
  ) => void;
}

export const useApi = <TArgs extends Array<unknown>, TResult>(
  apiFunc: (...args: TArgs) => Promise<TResult>,
  defaultResult: TResult,
  options?: IOptions<TResult>,
) => {
  const [data, setData] = useState<TResult>(defaultResult);
  const [loading, setLoading] = useState<boolean>(false);

  const request = async (...args: TArgs) => {
    setLoading(true);
    try {
      const result = await apiFunc(...args);
      setData(result);
      if (options?.onCallback) {
        options.onCallback(result);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const status = err?.response?.status;
      const errorMsg = err?.response?.data?.title || err?.message || 'Unexpected Error!';

      if (options?.onError) {
        const details = err?.response?.data?.errors || {};
        options.onError(errorMsg, details);
      }

      if (options?.handleErrors) {
        toast.error(errorMsg);
      }

      if (status === 503) {
        if (window.location.pathname !== '/maintenance') window.location.href = '/maintenance';
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading,
    request,
  };
};
