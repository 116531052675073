import { InputAdornment, TextField } from '@mui/material';
import { useDebounce, useInput, useUpdateEffect } from '@hooks';

import SearchGreyIcon from '@assets/icons/search/search-grey.svg';
import SearchWhiteIcon from '@assets/icons/search/search-white.svg';
import { useMemo } from 'react';
import useStyles from './styles';

export enum SearchInputColors {
  grey = 'grey',
  white = 'white',
}

interface IProps {
  id?: string;
  onChange: (value: string) => void;
  delay: number;
  color: SearchInputColors;
  maxWidth: number;
  minWidth?: number;
  placeholder: string;
}

export const SearchInput = ({
  id,
  onChange,
  delay,
  color,
  maxWidth,
  minWidth,
  placeholder,
}: IProps) => {
  const { classes } = useStyles({ color, maxWidth, minWidth });

  const searchInput = useInput<string>('');
  const search = useDebounce<string>(searchInput.value, delay);

  useUpdateEffect(() => {
    onChange(search);
  }, [search]);

  const icon = useMemo(() => {
    switch (color) {
      case SearchInputColors.white: {
        return SearchWhiteIcon;
      }
      default: {
        return SearchGreyIcon;
      }
    }
  }, [color]);

  return (
    <TextField
      id={id}
      className={classes.searchInput}
      onChange={searchInput.onChange}
      value={searchInput.value}
      placeholder={placeholder}
      variant='standard'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <img alt='search' src={icon} />
          </InputAdornment>
        ),
      }}
    />
  );
};

SearchInput.defaultProps = {
  maxWidth: 300,
  delay: 1000,
  color: SearchInputColors.grey,
  placeholder: 'Search',
};
