import BaseProvider from './BaseProvider';
import { IGetSimulationsDataResponse } from '@models/interfaces/responses/IGetSimulationsDataResponse';
import { IGetSimulationsResponse } from '@models/interfaces/responses/IGetSimulationsResponse';
import moment from 'moment';

export default class SimulationsProvider extends BaseProvider {
  public getSimulations = async (
    url: string,
    startDate?: Date,
    endDate?: Date,
    scenario?: string,
  ) => {
    return this.get<IGetSimulationsResponse>(url, {
      params: {
        startDate: startDate ? moment(startDate).format('YYYY-MM') : undefined,
        endDate: endDate ? moment(endDate).format('YYYY-MM') : undefined,
        scenario,
      },
    });
  };

  public getSimulationsData = async (
    url: string,
    simulationIds?: string[],
    deltas?: string[],
    scenarios?: string[],
  ) => {
    return this.get<IGetSimulationsDataResponse>(url, {
      params: {
        simulationIds,
        deltas,
        scenarios,
      },
    });
  };
}
