import { Fragment, useCallback, useMemo, useState } from 'react';
import {
  IReportSectionLineValue,
  ReportSectionLineValue,
  ReportSectionLineValueAlignment,
  ReportSectionLineValueColor,
  ReportSectionLineValueFormat,
  ReportSectionLineValueMode,
} from '../ReportSectionLineValue';

import { Box } from '@mui/material';
import { ReportSectionLineDivider } from '../ReportSectionLineDivider';
import useStyles from './styles';

export enum ReportSectionLineTrackingMode {
  higherIsBetter = 'higherIsBetter',
  lowerIsBetter = 'lowerIsBetter',
}

export interface IReportSectionLine {
  title: IReportSectionLineValue;
  subtitle: IReportSectionLineValue;
  configuration: [IReportSectionLineValue, IReportSectionLineValue, IReportSectionLineValue];
  rows: {
    data: IReportSectionLineValue[];
    additional?: IReportSectionLineValue;
  }[];
  format?: ReportSectionLineValueFormat;
  editableConfiguration?: boolean;
  trackingMode?: ReportSectionLineTrackingMode;
}

interface IProps {
  item: IReportSectionLine;
  highlighted?: boolean;
  dataColumnsCount: number;
  hideAdditional?: boolean;
}

export const ReportSectionLine = ({
  item: {
    title,
    subtitle,
    configuration: initialConfiguration,
    rows,
    format,
    editableConfiguration,
    trackingMode,
  },
  highlighted,
  dataColumnsCount,
  hideAdditional,
}: IProps) => {
  const { classes } = useStyles({
    dataColumnsCount: dataColumnsCount,
    hideAdditional: hideAdditional,
  });

  const [configuration, setConfiguration] = useState(initialConfiguration);

  const onConfigurationChange = useCallback((index: number, newValue?: string) => {
    if (newValue === undefined || newValue === null || isNaN(Number(newValue))) {
      console.warn(`Skipped update: "${newValue}" is not a valid number`);
      return;
    }

    setConfiguration((prev) => {
      const updatedConfig = [...prev] as typeof prev;
      updatedConfig[index] = {
        ...updatedConfig[index],
        value: newValue,
      };

      return updatedConfig;
    });
  }, []);

  const getNormalizedRowData = useCallback(
    (data: IReportSectionLineValue[]): IReportSectionLineValue[] => {
      if (data.length === dataColumnsCount) {
        return data;
      }

      if (data.length < dataColumnsCount) {
        const missingCount = dataColumnsCount - data.length;
        return [
          ...data,
          ...Array(missingCount).fill({
            value: '',
          } as IReportSectionLineValue),
        ];
      }

      return data.slice(0, dataColumnsCount);
    },
    [dataColumnsCount],
  );

  const getCellColor = useCallback(
    (
      cellValue: number,
      target?: number,
      trigger?: number,
      limit?: number,
      trackingMode?: ReportSectionLineTrackingMode,
    ): ReportSectionLineValueColor | undefined => {
      if (!trackingMode) return undefined;

      const isHigherBetter = trackingMode === ReportSectionLineTrackingMode.higherIsBetter;
      const isLowerBetter = trackingMode === ReportSectionLineTrackingMode.lowerIsBetter;

      const hasTarget = target !== undefined;
      const hasTrigger = trigger !== undefined;
      const hasLimit = limit !== undefined;

      if (isHigherBetter) {
        if (hasTarget && hasTrigger && cellValue < target && cellValue >= trigger) {
          return ReportSectionLineValueColor.yellow;
        }

        if (hasTarget && hasLimit && !hasTrigger && cellValue < target && cellValue >= limit) {
          return ReportSectionLineValueColor.yellow;
        }

        if (hasTarget && !hasTrigger && !hasLimit && cellValue < target) {
          return ReportSectionLineValueColor.yellow;
        }

        if (hasTarget && cellValue >= target) {
          return ReportSectionLineValueColor.blue;
        }

        if (!hasTarget && hasTrigger && cellValue >= trigger) {
          return ReportSectionLineValueColor.blue;
        }

        if (!hasTarget && !hasTrigger && hasLimit && cellValue >= limit) {
          return ReportSectionLineValueColor.blue;
        }

        if (hasLimit && cellValue < limit) {
          return ReportSectionLineValueColor.red;
        }

        if (hasTrigger && cellValue < trigger) {
          return ReportSectionLineValueColor.orange;
        }
      }

      if (isLowerBetter) {
        if (hasTarget && hasTrigger && cellValue > target && cellValue <= trigger) {
          return ReportSectionLineValueColor.yellow;
        }

        if (hasTarget && hasLimit && !hasTrigger && cellValue > target && cellValue <= limit) {
          return ReportSectionLineValueColor.yellow;
        }

        if (hasTarget && !hasTrigger && !hasLimit && cellValue > target) {
          return ReportSectionLineValueColor.yellow;
        }

        if (hasTarget && cellValue <= target) {
          return ReportSectionLineValueColor.blue;
        }

        if (!hasTarget && hasTrigger && cellValue <= trigger) {
          return ReportSectionLineValueColor.blue;
        }

        if (!hasTarget && !hasTrigger && hasLimit && cellValue <= limit) {
          return ReportSectionLineValueColor.blue;
        }

        if (hasLimit && cellValue > limit) {
          return ReportSectionLineValueColor.red;
        }

        if (hasTrigger && cellValue > trigger) {
          return ReportSectionLineValueColor.orange;
        }
      }

      return undefined;
    },
    [],
  );

  const parseConfigValue = useCallback((val?: string): number | undefined => {
    if (!val) return undefined;
    const parsed = Number(val);
    return !isNaN(parsed) ? parsed : undefined;
  }, []);

  const { targetValue, triggerValue, limitValue, noConfigSet } = useMemo(() => {
    const target = parseConfigValue(configuration[0]?.value);
    const trigger = parseConfigValue(configuration[1]?.value);
    const limit = parseConfigValue(configuration[2]?.value);

    const noneSet = target === undefined && trigger === undefined && limit === undefined;

    return {
      targetValue: target,
      triggerValue: trigger,
      limitValue: limit,
      noConfigSet: noneSet,
    };
  }, [configuration, parseConfigValue]);

  const multiRow = useMemo(() => rows.length > 1, [rows]);

  return (
    <Box className={classes.root}>
      <ReportSectionLineValue
        item={subtitle}
        highlighted={highlighted}
        rowPositions={[1, rows.length]}
      />
      <ReportSectionLineDivider rowPositions={[1, rows.length]} />
      <ReportSectionLineValue
        item={title}
        highlighted={highlighted}
        alignment={ReportSectionLineValueAlignment.left}
        rowPositions={[1, rows.length]}
      />

      {configuration.map((item, index) => {
        let color: ReportSectionLineValueColor | undefined = item.color;

        if (item.value) {
          if (index === 0) color = ReportSectionLineValueColor.blue;
          if (index === 1) color = ReportSectionLineValueColor.orange;
          if (index === 2) color = ReportSectionLineValueColor.red;
        }

        const updatedItem: IReportSectionLineValue = {
          ...item,
          color,
          mode: color ? ReportSectionLineValueMode.underlined : item.mode,
          bolded: color ? true : item.bolded,
        };

        return (
          <Fragment key={index}>
            <ReportSectionLineDivider rowPositions={[1, rows.length]} />
            <ReportSectionLineValue
              item={updatedItem}
              highlighted={highlighted}
              rowPositions={[1, rows.length]}
              onChange={
                editableConfiguration ? (value) => onConfigurationChange(index, value) : undefined
              }
              format={format}
            />
          </Fragment>
        );
      })}

      {rows.map((row, index) => {
        const data = getNormalizedRowData(row.data);

        return (
          <Fragment key={index}>
            <ReportSectionLineDivider value={multiRow ? (index + 1).toString() : undefined} />

            {data.map((valueObj, i) => {
              const parsedValue = valueObj.value ? Number(valueObj.value) : undefined;

              let color: ReportSectionLineValueColor | undefined;
              let mode: ReportSectionLineValueMode | undefined;

              if (!noConfigSet && parsedValue && !isNaN(parsedValue)) {
                color = getCellColor(
                  parsedValue,
                  targetValue,
                  triggerValue,
                  limitValue,
                  trackingMode,
                );
                mode = color ? ReportSectionLineValueMode.filled : undefined;
              } else {
                color = valueObj.color;
                mode = valueObj.mode;
              }

              const updatedItem: IReportSectionLineValue = {
                ...valueObj,
                color,
                mode,
              };

              return (
                <ReportSectionLineValue
                  key={i}
                  item={updatedItem}
                  highlighted={highlighted}
                  format={format}
                />
              );
            })}

            {!hideAdditional && (
              <>
                <Box />
                <ReportSectionLineValue
                  item={row.additional || { value: '' }}
                  highlighted={highlighted}
                  format={format}
                />
              </>
            )}
          </Fragment>
        );
      })}
    </Box>
  );
};
