import { Badge, Box, Button, IconButton, Tab, Tabs, Typography } from '@mui/material';
import {
  addGroupMember,
  deleteGroup,
  getClients,
  getGroup,
  getGroupMembers,
  removeGroupMember,
} from '@services/api';
import { useApi, useConfirm, useLoader, useUpdateEffect } from '@hooks';
import { useCallback, useEffect, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { AddGroupMemberDialog } from './components/AddGroupMemberDialog';
import { ClientRoles } from '@models/enums/Roles';
import EditFilledIcon from '@assets/icons/item-view/edit-3-filled.svg';
import { IAddGroupMemberData } from '@models/interfaces/additional/IAddGroupMemberData';
import { IClient } from '@models/interfaces/entities/IClient';
import { IGroup } from '@models/interfaces/entities/IGroup';
import { IRoleAssignment } from '@models/interfaces/entities/IRoleAssignment';
import { IUser } from '@models/interfaces/entities/IUser';
import { Loader } from '@components/Loader';
import { MemberCard } from './components/MemberCard';
import PlusIcon from '@assets/icons/item-view/plus-filled.svg';
import { RolesTabView } from './components/RolesTabView';
import TrashFilledIcon from '@assets/icons/item-view/trash-filled.svg';
import clsx from 'clsx';
import { getGroupRoles } from '@services/api/groupRoles';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';

const roles = [ClientRoles.clientManager, ClientRoles.projectManager, ClientRoles.clientUser];

enum GroupTabs {
  members = 0,
  roles = 1,
}

interface IProps {
  groupId: string;
  onGroupChanged: () => void;
}

export const GroupView = ({ onGroupChanged, groupId }: IProps) => {
  const { classes } = useStyles();
  const confirm = useConfirm();
  const [openDialog, setOpenDialog] = useState(false);
  const [group, setGroup] = useState<IGroup | null>(null);
  const [roleAssignments, setRoleAssignments] = useState<IRoleAssignment[]>([]);
  const [clients, setClients] = useState<IClient[]>([]);
  const [members, setMembers] = useState<IUser[]>([]);
  const [activeTab, setActiveTab] = useState<GroupTabs | undefined>(undefined);
  const navigate = useNavigate();

  const {
    request: getGroupRequest,
    loading: getGroupLoading,
    data: getGroupData,
  } = useApi(getGroup, null, { handleErrors: true });

  const { request: deleteGroupRequest, loading: deleteGroupLoading } = useApi(deleteGroup, null, {
    handleErrors: true,
    onCallback: () => {
      toast.info('Successfully deleted the group');
      onGroupChanged();
      navigate('/admin/groups');
    },
  });

  const {
    request: getClientsRequest,
    loading: getClientsLoading,
    data: getClientsData,
  } = useApi(getClients, null, { handleErrors: true });

  const {
    request: getGroupRolesRequest,
    loading: getGroupRolesLoading,
    data: getGroupRolesData,
  } = useApi(getGroupRoles, null, { handleErrors: true });

  const {
    request: getGroupMembersRequest,
    data: getGroupMembersData,
    loading: getGroupMembersLoading,
  } = useApi(getGroupMembers, null, { handleErrors: true });

  const {
    request: removeGroupMemberRequest,
    loading: removeGroupMemberLoading,
    data: removeGroupMemberData,
  } = useApi(removeGroupMember, null, { handleErrors: true });

  const {
    request: addGroupMemberRequest,
    loading: addGroupMemberLoading,
    data: addGroupMemberData,
  } = useApi(addGroupMember, null, { handleErrors: true });

  const onEdit = () => {
    toast.info('Not implemented yet');
  };

  const onAddMember = () => {
    setOpenDialog(true);
  };

  const onDelete = useCallback(async () => {
    if (group?.links[Actions.delete]?.href) {
      await confirm({
        title: 'Delete Group',
        description: 'Are you sure that you want to delete this group?',
        confirmationText: 'Delete',
      });
      deleteGroupRequest(group.links[Actions.delete].href);
    }
  }, [group]);

  const onRemoveMember = async (item: IUser) => {
    if (item.links[Actions.removeMembership]?.href) {
      await confirm({
        title: 'Remove member',
        description: `Are you sure that you want to remove ${item.fullName} from ${group?.name}?`,
        confirmationText: 'Remove',
      });
      removeGroupMemberRequest(item.links[Actions.removeMembership].href);
    }
  };

  const onCloseAddMemberDialog = async (data?: IAddGroupMemberData) => {
    if (!data) {
      setOpenDialog(false);
    } else {
      const url = group?.links[Actions.addUser]?.href;
      if (url) {
        addGroupMemberRequest(url, data);
      }
    }
  };

  const onTabChanged = (tab: GroupTabs) => {
    setActiveTab(tab);
  };

  useUpdateEffect(() => {
    if (getGroupData) {
      setGroup(getGroupData);

      if (getGroupData.links[Actions.getMembers]?.href) {
        setActiveTab(GroupTabs.members);
        getGroupMembersRequest(getGroupData.links[Actions.getMembers].href);
      }

      if (getGroupData.links[Actions.getRoles]?.href) {
        getGroupRolesRequest(getGroupData.links[Actions.getRoles].href);

        getClientsRequest('/clients');
      }
    }
  }, [getGroupData]);

  useUpdateEffect(() => {
    if (getGroupRolesData) {
      setRoleAssignments(getGroupRolesData.items.filter((x) => roles.includes(x.role)));
    }
  }, [getGroupRolesData]);

  useEffect(() => {
    if (getGroupMembersData) {
      setMembers(getGroupMembersData);
    }
  }, [getGroupMembersData]);

  useEffect(() => {
    if (getClientsData) {
      setClients(getClientsData);
    }
  }, [getClientsData]);

  useUpdateEffect(() => {
    if (addGroupMemberData) {
      setOpenDialog(false);
      toast.info('Member successfully added');
      setMembers(addGroupMemberData);
    }
  }, [addGroupMemberData]);

  useUpdateEffect(() => {
    if (removeGroupMemberData) {
      toast.info('Member successfully removed');
      setMembers(removeGroupMemberData);
    }
  }, [removeGroupMemberData]);

  useEffect(() => {
    getGroupRequest(`/groups/${groupId}`);
  }, [groupId]);

  const showLoader = useLoader(
    getGroupLoading,
    getGroupMembersLoading,
    getGroupRolesLoading,
    getClientsLoading,
    removeGroupMemberLoading,
    addGroupMemberLoading,
    deleteGroupLoading,
  );

  return (
    <Box className={classes.container}>
      {group && (
        <>
          <Box className={classes.infoSection}>
            <Box className={classes.title}>
              <Typography variant='h6'>{group.name}</Typography>
              <IconButton onClick={onEdit}>
                <img src={EditFilledIcon} alt='Edit' />
              </IconButton>
              {!!group.links[Actions.delete]?.href && (
                <IconButton onClick={() => onDelete()}>
                  <img src={TrashFilledIcon} alt='Delete' />
                </IconButton>
              )}
            </Box>

            <Box className={classes.tabsContainer}>
              <Tabs value={activeTab} onChange={(e, t) => onTabChanged(t)} aria-label='tabs'>
                {group?.links[Actions.getMembers]?.href && (
                  <Tab
                    id='members-tab'
                    aria-controls='members-tab-panel'
                    label={
                      <Box className={clsx([classes.flexRow, classes.flexGap20])}>
                        <Typography variant='subtitle1'>Members</Typography>
                        <Badge badgeContent={members.length} color='secondary' />
                      </Box>
                    }
                    value={GroupTabs.members}
                  />
                )}
                {group?.links[Actions.getRoles]?.href && (
                  <Tab
                    id='roles-tab'
                    aria-controls='roles-tab-panel'
                    label={
                      <Box className={clsx([classes.flexRow, classes.flexGap20])}>
                        <Typography variant='subtitle1'>Roles</Typography>
                        <Badge badgeContent={roleAssignments.length} color='secondary' />
                      </Box>
                    }
                    value={GroupTabs.roles}
                  />
                )}
              </Tabs>
              {activeTab === GroupTabs.members && !!getGroupData?.links[Actions.addUser]?.href && (
                <Button
                  className={classes.addButton}
                  variant='text'
                  color='info'
                  startIcon={<img src={PlusIcon} alt='Add' />}
                  onClick={onAddMember}
                >
                  Add Member
                </Button>
              )}
            </Box>
          </Box>

          <Box>
            {group?.links[Actions.getMembers]?.href && (
              <Box
                role='tabpanel'
                hidden={activeTab !== GroupTabs.members}
                id='members-tab-panel'
                data-testid='members-tab-panel'
                aria-labelledby='members-tab'
              >
                <Box className={classes.membersList}>
                  {members.map((item) => (
                    <MemberCard key={item.id} member={item} onRemove={() => onRemoveMember(item)} />
                  ))}
                </Box>
              </Box>
            )}
            {group?.links[Actions.getRoles]?.href && (
              <Box
                role='tabpanel'
                hidden={activeTab !== GroupTabs.roles}
                id='roles-tab-panel'
                data-testid='roles-tab-panel'
                aria-labelledby='roles-tab'
              >
                <RolesTabView clients={clients} roleAssignments={roleAssignments} />
              </Box>
            )}
          </Box>
        </>
      )}
      <AddGroupMemberDialog open={openDialog} onClose={onCloseAddMemberDialog} members={members} />
      <Loader show={showLoader} />
    </Box>
  );
};
