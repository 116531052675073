import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import { ISimulation } from '@models/interfaces/entities/ISimulation';
import { ISimulationAbbreviation } from '@models/interfaces/entities/ISimulationAbbreviation';
import { ISimulationAbbreviationDto } from '@models/interfaces/dtos/ISimulationAbbreviationDto';
import { ISimulationDto } from '@models/interfaces/dtos/ISimulationDto';
import SimulationsProvider from '@providers/SimulationsProvider';
import mapper from '@models/mapper';

const simulationsProvider = new SimulationsProvider();

export const getSimulations = async (
  url: string,
  startDate?: Date,
  endDate?: Date,
  scenario?: string,
) => {
  const result = await simulationsProvider.getSimulations(url, startDate, endDate, scenario);
  return {
    startDate,
    endDate,
    scenario,
    items: mapper.mapArray<ISimulationAbbreviationDto, ISimulationAbbreviation>(
      result.items,
      'ISimulationAbbreviationDto',
      'ISimulationAbbreviation',
    ),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const getSimulationsData = async (
  url: string,
  simulationIds?: string[],
  deltas?: string[],
  scenarios?: string[],
) => {
  const result = await simulationsProvider.getSimulationsData(
    url,
    simulationIds,
    deltas,
    scenarios,
  );
  return {
    simulationIds,
    deltas,
    scenarios,
    items: mapper.mapArray<ISimulationDto, ISimulation>(result, 'ISimulationDto', 'ISimulation'),
  };
};
