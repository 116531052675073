import DataRecordsProvider from '@providers/DataRecordsProvider';
import { IFileContent } from '@models/interfaces/entities/IFileContent';
import { IFileContentDto } from '@models/interfaces/dtos/IFileContentDto';
import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import mapper from '@models/mapper';

const dataRecordsProvider = new DataRecordsProvider();

export const getTypeCodeSamples = async (
  url: string,
  code: string,
  categoryId?: string,
  type?: number,
) => {
  const result = await dataRecordsProvider.getTypeCodeSamples(url, code, categoryId, type);
  return {
    items: result.items,
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const getDataContent = async (url: string, categoryId: string) => {
  const result = await dataRecordsProvider.getDataContent(url, categoryId);
  return mapper.map<IFileContentDto, IFileContent>(result, 'IFileContentDto', 'IFileContent');
};
