import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const getVariantOptions = (t: Theme, variant: string) => {
  switch (variant) {
    case 'default':
      return {
        background: t.palette.divider,
        fontWeight: t.typography.caption.fontWeight,
        fontSize: t.typography.caption.fontSize,
        lineHeight: t.typography.caption.lineHeight,
        letterSpacing: t.typography.caption.letterSpacing,
        backgroundColor: t.palette.divider,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 2,
        paddingBottom: 2,
        minWidth: 64,
      };
    case 'dialog-control':
      return {
        backgroundColor: 'transparent',
        fontWeight: t.typography.body2.fontWeight,
        fontSize: t.typography.body2.fontSize,
        lineHeight: t.typography.body2.lineHeight,
        letterSpacing: t.typography.body2.letterSpacing,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 2,
        paddingBottom: 2,
        textTransform: 'none' as const,
      };
    case 'table-control':
      return {
        backgroundColor: 'transparent',
        fontWeight: t.typography.body2.fontWeight,
        fontSize: t.typography.body2.fontSize,
        lineHeight: t.typography.body2.lineHeight,
        letterSpacing: t.typography.body2.letterSpacing,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 2,
        paddingBottom: 2,
        textTransform: 'none' as const,
        flexDirection: 'row-reverse' as const,
        gap: 8,

        '& > span': {
          margin: 0,
        },
      };
    case 'pagination':
      return {
        backgroundColor: 'transparent',
        fontWeight: t.typography.subtitle2.fontWeight,
        fontSize: t.typography.subtitle2.fontSize,
        lineHeight: t.typography.subtitle2.lineHeight,
        letterSpacing: t.typography.subtitle2.letterSpacing,
        padding: '4px 8px 4px 4px',
        minWidth: 40,
      };
    case 'sorting':
      return {
        textTransform: 'none' as const,
        justifyContent: 'flex-end',
        color: t.palette.custom.white[87],
        backgroundColor: 'transparent',
        fontWeight: t.typography.subtitle2.fontWeight,
        fontSize: t.typography.subtitle2.fontSize,
        lineHeight: t.typography.subtitle2.lineHeight,
        letterSpacing: t.typography.subtitle2.letterSpacing,
        padding: '4px 8px 4px 4px',
        minWidth: 120,
      };
    default:
      return { background: t.palette.grey[400] };
  }
};

const useStyles = makeStyles<{ variant: string }>()((t, { variant }) => {
  const options = getVariantOptions(t, variant);
  return {
    button: {
      ...options,
    },
    paper: {
      borderRadius: '0 0 10px 10px',
      boxShadow: '5px 5px 10px #0000001A',
      marginTop: 8,
    },
  };
});

export default useStyles;
