import CheckCircleFilledIcon from '../assets/icons/switch/check-circle-filled.svg';
import XCircleFilledIcon from '../assets/icons/switch/x-circle-filled.svg';
import { createTheme } from '@mui/material';

const percentToHex = (p: number) => {
  const percent = Math.max(0, Math.min(100, p));
  const intValue = Math.round((percent / 100) * 255);
  const hexValue = intValue.toString(16);
  return hexValue.padStart(2, '0').toUpperCase();
};

const createCustomPalette = (hexColor: string) => {
  const palette = new Map<number, string>();
  for (let i = 1; i < 100; i++) {
    palette.set(i, `${hexColor}${percentToHex(i)}`);
  }
  palette.set(100, hexColor);
  return Object.fromEntries(palette);
};

const palette = {
  primary: {
    main: '#484E59',
    dark: '#202630',
    contrastText: '#fff',
  },
  secondary: {
    main: '#132847',
    dark: '#091322',
    contrastText: '#fff',
    500: '#132847',
    700: '#091322',
  },
  text: {
    primary: '#14151b',
  },
  divider: '#14151b10',
  background: {
    default: '#fafafa',
    paper: '#fff',
  },
  error: {
    main: '#C00000',
    contrastText: '#fff',
  },
  info: {
    main: '#71ADB7',
    contrastText: '#fff',
  },
  custom: {
    dark: createCustomPalette('#14151B'),
    white: createCustomPalette('#ffffff'),
    black: createCustomPalette('#000000'),
    primary: createCustomPalette('#484E59'),
    error: createCustomPalette('#C00000'),
    success: createCustomPalette('#2e7d32'),
    info: createCustomPalette('#71ADB7'),
    warning: createCustomPalette('#CC793E'),
    yellow: createCustomPalette('#f8db66'),
    orange: createCustomPalette('#faa84a'),
  },
};
const typography = {
  fontFamily: 'Proxima Nova',
  h1: {
    fontWeight: 300,
    fontSize: '6rem',
    lineHeight: '7rem',
    letterSpacing: '-0.09375rem',
  },
  h2: {
    fontWeight: 300,
    fontSize: '3.75rem',
    lineHeight: '4.5rem',
    letterSpacing: '-0.03125rem',
  },
  h3: {
    fontWeight: 400,
    fontSize: '3rem',
    lineHeight: '3.5rem',
  },
  h4: {
    fontWeight: 600,
    fontSize: '2.125rem',
    lineHeight: '2.25rem',
  },
  h5: {
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.01125rem',
  },
  h6: {
    fontWeight: 600,
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.009375rem',
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.009375rem',
  },
  subtitle2: {
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.00625rem',
  },
  body1: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.03125rem',
  },
  body2: {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    letterSpacing: '0.015625rem',
  },
  button: {
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '1rem',
    letterSpacing: '0.078125rem',
  },
  caption: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    letterSpacing: '0.025rem',
  },
  overline: {
    fontWeight: 600,
    fontSize: '0.625rem',
    lineHeight: '1rem',
    letterSpacing: '0.03125rem',
  },
};

export const theme = createTheme({
  palette,
  typography,
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: `1px solid ${palette.divider}`,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: 24,
          borderRadius: 20,
          backgroundColor: '#fff',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 800,
          fontSize: 24,
          lineHeight: '120%',
          padding: 0,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: 32,
          paddingBottom: 32,
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDivider: {
      variants: [
        {
          props: {
            variant: 'dialog',
          },
          style: {
            marginTop: 8,
          },
        },
      ],
    },
    MuiInputLabel: {
      styleOverrides: {
        standard: {
          color: palette.custom.dark[60],
          top: 8,
          left: 16,
          '&.Mui-focused': {
            color: palette.custom.dark[60],
          },
          '&.Mui-error': {
            color: palette.error.main,
          },
          '&.MuiInputLabel-shrink': {
            left: 0,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        inputMultiline: {
          paddingTop: 4,
          paddingBottom: 5,
        },
        input: {
          ...typography.subtitle1,
          fontWeight: 600,
          height: 40,
          paddingLeft: 16,
          paddingRight: 16,
        },
        root: {
          '&:before': {
            borderColor: palette.divider,
          },
          '& .MuiInputAdornment-positionEnd .MuiIconButton-edgeEnd': {
            marginRight: 0,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          paddingLeft: 16,
          paddingRight: 46,
        },
        endAdornment: {
          paddingRight: 8,
        },
        paper: {
          borderRadius: 0,
          marginTop: 8,
        },
        listbox: { padding: 0 },
        option: {
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 16,
          paddingRight: 16,
          borderBottom: `1px solid ${palette.divider}`,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          borderRadius: 4,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...typography.button,
          lineHeight: '120%',
          borderRadius: 10,
        },
        sizeLarge: {
          fontSize: 16,
          paddingTop: 16,
          paddingBottom: 16,
          paddingLeft: 64,
          paddingRight: 64,

          '&.narrow': {
            paddingLeft: 32,
            paddingRight: 32,
          },
        },
        sizeMedium: {
          fontSize: 15,
          paddingTop: 12,
          paddingBottom: 12,
          paddingLeft: 44,
          paddingRight: 44,

          '&.narrow': {
            paddingLeft: 22,
            paddingRight: 22,
          },

          '&.wide': {
            paddingLeft: 66,
            paddingRight: 66,
          },
        },
        sizeSmall: {
          fontSize: 14,
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 24,
          paddingRight: 24,

          '&.narrow': {
            paddingLeft: 12,
            paddingRight: 12,
          },

          '&.low': {
            paddingTop: 4,
            paddingBottom: 4,
          },
        },
        containedSecondary: {
          '&.Mui-disabled': {
            color: '#fff',
            backgroundColor: palette.secondary[700],
            opacity: 0.4,
          },
        },
        contained: {
          boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.25)',
          ':hover': {
            boxShadow: 'none',
          },
          ':active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          padding: 4,
        },
        message: {
          ...typography.caption,
          padding: 0,
        },
        filled: {
          '&.default': {
            backgroundColor: palette.custom.primary[70],
          },
        },
        filledInfo: {
          backgroundColor: palette.custom.info[100],
        },
        filledWarning: {
          backgroundColor: palette.custom.warning[100],
        },
        filledSuccess: {
          backgroundColor: palette.custom.success[100],
        },
        filledError: {
          backgroundColor: palette.custom.error[100],
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          backgroundImage: `url(${XCircleFilledIcon})`,
          backgroundSize: '90%',
          backgroundPosition: 'center',
          backgroundColor: palette.custom.white[100],
        },
        root: {
          '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
            backgroundImage: `url(${CheckCircleFilledIcon})`,
            backgroundColor: palette.custom.success[100],
          },

          '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: palette.custom.success[60],
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterDelay: 1000,
        enterNextDelay: 1000,
      },
    },
  },
});
