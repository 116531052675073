import { Badge, Box, Button, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  createClientFeature,
  createClientProduct,
  deleteClient,
  deleteClientFeature,
  getClient,
  getClientFeatures,
  getClientProducts,
  getClientRoles,
  getGroups,
  updateClient,
  updateClientRoles,
} from '@services/api';
import { useApi, useAppSelector, useConfirm, useLoader, useUpdateEffect } from '@hooks';

import { Actions } from '@models/enums/Actions';
import { AddGroupDialog } from './components/AddGroupDialog';
import { EditClientDialog } from './components/EditClientDialog';
import EditFilledIcon from '@assets/icons/item-view/edit-3-filled.svg';
import { FeaturesTabView } from './components/FeaturesTabView';
import { GroupsTabView } from './components/GroupsTabView';
import { IClient } from '@models/interfaces/entities/IClient';
import { IClientFeature } from '@models/interfaces/entities/IClientFeature';
import { IClientProduct } from '@models/interfaces/entities/IClientProduct';
import { ICreateClientFeatureData } from '@models/interfaces/additional/ICreateClientFeatureData';
import { IFeature } from '@models/interfaces/entities/IFeature';
import { IGroup } from '@models/interfaces/entities/IGroup';
import { IRoleAssignment } from '@models/interfaces/entities/IRoleAssignment';
import { IUpdateClientData } from '@models/interfaces/additional/IUpdateClientData';
import { IUpdateClientRolesData } from '@models/interfaces/additional/IUpdateClientRolesData';
import { Loader } from '@components/Loader';
import PlusIcon from '@assets/icons/item-view/plus-filled.svg';
import { ProductsTabView } from './components/ProductsTabView';
import TrashFilledIcon from '@assets/icons/item-view/trash-filled.svg';
import clsx from 'clsx';
import { selectAuthGetGroupsActionLink } from '@reducers/authSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';

enum ClientTabs {
  products = 0,
  groups = 1,
  features = 2,
}

interface IProps {
  clientId: string;
  onClientChanged: () => void;
}

export const ClientView = ({ onClientChanged, clientId }: IProps) => {
  const { classes } = useStyles();
  const confirm = useConfirm();
  const getGroupsActionLink = useAppSelector(selectAuthGetGroupsActionLink);
  const [client, setClient] = useState<IClient | null>(null);
  const [roleAssignments, setRoleAssignments] = useState<IRoleAssignment[]>([]);
  const [clientProducts, setClientProducts] = useState<IClientProduct[]>([]);
  const [clientFeatures, setClientFeatures] = useState<IClientFeature[]>([]);
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openAddGroupDialog, setOpenAddGroupDialog] = useState(false);
  const [activeTab, setActiveTab] = useState<ClientTabs | undefined>(undefined);
  const navigate = useNavigate();

  const { request: updateClientRequest, loading: updateClientLoading } = useApi(
    updateClient,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        setClient(data);
        setOpenEditDialog(false);
        toast.info('Successfully updated the client');
        onClientChanged();
      },
    },
  );

  const {
    request: getClientRequest,
    loading: getClientLoading,
    data: getClientData,
  } = useApi(getClient, null, { handleErrors: true });

  const {
    request: getClientProductsRequest,
    data: getClientProductsData,
    loading: getClientProductsLoading,
  } = useApi(getClientProducts, null, { handleErrors: true });

  const {
    request: getClientFeaturesRequest,
    data: getClientFeaturesData,
    loading: getClientFeaturesLoading,
  } = useApi(getClientFeatures, null, { handleErrors: true });

  const {
    request: createClientProductRequest,
    data: createClientProductData,
    loading: createClientProductLoading,
  } = useApi(createClientProduct, null, { handleErrors: true });

  const {
    request: createClientFeaturesRequest,
    data: createClientFeaturesData,
    loading: createClientFeaturesLoading,
  } = useApi(
    async (createLink: string, data: ICreateClientFeatureData[]) => {
      if (data.length) {
        let result: IClientFeature[] | null = null;
        for (const item of data) {
          result = await createClientFeature(createLink, item);
        }
        return result;
      }
    },
    null,
    { handleErrors: true },
  );

  const { request: deleteClientFeaturesRequest, loading: deleteClientFeaturesLoading } = useApi(
    async (deleteLinks: string[]) => {
      if (deleteLinks.length) {
        for (const link of deleteLinks) {
          await deleteClientFeature(link);
        }
        reloadClientFeatures();
      }
    },
    null,
    {
      handleErrors: true,
    },
  );

  const {
    request: getClientRolesRequest,
    loading: getClientRolesLoading,
    data: getClientRolesData,
  } = useApi(getClientRoles, null, { handleErrors: true });

  const {
    request: getGroupsRequest,
    loading: getGroupsLoading,
    data: getGroupsData,
  } = useApi(getGroups, { items: [], links: {} }, { handleErrors: true });

  const { request: deleteClientRequest, loading: deleteClientLoading } = useApi(
    deleteClient,
    null,
    {
      handleErrors: true,
      onCallback: () => {
        toast.info('Successfully deleted the client');
        onClientChanged();
        navigate('/admin/clients');
      },
    },
  );

  const {
    request: updateClientRolesRequest,
    loading: updateClientRolesLoading,
    data: updateClientRolesData,
  } = useApi(updateClientRoles, null, { handleErrors: true });

  const onCloseEditDialog = async (item?: IUpdateClientData) => {
    if (!item) {
      setOpenEditDialog(false);
    } else {
      const url = getClientData?.links[Actions.update]?.href;
      if (url) {
        updateClientRequest(url, item);
      }
    }
  };

  const onEdit = () => {
    setOpenEditDialog(true);
  };

  const onDelete = async () => {
    if (client?.links[Actions.delete]?.href) {
      await confirm({
        title: 'Delete Client',
        description: 'Are you sure that you want to delete this client?',
        confirmationText: 'Delete',
      });
      deleteClientRequest(client.links[Actions.delete].href);
    }
  };

  const onAddGroup = () => {
    setOpenAddGroupDialog(true);
  };

  const onCloseAddGroupDialog = async (data?: IUpdateClientRolesData) => {
    if (!data) {
      setOpenAddGroupDialog(false);
    } else {
      const url = getClientRolesData?.links[Actions.edit]?.href;
      if (url) {
        updateClientRolesRequest(url, data);
      }
    }
  };

  const onRoleAssignmentsChanged = (data: IUpdateClientRolesData) => {
    if (getClientRolesData?.links[Actions.edit]?.href) {
      updateClientRolesRequest(getClientRolesData.links[Actions.edit].href, data);
    }
  };

  const onActivateClientProduct = async (productId: string) => {
    if (getClientData?.links[Actions.addProduct]?.href) {
      await confirm({
        title: 'Activate Product',
        description: 'Are you sure that you want to activate this product?',
        confirmationText: 'Activate',
      });
      createClientProductRequest(getClientData?.links[Actions.addProduct]?.href, { productId });
    }
  };

  const onActivateClientFeatures = async (featureId: string, roles: string[]) => {
    if (getClientData?.links[Actions.addFeature]?.href && roles.length) {
      await confirm({
        title: 'Enable Feature',
        description: 'Are you sure that you want to enable this feature?',
        confirmationText: 'Enable',
      });
      createClientFeaturesRequest(
        getClientData.links[Actions.addFeature].href,
        roles.map((x) => ({
          featureId,
          clientId: getClientData.id,
          role: x,
        })),
      );
    }
  };

  const onDeactivateClientFeatures = async (
    features: Array<IFeature & { role: string } & { clientFeature?: IClientFeature }>,
  ) => {
    const deleteLinks = features
      .filter((x) => x.clientFeature?.links[Actions.delete]?.href)
      .map((x) => x.clientFeature?.links[Actions.delete]?.href)
      .filter((href): href is string => !!href);

    if (deleteLinks.length > 0) {
      await confirm({
        title: 'Disable Feature',
        description: 'Are you sure that you want to disable this feature?',
        confirmationText: 'Disable',
      });

      await deleteClientFeaturesRequest(deleteLinks);
    }
  };

  const onTabChanged = (tab: ClientTabs) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (getGroupsActionLink) {
      getGroupsRequest(getGroupsActionLink.href);
    }
  }, [getGroupsActionLink]);

  useEffect(() => {
    if (getGroupsData) {
      setGroups(getGroupsData.items);
    }
  }, [getGroupsData]);

  useUpdateEffect(() => {
    if (getClientData) {
      setClient(getClientData);
      if (getClientData.links[Actions.getRoles]?.href) {
        setActiveTab(ClientTabs.groups);
        getClientRolesRequest(getClientData.links[Actions.getRoles].href);
      }
      if (getClientData?.links[Actions.getProducts]?.href) {
        getClientProductsRequest(getClientData?.links[Actions.getProducts]?.href);
      }
      if (getClientData?.links[Actions.getFeatures]?.href) {
        getClientFeaturesRequest(getClientData?.links[Actions.getFeatures]?.href, true);
      }
    }
  }, [getClientData]);

  useUpdateEffect(() => {
    if (getClientRolesData) {
      setRoleAssignments(getClientRolesData.items);
    }
  }, [getClientRolesData]);

  useUpdateEffect(() => {
    if (updateClientRolesData) {
      setRoleAssignments(updateClientRolesData.items);
      if (openAddGroupDialog) {
        toast.info('Successfully added the group');
        setOpenAddGroupDialog(false);
      }
    }
  }, [updateClientRolesData]);

  useUpdateEffect(() => {
    if (getClientProductsData) {
      setClientProducts(getClientProductsData);
    }
  }, [getClientProductsData]);

  useUpdateEffect(() => {
    if (createClientProductData) {
      setClientProducts(createClientProductData);
    }
  }, [createClientProductData]);

  useUpdateEffect(() => {
    if (getClientFeaturesData) {
      setClientFeatures(getClientFeaturesData);
    }
  }, [getClientFeaturesData]);

  useUpdateEffect(() => {
    if (createClientFeaturesData) {
      setClientFeatures(createClientFeaturesData);
    }
  }, [createClientFeaturesData]);

  useEffect(() => {
    if (clientId) {
      getClientRequest(`/clients/${clientId}`);
    }
  }, [clientId]);

  const reloadClientFeatures = () => {
    if (getClientData?.links[Actions.getFeatures]?.href) {
      getClientFeaturesRequest(getClientData?.links[Actions.getFeatures]?.href, true);
    }
  };

  const infoItems = useMemo<{ value: string; label: string; action?: ReactNode }[]>(() => {
    return [
      {
        label: 'Account Number',
        value: client?.accountNumber || '-',
      },
      {
        label: 'NetSuite Id',
        value: client?.externalId || '-',
      },
      {
        label: 'Type',
        value: client?.institutionType || '-',
      },
      {
        label: 'State',
        value: client?.addressState || '-',
      },
    ];
  }, [client, roleAssignments]);

  const showLoader = useLoader(
    getClientLoading,
    deleteClientLoading,
    updateClientLoading,
    getGroupsLoading,
    getClientRolesLoading,
    updateClientRolesLoading,
    getClientProductsLoading,
    createClientProductLoading,
    getClientFeaturesLoading,
    createClientFeaturesLoading,
    deleteClientFeaturesLoading,
  );

  return (
    <Box className={classes.container}>
      {client && (
        <>
          <Box className={classes.infoSection}>
            <Box className={classes.title}>
              <Typography variant='h6'>{client.name}</Typography>
              {!!client?.links[Actions.update]?.href && (
                <IconButton onClick={onEdit}>
                  <img src={EditFilledIcon} alt='Edit' />
                </IconButton>
              )}
              {!!client?.links[Actions.delete]?.href && (
                <IconButton onClick={onDelete}>
                  <img src={TrashFilledIcon} alt='Delete' />
                </IconButton>
              )}
            </Box>
            <Box className={classes.infoContent}>
              {infoItems.map((item) => (
                <Box key={item.label} className={classes.infoItem}>
                  <Typography className='label' variant='caption'>
                    {item.label}
                  </Typography>
                  <Box className={classes.flexRow}>
                    <Typography variant='subtitle1'>{item.value}</Typography>
                    {item.action}
                  </Box>
                </Box>
              ))}
            </Box>
            <Box className={classes.tabsContainer}>
              <Tabs value={activeTab} onChange={(e, t) => onTabChanged(t)} aria-label='tabs'>
                {client?.links[Actions.getProducts]?.href && (
                  <Tab
                    id='products-tab'
                    aria-controls='products-tab-panel'
                    label={
                      <Box className={clsx([classes.flexRow, classes.flexGap20])}>
                        <Typography variant='subtitle1'>Products</Typography>
                        <Badge badgeContent={clientProducts.length} color='secondary' />
                      </Box>
                    }
                    value={ClientTabs.products}
                  />
                )}
                {client?.links[Actions.getFeatures]?.href && (
                  <Tab
                    id='features-tab'
                    aria-controls='features-tab-panel'
                    label={
                      <Box className={clsx([classes.flexRow, classes.flexGap20])}>
                        <Typography variant='subtitle1'>Features</Typography>
                        <Badge badgeContent={clientFeatures.length} color='secondary' />
                      </Box>
                    }
                    value={ClientTabs.features}
                  />
                )}
                {client?.links[Actions.getRoles]?.href && (
                  <Tab
                    id='groups-tab'
                    aria-controls='groups-tab-panel'
                    label={
                      <Box className={clsx([classes.flexRow, classes.flexGap20])}>
                        <Typography variant='subtitle1'>Groups</Typography>
                        <Badge badgeContent={roleAssignments.length} color='secondary' />
                      </Box>
                    }
                    value={ClientTabs.groups}
                  />
                )}
              </Tabs>
              {activeTab === ClientTabs.groups && getClientRolesData?.links[Actions.edit]?.href && (
                <Button
                  className={classes.addButton}
                  variant='text'
                  color='info'
                  startIcon={<img src={PlusIcon} alt='Add' />}
                  onClick={onAddGroup}
                >
                  Add Group
                </Button>
              )}
            </Box>
          </Box>
          <Box>
            {client?.links[Actions.getProducts]?.href && (
              <Box
                role='tabpanel'
                hidden={activeTab !== ClientTabs.products}
                id='products-tab-panel'
                data-testid='products-tab-panel'
                aria-labelledby='products-tab'
              >
                <ProductsTabView
                  clientProducts={clientProducts}
                  onActivateProduct={onActivateClientProduct}
                  isActivationEnabled={!!getClientData?.links[Actions.addProduct]?.href}
                />
              </Box>
            )}
            {client?.links[Actions.getFeatures]?.href && (
              <Box
                role='tabpanel'
                hidden={activeTab !== ClientTabs.features}
                id='features-tab-panel'
                data-testid='features-tab-panel'
                aria-labelledby='features-tab'
              >
                <FeaturesTabView
                  clientFeatures={clientFeatures}
                  onActivateFeatures={onActivateClientFeatures}
                  onDeactivateFeatures={onDeactivateClientFeatures}
                  isActivationEnabled={!!getClientData?.links[Actions.addFeature]?.href}
                />
              </Box>
            )}
            {client?.links[Actions.getRoles]?.href && (
              <Box
                role='tabpanel'
                hidden={activeTab !== ClientTabs.groups}
                id='groups-tab-panel'
                data-testid='groups-tab-panel'
                aria-labelledby='groups-tab'
              >
                <GroupsTabView
                  groups={groups}
                  roleAssignments={roleAssignments}
                  onChanged={onRoleAssignmentsChanged}
                />
              </Box>
            )}
          </Box>
          <EditClientDialog client={client} open={openEditDialog} onClose={onCloseEditDialog} />
        </>
      )}
      <AddGroupDialog
        groups={groups}
        roleAssignments={roleAssignments}
        open={openAddGroupDialog}
        onClose={onCloseAddGroupDialog}
      />
      <Loader show={showLoader} />
    </Box>
  );
};
