import { ReportSectionLineValue, ReportSectionLineValueAlignment } from '../ReportSectionLineValue';

import { Box } from '@mui/material';
import clsx from 'clsx';
import useStyles from './styles';

export interface IReportSectionHeader {
  configurationHeader: string;
  dataHeaders: string[];
  additionalHeader?: string;
}

interface IProps {
  item: IReportSectionHeader;
  dataColumnsCount: number;
  hideAdditional?: boolean;
}

export const ReportSectionHeader = ({
  item: { configurationHeader, dataHeaders, additionalHeader },
  dataColumnsCount,
  hideAdditional,
}: IProps) => {
  const { classes } = useStyles({
    dataColumnsCount: dataColumnsCount,
    hideAdditional: hideAdditional,
  });

  const normalizedDataHeaders = (() => {
    if (dataHeaders.length === dataColumnsCount) {
      return dataHeaders;
    }

    if (dataHeaders.length < dataColumnsCount) {
      const missingCount = dataColumnsCount - dataHeaders.length;
      return [...dataHeaders, ...Array(missingCount).fill('')];
    }

    return dataHeaders.slice(0, dataColumnsCount);
  })();

  return (
    <Box className={classes.root}>
      <Box className={clsx([classes.colSpan9, classes.textBold])}>
        <ReportSectionLineValue
          item={{ value: configurationHeader, bolded: true }}
          alignment={ReportSectionLineValueAlignment.left}
        />
      </Box>
      <Box />
      {normalizedDataHeaders.map((header, i) => (
        <ReportSectionLineValue key={i} item={{ value: header }} />
      ))}
      {!hideAdditional && (
        <>
          <Box />
          <ReportSectionLineValue item={{ value: additionalHeader || '' }} />
        </>
      )}
    </Box>
  );
};
