import { Accept, useDropzone } from 'react-dropzone';
import { Box, Button, Tooltip } from '@mui/material';
import {
  IJsonGLSubAccountsData,
  IJsonGLSubAccountsDataSchema,
} from '@models/interfaces/additional/IJsonGLSubAccountsData';
import {
  IUpdateAccountsData,
  IUpdateAccountsDataAccount,
} from '@models/interfaces/additional/IUpdateAccountsData';
import {
  IUpdateSubAccountsData,
  IUpdateSubAccountsDataSubAccount,
  IUpdateSubAccountsDataSubAccountTierLimit,
} from '@models/interfaces/additional/IUpdateSubAccountsData';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { updateAccounts, updateSubAccounts } from '@services/api';
import { useApi, useLoader } from '@hooks';

import { IJsonAccountData } from '@models/interfaces/additional/IJsonAccountData';
import { ILink } from '@models/interfaces/entities/ILink';
import JSZip from 'jszip';
import { Loader } from '@components/Loader';
import Papa from 'papaparse';
import { PathReporter } from 'io-ts/lib/PathReporter';
import { SubAccountCategory } from '@models/enums/SubAccountCategory';
import mapper from '@models/mapper';
import { toast } from 'react-toastify';

const simulationFormatError =
  'This simulation has not been converted to v8 format. Please load the simulation in v8 of the Decision Model and upload the updated simulation file.';

const accountsNameConflictError = 'This simulation contains multiple accounts with the same name.';
const missingAccountConflictError =
  // eslint-disable-next-line quotes
  "This simulation contains type code mappings to accounts that don't exist.";

const otherAccounts = [
  { name: 'Cash', type: 90 },
  { name: 'OtherAsset', type: 2 },
  { name: 'OtherLiab', type: 3 },
  { name: 'AllowanceForCreditLoss', type: 91 },
  { name: 'Capital', type: 92 },
];

interface IProps {
  id?: string;
  updateAccountsLink?: ILink;
  updateSubAccountsLink?: ILink;
  maxFileSize: number;
  accept: Accept | undefined;
  onFileSelected?: (fileName: string, clientNumber: string) => void;
}

export const SimulationImporter = forwardRef(function SimulationImporter(
  { maxFileSize, accept, onFileSelected }: IProps,
  ref: React.Ref<unknown>,
) {
  const [accountsData, setAccountsData] = useState<IUpdateAccountsData>();
  const [subAccountsData, setSubAccountsData] = useState<IUpdateSubAccountsData>();

  const { request: importSimulationRequest, loading: importSimulationLoading } = useApi(
    async (
      updateAccountsLink: string,
      updateSubAccountsLink: string,
      updateAccountsData: IUpdateAccountsData,
      updateSubAccountsData: IUpdateSubAccountsData,
    ) => {
      try {
        await updateAccounts(updateAccountsLink, updateAccountsData);
        await updateSubAccounts(updateSubAccountsLink, updateSubAccountsData);
        toast.info('Successfully imported the simulation');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        const message = err?.response?.data?.title || err?.message || 'Unexpected Error!';
        console.error('Error in importSimulationRequest:', err);

        if (
          message.startsWith(accountsNameConflictError) ||
          message.startsWith(missingAccountConflictError)
        )
          throw err;
        throw new Error('Failed to import the simulation');
      }
    },
    null,
    {
      handleErrors: true,
    },
  );

  const extractGLSubAccountsData = useCallback(async (file: JSZip.JSZipObject) => {
    const fileContent = await file.async('string');

    try {
      const jsonData = JSON.parse(fileContent);
      const jsonDataRoot = jsonData.value0;

      const result = IJsonGLSubAccountsDataSchema.decode(jsonDataRoot);

      if (result._tag === 'Right') {
        const resultData: IJsonGLSubAccountsData = result.right;
        return resultData;
      } else {
        console.error('Validation errors:', PathReporter.report(result));
        throw new Error(simulationFormatError);
      }
    } catch (error) {
      console.error('Error parsing JSON file:', error);
      if ((error as { message?: string })?.message === simulationFormatError) throw error;
      throw new Error('Failed to parse the General Ledger subaccounts file.');
    }
  }, []);

  const readCSVFile = useCallback(async (file: JSZip.JSZipObject) => {
    const content = await file.async('string');

    return new Promise<string[][]>((resolve, reject) => {
      Papa.parse<string[]>(content, {
        delimiter: ',',
        worker: true,
        error() {
          reject('Failed to parse the file');
        },
        complete({ data }) {
          resolve(data);
        },
      });
    });
  }, []);

  const extractCategoryFromFileName = useCallback((fileName: string) => {
    const match = fileName.match(/CMSubBud(\w+)\.dat$/);
    const category = match ? match[1] : '';
    switch (category) {
      case 'BR':
        return SubAccountCategory.borrowings;
      case 'CD':
        return SubAccountCategory.termDeposits;
      case 'IN':
        return SubAccountCategory.investments;
      case 'LN':
        return SubAccountCategory.loans;
      case 'SH':
        return SubAccountCategory.nonMaturityDeposits;
      case 'GL':
        return SubAccountCategory.generalLedger;
      default:
        return SubAccountCategory.unknown;
    }
  }, []);

  const extractOtherSubAccountsData = useCallback(
    async (file: JSZip.JSZipObject, category: SubAccountCategory) => {
      try {
        const data = await readCSVFile(file);

        const subAccounts: IUpdateSubAccountsDataSubAccount[] = [];

        let detectedThreeTierLimits = false;
        let detectedBalanceAndBalanceProgressive = false;

        for (const row of data) {
          if (row.length < 10) {
            continue;
          }

          const token = row[0];
          const subAcctId = row[1];
          const accountType = Number(row[2]);

          let index = 3;
          const tierLimits: IUpdateSubAccountsDataSubAccountTierLimit[] = [];
          while (row[index] !== '' || row[index + 1] !== '0' || row[index + 2] !== '0') {
            if (!row[index] && !row[index + 1] && !row[index + 2]) {
              throw Error('Detected row with incorrect format');
            }

            tierLimits.push({
              name: row[index],
              lower: Number(row[index + 1]),
              upper: Number(row[index + 2]),
            });
            index += 3;
          }

          if (
            tierLimits.length === 3 &&
            tierLimits.find((x) => x.name === 'coupon') &&
            tierLimits.find((x) => x.name === 'seasonality') &&
            tierLimits.find((x) => x.name === 'term')
          ) {
            detectedThreeTierLimits = true;
          }

          if (
            tierLimits.length === 2 &&
            tierLimits.find((x) => x.name === 'balance') &&
            tierLimits.find((x) => x.name === 'balance_progressive')
          ) {
            detectedBalanceAndBalanceProgressive = true;
          }

          const subAcctDesc = row[index + 3];
          const downloadId = row[index + 4];
          const subAccountIdFormat = row[index + 5];
          const accountName = row[index + 6];

          const ignored = downloadId.toUpperCase() === 'IGNORE';

          subAccounts.push({
            token,
            subAcctId,
            accountType,
            tierLimits,
            subAcctDesc,
            downloadId: ignored ? '' : downloadId,
            subAccountIdFormat,
            accountName,
            category,
            ignored,
          });
        }

        if (detectedThreeTierLimits) {
          toast.warning(
            // eslint-disable-next-line quotes
            "Loan type code mapping has nested 'Coupon', 'Seasonality', and 'Term' tier limits.  This scenario is not currently supported.  Please contact c. myers development.",
          );
        }

        if (detectedBalanceAndBalanceProgressive) {
          toast.warning(
            // eslint-disable-next-line quotes
            "Non-Maturity type code mapping has nested 'Balance' and 'Progressive Balance' tier limits.  This scenario is not currently supported.  Please contact c. myers development.",
          );
        }

        return subAccounts;
      } catch (error) {
        console.error('Error parsing DAT file:', error);
        throw new Error(`Failed to parse the ${category} subaccounts file.`);
      }
    },
    [readCSVFile],
  );

  const parseAccounts = useCallback(async (file: JSZip.JSZipObject) => {
    const fileContent = await file.async('string');

    const jsonData = JSON.parse(fileContent);

    const clientNumber = jsonData.ClientNumber;

    if (!clientNumber) {
      toast.error('Client number does not exist.');
      throw new Error('Failed to parse the accounts file.');
    }

    const accountsB = jsonData.AccountsB;

    if (Array.isArray(accountsB)) {
      const mappedRegularAccounts: IUpdateAccountsDataAccount[] = [];
      const mappedSummaryAccounts: IUpdateAccountsDataAccount[] = [];

      accountsB.forEach((account) => {
        const mappedAccount = mapper.map<IJsonAccountData, IUpdateAccountsDataAccount>(
          account,
          'IJsonAccountData',
          'IUpdateAccountsDataAccount',
        );

        if (mappedAccount.sumCode === 0) {
          mappedRegularAccounts.push(mappedAccount);
        } else if (mappedAccount.sumCode === 1) {
          mappedSummaryAccounts.push(mappedAccount);
        } else if (mappedAccount.sumCode === 2) {
          if (mappedSummaryAccounts.length > 0) {
            const lastSummaryAccount = mappedSummaryAccounts[mappedSummaryAccounts.length - 1];
            if (!Array.isArray(lastSummaryAccount.detailAccounts)) {
              lastSummaryAccount.detailAccounts = [mappedAccount];
            } else {
              lastSummaryAccount.detailAccounts.push(mappedAccount);
            }
          } else {
            throw new Error('Detected the detail account without related summary account');
          }
        }
      });

      const otherMappedAccounts = otherAccounts
        .map(({ name, type }) => {
          const acc = jsonData[name] as { Name: string };
          return {
            fileId: acc.Name,
            accountName: acc.Name,
            accountCategory: '',
            sumCode: 0,
            downloadId: acc.Name,
            accountType: type,
            tierGroup: '',
          } as IUpdateAccountsDataAccount;
        })
        .filter((x) => !!x.accountName);

      return {
        clientNumber,
        accounts: [...mappedRegularAccounts, ...mappedSummaryAccounts, ...otherMappedAccounts],
      };
    } else {
      toast.error('Accounts list does not exist.');
      throw new Error('Failed to parse the accounts file.');
    }
  }, []);

  const parseSubAccounts = useCallback(
    async (files: JSZip.JSZipObject[]) => {
      let otherSubAccounts: IUpdateSubAccountsDataSubAccount[] = [];
      let glSubAccounts: IJsonGLSubAccountsData | undefined = undefined;

      for (const file of files) {
        const category = extractCategoryFromFileName(file.name);
        if (category === SubAccountCategory.unknown) continue;

        if (category === SubAccountCategory.generalLedger) {
          glSubAccounts = await extractGLSubAccountsData(file);
        } else {
          const categorySubAccounts = await extractOtherSubAccountsData(file, category);
          otherSubAccounts = [...otherSubAccounts, ...categorySubAccounts];
        }
      }

      if (!glSubAccounts) throw new Error('General Ledger subaccounts are missing');

      const updateSubAccountsData = mapper.map<IJsonGLSubAccountsData, IUpdateSubAccountsData>(
        glSubAccounts,
        'IJsonGLSubAccountsData',
        'IUpdateSubAccountsData',
      );
      updateSubAccountsData.SubAccounts = otherSubAccounts;

      return updateSubAccountsData;
    },
    [extractCategoryFromFileName, extractGLSubAccountsData, extractOtherSubAccountsData],
  );

  const onDropFiles = useCallback(
    async (acceptedFiles: File[]) => {
      if (!acceptedFiles.length) return;
      const [file] = acceptedFiles;

      if (!file.name.toLowerCase().endsWith('.cmdm')) {
        toast.error('Only .cmdm files are allowed.');
        return;
      }

      try {
        const zip = await JSZip.loadAsync(file);

        const accountsFileName = 'CMBasicB.json';
        const accountsFile = zip.file(accountsFileName);

        const subAccountsFileNames = [
          'CMSubBudBR.dat',
          'CMSubBudCD.dat',
          'CMSubBudGL.dat',
          'CMSubBudIN.dat',
          'CMSubBudLN.dat',
          'CMSubBudSH.dat',
        ];
        const subAccountsFiles = subAccountsFileNames.map((x) => zip.file(x));

        if (accountsFile && subAccountsFiles.length === 6 && subAccountsFiles.every((x) => !!x)) {
          const { clientNumber, accounts } = await parseAccounts(accountsFile);
          setAccountsData(accounts);
          setSubAccountsData(await parseSubAccounts(subAccountsFiles as JSZip.JSZipObject[]));

          onFileSelected && onFileSelected(file.name, clientNumber);
        } else {
          throw new Error(simulationFormatError);
        }
      } catch (error) {
        console.error('Error processing simulation file:', error);
        if ((error as { message?: string })?.message === simulationFormatError) {
          toast.error((error as { message?: string }).message);
        } else {
          toast.error('Failed to extract contents from the zip file.');
        }
      }
    },
    [parseAccounts, parseSubAccounts, onFileSelected],
  );

  const onImport = useCallback(
    async (updateAccountsLink: ILink, updateSubAccountsLink: ILink) => {
      if (accountsData && subAccountsData) {
        await importSimulationRequest(
          updateAccountsLink.href,
          updateSubAccountsLink.href,
          accountsData,
          subAccountsData,
        );
      } else {
        toast.error('Simulation data is not loaded');
      }
    },
    [accountsData, subAccountsData],
  );

  useImperativeHandle(ref, () => ({
    import: onImport,
  }));

  const dropzone = useDropzone({
    accept,
    multiple: false,
    noDragEventsBubbling: true,
    preventDropOnDocument: false,
    onDrop: onDropFiles,
    onDropRejected(fr) {
      toast.info(
        `File skipped due to ${
          fr[0]?.errors[0] ? `error: ${fr[0]?.errors[0].message}` : 'unknown error'
        }`,
      );
    },
    maxSize: maxFileSize * 1024 * 1024,
  });

  const onUploadButtonClicked = () => {
    dropzone.open();
  };

  const showLoader = useLoader(importSimulationLoading);

  return (
    <>
      <Box>
        <Tooltip title='Import simulation'>
          <Button onClick={onUploadButtonClicked} variant='contained' color='info'>
            Select
          </Button>
        </Tooltip>
        <Box {...dropzone.getRootProps()}>
          <input data-testid='dropzone-input' {...dropzone.getInputProps()} />
        </Box>
      </Box>

      <Loader show={showLoader} />
    </>
  );
});
