export enum Actions {
  self = 'self',
  insert = 'insert',
  edit = 'edit',
  uploadFile = 'uploadFile',
  uploadResult = 'uploadResult',
  getFiles = 'getFiles',
  getFilesNames = 'getFilesNames',
  getFilesCount = 'getFilesCount',
  getFilesDirectory = 'getFilesDirectory',
  getClients = 'getClients',
  getClient = 'getClient',
  getCategories = 'getCategories',
  getPiiCategories = 'getPiiCategories',
  getStandardPiiCategories = 'getStandardPiiCategories',
  getFileCategories = 'getFileCategories',
  getWhitelistRecords = 'getWhitelistRecords',
  createWhitelistRecord = 'createWhitelistRecord',
  getFileSubCategories = 'getFileSubCategories',
  createClient = 'createClient',
  getUsers = 'getUsers',
  createUser = 'createUser',
  getMemberships = 'getMemberships',
  getGroups = 'getGroups',
  createGroup = 'createGroup',
  getMembers = 'getMembers',
  addUser = 'addUser',
  removeMembership = 'removeMembership',
  removeMember = 'removeMember',
  addMembership = 'addMembership',
  delete = 'delete',
  update = 'update',
  getProducts = 'getProducts',
  getFeatures = 'getFeatures',
  getProjects = 'getProjects',
  getPreviousProject = 'getPreviousProject',
  createProduct = 'createProduct',
  getPiiIncidents = 'getPiiIncidents',
  getContent = 'getContent',
  getContentPreview = 'getContentPreview',
  getDataExtractionResultContent = 'getDataExtractionResultContent',
  getTransformationResultContent = 'getTransformationResultContent',
  getTransformationResultContentPreview = 'getTransformationResultContentPreview',
  getTransformationResultLogs = 'getTransformationResultLogs',
  getCleanupResultContent = 'getCleanupResultContent',
  getCleanupResultLogs = 'getCleanupResultLogs',
  getRoles = 'roles',
  addProduct = 'addProduct',
  addFeature = 'addFeature',
  addClient = 'addClient',
  createProject = 'createProject',
  getDetails = 'getDetails',
  updateUserStatus = 'updateUserStatus',
  changeCategory = 'changeCategory',
  changeMapping = 'changeMapping',
  changeTransformationScript = 'changeTransformationScript',
  reopen = 'reopen',
  start = 'start',
  close = 'close',
  restart = 'restart',
  confirm = 'confirm',
  createCategory = 'createCategory',
  updateCategory = 'updateCategory',
  getActivities = 'getActivities',
  createSubCategory = 'createSubCategory',
  getFields = 'getFields',
  getMappings = 'getMappings',
  getTransformationScript = 'getTransformationScript',
  getMapping = 'getMapping',
  createTransformationScript = 'createTransformationScript',
  createMapping = 'createMapping',
  clone = 'clone',
  createField = 'createField',
  createResultsUploadedNotification = 'createResultsUploadedNotification',
  updateOrder = 'updateOrder',
  ignore = 'ignore',
  unignore = 'unignore',
  getAccounts = 'getAccounts',
  importAccounts = 'importAccounts',
  importSubAccounts = 'importSubAccounts',
  importMapping = 'importMapping',
  getBalances = 'getBalances',
  getAlert = 'getAlert',
  changeStartDate = 'changeStartDate',
  getTypeCodes = 'getTypeCodes',
  getTypeCodesCount = 'getTypeCodesCount',
  getNewTypeCodes = 'getNewTypeCodes',
  updateNewTypeCode = 'updateNewTypeCode',
  getTypeCodeSamples = 'getTypeCodeSamples',
  getData = 'getData',
  getAdjustmentDefinitions = 'getAdjustmentDefinitions',
  getAdjustments = 'getAdjustments',
  getTotalAdjustments = 'getTotalAdjustments',
  getScript = 'getScript',
  updateScript = 'updateScript',
  updateDefinition = 'updateDefinition',
  createAdjustmentDefinition = 'createAdjustmentDefinition',
  deleteDefinition = 'deleteDefinition',
  createAccount = 'createAccount',
  getRecalculationStatus = 'getRecalculationStatus',
  getSimulations = 'getSimulations',
}
