import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 16,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr 1fr',
    gridGap: 8,
    borderRadius: 10,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: t.palette.custom.dark[10],
    backgroundColor: t.palette.custom.white[100],
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  topHeaderCell: {
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  sideHeaderCell: {
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    gap: 8,
  },
  switch: {
    marginLeft: -12,
  },
}));

export default useStyles;
