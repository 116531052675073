import { Box, Collapse, Typography } from '@mui/material';

import { AdjustmentDetailsViewMode } from '../BalancingItem';
import { AdjustmentOperation } from '../../common/AdjustmentOperation';
import { AdjustmentsTable } from '../AdjustmentsTable';
import { BalanceAdjustmentsTable } from '../BalanceAdjustmentsTable';
import { BalanceSheetTable } from '../BalanceSheetTable';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { ICreateAdjustmentDefinitionData } from '@models/interfaces/additional/ICreateAdjustmentDefinitionData';
import { IProject } from '@models/interfaces/entities/IProject';
import { IUpdateAdjustmentDefinitionData } from '@models/interfaces/additional/IUpdateAdjustmentDefinitionData';
import { SourceBalanceDetails } from '../SourceBalanceDetails';
import clsx from 'clsx';
import useStyles from './styles';

interface IProps {
  project: IProject;
  accounts: IAccount[];
  adjustments: IAdjustment[];
  balanceAdjustments: IAdjustment[];
  types: number[];
  balancingType: number;
  category: string;
  availableOperations?: AdjustmentOperation[];
  balanceSheetTotal: number;
  accountsTotal: number;
  receivedSectionAdjustment: number;
  sentToOtherSectionAdjustment: number;
  isBalanced: boolean;
  withTopPadding?: boolean;
  manageAdjustmentDefinitions: (
    create?: { url: string; data: ICreateAdjustmentDefinitionData[] },
    update?: { url: string; data: IUpdateAdjustmentDefinitionData }[],
    callback?: () => void,
  ) => void;
  deleteAdjustmentDefinition: (url: string, callback?: () => void) => void;
  showSourceBalances?: boolean;
  showLineItems?: boolean;
  showSectionAdjustments?: boolean;
  showBalanceSheet?: boolean;
  onModeChanged: (tab: AdjustmentDetailsViewMode) => void;
  activeMode: AdjustmentDetailsViewMode | null;
  hasNewTypeCodes: boolean;
  onNewTypeCodesChanged: (type: number) => void;
  onAccountsChanged: (account?: IAccount) => void;
  onAdjustmentsChanged: () => void;
}

export const CollapsibleAdjustmentsDetails = ({
  project,
  accounts,
  adjustments,
  balanceAdjustments,
  types,
  balancingType,
  category,
  availableOperations,
  balanceSheetTotal,
  accountsTotal,
  receivedSectionAdjustment,
  sentToOtherSectionAdjustment,
  isBalanced,
  withTopPadding,
  manageAdjustmentDefinitions,
  deleteAdjustmentDefinition,
  showSourceBalances,
  showLineItems,
  showSectionAdjustments,
  showBalanceSheet,
  onModeChanged,
  activeMode,
  hasNewTypeCodes,
  onNewTypeCodesChanged,
  onAccountsChanged,
  onAdjustmentsChanged,
}: IProps) => {
  const { classes } = useStyles();

  return (
    <>
      <Collapse in={!!activeMode}>
        <Box className={withTopPadding ? classes.pt24 : undefined}>
          {showSourceBalances &&
            activeMode === AdjustmentDetailsViewMode.sourceBalances &&
            (types.length > 1 ? (
              <Box className={classes.tabPlaceholder}>
                <Typography>Not implemented yet.</Typography>
              </Box>
            ) : (
              <SourceBalanceDetails
                project={project}
                type={types[0]}
                accounts={accounts}
                hasNewTypeCodes={hasNewTypeCodes}
                onNewTypeCodesChanged={onNewTypeCodesChanged}
                onAccountsChanged={onAccountsChanged}
                onAdjustmentsChanged={onAdjustmentsChanged}
                manageAdjustmentDefinitions={manageAdjustmentDefinitions}
              />
            ))}

          {showLineItems && activeMode === AdjustmentDetailsViewMode.lineItems && (
            <AdjustmentsTable
              adjustments={adjustments}
              accounts={accounts}
              category={category}
              project={project}
              types={types}
              availableOperations={availableOperations}
              manageAdjustmentDefinitions={manageAdjustmentDefinitions}
              deleteAdjustmentDefinition={deleteAdjustmentDefinition}
            />
          )}

          {showSectionAdjustments &&
            activeMode === AdjustmentDetailsViewMode.sectionAdjustments && (
              <BalanceAdjustmentsTable
                adjustments={balanceAdjustments}
                isBalanced={isBalanced}
                accounts={accounts}
                category={category}
                project={project}
                types={types}
                balancingType={balancingType}
                balanceSheetTotal={balanceSheetTotal}
                accountsTotal={accountsTotal}
                manageAdjustmentDefinitions={manageAdjustmentDefinitions}
                deleteAdjustmentDefinition={deleteAdjustmentDefinition}
              />
            )}

          {showBalanceSheet && activeMode === AdjustmentDetailsViewMode.balanceSheet && (
            <BalanceSheetTable
              type={balancingType}
              balancingType={balancingType}
              accounts={accounts}
              project={project}
              manageAdjustmentDefinitions={manageAdjustmentDefinitions}
              deleteAdjustmentDefinition={deleteAdjustmentDefinition}
              receivedSectionAdjustment={receivedSectionAdjustment}
              sentToOtherSectionAdjustment={sentToOtherSectionAdjustment}
            />
          )}
        </Box>
      </Collapse>
      <Box className={classes.expandButtonsContainer}>
        <Box className={classes.expandButtonsInnerContainer}>
          <Box
            className={clsx([
              classes.expandButton,
              'blue',
              activeMode === AdjustmentDetailsViewMode.sourceBalances && 'active',
              !showSourceBalances && 'disabled',
            ])}
            onClick={() =>
              showSourceBalances && onModeChanged(AdjustmentDetailsViewMode.sourceBalances)
            }
          >
            <Typography variant='subtitle2'>Source Balances</Typography>
          </Box>
          <Box
            className={clsx([
              classes.expandButton,
              'blue',
              activeMode === AdjustmentDetailsViewMode.lineItems && 'active',
              !showLineItems && 'disabled',
            ])}
            onClick={() => showLineItems && onModeChanged(AdjustmentDetailsViewMode.lineItems)}
          >
            <Typography variant='subtitle2'>Line Items</Typography>
          </Box>
          <Box
            className={clsx([
              classes.expandButton,
              'blue',
              activeMode === AdjustmentDetailsViewMode.sectionAdjustments && 'active',
              !showSectionAdjustments && 'disabled',
            ])}
            onClick={() =>
              showSectionAdjustments && onModeChanged(AdjustmentDetailsViewMode.sectionAdjustments)
            }
          >
            <Typography variant='subtitle2'>Section Adjustments</Typography>
          </Box>
        </Box>
        <Box className={classes.expandButtonsInnerContainer}>
          <Box
            className={clsx([
              classes.expandButton,
              'green',
              activeMode === AdjustmentDetailsViewMode.balanceSheet && 'active',
              !showBalanceSheet && 'disabled',
            ])}
            onClick={() =>
              showBalanceSheet && onModeChanged(AdjustmentDetailsViewMode.balanceSheet)
            }
          >
            <Typography variant='subtitle2'>Balance Sheet</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
