import { BalancingItem, IBalancingItem } from '../BalancingItem';
import { Box, Typography } from '@mui/material';
import { useApi, useLoader } from '@hooks';
import { useCallback, useEffect, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { ILink } from '@models/interfaces/entities/ILink';
import { IProject } from '@models/interfaces/entities/IProject';
import { Loader } from '@components/Loader';
import clsx from 'clsx';
import { formatBalance } from '../../utils';
import { getTypeCodesCount } from '@services/api';
import useStyles from './styles';

export interface IProps {
  project: IProject;
  accounts: IAccount[];
  balanceAdjustments: IAdjustment[];
  main?: boolean;
  item: IBalancingItem;
  onAdjustmentsChanged: () => void;
  onAccountsChanged: (account?: IAccount) => void;
}

export const BalancingItemWithTabs = ({
  project,
  accounts,
  balanceAdjustments,
  item,
  onAdjustmentsChanged,
  onAccountsChanged,
}: IProps) => {
  const { classes } = useStyles();
  const [newTypeCodesCountMap, setNewTypeCodesCountMap] = useState<Record<number, number>>({});

  const { request: getTypeCodesCountRequest, loading: getTypeCodesCountLoading } = useApi(
    getTypeCodesCount,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data && data.type !== undefined) {
          setNewTypeCodesCountMap((prev) => ({
            ...prev,
            [data.type as number]: data.count,
          }));
        }
      },
    },
  );

  const reloadNewTypeCodesCount = useCallback(
    (link: ILink, accountsType?: number) => {
      if (link && accountsType !== undefined) {
        if (accountsType === -1) {
          item.sectionAccountsTypes.forEach((type) => {
            getTypeCodesCountRequest(link.href, undefined, type, true, false, false);
          });
        } else {
          getTypeCodesCountRequest(link.href, undefined, accountsType, true, false, false);
        }
      }
    },
    [item.sectionAccountsTypes],
  );

  useEffect(() => {
    reloadNewTypeCodesCount(project.links[Actions.getTypeCodesCount], item.accountsType);
  }, [project.links[Actions.getTypeCodesCount], item.accountsType, reloadNewTypeCodesCount]);

  const onNewTypeCodesChanged = useCallback(
    (type: number) => {
      reloadNewTypeCodesCount(project.links[Actions.getTypeCodesCount], type);
    },
    [project.links[Actions.getTypeCodesCount], reloadNewTypeCodesCount],
  );

  const showLoader = useLoader(getTypeCodesCountLoading);

  return (
    <Box className={classes.root}>
      <Box className={clsx([classes.tab, 'left', 'blue'])}>
        <Typography variant='subtitle1'>
          {formatBalance(item.sourceBalance + item.adjustments + item.sectionAdjustments)}
        </Typography>
      </Box>
      <Box className={clsx([classes.tab, 'right', 'green'])}>
        <Typography variant='subtitle1'>
          {formatBalance(
            item.totalBalance + item.receivedSectionAdjustment - item.sentToOtherSectionAdjustment,
          )}
        </Typography>
      </Box>
      <BalancingItem
        project={project}
        item={item}
        main
        accounts={accounts}
        balanceAdjustments={balanceAdjustments}
        newTypeCodesCountMap={newTypeCodesCountMap}
        onNewTypeCodesChanged={onNewTypeCodesChanged}
        onAdjustmentsChanged={onAdjustmentsChanged}
        onAccountsChanged={onAccountsChanged}
      />
      <Loader show={showLoader} />
    </Box>
  );
};
