import { AccountDetails, DetailsTabs } from '../AccountDetails';
import { Box, IconButton } from '@mui/material';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridRowModel,
} from '@mui/x-data-grid-premium';
import { formatBalance, useTableExpand } from '../../utils';
import { useApi, useLoader } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { AccountsTableFooter } from '../AccountsTableFooter';
import { Actions } from '@models/enums/Actions';
import { AdjustmentDetailsViewMode } from '../BalancingItem';
import { AdjustmentOperation } from '../../common/AdjustmentOperation';
import ArrowDownIcon from '@assets/icons/chevron-down.svg';
import ArrowUpIcon from '@assets/icons/chevron-up.svg';
import { CollapsibleAdjustmentsDetails } from '../CollapsibleAdjustmentsDetails';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { ICreateAdjustmentDefinitionData } from '@models/interfaces/additional/ICreateAdjustmentDefinitionData';
import { ILink } from '@models/interfaces/entities/ILink';
import { IProject } from '@models/interfaces/entities/IProject';
import { IUpdateAdjustmentDefinitionData } from '@models/interfaces/additional/IUpdateAdjustmentDefinitionData';
import { Loader } from '@components/Loader';
import clsx from 'clsx';
import { getAdjustments } from '@services/api';
import useStyles from './styles';

interface IExpandedRow {
  id: string;
  tabName: DetailsTabs;
}

interface IAccountBalance {
  accountId: string;
  accountName: string;
  sourceBalance: number;
  manualAdjustment: number;
  glAdjustment: number;
  fees: number;
  premium: number;
  sectionAdjustment: number;
  totalBalance: number;
}

interface IProps {
  project: IProject;
  accounts: IAccount[];
  type: number;
  balancingType: number;
  category: string;
  availableOperations?: AdjustmentOperation[];
  balanceSheetTotal: number;
  accountsTotal: number;
  receivedSectionAdjustment: number;
  sentToOtherSectionAdjustment: number;
  showFees?: boolean;
  showPremiums?: boolean;
  showSourceBalance?: boolean;
  showBalanceSheet?: boolean;
  isBalanced: boolean;
  onModeChanged: (tab: AdjustmentDetailsViewMode) => void;
  activeMode: AdjustmentDetailsViewMode | null;
  hasNewTypeCodes: boolean;
  onNewTypeCodesChanged: (type: number) => void;
  onAccountsChanged: (account?: IAccount) => void;
  onAdjustmentsChanged: () => void;
  manageAdjustmentDefinitions: (
    create?: { url: string; data: ICreateAdjustmentDefinitionData[] },
    update?: { url: string; data: IUpdateAdjustmentDefinitionData }[],
    callback?: () => void,
  ) => void;
  deleteAdjustmentDefinition: (url: string, callback?: () => void) => void;
}

export const AccountsTable = ({
  project,
  accounts,
  type,
  balancingType,
  category,
  availableOperations,
  balanceSheetTotal,
  accountsTotal,
  receivedSectionAdjustment,
  sentToOtherSectionAdjustment,
  showFees,
  showPremiums,
  showSourceBalance,
  showBalanceSheet,
  isBalanced,
  onModeChanged,
  activeMode,
  hasNewTypeCodes,
  onNewTypeCodesChanged,
  onAccountsChanged,
  onAdjustmentsChanged,
  manageAdjustmentDefinitions,
  deleteAdjustmentDefinition,
}: IProps) => {
  const { classes } = useStyles();

  const [unadjustedAccountsVisibility, setUnadjustedAccountsVisibility] = useState(true);

  const [initialAdjustments, setInitialAdjustments] = useState<IAdjustment[]>([]);
  const [manualAdjustments, setManualAdjustments] = useState<IAdjustment[]>([]);
  const [glAddAdjustments, setGlAddAdjustments] = useState<IAdjustment[]>([]);
  const [glSubtractAdjustments, setGlSubtractAdjustments] = useState<IAdjustment[]>([]);
  const [glMatchAdjustments, setGlMatchAdjustments] = useState<IAdjustment[]>([]);
  const [glBalanceAdjustments, setGlBalanceAdjustments] = useState<IAdjustment[]>([]);
  const [feesAdjustments, setFeesAdjustments] = useState<IAdjustment[]>([]);
  const [premiumAdjustments, setPremiumAdjustments] = useState<IAdjustment[]>([]);
  const { isTableExpanded, onToggleTableExpand } = useTableExpand();
  const [expandedRows, setExpandedRows] = useState<IExpandedRow[]>([]);

  const reloadAdjustments = useCallback(
    (link: ILink, type: number, showFees: boolean, showPremiums: boolean) => {
      if (link) {
        const operations = [
          AdjustmentOperation.Initial,
          AdjustmentOperation.Manual,
          AdjustmentOperation.Add,
          AdjustmentOperation.Subtract,
          AdjustmentOperation.Match,
          AdjustmentOperation.Balance,
        ];
        if (showFees) {
          operations.push(AdjustmentOperation.Fees);
        }
        if (showPremiums) {
          operations.push(AdjustmentOperation.Premium);
        }
        getAdjustmentsRequest(link.href, operations, undefined, undefined, type);
      }
    },
    [],
  );

  const { request: getAdjustmentsRequest, loading: getAdjustmentsLoading } = useApi(
    getAdjustments,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          setInitialAdjustments(
            data.items.filter((x) => x.operation === AdjustmentOperation.Initial),
          );
          setManualAdjustments(
            data.items.filter((x) => x.operation === AdjustmentOperation.Manual),
          );
          setGlAddAdjustments(data.items.filter((x) => x.operation === AdjustmentOperation.Add));
          setGlSubtractAdjustments(
            data.items.filter((x) => x.operation === AdjustmentOperation.Subtract),
          );
          setGlMatchAdjustments(
            data.items.filter((x) => x.operation === AdjustmentOperation.Match),
          );
          setFeesAdjustments(data.items.filter((x) => x.operation === AdjustmentOperation.Fees));
          setPremiumAdjustments(
            data.items.filter((x) => x.operation === AdjustmentOperation.Premium),
          );
          setGlBalanceAdjustments(
            data.items.filter((x) => x.operation === AdjustmentOperation.Balance),
          );
        }
      },
    },
  );

  const onAdjustmentsUpdated = useCallback(() => {
    reloadAdjustments(
      project.links[Actions.getAdjustments],
      type,
      showFees || false,
      showPremiums || false,
    );
    onAdjustmentsChanged();
  }, [
    onAdjustmentsChanged,
    reloadAdjustments,
    project.links[Actions.getAdjustments],
    type,
    showFees,
    showPremiums,
  ]);

  const onManageAdjustmentDefinitions = useCallback(
    (
      create?: { url: string; data: ICreateAdjustmentDefinitionData[] },
      update?: { url: string; data: IUpdateAdjustmentDefinitionData }[],
      callback?: () => void,
    ) => {
      manageAdjustmentDefinitions(create, update, () => {
        reloadAdjustments(
          project.links[Actions.getAdjustments],
          type,
          showFees || false,
          showPremiums || false,
        );
        if (callback) {
          callback();
        }
      });
    },
    [
      manageAdjustmentDefinitions,
      reloadAdjustments,
      project.links[Actions.getAdjustments],
      type,
      showFees,
      showPremiums,
    ],
  );

  const onDeleteAdjustmentDefinition = useCallback(
    (url: string, callback?: () => void) => {
      deleteAdjustmentDefinition(url, () => {
        reloadAdjustments(
          project.links[Actions.getAdjustments],
          type,
          showFees || false,
          showPremiums || false,
        );
        if (callback) {
          callback();
        }
      });
    },
    [
      deleteAdjustmentDefinition,
      reloadAdjustments,
      project.links[Actions.getAdjustments],
      type,
      showFees,
      showPremiums,
    ],
  );

  const onCellEditStart: GridEventListener<'cellEditStart'> = (params, event) => {
    if (event.type === 'dblclick') {
      setTimeout(() => {
        const input = (event.target as HTMLElement).querySelector('input');
        if (input) {
          input.select();
        }
      });
    }
  };

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    const isChanged = newRow.manualAdjustment !== oldRow.manualAdjustment;

    if (isChanged) {
      const existingManualAdjustment = manualAdjustments.find(
        (x) => x.accountId === newRow.accountId,
      );

      if (existingManualAdjustment && existingManualAdjustment.links[Actions.updateDefinition]) {
        onManageAdjustmentDefinitions(undefined, [
          {
            url: existingManualAdjustment.links[Actions.updateDefinition].href,
            data: {
              source: {
                type: 'manual',
                value: newRow.manualAdjustment,
              },
              targets: [existingManualAdjustment.accountId],
              operation: AdjustmentOperation.Manual,
              baseline: [],
              tierLimits: [],
            },
          },
        ]);
      } else if (!existingManualAdjustment && project.links[Actions.createAdjustmentDefinition]) {
        onManageAdjustmentDefinitions({
          url: project.links[Actions.createAdjustmentDefinition].href,
          data: [
            {
              reverseBalance: false,
              source: {
                type: 'manual',
                value: newRow.manualAdjustment,
              },
              targets: [newRow.accountId],
              operation: AdjustmentOperation.Manual,
              baseline: [],
              tierLimits: [],
              description: '',
            },
          ],
        });
      }
    }

    return newRow;
  };

  const onChangeUnadjustedAccountsVisibility = (value: boolean) => {
    setUnadjustedAccountsVisibility(value);
  };

  const onRowExpansionChange = useCallback((rows: IExpandedRow[]) => {
    setExpandedRows(rows);
  }, []);

  const columns = useMemo(() => {
    const baseColumns: GridColDef[] = [
      {
        field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
        headerName: '',
        width: 50,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const { rowNode, id } = params;

          if (rowNode.type === 'pinnedRow') {
            return '';
          }

          const isExpanded = expandedRows.find((x) => x.id === id);

          return (
            <Box
              className={clsx([classes.flex, classes.cursorPointer])}
              onClick={() => {
                const newExpandedRows = isExpanded
                  ? expandedRows.filter((row) => row.id !== id)
                  : [...expandedRows, { id: id as string, tabName: DetailsTabs.sourceBalance }];

                onRowExpansionChange(newExpandedRows);
              }}
            >
              <IconButton size='small'>
                {isExpanded ? (
                  <img src={ArrowUpIcon} alt='Collapse row' />
                ) : (
                  <img src={ArrowDownIcon} alt='Expand row' />
                )}
              </IconButton>
            </Box>
          );
        },
      },
      {
        field: 'accountName',
        headerName: 'Account',
        type: 'string',
        flex: 1,
      },
      {
        field: 'sourceBalance',
        headerName: 'Source Balance',
        type: 'number',
        flex: 1,
        renderCell: (params) => formatBalance(params.value || 0),
      },
      {
        field: 'manualAdjustment',
        headerName: 'Manual Adjustment',
        type: 'number',
        flex: 1,
        editable: true,
        renderCell: (params) => formatBalance(params.value || 0),
      },
      {
        field: 'glAdjustment',
        headerName: 'GL Adjustments',
        type: 'number',
        flex: 1,
        renderCell: (params) => formatBalance(params.value || 0),
      },
    ];

    if (showFees) {
      baseColumns.push({
        field: 'fees',
        headerName: 'Fees',
        type: 'number',
        flex: 1,
        renderCell: (params) => formatBalance(params.value || 0),
      });
    }

    if (showPremiums) {
      baseColumns.push({
        field: 'premium',
        headerName: 'Premiums/Discounts',
        type: 'number',
        flex: 1,
        renderCell: (params) => formatBalance(params.value || 0),
      });
    }

    baseColumns.push({
      field: 'sectionAdjustment',
      headerName: 'Section Adjustments',
      type: 'number',
      flex: 1,
      renderCell: (params) => formatBalance(params.value || 0),
    });

    baseColumns.push({
      field: 'totalBalance',
      headerName: 'Total Balance',
      type: 'number',
      flex: 1,
      renderCell: (params) => formatBalance(params.value || 0),
    });

    return baseColumns;
  }, [showFees, showPremiums, expandedRows, onRowExpansionChange]);

  useEffect(() => {
    reloadAdjustments(
      project.links[Actions.getAdjustments],
      type,
      showFees || false,
      showPremiums || false,
    );
  }, [project.links[Actions.getAdjustments]?.href, type, showFees, showPremiums]);

  const filteredAccounts = useMemo(
    () =>
      accounts.filter((x) => x.accountType.type === type && x.summaryCode !== 1 && !x.isBalancing),
    [accounts],
  );

  const accountBalances = useMemo<IAccountBalance[]>(() => {
    let accountRecords = filteredAccounts.map((x) => {
      const sourceBalance = initialAdjustments
        .filter((a) => a.accountId === x.id)
        .reduce((sum, a) => sum + a.amount, 0);
      const manualAdjustment = manualAdjustments
        .filter((a) => a.accountId === x.id)
        .reduce((sum, a) => sum + a.amount, 0);
      const glAdjustment = [...glAddAdjustments, ...glSubtractAdjustments, ...glMatchAdjustments]
        .filter((a) => a.accountId === x.id)
        .reduce((sum, a) => sum + a.amount, 0);
      const fees = feesAdjustments
        .filter((a) => a.accountId === x.id)
        .reduce((sum, a) => sum + a.amount, 0);
      const premium = premiumAdjustments
        .filter((a) => a.accountId === x.id)
        .reduce((sum, a) => sum + a.amount, 0);
      const sectionAdjustment = glBalanceAdjustments
        .filter((a) => a.accountId === x.id)
        .reduce((sum, a) => sum + a.amount, 0);
      return {
        accountId: x.id,
        accountName: x.accountName,
        sourceBalance,
        manualAdjustment,
        glAdjustment,
        fees,
        premium,
        sectionAdjustment,
        totalBalance:
          sourceBalance + manualAdjustment + glAdjustment + fees + premium + sectionAdjustment,
      };
    });

    if (!unadjustedAccountsVisibility) {
      accountRecords = accountRecords.filter(
        (x) =>
          x.manualAdjustment !== 0 ||
          x.glAdjustment !== 0 ||
          x.fees !== 0 ||
          x.premium !== 0 ||
          x.sectionAdjustment !== 0,
      );
    }
    return accountRecords;
  }, [
    filteredAccounts,
    initialAdjustments,
    manualAdjustments,
    glAddAdjustments,
    glSubtractAdjustments,
    glMatchAdjustments,
    glBalanceAdjustments,
    feesAdjustments,
    premiumAdjustments,
    unadjustedAccountsVisibility,
  ]);

  const adjustments = useMemo<IAdjustment[]>(
    () => [
      ...initialAdjustments,
      ...manualAdjustments,
      ...glAddAdjustments,
      ...glSubtractAdjustments,
      ...glMatchAdjustments,
      ...glBalanceAdjustments,
      ...feesAdjustments,
      ...premiumAdjustments,
    ],
    [
      initialAdjustments,
      manualAdjustments,
      glAddAdjustments,
      glSubtractAdjustments,
      glMatchAdjustments,
      glBalanceAdjustments,
      feesAdjustments,
      premiumAdjustments,
    ],
  );

  const getDetailPanelHeight = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelHeight']>
  >(() => 'auto' as const, []);

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelContent']>
  >(
    ({ row }) => {
      const expandedRow = expandedRows.find((x) => x.id === row.accountId);
      if (!expandedRow) return null;
      return (
        <AccountDetails
          accountId={row.accountId}
          adjustments={adjustments}
          accounts={accounts}
          project={project}
          tab={expandedRow.tabName}
          hideFeesTab={!showFees}
          hidePremiumsTab={!showPremiums}
        />
      );
    },
    [accounts, adjustments, category, project, expandedRows, showFees, showPremiums],
  );

  const handleCellClick = (params: GridCellParams) => {
    if (
      params.field === 'sourceBalance' ||
      params.field === 'glAdjustment' ||
      params.field === 'fees' ||
      params.field === 'premium' ||
      params.field === 'sectionAdjustment'
    ) {
      let tabName = DetailsTabs.sourceBalance;
      if (params.field === 'glAdjustment') {
        tabName = DetailsTabs.glAdjustments;
      } else if (params.field === 'fees') {
        tabName = DetailsTabs.fees;
      } else if (params.field === 'premium') {
        tabName = DetailsTabs.premiums;
      } else if (params.field === 'sectionAdjustment') {
        tabName = DetailsTabs.sectionAdjustments;
      }
      const isExpanded = expandedRows.some((row) => row.id === params.id);
      setExpandedRows((prev) =>
        isExpanded
          ? prev.filter((row) => row.id !== params.id)
          : [...prev, { id: params.id as string, tabName }],
      );
    }
  };

  const expandedRowIds = useMemo<string[]>(() => expandedRows.map((row) => row.id), [expandedRows]);

  const types = useMemo<number[]>(() => [type], [type]);

  const showLoader = useLoader(getAdjustmentsLoading);

  return (
    <>
      <Box className={classes.root}>
        <DataGridPremium
          rows={accountBalances}
          density='compact'
          columns={columns}
          className={clsx([classes.table, !isTableExpanded && classes.limitedHeightTable])}
          initialState={{
            sorting: {
              sortModel: [{ field: 'accountName', sort: 'asc' }],
            },
            aggregation: {
              model: {
                sourceBalance: 'sum',
                manualAdjustment: 'sum',
                glAdjustment: 'sum',
                fees: 'sum',
                premium: 'sum',
                sectionAdjustment: 'sum',
                totalBalance: 'sum',
              },
            },
          }}
          slots={{
            footer: () => (
              <AccountsTableFooter
                unadjustedAccountsVisibility={unadjustedAccountsVisibility}
                onChangeUnadjustedAccountsVisibility={onChangeUnadjustedAccountsVisibility}
                showTableExpandSwitch={accountBalances.length > 10 || !!expandedRowIds.length}
                isTableExpanded={isTableExpanded}
                onToggleTableExpand={onToggleTableExpand}
              />
            ),
            detailPanelExpandIcon: () => null,
            detailPanelCollapseIcon: () => null,
          }}
          getRowId={(row) => row.accountId}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
          onCellClick={handleCellClick}
          detailPanelExpandedRowIds={expandedRowIds}
          rowBuffer={100}
          processRowUpdate={processRowUpdate}
          onCellEditStart={onCellEditStart}
        />
        <CollapsibleAdjustmentsDetails
          adjustments={adjustments}
          balanceAdjustments={glBalanceAdjustments}
          isBalanced={isBalanced}
          accounts={accounts}
          category={category}
          availableOperations={availableOperations}
          project={project}
          types={types}
          balancingType={balancingType}
          balanceSheetTotal={balanceSheetTotal}
          accountsTotal={accountsTotal}
          receivedSectionAdjustment={receivedSectionAdjustment}
          sentToOtherSectionAdjustment={sentToOtherSectionAdjustment}
          manageAdjustmentDefinitions={onManageAdjustmentDefinitions}
          deleteAdjustmentDefinition={onDeleteAdjustmentDefinition}
          withTopPadding
          showSourceBalances={showSourceBalance}
          showLineItems
          showSectionAdjustments
          showBalanceSheet={showBalanceSheet}
          activeMode={activeMode}
          onModeChanged={onModeChanged}
          hasNewTypeCodes={hasNewTypeCodes}
          onNewTypeCodesChanged={onNewTypeCodesChanged}
          onAccountsChanged={onAccountsChanged}
          onAdjustmentsChanged={onAdjustmentsUpdated}
        />
      </Box>
      <Loader show={showLoader} />
    </>
  );
};
