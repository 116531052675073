import { Box, Button, TextField, Tooltip, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { CustomDatePicker } from '@components/CustomDatePicker';
import { CustomDialog } from '@components/CustomDialog';
import { IClient } from '@models/interfaces/entities/IClient';
import { ICreateProjectData } from '@models/interfaces/additional/ICreateProjectData';
import { ILink } from '@models/interfaces/entities/ILink';
import { IProject } from '@models/interfaces/entities/IProject';
import InfoIcon from '@assets/icons/dashboard/info.svg';
import PlusFilledIcon from '@assets/icons/dialog/plus-filled.svg';
import { SimulationImporter } from '@pages/dashboard/project/components/SimulationImporter';
import clsx from 'clsx';
import useStyles from './styles';

interface IProps {
  client: IClient;
  open: boolean;
  onClose: (data?: ICreateProjectData, callback?: (project: IProject) => Promise<void>) => void;
  subAccountMatchingEnabled?: boolean;
}

type IFormData = ICreateProjectData;

export const CreateProjectDialog = ({
  client,
  open,
  onClose,
  subAccountMatchingEnabled,
}: IProps) => {
  const { classes } = useStyles();

  const simulationImporterRef = useRef<{
    import: (updateAccountsLink: ILink, updateSubAccountsLink: ILink) => Promise<void>;
  }>(null);

  const [selectedFile, setSelectedFile] = useState<string>();
  const [error, setError] = useState<string>();

  const toStartDate = (local: Moment) => {
    const year = local.year();
    const month = local.month();
    const date = local.date();
    return new Date(Date.UTC(year, month, date, 12, 0, 0));
  };

  const formDefaultData = useMemo(() => {
    return {
      name: '',
      startDate: toStartDate(moment()),
    };
  }, [toStartDate]);

  const form = useForm<IFormData>({
    defaultValues: formDefaultData,
  });

  const projectCreationCallback = useMemo<((project: IProject) => Promise<void>) | undefined>(
    () =>
      selectedFile
        ? async (project: IProject) => {
            if (project.links[Actions.importAccounts] && project.links[Actions.importSubAccounts]) {
              await simulationImporterRef.current?.import(
                project.links[Actions.importAccounts],
                project.links[Actions.importSubAccounts],
              );
            }
          }
        : undefined,
    [selectedFile],
  );

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    const data = form.getValues();

    onClose(data, projectCreationCallback);
  };

  const onSimulationFileSelected = useCallback(
    (fileName: string, clientNumber: string) => {
      setSelectedFile(fileName);
      if (clientNumber !== client.accountNumber) {
        setError(
          'The provided simulation is for a different client. Please provide a simulation for the correct client before proceeding.',
        );
      } else {
        setError(undefined);
      }
    },
    [client],
  );

  useEffect(() => {
    form.reset(formDefaultData);
    setSelectedFile(undefined);
    setError(undefined);
    if (open) {
      setTimeout(() => {
        form.setFocus('name');
      }, 0);
    }
  }, [open]);

  return (
    <CustomDialog
      title='Add New Project'
      onClose={onCancel}
      open={open}
      maxWidth='xs'
      fullWidth
      actions={
        <>
          <div />
          <Button
            type='submit'
            form='form'
            variant='contained'
            color='secondary'
            size='large'
            startIcon={<img alt='add' src={PlusFilledIcon} />}
          >
            Add Project
          </Button>
          <div />
        </>
      }
    >
      <form
        id='form'
        onSubmit={form.handleSubmit(() => {
          onSave();
        })}
        noValidate
      >
        <Box>
          <Controller
            name={'name'}
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Please enter the name',
              },
            }}
            render={({ field: { onChange, value, ref }, formState }) => (
              <TextField
                inputRef={ref}
                fullWidth
                label='Name'
                variant='standard'
                error={!!formState.errors.name}
                helperText={formState.errors.name?.message}
                onChange={onChange}
                value={value}
                autoComplete='off'
              />
            )}
          />
        </Box>
        <Box className={classes.mt24}>
          <Controller
            name={'startDate'}
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Please enter the start date',
              },
            }}
            render={({ field: { onChange, value }, formState }) => (
              <CustomDatePicker
                value={value}
                onChange={(newValue) => {
                  onChange({
                    target: {
                      value: newValue ? toStartDate(moment(newValue)) : null,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label='Start Date'
                    variant='standard'
                    error={!!formState.errors.startDate}
                    helperText={formState.errors.startDate?.message}
                    autoComplete='off'
                  />
                )}
              />
            )}
          />
        </Box>
        {subAccountMatchingEnabled && (
          <Box className={classes.mt24}>
            <Box className={classes.flex}>
              <Typography variant='body2' className={classes.label}>
                Previous simulation
              </Typography>
              <Tooltip
                title={
                  <Box>
                    <strong>Required for GL Balancing.</strong>
                    <br />
                    Upload the final base simulation from the previous project.
                  </Box>
                }
              >
                <img src={InfoIcon} alt='info' />
              </Tooltip>
            </Box>
            <Box className={clsx([classes.flex, classes.mt12])}>
              <SimulationImporter
                ref={simulationImporterRef}
                onFileSelected={onSimulationFileSelected}
                maxFileSize={200}
                accept={{ '': ['.cmdm'], 'text/x-cmdm': ['.cmdm'] }}
              />
            </Box>
            <Box className={clsx([classes.flex, classes.mt12])}>
              <Tooltip title={selectedFile}>
                <Typography variant='subtitle1' className={classes.label}>
                  {selectedFile}
                </Typography>
              </Tooltip>
            </Box>
            {error && (
              <Box className={clsx([classes.flex, classes.mt12])}>
                <Tooltip title={selectedFile}>
                  <Typography variant='subtitle2' className={classes.redText}>
                    {error}
                  </Typography>
                </Tooltip>
              </Box>
            )}
          </Box>
        )}
      </form>
    </CustomDialog>
  );
};
