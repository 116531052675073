import { Box, Button, Typography } from '@mui/material';

import { ClientRoles } from '@models/enums/Roles';
import { Dropdown } from '@components/Dropdown';
import { useMemo } from 'react';
import useStyles from './styles';

interface IProps {
  item: {
    groupName: string;
    role: ClientRoles;
  };
  onRoleChanged: (role: ClientRoles) => void;
  onRemove: () => void;
  disabled?: boolean;
}

export const GroupCard = ({ item, onRoleChanged, onRemove, disabled }: IProps) => {
  const { classes } = useStyles();

  const roleOptions = useMemo(
    () => [
      {
        value: ClientRoles.clientManager,
        label: 'Client Manager',
      },
      {
        value: ClientRoles.clientUser,
        label: 'Client User',
      },
      {
        value: ClientRoles.projectManager,
        label: 'Project Manager',
      },
    ],
    [],
  );

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Typography variant='subtitle1'>{item.groupName}</Typography>
        <Dropdown
          value={item.role}
          onChanged={(value) => onRoleChanged(value as ClientRoles)}
          options={roleOptions}
          disabled={disabled}
        />
      </Box>
      <Box className={classes.controls}>
        <Button
          className={classes.removeBtn}
          variant='text'
          color='error'
          onClick={() => onRemove()}
          disabled={disabled}
        >
          Remove
        </Button>
      </Box>
    </Box>
  );
};
