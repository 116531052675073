import { Box, TextField } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useInput } from '@hooks';
import useStyles from './styles';

export enum ReportSectionLineValueMode {
  underlined = 'underlined',
  filled = 'filled',
}

export enum ReportSectionLineValueColor {
  blue = 'blue',
  yellow = 'yellow',
  orange = 'orange',
  red = 'red',
}

export enum ReportSectionLineValueAlignment {
  left = 'left',
  center = 'center',
  right = 'right',
}

export enum ReportSectionLineValueFormat {
  amount = 'amount',
  percentage = 'percentage',
}

export interface IReportSectionLineValue {
  value?: string;
  mode?: ReportSectionLineValueMode;
  bolded?: boolean;
  color?: ReportSectionLineValueColor;
}

interface IProps {
  item: IReportSectionLineValue;
  highlighted?: boolean;
  alignment: ReportSectionLineValueAlignment;
  format?: ReportSectionLineValueFormat;
  columnPositions?: [number, number];
  rowPositions?: [number, number];
  onChange?: (value?: string) => void;
}

export const ReportSectionLineValue = ({
  item: { value, mode, bolded, color },
  highlighted,
  alignment,
  format,
  columnPositions,
  rowPositions,
  onChange,
}: IProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const currentValueInput = useInput(value || '');

  const inputRef = useRef<HTMLInputElement>(null);

  const { classes } = useStyles({
    mode,
    color,
    highlighted: highlighted || false,
    bolded: bolded || false,
    alignment,
    columnPositions,
    rowPositions,
    isEditing,
  });

  const formatBalance = useCallback((value: number) => {
    const formatted = `$${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(Math.abs(value))}`;

    return value < 0 ? `(${formatted})` : formatted;
  }, []);

  const formatPercentage = useCallback((value: number) => {
    const formatted = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value / 100);
    return formatted;
  }, []);

  const getFormattedValue = useCallback(
    (value?: string): string => {
      if (!value) return '';

      if (format === ReportSectionLineValueFormat.percentage) {
        const num = typeof value === 'number' ? value : parseFloat(value);
        return isNaN(num) ? value.toString() : formatPercentage(num);
      }

      if (format === ReportSectionLineValueFormat.amount) {
        const num = typeof value === 'number' ? value : parseFloat(value);
        return isNaN(num) ? value.toString() : formatBalance(num);
      }

      return value;
    },
    [formatBalance, formatPercentage],
  );

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing]);

  useEffect(() => {
    if (!isEditing) {
      currentValueInput.set(value || '');
    }
  }, [value, isEditing]);

  const onDoubleClick = () => {
    if (onChange) {
      setIsEditing(true);
    }
  };

  const commitChangeAndBlur = () => {
    setIsEditing(false);
    if (onChange && currentValueInput.value !== value) {
      onChange(currentValueInput.value);
    }
  };

  const onBlur = () => {
    commitChangeAndBlur();
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission if inside a form
      commitChangeAndBlur();
    }
  };

  const onPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    if (!isEditing && onChange) {
      e.preventDefault();
      const pastedText = e.clipboardData.getData('Text').trim();
      if (pastedText.length > 0) {
        currentValueInput.set(pastedText);
        setIsEditing(true);
      }
    }
  };

  const formattedValue = useMemo(
    () => getFormattedValue(currentValueInput.value),
    [currentValueInput.value, getFormattedValue],
  );

  return (
    <Box className={classes.root} onDoubleClick={onDoubleClick} onPaste={onPaste}>
      {isEditing ? (
        <TextField
          inputRef={inputRef}
          value={currentValueInput.value}
          onChange={currentValueInput.onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          variant='standard'
          size='small'
          fullWidth
          autoFocus
          InputProps={{
            disableUnderline: true,
            className: classes.input,
          }}
        />
      ) : (
        formattedValue
      )}
    </Box>
  );
};

ReportSectionLineValue.defaultProps = {
  alignment: ReportSectionLineValueAlignment.center,
};
