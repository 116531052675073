import BaseProvider from './BaseProvider';
import { IFileContentDto } from '@models/interfaces/dtos/IFileContentDto';
import { IGetTypeCodeSamplesResponse } from '@models/interfaces/responses/IGetTypeCodeSamplesResponse';

export default class DataRecordsProvider extends BaseProvider {
  public getTypeCodeSamples = async (
    url: string,
    code: string,
    categoryId?: string,
    type?: number,
  ) => {
    return this.get<IGetTypeCodeSamplesResponse>(url, {
      params: {
        type,
        categoryId,
        code,
      },
    });
  };

  public getDataContent = async (url: string, categoryId: string): Promise<IFileContentDto> => {
    const response = await this.getWithHeaders<ArrayBuffer>(url, {
      responseType: 'arraybuffer',
      params: {
        categoryId,
      },
    });

    const contentDisposition = response.headers['content-disposition'];
    const contentType = response.headers['content-type'];
    const match = contentDisposition.match(/filename=(.*);/);
    const fileName = match && match[1] ? match[1].replace(/"/g, '') : 'Unknown';

    return {
      content: response.data,
      fileName,
      contentType,
    };
  };
}
