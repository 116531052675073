import { Box, Switch, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';

import { ClientRoles } from '@models/enums/Roles';
import { IClient } from '@models/interfaces/entities/IClient';
import { IRoleAssignment } from '@models/interfaces/entities/IRoleAssignment';
import useStyles from './styles';

interface IProps {
  roleAssignments: IRoleAssignment[];
  clients: IClient[];
}

const roles = [ClientRoles.clientManager, ClientRoles.projectManager, ClientRoles.clientUser];

const RolesMap: Readonly<Record<string, string>> = Object.freeze({
  [ClientRoles.clientManager]: 'Client Manager',
  [ClientRoles.projectManager]: 'Project Manager',
  [ClientRoles.clientUser]: 'Client User',
});

export const RolesTabView = ({ roleAssignments, clients }: IProps) => {
  const { classes } = useStyles();

  const roleAssignmentItems = useMemo<{ client: IClient; role: string }[]>(() => {
    const items: { client: IClient; role: string }[] = [];

    roleAssignments.forEach((item) => {
      item.clientIds?.forEach((clientId) => {
        const client = clients.find((x) => x.id === clientId);
        if (client) {
          items.push({ client, role: item.role });
        }
      });
    });

    return items;
  }, [clients, roleAssignments, roles]);

  const getRoleAssignment = useCallback(
    (clientId: string, role: string) =>
      roleAssignmentItems.find((x) => x.client.id === clientId && x.role === role),
    [roleAssignmentItems],
  );

  const assignedClients = useMemo(() => {
    const usedClientIds = new Set(roleAssignments.flatMap((item) => item.clientIds ?? []));
    return clients
      .filter((x) => usedClientIds.has(x.id))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [roleAssignments, clients]);

  if (!roleAssignmentItems.length) return null;

  return (
    <Box className={classes.root}>
      <Box className={classes.grid}>
        <Box />
        {roles.map((x) => (
          <Box key={x} className={classes.topHeaderCell}>
            <Typography variant='subtitle1'>{RolesMap[x]}</Typography>
          </Box>
        ))}

        {assignedClients.map((c) => (
          <>
            <Box key={c.id} className={classes.sideHeaderCell}>
              <Typography variant='subtitle1'>{c.name}</Typography>
            </Box>
            {roles.map((x) => {
              const item = getRoleAssignment(c.id, x);

              return (
                <Box className={classes.cell} key={`${c.id}-${x}`}>
                  <Switch
                    className={classes.switch}
                    disabled={true}
                    checked={!!item}
                    inputProps={{ role: 'switch' }}
                  />
                </Box>
              );
            })}
          </>
        ))}
      </Box>
    </Box>
  );
};
