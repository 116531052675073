import {
  Box,
  CircularProgress,
  Container,
  Fab,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { SearchInput, SearchInputColors } from '@components/SearchInput';
import {
  changeFileCategory,
  changeFileMapping,
  changeFileTransformationScript,
  confirmProject,
  createFileSubCategory,
  deleteFile,
  getClientFeatures,
  getClientProduct,
  getDataContent,
  getFile,
  getFileCategories,
  getFileContent,
  getFileSubCategories,
  getFileSubCategory,
  getPreviousProject,
  getProduct,
  getProject,
  getProjectFiles,
  getProjectRecalculationStatus,
  ignoreFile,
  unignoreFile,
  updateFileSubCategory,
  updateFileUserStatus,
} from '@services/api';
import {
  cloneFileSubCategoryMapping,
  getFileSubCategoryMapping,
} from '@services/api/fileSubCategoryMappings';
import { selectAuthFeatures, selectAuthProducts } from '@reducers/authSlice';
import {
  useApi,
  useAppSelector,
  useConfirm,
  useDebounce,
  useInput,
  useLoader,
  useUpdateEffect,
} from '@hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Actions } from '@models/enums/Actions';
import { BalancingTab } from './components/BalancingTab';
import CheckFilledWhiteIcon from '@assets/icons/dashboard/check-filled-white.svg';
import { DashboardBreadcrumbs } from '@components/DashboardBreadcrumbs';
import { Dropdown } from '@components/Dropdown';
import { ExternalResources } from '@components/ExternalResources';
import EyeIcon from '@assets/icons/dashboard/eye.svg';
import EyeOffIcon from '@assets/icons/dashboard/eye-off.svg';
import { Features } from '@models/enums/Features';
import { FileCard } from './components/FileCard';
import { FileCategoryCard } from './components/FileCategoryCard';
import { FileDownloader } from './components/FileDownloader';
import { FileTypes } from '@models/enums/FileTypes';
import { FileUserStatuses } from '@models/enums/FileUserStatuses';
import { GuideTour } from '@components/GuideTour';
import { GuideTourMarker } from '@components/GuideTourMarker';
import { IClientFeature } from '@models/interfaces/entities/IClientFeature';
import { IFile } from '@models/interfaces/entities/IFile';
import { IFileCategory } from '@models/interfaces/entities/IFileCategory';
import { IFileSubCategory } from '@models/interfaces/entities/IFileSubCategory';
import { ILink } from '@models/interfaces/entities/ILink';
import { IProject } from '@models/interfaces/entities/IProject';
import { IUpdateFileSubCategoryData } from '@models/interfaces/additional/IUpdateFileSubCategoryData';
import { IssuesAlert } from '@components/IssuesAlert';
import ListIcon from '@assets/icons/dashboard/list.svg';
import { Loader } from '@components/Loader';
import MailFilledWhiteIcon from '@assets/icons/dashboard/mail-filled-white.svg';
import MinusSquareIcon from '@assets/icons/dashboard/minus-square.svg';
import { PIIScanResultStatuses } from '@models/enums/PIIScanResultStatuses';
import { PiiScanResultDetailsDialog } from '@components/PiiScanResultDetailsDialog';
import PlusSquareIcon from '@assets/icons/dashboard/plus-square.svg';
import { ProjectStatuses } from '@models/enums/ProjectStatuses';
import { ReportsTab } from './components/ReportsTab';
import { SendNotificationDialog } from './components/SendNotificationDialog';
import { SignalREventHandler } from './components/SignalREventHandler';
import { SourceFileCard } from './components/SourceFileCard';
import { Step } from 'react-joyride';
import { TransformationResultStatuses } from '@models/enums/TransformationResultStatuses';
import { Uploader } from './components/Uploader';
import { WhitelistRecordsDialog } from '@components/WhitelistRecordsDialog';
import { clsx } from 'clsx';
import saveAs from 'file-saver';
import { selectGeneralAutoCloseScanResultsDialog } from '@reducers/generalSlice';
import { toast } from 'react-toastify';
import useStyles from './styles';

export enum UpdateActionTypes {
  updateFile = 1,
  deleteFile = 2,
  reloadFile = 3,
  addFile = 4,
  updateFileStatus = 5,
  updateFileTransformationStatus = 6,
  updateFileIngestionStatus = 7,

  addFileSubCategory = 8,
  updateFileSubCategory = 9,
  reloadFileSubCategory = 10,

  reloadProjectFiles = 11,
  reloadProjectFilesAndSubCategories = 12,
}

export interface UpdateAction {
  type: UpdateActionTypes;
  fileId?: string;
  file?: IFile;
  subCategoryId?: string;
  subCategory?: IFileSubCategory;
}

enum SortTypes {
  date = 'date',
  name = 'name',
}

enum ProjectTabs {
  dataFiles = 'dataFiles',
  resultFiles = 'resultFiles',
  balancing = 'balancing',
  reports = 'reports',
}

interface IProps {
  delay: number;
  projectReloadDelay: number;
}

export const ProjectPage = ({ delay, projectReloadDelay }: IProps) => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const products = useAppSelector(selectAuthProducts);
  const features = useAppSelector(selectAuthFeatures);
  const autoCloseScanResultsDialog = useAppSelector(selectGeneralAutoCloseScanResultsDialog);

  const { clientId, productId, projectId, type } = useParams();
  const searchInput = useInput<string>('');
  const [sort, setSort] = useState(SortTypes.name);

  const [updateActions, setUpdateActions] = useState<UpdateAction[]>([]);

  const [projectRecalculationInProgress, setProjectRecalculationInProgress] = useState<
    boolean | null
  >(null);
  const [draggedFile, setDraggedFile] = useState<IFile | null>(null);
  const [selectedFileCategories, setSelectedFileCategories] = useState<{
    categoryId?: string;
    subCategoryId?: string;
  } | null>(null);
  const [selectedFile, setSelectedFile] = useState<IFile | null>(null);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [openSendNotificationDialog, setOpenSendNotificationDialog] = useState(false);
  const [openWhitelistRecordsDialog, setOpenWhitelistRecordsDialog] = useState(false);

  const [clientFeatures, setClientFeatures] = useState<IClientFeature[]>([]);
  const [fileCategories, setFileCategories] = useState<IFileCategory[]>([]);
  const [fileSubCategories, setFileSubCategories] = useState<IFileSubCategory[]>([]);
  const [projectFiles, setProjectFiles] = useState<IFile[]>([]);
  const [closedProjectFiles, setClosedProjectFiles] = useState<IFile[]>([]);
  const [project, setProject] = useState<IProject>();
  const [closedProject, setClosedProject] = useState<IProject>();
  const [showHiddenFiles, setShowHiddenFiles] = useState(false);
  const [collapsedSourceFiles, setCollapsedSourceFiles] = useState<string[]>([]);

  const [invalidCategories, setInvalidCategories] = useState<string[]>([]);
  const [invalidUncategorized, setInvalidUncategorized] = useState<boolean>(false);

  const [alert, setAlert] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const rootRef = useRef<HTMLDivElement>(null);

  const [activeTab, setActiveTab] = useState<ProjectTabs>(
    type === 'results'
      ? ProjectTabs.resultFiles
      : type === 'balancing'
      ? ProjectTabs.balancing
      : type === 'reports'
      ? ProjectTabs.reports
      : ProjectTabs.dataFiles,
  );

  const debouncedUpdateActions = useDebounce<UpdateAction[]>(updateActions, delay);

  const projectResultFilesDataString = useMemo(() => {
    return JSON.stringify(
      projectFiles
        .filter((x) => x.fileType === FileTypes.resultFile)
        .map(({ id }) => ({
          id,
        })),
    );
  }, [projectFiles]);

  const debouncedProjectResultFilesDataString = useDebounce<string>(
    projectResultFilesDataString,
    projectReloadDelay,
  );

  const {
    request: confirmProjectRequest,
    data: confirmProjectData,
    loading: confirmProjectLoading,
  } = useApi(confirmProject, null, {
    onError: (error, details) => {
      let withIndication = false;
      if (error === 'One or more validation errors occurred.') {
        if (Object.keys(details).includes('Project.FailedToConfirmDueToUncategorizedFiles')) {
          toast.error(details['Project.FailedToConfirmDueToUncategorizedFiles'][0] || '');
          setInvalidUncategorized(true);
          withIndication = true;
        } else if (Object.keys(details).includes('Project.FailedToConfirmDueToUnpairedFiles')) {
          const errData = details['Project.FailedToConfirmDueToUnpairedFiles'][0] || '';
          const regex = /\s\(categories:\s*\[([\w-]+(,\s*[\w-]+)*)\]\)/;
          const match = regex.exec(errData);
          if (match) {
            const categoriesString = match[0];
            const categoryIds = match[1].split(',').map((id) => id.trim());
            toast.error(errData.replace(categoriesString, ''));
            setInvalidCategories(categoryIds);
            withIndication = true;
          }
        }
      }
      if (!withIndication) {
        toast.error(error);
      } else {
        setAlert(true);
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          setAlert(false);
        }, 10000);
      }
    },
  });

  const {
    request: getClientFeaturesRequest,
    data: getClientFeaturesData,
    loading: getClientFeaturesLoading,
  } = useApi(getClientFeatures, null, { handleErrors: true });

  const {
    request: getFileCategoriesRequest,
    data: getFileCategoriesData,
    loading: getFileCategoriesLoading,
  } = useApi(getFileCategories, null, { handleErrors: true });

  const {
    request: getFileSubCategoriesRequest,
    data: getFileSubCategoriesData,
    loading: getFileSubCategoriesLoading,
  } = useApi(getFileSubCategories, null, { handleErrors: true });

  const {
    request: getFileSubCategoryRequest,
    data: getFileSubCategoryData,
    loading: getFileSubCategoryLoading,
  } = useApi(getFileSubCategory, null, { handleErrors: true });

  const {
    request: getProductRequest,
    data: getProductData,
    loading: getProductLoading,
  } = useApi(getProduct, null, { handleErrors: true });

  const {
    request: getClientProductRequest,
    data: getClientProductData,
    loading: getClientProductLoading,
  } = useApi(getClientProduct, null, { handleErrors: true });

  const {
    request: getProjectRequest,
    data: getProjectData,
    loading: getProjectLoading,
  } = useApi(getProject, null, { handleErrors: true });

  const {
    request: getFileRequest,
    data: getFileData,
    loading: getFileLoading,
  } = useApi(getFile, null, { handleErrors: true });

  const {
    request: getProjectFilesRequest,
    data: getProjectFilesData,
    loading: getProjectFilesLoading,
  } = useApi(getProjectFiles, null, {
    handleErrors: true,
  });

  const {
    request: getClosedProjectFilesRequest,
    data: getClosedProjectFilesData,
    loading: getClosedProjectFilesLoading,
  } = useApi(getProjectFiles, null, {
    handleErrors: true,
  });

  const {
    request: getPreviousProjectRequest,
    data: getPreviousProjectData,
    loading: getPreviousProjectLoading,
  } = useApi(getPreviousProject, null, {
    onError: (error) => {
      if (error !== 'The requested project does not exist.') {
        toast.error(error);
      }
    },
  });

  const {
    request: getProjectRecalculationStatusRequest,
    loading: getProjectRecalculationStatusLoading,
  } = useApi(getProjectRecalculationStatus, null, {
    handleErrors: true,
    onCallback: (data) => {
      if (data !== null) setProjectRecalculationInProgress(data);
    },
  });

  const { request: getFileContentRequest, loading: getFileContentLoading } = useApi(
    async (getFileContentLink: ILink, fileName: string) => {
      const fileContent = await getFileContent(getFileContentLink.href);
      return { fileContent, fileName };
    },
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data) saveAs(data.fileContent.content, data.fileName);
      },
    },
  );

  const {
    request: deleteFileRequest,
    data: deleteFileData,
    loading: deleteFileLoading,
  } = useApi(
    async (deleteFileLink: ILink, updateSubCategoryLink?: ILink) => {
      await deleteFile(deleteFileLink.href);
      const updatedSubCategory = updateSubCategoryLink
        ? await updateFileSubCategory(updateSubCategoryLink.href, {
            disabled: true,
          })
        : undefined;
      return { updatedSubCategory };
    },
    null,
    {
      handleErrors: true,
      onCallback: () => {
        if (project?.links[Actions.getFiles]?.href) {
          getProjectFilesRequest(project?.links[Actions.getFiles]?.href);
        }
      },
    },
  );

  const {
    request: updateFileUserStatusRequest,
    data: updateFileUserStatusData,
    loading: updateFileUserStatusLoading,
  } = useApi(updateFileUserStatus, null, {
    handleErrors: true,
  });

  const {
    request: ignoreFileRequest,
    data: ignoreFileData,
    loading: ignoreFileLoading,
  } = useApi(ignoreFile, null, {
    handleErrors: true,
  });

  const {
    request: unignoreFileRequest,
    data: unignoreFileData,
    loading: unignoreFileLoading,
  } = useApi(unignoreFile, null, {
    handleErrors: true,
  });

  const {
    request: updateFileSubCategoryRequest,
    data: updateFileSubCategoryData,
    loading: updateFileSubCategoryLoading,
  } = useApi(updateFileSubCategory, null, {
    handleErrors: true,
  });

  const {
    request: changeFileTransformationScriptRequest,
    data: changeFileTransformationScriptData,
    loading: changeFileTransformationScriptLoading,
  } = useApi(changeFileTransformationScript, null, {
    handleErrors: true,
  });

  const {
    request: changeFileCategoryRequest,
    data: changeFileCategoryData,
    loading: changeFileCategoryLoading,
  } = useApi(
    async (
      changeFileCategoryLink: ILink,
      createNewSubCategoryLink?: ILink,
      updateExistingSubCategoryLink?: ILink,
      categoryId?: string,
      subCategoryId?: string,
      notes?: string,
    ) => {
      const createdSubCategory =
        createNewSubCategoryLink && categoryId
          ? await createFileSubCategory(createNewSubCategoryLink.href, {
              categoryId,
              notes,
            })
          : undefined;
      const file = await changeFileCategory(changeFileCategoryLink.href, {
        categoryId,
        subCategoryId: createdSubCategory?.id || subCategoryId,
      });
      const updatedSubCategory = updateExistingSubCategoryLink
        ? await updateFileSubCategory(updateExistingSubCategoryLink.href, {
            disabled: true,
          })
        : undefined;

      if (file.fileSubCategoryId && !file.fileSubCategoryMappingId) {
        console.log('try to clone the mapping');
        cloneFileMapping(file);
      }

      return { createdSubCategory, file, updatedSubCategory };
    },
    null,
    {
      handleErrors: true,
    },
  );

  const {
    request: cloneFileMappingRequest,
    data: cloneFileMappingData,
    loading: cloneFileMappingLoading,
  } = useApi(
    async (getMappingLink: ILink, changeFileMappingLink: ILink) => {
      const mapping = await getFileSubCategoryMapping(getMappingLink.href);
      const cloneLink = mapping.links[Actions.clone];
      if (cloneLink) {
        const clonedMapping = await cloneFileSubCategoryMapping(cloneLink.href);
        const updatedFile = await changeFileMapping(changeFileMappingLink.href, {
          mappingId: clonedMapping.id,
        });
        return {
          file: updatedFile,
        };
      } else {
        return { file: undefined };
      }
    },
    null,
    {
      handleErrors: true,
    },
  );

  const { request: getDataContentRequest, loading: getDataContentLoading } = useApi(
    getDataContent,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data) saveAs(data.content);
      },
    },
  );

  const addFile = useCallback(
    (fileId: string) => {
      const file = projectFiles.find((x) => x.id === fileId);
      if (!file) {
        getFileRequest(`/files/${fileId}`);
      }
      return true;
    },
    [projectFiles],
  );

  const reloadFile = useCallback(
    (fileId: string) => {
      const file = projectFiles.find((x) => x.id === fileId);
      if (file?.links[Actions.self]?.href) {
        getFileRequest(file.links[Actions.self].href);
        return true;
      } else {
        return false;
      }
    },
    [projectFiles],
  );

  const addFileSubCategory = useCallback(
    (subCategoryId: string) => {
      const fileSubCategory = fileSubCategories.find((x) => x.id === subCategoryId);
      if (!fileSubCategory) {
        getFileSubCategoryRequest(`/file-sub-categories/${subCategoryId}`);
      }
      return true;
    },
    [projectFiles],
  );

  const reloadFileSubCategory = useCallback(
    (subCategoryId: string) => {
      const fileSubCategory = fileSubCategories.find((x) => x.id === subCategoryId);
      if (fileSubCategory?.links[Actions.self]?.href) {
        getFileSubCategoryRequest(fileSubCategory.links[Actions.self].href);
        return true;
      } else {
        return false;
      }
    },
    [fileSubCategories],
  );

  const reloadProjectFiles = useCallback(() => {
    if (project?.links[Actions.getFiles]?.href) {
      getProjectFilesRequest(project?.links[Actions.getFiles]?.href);
      return true;
    } else {
      return false;
    }
  }, [project?.id]);

  const reloadFileSubCategories = useCallback(() => {
    if (getClientProductData?.links[Actions.getFileSubCategories]?.href) {
      getFileSubCategoriesRequest(getClientProductData?.links[Actions.getFileSubCategories]?.href);
      return true;
    } else {
      return false;
    }
  }, [getClientProductData]);

  const tryToCloseDetailsDialog = () => {
    if (autoCloseScanResultsDialog) {
      setOpenDetailsDialog(false);
    }
  };

  const cloneFileMapping = useCallback(
    (file: IFile) => {
      const pairedFile = closedProjectFiles.find(
        (x) => x.fileSubCategoryId === file.fileSubCategoryId,
      );
      const getMappingLink = pairedFile?.links[Actions.getMapping];
      if (
        pairedFile?.fileSubCategoryTransformationScriptId &&
        file.links[Actions.changeTransformationScript]
      ) {
        changeFileTransformationScriptRequest(file.links[Actions.changeTransformationScript].href, {
          transformationScriptId: pairedFile.fileSubCategoryTransformationScriptId,
        });
      }
      if (getMappingLink && file.links[Actions.changeMapping]) {
        cloneFileMappingRequest(getMappingLink, file.links[Actions.changeMapping]);
      }
    },
    [closedProjectFiles],
  );

  useEffect(() => {
    if (productId) {
      const product = products.find((x) => x.id === productId);
      if (product) {
        getProductRequest(product.links[Actions.self].href);
      }
    }
  }, [products, productId]);

  useEffect(() => {
    if (getProductData?.links[Actions.getFileCategories]?.href) {
      getFileCategoriesRequest(getProductData.links[Actions.getFileCategories].href);
    }
  }, [getProductData]);

  useEffect(() => {
    if (projectId) {
      getProjectRequest(`/projects/${projectId}`);
    }
  }, [projectId, debouncedProjectResultFilesDataString]);

  useEffect(() => {
    if (clientId && productId)
      getClientProductRequest(`/clients/${clientId}/products/${productId}`);
  }, [clientId, productId]);

  useEffect(() => {
    if (clientId) getClientFeaturesRequest(`/clients/${clientId}/features`);
  }, [clientId]);

  useEffect(() => {
    if (getClientProductData?.links[Actions.getFileSubCategories]?.href) {
      getFileSubCategoriesRequest(getClientProductData?.links[Actions.getFileSubCategories]?.href);
    }
  }, [getClientProductData]);

  useEffect(() => {
    if (getPreviousProjectData?.links[Actions.getFiles]?.href) {
      setClosedProject(getPreviousProjectData);
      getClosedProjectFilesRequest(getPreviousProjectData.links[Actions.getFiles].href);
    }
  }, [getPreviousProjectData]);

  useEffect(() => {
    if (getFileSubCategoriesData) {
      setFileSubCategories(getFileSubCategoriesData.items);
    }
  }, [getFileSubCategoriesData]);

  useEffect(() => {
    if (getFileSubCategoriesData) {
      setFileSubCategories(getFileSubCategoriesData.items);
    }
  }, [getFileSubCategoriesData]);

  useEffect(() => {
    if (updateFileSubCategoryData) {
      setFileSubCategories((prev) =>
        prev.map((x) => (x.id !== updateFileSubCategoryData.id ? x : updateFileSubCategoryData)),
      );
    }
  }, [updateFileSubCategoryData]);

  useEffect(() => {
    if (activeTab === ProjectTabs.balancing) {
      if (project?.links[Actions.getRecalculationStatus]) {
        getProjectRecalculationStatusRequest(project.links[Actions.getRecalculationStatus].href);
      }
    } else {
      setProjectRecalculationInProgress(null);
    }
  }, [activeTab, project?.links[Actions.getRecalculationStatus]]);

  useUpdateEffect(() => {
    if (
      !getProjectFilesLoading &&
      !getFileLoading &&
      !getFileSubCategoriesLoading &&
      !getFileSubCategoriesLoading &&
      debouncedUpdateActions.length
    ) {
      setUpdateActions((actions) => {
        const [action, ...rest] = actions;
        if (!action) {
          return actions;
        }
        let executed = false;
        switch (action.type) {
          case UpdateActionTypes.deleteFile: {
            setProjectFiles((files) =>
              files.filter((x) => x.id !== action.fileId && x.sourceFileId !== action.fileId),
            );
            executed = true;
            break;
          }
          case UpdateActionTypes.updateFileStatus: {
            setProjectFiles((files) =>
              files.map((x) =>
                x.id !== action.fileId || !action.file
                  ? x
                  : {
                      ...x,
                      piiScanResultStatus: action.file.piiScanResultStatus,
                    },
              ),
            );
            executed = true;
            break;
          }
          case UpdateActionTypes.updateFileIngestionStatus: {
            setProjectFiles((files) =>
              files.map((x) =>
                x.id !== action.fileId || !action.file
                  ? x
                  : {
                      ...x,
                      ingestionResultStatus: action.file.ingestionResultStatus,
                      ingestionResultMessage: action.file.ingestionResultMessage,
                    },
              ),
            );
            executed = true;
            break;
          }
          case UpdateActionTypes.updateFileTransformationStatus: {
            setProjectFiles((files) =>
              files.map((x) =>
                x.id !== action.fileId || !action.file
                  ? x
                  : {
                      ...x,
                      transformationResultStatus: action.file.transformationResultStatus,
                    },
              ),
            );
            executed = true;
            break;
          }
          case UpdateActionTypes.addFile: {
            if (action.fileId) executed = addFile(action.fileId);
            break;
          }
          case UpdateActionTypes.reloadFile: {
            if (action.fileId) executed = reloadFile(action.fileId);
            break;
          }
          case UpdateActionTypes.updateFile: {
            setProjectFiles((files) => {
              if (!action.file || !action.fileId) return files;
              if (files.findIndex((x) => x.id === action.fileId) !== -1) {
                return files.map((x) => (x.id !== action.fileId || !action.file ? x : action.file));
              } else {
                return [...files, action.file];
              }
            });
            executed = true;
            break;
          }
          case UpdateActionTypes.addFileSubCategory: {
            if (action.subCategoryId) executed = addFileSubCategory(action.subCategoryId);
            break;
          }
          case UpdateActionTypes.reloadFileSubCategory: {
            if (action.subCategoryId) executed = reloadFileSubCategory(action.subCategoryId);
            break;
          }
          case UpdateActionTypes.updateFileSubCategory: {
            setFileSubCategories((fileSubCategories) => {
              if (!action.subCategory || !action.subCategoryId) return fileSubCategories;
              if (fileSubCategories.findIndex((x) => x.id === action.subCategoryId) !== -1) {
                return fileSubCategories.map((x) =>
                  x.id !== action.subCategoryId || !action.subCategory ? x : action.subCategory,
                );
              } else {
                return [...fileSubCategories, action.subCategory];
              }
            });
            executed = true;
            break;
          }
          case UpdateActionTypes.reloadProjectFiles: {
            executed = reloadProjectFiles();
            break;
          }
          case UpdateActionTypes.reloadProjectFilesAndSubCategories: {
            executed = reloadProjectFiles() && reloadFileSubCategories();
            break;
          }
        }
        return executed ? rest : [...rest, action];
      });
    }
  }, [
    getProjectFilesLoading,
    getFileLoading,
    getFileSubCategoriesLoading,
    getFileSubCategoryLoading,
    debouncedUpdateActions,
    reloadFile,
    reloadFileSubCategory,
    reloadProjectFiles,
    reloadFileSubCategories,
    cloneFileMapping,
  ]);

  useUpdateEffect(() => {
    if (updateActions.length > 5) {
      const subCategoriesTypes = [
        UpdateActionTypes.addFileSubCategory,
        UpdateActionTypes.reloadFileSubCategory,
        UpdateActionTypes.updateFileSubCategory,
      ];

      if (updateActions.findIndex((x) => subCategoriesTypes.includes(x.type)) !== -1) {
        setUpdateActions([{ type: UpdateActionTypes.reloadProjectFilesAndSubCategories }]);
      } else {
        setUpdateActions([{ type: UpdateActionTypes.reloadProjectFiles }]);
      }
    }
  }, [updateActions]);

  useUpdateEffect(() => {
    if (confirmProjectData) {
      toast.success('Project has been successfully submitted');
      setProject(confirmProjectData);
    }
  }, [confirmProjectData]);

  useUpdateEffect(() => {
    if (getProjectData) {
      setProject(getProjectData);
    }
  }, [getProjectData]);

  useUpdateEffect(() => {
    if (project) {
      reloadProjectFiles();
      if (project.links[Actions.getPreviousProject]) {
        getPreviousProjectRequest(project.links[Actions.getPreviousProject].href);
      }
    }
  }, [project?.id, reloadProjectFiles]);

  useUpdateEffect(() => {
    if (getProjectFilesData) {
      setProjectFiles(getProjectFilesData);
    }
  }, [getProjectFilesData]);

  useUpdateEffect(() => {
    if (getClosedProjectFilesData) {
      setClosedProjectFiles(getClosedProjectFilesData);
    }
  }, [getClosedProjectFilesData]);

  useUpdateEffect(() => {
    if (getFileCategoriesData) {
      setFileCategories(getFileCategoriesData.items);
    }
  }, [getFileCategoriesData]);

  useUpdateEffect(() => {
    if (getClientFeaturesData) {
      setClientFeatures(getClientFeaturesData);
    }
  }, [getClientFeaturesData]);

  useUpdateEffect(() => {
    if (updateFileUserStatusData) {
      const newActions: UpdateAction[] = [
        {
          type: UpdateActionTypes.updateFile,
          fileId: updateFileUserStatusData.id,
          file: updateFileUserStatusData,
        },
      ];
      if (updateFileUserStatusData.sourceFileId) {
        newActions.push({
          type: UpdateActionTypes.reloadFile,
          fileId: updateFileUserStatusData.sourceFileId,
        });
      }
      setUpdateActions((actions) => [...actions, ...newActions]);

      tryToCloseDetailsDialog();
    }
  }, [updateFileUserStatusData]);

  useUpdateEffect(() => {
    if (ignoreFileData) {
      const newActions: UpdateAction[] = [
        {
          type: UpdateActionTypes.updateFile,
          fileId: ignoreFileData.id,
          file: ignoreFileData,
        },
      ];
      if (ignoreFileData.sourceFileId) {
        newActions.push({
          type: UpdateActionTypes.reloadFile,
          fileId: ignoreFileData.sourceFileId,
        });
      }
      setUpdateActions((actions) => [...actions, ...newActions]);
    }
  }, [ignoreFileData]);

  useUpdateEffect(() => {
    if (unignoreFileData) {
      const newActions: UpdateAction[] = [
        {
          type: UpdateActionTypes.updateFile,
          fileId: unignoreFileData.id,
          file: unignoreFileData,
        },
      ];
      if (unignoreFileData.sourceFileId) {
        newActions.push({
          type: UpdateActionTypes.reloadFile,
          fileId: unignoreFileData.sourceFileId,
        });
      }
      setUpdateActions((actions) => [...actions, ...newActions]);
    }
  }, [unignoreFileData]);

  useUpdateEffect(() => {
    if (changeFileCategoryData) {
      const { createdSubCategory, file, updatedSubCategory } = changeFileCategoryData;
      if (createdSubCategory) {
        setUpdateActions((actions) => [
          ...actions,
          {
            type: UpdateActionTypes.updateFileSubCategory,
            subCategoryId: createdSubCategory.id,
            subCategory: createdSubCategory,
          },
        ]);
      }
      setUpdateActions((actions) => [
        ...actions,
        {
          type: UpdateActionTypes.updateFile,
          fileId: file.id,
          file: file,
        },
      ]);
      if (updatedSubCategory) {
        setUpdateActions((actions) => [
          ...actions,
          {
            type: UpdateActionTypes.updateFileSubCategory,
            subCategoryId: updatedSubCategory.id,
            subCategory: updatedSubCategory,
          },
        ]);
      }
    }
  }, [changeFileCategoryData]);

  useUpdateEffect(() => {
    if (cloneFileMappingData) {
      const { file } = cloneFileMappingData;
      if (file) {
        setUpdateActions((actions) => [
          ...actions,
          {
            type: UpdateActionTypes.updateFile,
            fileId: file.id,
            file: file,
          },
        ]);
      }
    }
  }, [cloneFileMappingData]);

  useUpdateEffect(() => {
    if (changeFileTransformationScriptData) {
      setUpdateActions((actions) => [
        ...actions,
        {
          type: UpdateActionTypes.updateFile,
          fileId: changeFileTransformationScriptData.id,
          file: changeFileTransformationScriptData,
        },
      ]);
    }
  }, [changeFileTransformationScriptData]);

  useUpdateEffect(() => {
    if (deleteFileData) {
      const { updatedSubCategory } = deleteFileData;
      if (updatedSubCategory) {
        setUpdateActions((actions) => [
          ...actions,
          {
            type: UpdateActionTypes.updateFileSubCategory,
            subCategoryId: updatedSubCategory.id,
            subCategory: updatedSubCategory,
          },
        ]);
      }
    }
  }, [deleteFileData]);

  useUpdateEffect(() => {
    if (getFileData) {
      if (getFileData.projectId !== projectId) return;
      const actionsToAdd: UpdateAction[] = [
        { type: UpdateActionTypes.updateFile, fileId: getFileData.id, file: getFileData },
      ];
      if (getFileData.parts?.length) {
        getFileData.parts.forEach((item) => {
          actionsToAdd.push({ type: UpdateActionTypes.updateFile, fileId: item.id, file: item });
        });
      }
      if (getFileData.sourceFileId) {
        actionsToAdd.push({ type: UpdateActionTypes.reloadFile, fileId: getFileData.sourceFileId });
      }
      setUpdateActions((actions) => [...actions, ...actionsToAdd]);
    }
  }, [getFileData, projectId]);

  useUpdateEffect(() => {
    if (getFileSubCategoryData) {
      setUpdateActions((actions) => [
        ...actions,
        {
          type: UpdateActionTypes.updateFileSubCategory,
          subCategoryId: getFileSubCategoryData.id,
          subCategory: getFileSubCategoryData,
        },
      ]);
    }
  }, [getFileSubCategoryData]);

  // trick to handle case when some signalR events were missed
  useUpdateEffect(() => {
    if (
      projectFiles.some(
        (x) =>
          (!x.isSource &&
            (x.piiScanResultStatus === PIIScanResultStatuses.queued ||
              x.piiScanResultStatus === PIIScanResultStatuses.inProgress)) ||
          (x.isSource &&
            (x.transformationResultStatus === TransformationResultStatuses.queued ||
              x.transformationResultStatus === TransformationResultStatuses.inProgress)),
      )
    ) {
      const timeout = setTimeout(() => {
        setUpdateActions([{ type: UpdateActionTypes.reloadProjectFiles }]);
      }, 15000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [projectFiles]);

  const onTabChanged = (tab: ProjectTabs) => {
    setActiveTab(tab);
    navigate(
      `/clients/${clientId}/products/${productId}/projects/${projectId}/${
        tab === ProjectTabs.dataFiles
          ? 'data'
          : tab === ProjectTabs.balancing
          ? 'balancing'
          : tab === ProjectTabs.reports
          ? 'reports'
          : 'results'
      }`,
    );
  };

  const onSortChanged = (sortType: SortTypes) => {
    setSort(sortType);
  };

  const onToggleShowHidden = () => {
    setShowHiddenFiles((val) => !val);
  };

  const onDownloadFile = (file: IFile) => {
    if (file.links[Actions.getContent]?.href) {
      const fileName = file.sourceFileName
        ? `${file.sourceFileName} - ${file.alternativeName || file.name}`
        : file.alternativeName || file.name;
      getFileContentRequest(file.links[Actions.getContent], fileName);
    }
  };

  const onDeleteFile = async (file: IFile) => {
    if (file?.links[Actions.delete].href) {
      await confirm({
        title: 'Delete file',
        description:
          (file.fileType === FileTypes.dataFile && project?.status === ProjectStatuses.open) ||
          (file.fileType === FileTypes.resultFile && project?.status === ProjectStatuses.inProgress)
            ? 'This will permanently delete this file.'
            : project?.status === ProjectStatuses.inProgress
            ? 'We have started processing the projects files. Are you sure you still intend to delete files from this project?'
            : 'The project is currently closed. Are you sure you still intend to delete files from this project?',
        confirmationText: 'Confirm',
      });

      const pairedFile = file.fileSubCategoryId
        ? closedProjectFiles.find((x) => x.fileSubCategoryId === file.fileSubCategoryId)
        : undefined;
      const subCategoryToDisable =
        file.fileSubCategoryId && !pairedFile
          ? fileSubCategories.find((x) => x.id === file.fileSubCategoryId)
          : undefined;

      deleteFileRequest(file.links[Actions.delete], subCategoryToDisable?.links[Actions.update]);
    }
  };

  const onIgnoreFile = async (file: IFile) => {
    if (file?.links[Actions.ignore]?.href) {
      await confirm({
        title: file.isSource ? 'Ignore all Accepted sub-sheets' : 'Ignore file',
        description: file.isSource
          ? 'Are you sure you want to ignore all accepted sub-sheets?'
          : 'Are you sure you want to ignore file?',
        confirmationText: 'Confirm',
      });

      ignoreFileRequest(file.links[Actions.ignore].href);
    }
  };

  const onUnignoreFile = async (file: IFile) => {
    if (file?.links[Actions.unignore]?.href) {
      await confirm({
        title: 'Unignore file',
        description: 'Are you sure you want to unignore file?',
        confirmationText: 'Confirm',
      });

      unignoreFileRequest(file.links[Actions.unignore].href);
    }
  };

  const onCloseDetailsDialog = () => {
    setOpenDetailsDialog(false);
    setSelectedFile(null);
  };

  const onCloseSendNotificationDialog = () => {
    setOpenSendNotificationDialog(false);
  };

  const onCloseWhitelistRecordsDialog = () => {
    setOpenWhitelistRecordsDialog(false);
  };

  const showWhitelistRecords = () => {
    setOpenWhitelistRecordsDialog(true);
  };

  const onViewPiiScanResultDetails = (file: IFile) => {
    setSelectedFile(file);
    setOpenDetailsDialog(true);
  };

  const onChangeCollapsed = (file: IFile, value: boolean) => {
    if (value) {
      setCollapsedSourceFiles((prev) => [...prev, file.id]);
    } else {
      setCollapsedSourceFiles((prev) => prev.filter((x) => x !== file.id));
    }
  };

  const changeFileCategories = async (
    file: IFile,
    categoryId?: string,
    subCategoryId?: string,
    subCategoryToDisable?: IFileSubCategory,
    skipConfirmation?: boolean,
  ) => {
    if (!file.links[Actions.changeCategory]?.href) {
      return;
    }

    let notes: string | undefined = undefined;
    if (categoryId && !subCategoryId && !skipConfirmation) {
      const data = await confirm({
        title: 'Add Data Input File',
        description:
          'Please give us some details about this new file that you are providing to be included in our analysis.',
        confirmationText: 'Confirm',
        showNotesInput: true,
        notesInputRequired: true,
      });

      notes = data?.notes;
    }

    changeFileCategoryRequest(
      file.links[Actions.changeCategory],
      !subCategoryId ? getFileSubCategoriesData?.links[Actions.createSubCategory] : undefined,
      subCategoryToDisable ? subCategoryToDisable.links[Actions.update] : undefined,
      categoryId,
      subCategoryId,
      notes,
    );
  };

  const onUpdateSubCategory = async (
    fileSubCategory: IFileSubCategory,
    data: IUpdateFileSubCategoryData,
  ) => {
    if (fileSubCategory.links[Actions.update]?.href) {
      updateFileSubCategoryRequest(fileSubCategory.links[Actions.update].href, data);
    }
  };

  const onUpdateStatus = (file: IFile, status: FileUserStatuses) => {
    if (file?.links[Actions.updateUserStatus]?.href) {
      updateFileUserStatusRequest(file.links[Actions.updateUserStatus].href, {
        status,
      });
    }
  };

  const onAddUpdateAction = useCallback((updateAction: UpdateAction) => {
    setUpdateActions((actions) => [...actions, updateAction]);
  }, []);

  const onRecalculationStatusChanged = useCallback((inProgress: boolean) => {
    setProjectRecalculationInProgress(inProgress);
  }, []);

  const onFileDragStart = (file: IFile) => {
    setSelectedFileCategories(null);
    setDraggedFile(file);
  };

  const onFileDragEnd = (file: IFile, alternativeFile?: IFile) => {
    setDraggedFile(null);
    if (!selectedFileCategories) return;
    const { categoryId, subCategoryId } = selectedFileCategories;

    let category: IFileCategory | undefined = undefined;
    if (categoryId) {
      category = fileCategories.find((x) => x.id === categoryId);
    }

    const selectedFile =
      (!category || (category && !!category.mappingsDisabled)) && !!alternativeFile
        ? alternativeFile
        : file;

    if (
      selectedFile.fileCategoryId === categoryId &&
      selectedFile.fileSubCategoryId === subCategoryId
    ) {
      return;
    }

    let subCategoryToDisable: IFileSubCategory | undefined = undefined;

    if (selectedFile.fileSubCategoryId) {
      const oldFileWithSubCategory = closedProjectFiles.find(
        (x) => x.fileSubCategoryId === selectedFile.fileSubCategoryId,
      );

      if (!oldFileWithSubCategory) {
        subCategoryToDisable = fileSubCategories.find(
          (x) => x.id === selectedFile.fileSubCategoryId,
        );
      }
    }

    changeFileCategories(
      selectedFile,
      categoryId,
      subCategoryId,
      subCategoryToDisable,
      !!category?.subCategoriesDisabled,
    );
  };

  const onFileCategoriesSelected = ({
    categoryId,
    subCategoryId,
  }: {
    categoryId?: string;
    subCategoryId?: string;
  }) => {
    setSelectedFileCategories({
      categoryId,
      subCategoryId,
    });
  };

  const tabFiles = useMemo(() => {
    return projectFiles.filter(
      (x) =>
        x.fileType ===
        (activeTab === ProjectTabs.resultFiles ? FileTypes.resultFile : FileTypes.dataFile),
    );
  }, [projectFiles, activeTab]);

  const filteredItems = useMemo(() => {
    const regexp = new RegExp(searchInput.value, 'i');
    let items: IFile[] = [];

    if (regexp) {
      const filteredSimpleFiles = tabFiles.filter(
        (x) => regexp.test(x.name) && !x.isSource && !x.sourceFileId,
      );

      const filteredPartFiles = tabFiles.filter((x) => regexp.test(x.name) && x.sourceFileId);
      const filteredPartFileSources = tabFiles.filter((s) =>
        filteredPartFiles.some((p) => p.sourceFileId === s.id),
      );

      const filteredSourceFiles = tabFiles.filter((x) => regexp.test(x.name) && x.isSource);
      const filteredSourceFileParts = tabFiles.filter((p) =>
        filteredSourceFiles.some((s) => s.id === p.sourceFileId),
      );

      const uniqueItemIds = Array.from(
        new Set(
          [
            ...filteredSimpleFiles,
            ...filteredPartFiles,
            ...filteredPartFileSources,
            ...filteredSourceFiles,
            ...filteredSourceFileParts,
          ].map((x) => x.id),
        ),
      );

      items = tabFiles.filter((x) => uniqueItemIds.includes(x.id) || x.fileCategoryId);
    } else {
      items = [...tabFiles];
    }
    if (!showHiddenFiles) {
      items = items.filter((x) => x.userStatus !== FileUserStatuses.rejected && !x.ignored);
    }
    if (sort === SortTypes.name) {
      items.sort((a, b) => {
        const aNameLowercased = a.name.toLowerCase();
        const bNameLowercased = b.name.toLowerCase();
        return aNameLowercased > bNameLowercased ? 1 : bNameLowercased > aNameLowercased ? -1 : 0;
      });
    } else if (sort === SortTypes.date) {
      items.sort((a, b) => (a.uploaded < b.uploaded ? 1 : b.uploaded < a.uploaded ? -1 : 0));
    }
    return items;
  }, [tabFiles, searchInput.value, showHiddenFiles, sort]);

  const anyFileWithChangeCategoryEnabled = useMemo(() => {
    return filteredItems.some((x) => !!x.links[Actions.changeCategory]?.href);
  }, [filteredItems]);

  const categorizedFiles = useMemo(() => {
    const map = new Map<string, IFile[]>();
    filteredItems.forEach((x) => {
      const categoryId = x.fileCategoryId || '';
      if (map.has(categoryId)) {
        const existingFiles = map.get(categoryId) || [];
        map.set(categoryId, [...existingFiles, x]);
      } else {
        map.set(categoryId, [x]);
      }
    });
    return map;
  }, [filteredItems]);

  const categorizedClosedFiles = useMemo(() => {
    const map = new Map<string, IFile[]>();
    closedProjectFiles.forEach((x) => {
      const categoryId = x.fileCategoryId || '';
      if (map.has(categoryId)) {
        const existingFiles = map.get(categoryId) || [];
        map.set(categoryId, [...existingFiles, x]);
      } else {
        map.set(categoryId, [x]);
      }
    });
    return map;
  }, [closedProjectFiles]);

  const categorizedSubCategories = useMemo(() => {
    const map = new Map<string, IFileSubCategory[]>();
    fileSubCategories.forEach((x) => {
      const categoryId = x.fileCategoryId;
      if (map.has(categoryId)) {
        const existingSubCategories = map.get(categoryId) || [];
        map.set(categoryId, [...existingSubCategories, x]);
      } else {
        map.set(categoryId, [x]);
      }
    });
    return map;
  }, [fileSubCategories]);

  const files = useMemo(() => {
    const filesWithoutCategory = categorizedFiles.get('') || [];
    const partFiles = filesWithoutCategory.filter((x) => x.sourceFileId);
    const nonPartFiles = filesWithoutCategory
      .filter((x) => !x.sourceFileId)
      .map((x) => {
        const parts = partFiles.filter((p) => p.sourceFileId === x.id);
        const totalPartsCount = tabFiles.filter((p) => p.sourceFileId === x.id).length;
        return {
          ...x,
          parts: parts.length ? parts : undefined,
          totalPartsCount: totalPartsCount !== 0 ? totalPartsCount : undefined,
        };
      });
    return nonPartFiles.filter(
      (x) =>
        !x.isSource ||
        x.parts ||
        !(
          x.transformationResultStatus === TransformationResultStatuses.complete ||
          x.transformationResultStatus === TransformationResultStatuses.skipped
        ),
    );
  }, [categorizedFiles, tabFiles]);

  const firstUnpairedClosedFile = useMemo(() => {
    let unpairedOldFile: IFile | undefined = undefined;

    for (const category of fileCategories) {
      const categoryFiles = categorizedFiles.get(category.id) || [];
      const categoryOldFiles = categorizedClosedFiles.get(category.id) || [];
      const categorySubCategories = categorizedSubCategories.get(category.id) || [];

      if (category.subCategoriesDisabled) continue;

      const pairs = categorySubCategories.map((subCategory) => {
        const file = categoryFiles.find((x) => x.fileSubCategoryId === subCategory.id);
        const oldFile = categoryOldFiles.find((x) => x.fileSubCategoryId === subCategory.id);
        return {
          subCategory,
          file,
          oldFile,
        };
      });
      pairs.sort((a, b) => {
        const aNameLowercased = a.oldFile?.name.toLowerCase();
        const bNameLowercased = b.oldFile?.name.toLowerCase();
        if (!aNameLowercased) {
          return 1;
        }
        if (!bNameLowercased) {
          return -1;
        }
        return aNameLowercased > bNameLowercased ? 1 : bNameLowercased > aNameLowercased ? -1 : 0;
      });
      const pair = pairs.find(
        ({ subCategory, oldFile, file }) => !subCategory.disabled && oldFile && !file,
      );
      if (pair) {
        unpairedOldFile = pair.oldFile;
        break;
      }
    }

    return unpairedOldFile;
  }, [fileCategories, categorizedFiles, categorizedClosedFiles, categorizedSubCategories]);

  const onSubmitProject = async () => {
    if (project?.links[Actions.confirm]) {
      await confirm({
        title: 'Submit Project',
        description: `Please confirm that all required files have been uploaded before proceeding.
        Proceed with submitting all files for processing?`,
        confirmationText: 'Confirm',
      });

      // clear all the indication data
      setAlert(false);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setInvalidCategories([]);
      setInvalidUncategorized(false);

      confirmProjectRequest(project.links[Actions.confirm].href);
    }
  };

  const onNotifyUsers = async () => {
    setOpenSendNotificationDialog(true);
  };

  const configureMappingEnabled = useMemo(() => {
    const feature = features.find((f) => f.name === Features.columnsMapping);
    if (!feature) return false;
    return clientFeatures.findIndex((x) => x.featureId === feature.id) !== -1;
  }, [features, clientFeatures]);

  const subAccountMatchingEnabled = useMemo(() => {
    const feature = features.find((f) => f.name === Features.subAccountMatching);
    if (!feature) return false;
    return clientFeatures.findIndex((x) => x.featureId === feature.id) !== -1;
  }, [features, clientFeatures]);

  const simulationReportsEnabled = useMemo(() => {
    const feature = features.find((f) => f.name === Features.simulationReports);
    if (!feature) return false;
    return clientFeatures.findIndex((x) => x.featureId === feature.id) !== -1;
  }, [features, clientFeatures]);

  const hasAllCollapsedFiles = useMemo(() => {
    const sourceFiles = files.filter((x) => x.isSource).map((x) => x.id);
    return !sourceFiles.some((item) => !collapsedSourceFiles.includes(item));
  }, [files, collapsedSourceFiles]);

  const onChangeCollapsedAll = (value: boolean) => {
    if (value) {
      const sourceFiles = files.filter((x) => x.isSource).map((x) => x.id);
      setCollapsedSourceFiles(sourceFiles);
    } else {
      setCollapsedSourceFiles([]);
    }
  };

  const onDownloadData = useMemo<((categoryId: string) => void) | undefined>(() => {
    if (getProjectData?.links[Actions.getData].href) {
      return (categoryId: string) => {
        getDataContentRequest(getProjectData.links[Actions.getData].href, categoryId);
      };
    } else return undefined;
  }, [getProjectData?.links[Actions.getData]?.href]);

  const showLoader = useLoader(
    getClientFeaturesLoading,
    getProductLoading,
    getPreviousProjectLoading,
    getClosedProjectFilesLoading,
    getFileCategoriesLoading,
    getFileSubCategoriesLoading,
    getFileSubCategoryLoading,
    getClientProductLoading,
    getProjectLoading,
    getProjectFilesLoading && !projectFiles.length,
    getFileContentLoading,
    deleteFileLoading,
    updateFileUserStatusLoading,
    changeFileCategoryLoading,
    updateFileSubCategoryLoading,
    cloneFileMappingLoading,
    confirmProjectLoading,
    ignoreFileLoading,
    unignoreFileLoading,
    changeFileTransformationScriptLoading,
    getDataContentLoading,
    getProjectRecalculationStatusLoading,
  );

  const sortOptions = useMemo(
    () => [
      {
        value: SortTypes.date,
        label: 'Sort by date',
      },
      {
        value: SortTypes.name,
        label: 'Sort by name',
      },
    ],
    [],
  );

  const tour1Steps = useMemo<Step[]>(() => {
    return [
      {
        target: '#project-title',
        content:
          'This display shows the project you are currently viewing, along with the number of files that have been uploaded for the project.',
      },
      {
        target: '#data-files-tab',
        content:
          'The Data tab allows you to upload new files and view files that have already been uploaded for the project.',
      },
      {
        target: '#result-files-tab',
        content:
          'Once the project is completed, the final results can be downloaded and viewed from this tab.',
      },
      activeTab === ProjectTabs.dataFiles
        ? {
            target: '#uploader',
            content:
              'To upload files for processing as part of this project, click here or drag and drop the files into this area.',
          }
        : null,
    ].filter((x) => x !== null) as Step[];
  }, [activeTab, project]);

  const tour2Steps = useMemo<Step[]>(() => {
    if (activeTab !== ProjectTabs.dataFiles || !files.length) {
      return [];
    }

    let firstFileWithDownloadAndDeleteAvailable: IFile | undefined = undefined;

    for (const file of files) {
      if (file.links[Actions.getContent] && file.links[Actions.delete]) {
        firstFileWithDownloadAndDeleteAvailable = file;
        break;
      } else if (file.isSource) {
        const part = file.parts?.find(
          (x) => x.links[Actions.getContent] && x.links[Actions.delete],
        );
        if (part) {
          firstFileWithDownloadAndDeleteAvailable = part;
          break;
        }
      }
    }

    if (!firstFileWithDownloadAndDeleteAvailable) {
      return [];
    }

    return [
      {
        target: '#uncategorized-files-list',
        content:
          'All currently uploaded files that have not been categorized (on the right) are listed here.',
        placementBeacon: 'top',
      },
      {
        target: `#file-${firstFileWithDownloadAndDeleteAvailable.id} [data-tour='name']`,
        content: 'Hover over the name of any file to see details about it.',
      },
      {
        target: `#file-${firstFileWithDownloadAndDeleteAvailable.id} [data-tour='download']`,
        content:
          'The original file can be downloaded by clicking on this icon.  In some scenarios, the ability to download the original file may become disabled.',
      },
      {
        target: `#file-${firstFileWithDownloadAndDeleteAvailable.id} [data-tour='delete']`,
        content: (
          <span>
            A file can be deleted by clicking on this icon.{' '}
            <strong>
              This action is irreversible: the file will be removed from all c. myers systems and
              will require the original file to be uploaded again if it is deleted by mistake.
            </strong>
          </span>
        ),
      },
      {
        target: '#download-all-files',
        content:
          'All files uploaded to this project can be downloaded as a compressed zip file to your machine by clicking on this icon.',
      },
      {
        target: '#search-files-input',
        content:
          'To search for a specific uploaded file on the left, enter a few characters from the desired name.',
      },
    ].filter((x) => x !== null) as Step[];
  }, [activeTab, files]);

  const tour3Steps = useMemo<Step[]>(() => {
    if (activeTab !== ProjectTabs.dataFiles || !files.length || !closedProject) {
      return [];
    }

    let firstAcceptedFile: IFile | undefined = undefined;

    for (const file of files) {
      if (!file.isSource) {
        if (file.userStatus === FileUserStatuses.accepted) {
          firstAcceptedFile = file;
          break;
        }
      } else {
        const acceptedPart = file.parts?.find((x) => x.userStatus === FileUserStatuses.accepted);
        if (acceptedPart) {
          firstAcceptedFile = acceptedPart;
          break;
        }
      }
    }

    if (!firstAcceptedFile) {
      return [];
    }

    if (!firstUnpairedClosedFile) {
      return [];
    }

    const addSubCategoryEnabled =
      !!getFileSubCategoriesData?.links[Actions.createSubCategory]?.href &&
      anyFileWithChangeCategoryEnabled;

    return [
      {
        target: `#file-${firstAcceptedFile.id} [data-tour='user-accepted-icon']`,
        content:
          'Accepted files are indicated by this icon.  Either personably identifiable information (PII) was not detected in this file so it was automatically accepted, or someone has reviewed the file and manually accepted it.  Hover over this icon to see the conditions under which the file was accepted.  Accepted files are classified as not containing PII and can be sent to c. myers for processing.',
      },
      {
        target: `#file-category-${firstUnpairedClosedFile.fileCategoryId}`,
        content:
          'In order to send files for processing, they must be categorized in order to be paired with the necessary files needed for your project.',
      },
      {
        target: `#old-file-${firstUnpairedClosedFile.id}`,
        content:
          'To ensure the necessary files are sent to process your project, each file must be paired with a file from the previous project.',
      },
      firstUnpairedClosedFile.links[Actions.getContent]
        ? {
            target: `#old-file-${firstUnpairedClosedFile.id} [data-tour='download']`,
            content: 'Download the file from the previous project by clicking on this icon.',
          }
        : null,
      {
        target: `#file-category-${firstUnpairedClosedFile.fileCategoryId} [data-tour='previous-project']`,
        content:
          'Click here to navigate to the previous project and view the files that were uploaded for that project.',
      },
      {
        target: `#file-sub-category-${firstUnpairedClosedFile.fileSubCategoryId}`,
        content: (
          <span>
            To categorize and pair files, drag and drop an Accepted file to this location inside the
            appropriate category and match it with the same file that was provided for the previous
            project. If a file is paired incorrectly, it can be dragged to a new pair or moved back
            to the uncategorized list on the left.{' '}
            <strong>Only Accepted files can be categorized and paired.</strong>
          </span>
        ),
      },
      addSubCategoryEnabled
        ? {
            target: `#file-category-${firstUnpairedClosedFile.fileCategoryId} [data-tour='new-file-sub-category']`,
            content: `If the file you wish to pair is new to this project and doesn't have an existing file to pair with from a previous project, drop the file here. 
              You will be asked to provide a brief explanation for this new file and confirm that you wish to create a new pairing.`,
          }
        : null,
      {
        target: `#old-file-${firstUnpairedClosedFile.id} [data-tour='switch']`,
        content: `If you no longer intend to deliver a file that pairs with a previous project file, you can remove that file from future processing by toggling this button.
          You will be asked to provide a brief explanation for why you are no longer providing this file and confirm the removal of the file.
          This action can be undone by toggling the button back on.`,
      },
      project && project.status === ProjectStatuses.open && project.links[Actions.confirm]
        ? {
            target: '#submit-project-button',
            content: (
              <Box>
                Once all files are categorized and paired, submit the project by clicking on this
                button. Once submitted, c. myers will be notified and will begin processing your
                files.{' '}
                <strong>
                  A project cannot be submitted until: <br />
                  1) All uploaded files have been categorized and paired, and <br />
                  2) All previous project files either have a newly paired file or have been
                  disabled.
                </strong>
              </Box>
            ),
          }
        : null,
    ].filter((x) => x !== null) as Step[];
  }, [
    project,
    activeTab,
    files,
    closedProject,
    firstUnpairedClosedFile,
    getFileSubCategoriesData,
    anyFileWithChangeCategoryEnabled,
  ]);

  const tour4Steps = useMemo<Step[]>(() => {
    if (activeTab !== ProjectTabs.dataFiles || !files.length) {
      return [];
    }

    let firstFileWithPii: IFile | undefined = undefined;

    for (const file of files) {
      if (!file.isSource) {
        if (
          file.piiScanResultStatus === PIIScanResultStatuses.completedWithPii &&
          !file.userStatus
        ) {
          firstFileWithPii = file;
          break;
        }
      } else {
        const partWithPii = file.parts?.find(
          (x) => x.piiScanResultStatus === PIIScanResultStatuses.completedWithPii && !x.userStatus,
        );
        if (partWithPii) {
          firstFileWithPii = partWithPii;
          break;
        }
      }
    }

    if (!firstFileWithPii) {
      return [];
    }

    return [
      {
        target: `#file-${firstFileWithPii.id} [data-tour='action-required']`,
        content: (
          <span>
            Files that have been detected to potentially contain personally identifiable information
            (<strong>PII</strong>) are indicated by this icon. Click on this icon to see more detail
            about what has been found in the file and for actions you can take with this file.
          </span>
        ),
      },
    ].filter((x) => x !== null) as Step[];
  }, [activeTab, files]);

  const tour5Steps = useMemo<Step[]>(() => {
    if (activeTab !== ProjectTabs.dataFiles || !files.length || !closedProject) {
      return [];
    }

    const firstSourceFile = files.find((x) => x.isSource && x.parts?.length);
    if (!firstSourceFile) {
      return [];
    }

    return [
      {
        target: `#file-${firstSourceFile.id}`,
        content:
          'Multi-sheet (Excel) files may contain multiple sub-sheets (worksheets), which may require slightly different processing than other files.',
      },
      {
        target: `#file-${firstSourceFile.id} [data-tour='file-card']:first-of-type`,
        content: `Each sub-sheet will be scanned for personally identifiable information (PII) separately and listed here. You can treat each sub-sheet as an independent file;
          they can be downloaded and deleted separately, and their PII must be evaluated independently of other sub-sheets.`,
      },
      {
        target: `#file-${firstSourceFile.id} [data-tour='collapse-button']`,
        content: 'You can hide individual sub-sheets by collapsing the original file here.',
      },
      {
        target: `#file-${firstSourceFile.id} [data-tour='pairing-status']`,
        content: `If all sub-sheets are in the "Accepted" state and none of them have been paired independently from the original source file, 
          the entire source file can be paired as a whole. An icon will indicate whether the entire file is currently eligible for whole file pairing. 
          Note that only certain file classifications support whole file pairing, and these will be indicated by a similar icon.`,
      },
      firstSourceFile.links[Actions.ignore]
        ? {
            target: `#file-${firstSourceFile.id} [data-tour='ignore-button']`,
            content: (
              <span>
                When individual sub-sheets need to be paired independently, you can ignore
                sub-sheets that are not required by clicking this icon. Additionally, sub-sheets can
                be ignored on an individual basis by clicking the same icon on the respective
                sub-sheet. Ignored sub-sheets will be hidden from view and are not mandatory for
                pairing.{' '}
                <strong>
                  Please note that only &quot;Accepted&quot; sub-sheets can be ignored.
                </strong>
              </span>
            ),
          }
        : null,
      {
        // eslint-disable-next-line quotes
        target: `[data-tour='toggle-hidden-files-visibility-button']`,
        content: `You can adjust the visibility of all ignored and rejected files by toggling this icon. 
          If you’re currently viewing ignored files, you can unignore files that were previously ignored by toggling the icon on the respective file.`,
      },
    ].filter((x) => x !== null) as Step[];
  }, [activeTab, files, closedProject]);

  return (
    <Box ref={rootRef} className={clsx([classes.root, project && 'loaded'])}>
      {project && (
        <>
          <GuideTourMarker names={['accept-reject-dialog']} />
          <GuideTour steps={tour1Steps} name='first-project-view' />
          <GuideTour steps={tour2Steps} name='second-project-view' previous='first-project-view' />
          <GuideTour
            steps={tour3Steps}
            name='third-project-view'
            previous='second-project-view'
            onStepComplete={
              firstUnpairedClosedFile?.fileCategoryId
                ? (step) => {
                    if (step === 0) {
                      const trigger = rootRef.current?.querySelector(
                        `#file-category-${firstUnpairedClosedFile.fileCategoryId} [data-tour='expand']`,
                      );
                      if (trigger) {
                        (trigger as HTMLElement).click();
                      }
                    }
                  }
                : undefined
            }
          />
          <GuideTour steps={tour4Steps} name='fourth-project-view' previous='second-project-view' />
          <GuideTour steps={tour5Steps} name='fifth-project-view' previous='third-project-view' />
          {clientId && productId && projectId && (
            <SignalREventHandler
              clientId={clientId}
              productId={productId}
              projectId={projectId}
              onAddUpdateAction={onAddUpdateAction}
              onRecalculationStatusChanged={onRecalculationStatusChanged}
            />
          )}
          <Box className={classes.hiddenSection} />
          <Box className={classes.topSection}>
            <Container maxWidth='xl'>
              <Box className={classes.topSectionContainer}>
                <DashboardBreadcrumbs
                  clientId={clientId}
                  productId={productId}
                  projectId={projectId}
                />
                <IssuesAlert clientId={clientId} productId={productId} projectId={projectId} />
                <ExternalResources />
              </Box>
            </Container>
          </Box>
          <Box className={classes.mainSection}>
            <Box className={classes.title}>
              <Container maxWidth='xl'>
                <Box className={clsx([classes.flex, classes.spaceBetween])}>
                  <Box className={clsx([classes.flex, classes.gap4])} id='project-title'>
                    <Typography variant='h6'>{project.name}</Typography>
                    <Typography className={classes.filesCount} component='div' variant='caption'>
                      ({filteredItems.length} file
                      {filteredItems.length !== 1 && 's'})
                    </Typography>
                  </Box>

                  <Tabs
                    className={classes.tabsContainer}
                    value={activeTab}
                    onChange={(e, t) => onTabChanged(t)}
                    aria-label='tabs'
                  >
                    <Tab
                      data-testid='data-files-tab'
                      id='data-files-tab'
                      label='Data'
                      value={ProjectTabs.dataFiles}
                    />
                    {subAccountMatchingEnabled && (
                      <Tab
                        data-testid='balancing-tab'
                        id='balancing-tab'
                        label='Balancing'
                        value={ProjectTabs.balancing}
                      />
                    )}
                    <Tab
                      data-testid='result-files-tab'
                      id='result-files-tab'
                      label='Results'
                      value={ProjectTabs.resultFiles}
                    />
                    {simulationReportsEnabled && (
                      <Tab
                        data-testid='reports-tab'
                        id='reports-tab'
                        label='Reports'
                        value={ProjectTabs.reports}
                      />
                    )}
                  </Tabs>

                  <Box className={clsx([classes.flex, classes.spaceBetween, classes.gap4])}>
                    <Tooltip title='Show client whitelisted values'>
                      <IconButton onClick={showWhitelistRecords}>
                        <img alt='list' src={ListIcon} />
                      </IconButton>
                    </Tooltip>
                    {project.links[Actions.getFilesDirectory]?.href && (
                      <FileDownloader
                        id='download-all-files'
                        project={project}
                        files={tabFiles}
                        categories={fileCategories}
                        structured={activeTab === ProjectTabs.dataFiles}
                      />
                    )}
                    {project.status === ProjectStatuses.open &&
                      (!project.links[Actions.confirm] ? (
                        <Fab size='small' color='success' disabled>
                          <img alt='check' src={CheckFilledWhiteIcon} />
                        </Fab>
                      ) : (
                        <Tooltip title='Submit project'>
                          <Fab
                            size='small'
                            color='success'
                            onClick={onSubmitProject}
                            id='submit-project-button'
                          >
                            <img alt='check' src={CheckFilledWhiteIcon} />
                          </Fab>
                        </Tooltip>
                      ))}
                    {project.status === ProjectStatuses.inProgress &&
                      (!project.links[Actions.createResultsUploadedNotification] ? (
                        <Fab size='small' color='info' disabled>
                          <img alt='check' src={MailFilledWhiteIcon} />
                        </Fab>
                      ) : (
                        <Tooltip title='Send notification to users'>
                          <Fab size='small' color='info' onClick={onNotifyUsers}>
                            <img alt='check' src={MailFilledWhiteIcon} />
                          </Fab>
                        </Tooltip>
                      ))}
                  </Box>
                </Box>
              </Container>
            </Box>

            <Container
              maxWidth={
                activeTab === ProjectTabs.dataFiles || activeTab === ProjectTabs.balancing
                  ? 'xl'
                  : activeTab === ProjectTabs.reports
                  ? 'lg'
                  : 'md'
              }
              className={clsx([classes.flexGrow1, classes.overflowHidden])}
            >
              <Box
                className={clsx([
                  classes.h100,
                  classes.grid,
                  activeTab === ProjectTabs.dataFiles && classes.uploadingGrid,
                ])}
              >
                {(activeTab === ProjectTabs.dataFiles || activeTab === ProjectTabs.resultFiles) && (
                  <>
                    <Box
                      className={classes.filesSection}
                      onDrop={() =>
                        onFileCategoriesSelected({
                          categoryId: undefined,
                          subCategoryId: undefined,
                        })
                      }
                    >
                      {((activeTab === ProjectTabs.dataFiles &&
                        !!project.links[Actions.uploadFile]) ||
                        (activeTab === ProjectTabs.resultFiles &&
                          !!project.links[Actions.uploadResult])) && (
                        <Uploader
                          id='uploader'
                          project={project}
                          fileType={
                            activeTab === ProjectTabs.dataFiles
                              ? FileTypes.dataFile
                              : FileTypes.resultFile
                          }
                        />
                      )}
                      <Box className={clsx([classes.flex, classes.gap4, classes.spaceBetween])}>
                        <SearchInput
                          onChange={(value) => searchInput.onChange({ target: { value } })}
                          color={SearchInputColors.grey}
                          maxWidth={180}
                          id='search-files-input'
                        />

                        <Box className={clsx([classes.flex, classes.gap4])}>
                          <Dropdown
                            value={sort}
                            onChanged={(value) => onSortChanged(value as SortTypes)}
                            options={sortOptions}
                            variant='default'
                          />

                          {activeTab === ProjectTabs.dataFiles && (
                            <>
                              <Tooltip
                                title={`${
                                  showHiddenFiles ? 'Hide' : 'Show'
                                } rejected and ignored files`}
                              >
                                <IconButton
                                  onClick={onToggleShowHidden}
                                  data-tour='toggle-hidden-files-visibility-button'
                                >
                                  {showHiddenFiles ? (
                                    <img alt='eye-off' src={EyeOffIcon} />
                                  ) : (
                                    <img alt='eye' src={EyeIcon} />
                                  )}
                                </IconButton>
                              </Tooltip>
                              {hasAllCollapsedFiles ? (
                                <Tooltip title='Click here to expand all the files'>
                                  <IconButton onClick={() => onChangeCollapsedAll(false)}>
                                    <img src={PlusSquareIcon} alt='+' />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip title='Click here to collapse all the files'>
                                  <IconButton onClick={() => onChangeCollapsedAll(true)}>
                                    <img src={MinusSquareIcon} alt='-' />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </Box>
                      </Box>
                      <Box
                        id='uncategorized-files-list'
                        className={clsx([
                          classes.filesList,
                          alert && invalidUncategorized && 'invalid',
                        ])}
                        onDrop={() =>
                          onFileCategoriesSelected({
                            categoryId: undefined,
                            subCategoryId: undefined,
                          })
                        }
                      >
                        {files.map((item) =>
                          item.isSource ? (
                            !item.parts || item.totalPartsCount !== 1 ? (
                              <SourceFileCard
                                id={`file-${item.id}`}
                                key={item.id}
                                item={item}
                                collapsed={collapsedSourceFiles.includes(item.id)}
                                onChangeCollapsed={(value) => onChangeCollapsed(item, value)}
                                draggable={!!item.links[Actions.changeCategory]?.href}
                                onViewPiiScanResultDetails={onViewPiiScanResultDetails}
                                onDeleteFile={onDeleteFile}
                                onDownloadFile={onDownloadFile}
                                onFileDragStart={onFileDragStart}
                                onFileDragEnd={onFileDragEnd}
                                onIgnoreFile={onIgnoreFile}
                                onUnignoreFile={onUnignoreFile}
                              />
                            ) : (
                              <FileCard
                                id={`file-${item.parts[0].id}`}
                                key={item.parts[0].id}
                                item={item.parts[0]}
                                extendedName
                                draggable={!!item.parts[0].links[Actions.changeCategory]?.href}
                                onDetails={() =>
                                  !!item.parts && onViewPiiScanResultDetails(item.parts[0])
                                }
                                onDelete={() => !!item.parts && onDeleteFile(item.parts[0])}
                                onDownload={() => !!item.parts && onDownloadFile(item.parts[0])}
                                onIgnore={() => !!item.parts && onIgnoreFile(item.parts[0])}
                                onUnignore={() => !!item.parts && onUnignoreFile(item.parts[0])}
                                onDragStart={() => !!item.parts && onFileDragStart(item.parts[0])}
                                onDragEnd={() => !!item.parts && onFileDragEnd(item.parts[0], item)}
                              />
                            )
                          ) : (
                            <FileCard
                              id={`file-${item.id}`}
                              key={item.id}
                              item={item}
                              draggable={!!item.links[Actions.changeCategory]?.href}
                              onDetails={() => onViewPiiScanResultDetails(item)}
                              onDelete={() => onDeleteFile(item)}
                              onDownload={() => onDownloadFile(item)}
                              onIgnore={() => onIgnoreFile(item)}
                              onUnignore={() => onUnignoreFile(item)}
                              onDragStart={() => onFileDragStart(item)}
                              onDragEnd={() => onFileDragEnd(item)}
                            />
                          ),
                        )}
                        {!files.length && <Typography>No files here.</Typography>}
                      </Box>
                    </Box>
                    {activeTab === ProjectTabs.dataFiles && (
                      <Box className={classes.verticalDivider} />
                    )}
                    {activeTab === ProjectTabs.dataFiles && clientId && productId && (
                      <Box className={classes.fileCategoriesList}>
                        {fileCategories.map((x) => (
                          <FileCategoryCard
                            id={`file-category-${x.id}`}
                            key={x.id}
                            readonly={project.status === ProjectStatuses.closed}
                            item={x}
                            clientId={clientId}
                            productId={productId}
                            oldProject={closedProject}
                            onFileCategoriesSelected={onFileCategoriesSelected}
                            subCategories={categorizedSubCategories.get(x.id) || []}
                            addSubCategoryEnabled={
                              !!getFileSubCategoriesData?.links[Actions.createSubCategory]?.href &&
                              anyFileWithChangeCategoryEnabled
                            }
                            files={categorizedFiles.get(x.id) || []}
                            oldFiles={categorizedClosedFiles.get(x.id) || []}
                            onUpdateSubCategory={onUpdateSubCategory}
                            onViewPiiScanResultDetails={onViewPiiScanResultDetails}
                            onDeleteFile={onDeleteFile}
                            onDownloadFile={onDownloadFile}
                            onFileDragStart={onFileDragStart}
                            onFileDragEnd={onFileDragEnd}
                            configureMappingEnabled={configureMappingEnabled}
                            invalid={alert && invalidCategories.includes(x.id)}
                            dropDisabled={
                              draggedFile &&
                              (draggedFile.isSource || draggedFile.supportsColumnMapping !== true)
                                ? !x.mappingsDisabled || false
                                : undefined
                            }
                            onDownloadData={onDownloadData}
                          />
                        ))}
                      </Box>
                    )}
                  </>
                )}
                {activeTab === ProjectTabs.balancing && projectRecalculationInProgress !== null && (
                  <>
                    {projectRecalculationInProgress ? (
                      <Box className={clsx([classes.flex, classes.center, classes.gap4])}>
                        <CircularProgress color='secondary' size={16} />
                        <Typography variant='h6'>
                          Please wait one moment while we finish processing your files and calculate
                          your balances. This may take a couple minutes.
                        </Typography>
                      </Box>
                    ) : (
                      <BalancingTab project={project} />
                    )}
                  </>
                )}
                {activeTab === ProjectTabs.reports && <ReportsTab project={project} />}
              </Box>
            </Container>
          </Box>
        </>
      )}
      <Loader show={showLoader} />
      {selectedFile && (
        <PiiScanResultDetailsDialog
          file={selectedFile}
          open={openDetailsDialog}
          onClose={onCloseDetailsDialog}
          onUpdate={onUpdateStatus}
        />
      )}
      {project && (
        <>
          <SendNotificationDialog
            project={project}
            open={openSendNotificationDialog}
            onClose={onCloseSendNotificationDialog}
          />
          <WhitelistRecordsDialog
            project={project}
            open={openWhitelistRecordsDialog}
            onClose={onCloseWhitelistRecordsDialog}
          />
        </>
      )}
    </Box>
  );
};

ProjectPage.defaultProps = {
  delay: 1000,
  projectReloadDelay: 1000,
};
