import { Box, Button, TextField, Tooltip, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import moment, { Moment } from 'moment';
import { useApi, useLoader } from '@hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { CustomDatePicker } from '@components/CustomDatePicker';
import { CustomDialog } from '@components/CustomDialog';
import { IClient } from '@models/interfaces/entities/IClient';
import { ILink } from '@models/interfaces/entities/ILink';
import { IProject } from '@models/interfaces/entities/IProject';
import { IUpdateProjectData } from '@models/interfaces/additional/IUpdateProjectData';
import InfoIcon from '@assets/icons/dashboard/info.svg';
import { Loader } from '@components/Loader';
import SaveFilledIcon from '@assets/icons/dialog/save-filled.svg';
import { SimulationImporter } from '@pages/dashboard/project/components/SimulationImporter';
import clsx from 'clsx';
import { getTypeCodesCount } from '@services/api';
import useStyles from './styles';

interface IProps {
  client: IClient;
  project: IProject;
  open: boolean;
  onClose: (data?: IUpdateProjectData, callback?: (project: IProject) => Promise<void>) => void;
  subAccountMatchingEnabled?: boolean;
}

type IFormData = IUpdateProjectData;

export const EditProjectDialog = ({
  client,
  project,
  open,
  onClose,
  subAccountMatchingEnabled,
}: IProps) => {
  const { classes } = useStyles();
  const [knownTypeCodesCount, setKnownTypeCodesCount] = useState(0);

  const simulationImporterRef = useRef<{
    import: (updateAccountsLink: ILink, updateSubAccountsLink: ILink) => Promise<void>;
  }>(null);

  const [selectedFile, setSelectedFile] = useState<string>();
  const [error, setError] = useState<string>();

  const { request: getTypeCodesCountRequest, loading: getTypeCodesCountLoading } = useApi(
    getTypeCodesCount,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          setKnownTypeCodesCount(data.count);
        }
      },
    },
  );

  const toStartDate = (local: Moment) => {
    const year = local.year();
    const month = local.month();
    const date = local.date();
    return new Date(Date.UTC(year, month, date, 12, 0, 0));
  };

  const formDefaultData = useMemo(
    () => ({
      name: project.name,
      startDate: project.startDate,
    }),
    [project],
  );

  const form = useForm<IFormData>({
    defaultValues: formDefaultData,
  });

  const projectUpdatingCallback = useMemo<((project: IProject) => Promise<void>) | undefined>(
    () =>
      selectedFile
        ? async (project: IProject) => {
            if (project.links[Actions.importAccounts] && project.links[Actions.importSubAccounts]) {
              await simulationImporterRef.current?.import(
                project.links[Actions.importAccounts],
                project.links[Actions.importSubAccounts],
              );
            }
          }
        : undefined,
    [selectedFile],
  );

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    const data = form.getValues();

    onClose(data, projectUpdatingCallback);
  };

  const onSimulationFileSelected = useCallback(
    (fileName: string, clientNumber: string) => {
      setSelectedFile(fileName);
      if (clientNumber !== client.accountNumber) {
        setError(
          'The provided simulation is for a different client. Please provide a simulation for the correct client before proceeding.',
        );
      } else {
        setError(undefined);
      }
    },
    [client],
  );

  const reloadKnownTypeCodesCount = useCallback((link: ILink) => {
    if (link) {
      getTypeCodesCountRequest(link.href, undefined, undefined, undefined, true, undefined);
    }
  }, []);

  useEffect(() => {
    reloadKnownTypeCodesCount(project.links[Actions.getTypeCodesCount]);
  }, [project.links[Actions.getTypeCodesCount], reloadKnownTypeCodesCount]);

  useEffect(() => {
    form.reset(formDefaultData);
    setSelectedFile(undefined);
    setError(undefined);
  }, [open]);

  const showLoader = useLoader(getTypeCodesCountLoading);

  return (
    <>
      <CustomDialog
        title='Edit Project'
        onClose={onCancel}
        open={open}
        maxWidth='xs'
        fullWidth
        actions={
          <>
            <div />
            <Button
              type='submit'
              form='form'
              variant='contained'
              color='secondary'
              size='large'
              startIcon={<img alt='add' src={SaveFilledIcon} />}
            >
              Save
            </Button>
            <div />
          </>
        }
      >
        <form
          id='form'
          onSubmit={form.handleSubmit(() => {
            onSave();
          })}
          noValidate
        >
          <Box>
            <Controller
              name={'name'}
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: 'Please enter the name',
                },
              }}
              render={({ field: { onChange, value }, formState }) => (
                <TextField
                  fullWidth
                  label='Name'
                  variant='standard'
                  error={!!formState.errors.name}
                  helperText={formState.errors.name?.message}
                  onChange={onChange}
                  value={value}
                  autoComplete='off'
                />
              )}
            />
          </Box>
          {project.links[Actions.changeStartDate] && (
            <Box className={classes.mt24}>
              <Controller
                name={'startDate'}
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: 'Please enter the start date',
                  },
                }}
                render={({ field: { onChange, value }, formState }) => (
                  <CustomDatePicker
                    value={value}
                    onChange={(newValue) => {
                      onChange({
                        target: {
                          value: newValue ? toStartDate(moment(newValue)) : null,
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label='Start Date'
                        variant='standard'
                        error={!!formState.errors.startDate}
                        helperText={formState.errors.startDate?.message}
                        autoComplete='off'
                      />
                    )}
                  />
                )}
              />
            </Box>
          )}
          {subAccountMatchingEnabled && (
            <Box className={classes.mt24}>
              <Box className={classes.flex}>
                <Typography variant='body2' className={classes.label}>
                  Previous simulation
                </Typography>
                <Tooltip
                  title={
                    <Box>
                      <strong>Required for GL Balancing.</strong>
                      <br />
                      Upload the final base simulation from the previous project.
                    </Box>
                  }
                >
                  <img src={InfoIcon} alt='info' />
                </Tooltip>
              </Box>
              <Box className={clsx([classes.flex, classes.mt12])}>
                <SimulationImporter
                  ref={simulationImporterRef}
                  onFileSelected={onSimulationFileSelected}
                  maxFileSize={200}
                  accept={{ '': ['.cmdm'], 'text/x-cmdm': ['.cmdm'] }}
                />

                {knownTypeCodesCount > 0 ? (
                  <Typography variant='caption' className={classes.greenText}>
                    A simulation has already been uploaded, but a new one can be provided.
                  </Typography>
                ) : (
                  <Typography variant='caption' className={classes.redText}>
                    A simulation has not been uploaded.
                  </Typography>
                )}
              </Box>

              <Box className={clsx([classes.flex, classes.mt12])}>
                <Tooltip title={selectedFile}>
                  <Typography variant='subtitle1' className={classes.label}>
                    {selectedFile}
                  </Typography>
                </Tooltip>
              </Box>
              {error && (
                <Box className={clsx([classes.flex, classes.mt12])}>
                  <Tooltip title={selectedFile}>
                    <Typography variant='subtitle2' className={classes.redText}>
                      {error}
                    </Typography>
                  </Tooltip>
                </Box>
              )}
            </Box>
          )}
        </form>
      </CustomDialog>
      <Loader show={showLoader} />
    </>
  );
};
