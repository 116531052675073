import { ICreateProjectData } from '@models/interfaces/additional/ICreateProjectData';
import { ICreateProjectRequest } from '@models/interfaces/requests/ICreateProjectRequest';
import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import { IProject } from '@models/interfaces/entities/IProject';
import { IProjectDto } from '@models/interfaces/dtos/IProjectDto';
import { IUpdateProjectData } from '@models/interfaces/additional/IUpdateProjectData';
import { IUpdateProjectRequest } from '@models/interfaces/requests/IUpdateProjectRequest';
import { ProjectStatuses } from '@models/enums/ProjectStatuses';
import ProjectsProvider from '@providers/ProjectsProvider';
import mapper from '@models/mapper';

const projectsProvider = new ProjectsProvider();

export const getProject = async (url: string) => {
  const result = await projectsProvider.getProject(url);
  return mapper.map<IProjectDto, IProject>(result, 'IProjectDto', 'IProject');
};

export const getPreviousProject = async (url: string) => {
  const result = await projectsProvider.getPreviousProject(url);
  return mapper.map<IProjectDto, IProject>(result, 'IProjectDto', 'IProject');
};

export const getProjects = async (url: string, status?: ProjectStatuses) => {
  const result = await projectsProvider.getProjects(url, status);
  return {
    items: mapper.mapArray<IProjectDto, IProject>(result.items, 'IProjectDto', 'IProject'),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const createProject = async (url: string, data: ICreateProjectData) => {
  const requestData = mapper.map<ICreateProjectData, ICreateProjectRequest>(
    data,
    'ICreateProjectData',
    'ICreateProjectRequest',
  );
  const result = await projectsProvider.createProject(url, requestData);
  return mapper.map<IProjectDto, IProject>(result, 'IProjectDto', 'IProject');
};

export const updateProject = async (url: string, data: IUpdateProjectData) => {
  const requestData = mapper.map<IUpdateProjectData, IUpdateProjectRequest>(
    data,
    'IUpdateProjectData',
    'IUpdateProjectRequest',
  );
  const result = await projectsProvider.updateProject(url, requestData);
  return mapper.map<IProjectDto, IProject>(result, 'IProjectDto', 'IProject');
};

export const startProject = async (url: string) => {
  const result = await projectsProvider.startProject(url);
  return mapper.map<IProjectDto, IProject>(result, 'IProjectDto', 'IProject');
};

export const closeProject = async (url: string) => {
  const result = await projectsProvider.closeProject(url);
  return mapper.map<IProjectDto, IProject>(result, 'IProjectDto', 'IProject');
};

export const restartProject = async (url: string) => {
  const result = await projectsProvider.restartProject(url);
  return mapper.map<IProjectDto, IProject>(result, 'IProjectDto', 'IProject');
};

export const reopenProject = async (url: string) => {
  const result = await projectsProvider.reopenProject(url);
  return mapper.map<IProjectDto, IProject>(result, 'IProjectDto', 'IProject');
};

export const confirmProject = async (url: string) => {
  const result = await projectsProvider.confirmProject(url);
  return mapper.map<IProjectDto, IProject>(result, 'IProjectDto', 'IProject');
};

export const deleteProject = async (url: string) => {
  await projectsProvider.deleteProject(url);
  return true;
};

export const getProjectRecalculationStatus = async (url: string) => {
  const result = await projectsProvider.getProjectRecalculationStatus(url);
  return result;
};
