import BaseProvider from './BaseProvider';
import { IGetTypeCodesCountResponse } from '@models/interfaces/responses/IGetTypeCodesCountResponse';
import { IGetTypeCodesResponse } from '@models/interfaces/responses/IGetTypeCodesResponse';
import { IUpdateNewTypeCodeRequest } from '@models/interfaces/requests/IUpdateNewTypeCodeRequest';
import { IUpdateNewTypeCodeResponse } from '@models/interfaces/responses/IUpdateNewTypeCodeResponse';

export default class TypeCodesProvider extends BaseProvider {
  public getTypeCodes = async (
    url: string,
    categoryId?: string,
    type?: number,
    isNew?: boolean,
    isKnown?: boolean,
    isUsed?: boolean,
  ) => {
    return this.get<IGetTypeCodesResponse>(url, {
      params: {
        categoryId,
        type,
        isNew,
        isKnown,
        isUsed,
      },
    });
  };

  public getTypeCodesCount = async (
    url: string,
    categoryId?: string,
    type?: number,
    isNew?: boolean,
    isKnown?: boolean,
    isUsed?: boolean,
  ) => {
    return this.get<IGetTypeCodesCountResponse>(url, {
      params: {
        categoryId,
        type,
        isNew,
        isKnown,
        isUsed,
      },
    });
  };

  public updateNewTypeCode = (url: string, data: IUpdateNewTypeCodeRequest) => {
    return this.put<IUpdateNewTypeCodeRequest, IUpdateNewTypeCodeResponse>(url, data);
  };
}
