import { Button, Typography } from '@mui/material';
import { CustomAutocomplete, IAutocompleteOption } from '@components/CustomAutocomplete';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { useApi, useAppSelector, useLoader } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { AccessItemCard } from './components/AccessItemCard';
import { Box } from '@mui/system';
import { ClientRoles } from '@models/enums/Roles';
import { Loader } from '@components/Loader';
import Share2FilledIcon from '@assets/icons/dialog/share-2-filled.svg';
import { getGroups } from '@services/api';
import { selectAuthGetGroupsActionLink } from '@reducers/authSlice';
import { toast } from 'react-toastify';
import useStyles from './styles';
import { v4 as uuidv4 } from 'uuid';

export interface IStep2FormData {
  access: {
    role: ClientRoles.clientManager | ClientRoles.clientUser | ClientRoles.projectManager;
    adGroup: string;
  }[];
}

interface IProps {
  form: UseFormReturn<IStep2FormData>;
  formId: string;
  onFormSubmit: () => void;
}

export const Step2 = ({ form, formId, onFormSubmit }: IProps) => {
  const { classes } = useStyles();
  const getGroupsActionLink = useAppSelector(selectAuthGetGroupsActionLink);
  const [selectedGroup, setSelectedGroup] = useState<IAutocompleteOption | null>(null);
  const [selectionEnabled, setSelectionEnabled] = useState(true);

  const {
    request: getGroupsRequest,
    data: getGroupsData,
    loading: getGroupsLoading,
  } = useApi(getGroups, {
    items: [],
    links: {},
  });

  const {
    fields: accessItems,
    append: appendAccessItem,
    remove: removeAccessItem,
    update: updateAccessItem,
  } = useFieldArray({ name: 'access', control: form.control });

  const onRemoveAccessItem = (index: number) => {
    removeAccessItem(index);
  };

  const onGroupSelected = (option: IAutocompleteOption | null) => {
    if (option) {
      appendAccessItem({
        role: ClientRoles.clientManager,
        adGroup: option.value,
      });
      setSelectedGroup(null);
      setSelectionEnabled(false);
    }
  };

  useEffect(() => {
    if (getGroupsActionLink) getGroupsRequest(getGroupsActionLink.href);
  }, [getGroupsActionLink]);

  const groupOptions = useMemo<IAutocompleteOption[]>(
    () =>
      getGroupsData.items.map((x) => ({
        value: x.id,
        title: x.name,
      })),
    [getGroupsData],
  );

  useEffect(() => {
    if (!accessItems.length) {
      setSelectionEnabled(true);
    }
  }, [accessItems]);

  const onAccessItemRoleChanged = (index: number, role: ClientRoles) => {
    const item = accessItems[index];
    updateAccessItem(index, { ...item, role });
  };

  const onSubmit = () => {
    const data = form.getValues();
    if (!data.access.find((x) => x.role === ClientRoles.clientManager)) {
      toast.error('Please configure access for the client manager role');
      return;
    }
    onFormSubmit();
  };

  const getGroup = useCallback(
    (groupId: string) => getGroupsData.items.find((x) => x.id === groupId),
    [getGroupsData.items],
  );

  const showLoader = useLoader(getGroupsLoading);

  return (
    <>
      <form
        id={formId}
        onSubmit={form.handleSubmit(() => {
          onSubmit();
        })}
        noValidate
      >
        <Typography variant='h6' className={classes.info}>
          Attach groups
        </Typography>
        <Typography variant='subtitle2' className={classes.description}>
          At least 1 group should be attached to create a new client
        </Typography>
        {!!accessItems.length &&
          accessItems.map((item, index) => (
            <AccessItemCard
              key={uuidv4()}
              item={{
                groupName: getGroup(item.adGroup)?.name || 'Unknown',
                role: item.role,
              }}
              onRemove={() => onRemoveAccessItem(index)}
              onRoleChanged={(role) => onAccessItemRoleChanged(index, role)}
            />
          ))}

        <Box className={classes.controlContainer}>
          {selectionEnabled ? (
            <CustomAutocomplete
              value={selectedGroup}
              label='Select Group'
              placeholder='Please Select'
              onChange={onGroupSelected}
              options={groupOptions}
            />
          ) : (
            <Button
              variant='text'
              color='info'
              size='large'
              startIcon={<img alt='attach' src={Share2FilledIcon} />}
              onClick={() => setSelectionEnabled(true)}
            >
              Attach new group
            </Button>
          )}
        </Box>
      </form>
      <Loader show={showLoader} />
    </>
  );
};
