import {
  IUpdateAccountsData,
  IUpdateAccountsDataAccount,
} from '@models/interfaces/additional/IUpdateAccountsData';

import AccountsProvider from '@providers/AccountsProvider';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAccountDto } from '@models/interfaces/dtos/IAccountDto';
import { ICreateAccountData } from '@models/interfaces/additional/ICreateAccountData';
import { ICreateAccountRequest } from '@models/interfaces/requests/ICreateAccountRequest';
import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import { IUpdateAccountsRequestAccount } from '@models/interfaces/requests/IUpdateAccountsRequest';
import { IUpdateSubAccountsData } from '@models/interfaces/additional/IUpdateSubAccountsData';
import { IUpdateSubAccountsRequest } from '@models/interfaces/requests/IUpdateSubAccountsRequest';
import mapper from '@models/mapper';

const accountsProvider = new AccountsProvider();

export const getAccounts = async (url: string) => {
  const result = await accountsProvider.getAccounts(url);
  return {
    items: mapper.mapArray<IAccountDto, IAccount>(result.items, 'IAccountDto', 'IAccount'),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const updateAccounts = async (url: string, data: IUpdateAccountsData) => {
  const requestData = mapper.mapArray<IUpdateAccountsDataAccount, IUpdateAccountsRequestAccount>(
    data,
    'IUpdateAccountsDataAccount',
    'IUpdateAccountsRequestAccount',
  );
  const result = await accountsProvider.updateAccounts(url, requestData);
  return {
    items: mapper.mapArray<IAccountDto, IAccount>(result.items, 'IAccountDto', 'IAccount'),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const updateSubAccounts = async (url: string, data: IUpdateSubAccountsData) => {
  const requestData = mapper.map<IUpdateSubAccountsData, IUpdateSubAccountsRequest>(
    data,
    'IUpdateSubAccountsData',
    'IUpdateSubAccountsRequest',
  );
  await accountsProvider.updateSubAccounts(url, requestData);
  return true;
};

export const createAccount = async (url: string, data: ICreateAccountData) => {
  const requestData = mapper.map<ICreateAccountData, ICreateAccountRequest>(
    data,
    'ICreateAccountData',
    'ICreateAccountRequest',
  );
  const result = await accountsProvider.createAccount(url, requestData);
  return mapper.map<IAccountDto, IAccount>(result, 'IAccountDto', 'IAccount');
};
