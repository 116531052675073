import BaseProvider from './BaseProvider';
import { ICloseProjectResponse } from '@models/interfaces/responses/ICloseProjectResponse';
import { IConfirmProjectResponse } from '@models/interfaces/responses/IConfirmProjectResponse';
import { ICreateProjectRequest } from '@models/interfaces/requests/ICreateProjectRequest';
import { ICreateProjectResponse } from '@models/interfaces/responses/ICreateProjectResponse';
import { IGetPreviousProjectResponse } from '@models/interfaces/responses/IGetPreviousProjectResponse';
import { IGetProjectResponse } from '@models/interfaces/responses/IGetProjectResponse';
import { IGetProjectsResponse } from '@models/interfaces/responses/IGetProjectsResponse';
import { IReopenProjectResponse } from '@models/interfaces/responses/IReopenProjectResponse';
import { IRestartProjectResponse } from '@models/interfaces/responses/IRestartProjectResponse';
import { IStartProjectResponse } from '@models/interfaces/responses/IStartProjectResponse';
import { IUpdateProjectRequest } from '@models/interfaces/requests/IUpdateProjectRequest';
import { IUpdateProjectResponse } from '@models/interfaces/responses/IUpdateProjectResponse';
import { ProjectStatuses } from '@models/enums/ProjectStatuses';

export default class ProjectsProvider extends BaseProvider {
  public getProject = (url: string) => {
    return this.get<IGetProjectResponse>(url);
  };

  public getPreviousProject = (url: string) => {
    return this.get<IGetPreviousProjectResponse>(url);
  };

  public startProject = (url: string) => {
    return this.put<null, IStartProjectResponse>(url, null);
  };

  public closeProject = (url: string) => {
    return this.put<null, ICloseProjectResponse>(url, null);
  };

  public reopenProject = (url: string) => {
    return this.put<null, IReopenProjectResponse>(url, null);
  };

  public restartProject = (url: string) => {
    return this.put<null, IRestartProjectResponse>(url, null);
  };

  public confirmProject = (url: string) => {
    return this.put<null, IConfirmProjectResponse>(url, null);
  };

  public getProjects = (url: string, status?: ProjectStatuses) => {
    return this.get<IGetProjectsResponse>(url, {
      params: {
        status,
      },
    });
  };

  public createProject = (url: string, data: ICreateProjectRequest) => {
    return this.post<ICreateProjectRequest, ICreateProjectResponse>(url, data);
  };

  public updateProject = (url: string, data: IUpdateProjectRequest) => {
    return this.patch<IUpdateProjectRequest, IUpdateProjectResponse>(url, data);
  };

  public deleteProject = (url: string) => {
    return this.delete<void>(url);
  };

  public getProjectRecalculationStatus = (url: string) => {
    return this.get<boolean>(url);
  };
}
