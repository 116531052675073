import { Box, IconButton } from '@mui/material';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridColDef,
  GridRowId,
} from '@mui/x-data-grid-premium';
import { IUsedTypeCodeDetails, UsedTypeCodeDetails } from '../UsedTypeCodeDetails';
import { formatBalance, useTableExpand } from '../../../../utils';
import { getAdjustmentDefinitions, getAdjustments } from '@services/api';
import { useApi, useLoader, useUpdateEffect } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { AdjustmentOperation } from '../../../../common/AdjustmentOperation';
import ArrowDownIcon from '@assets/icons/chevron-down.svg';
import ArrowUpIcon from '@assets/icons/chevron-up.svg';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { IAdjustmentDefinition } from '@models/interfaces/entities/IAdjustmentDefinition';
import { IProject } from '@models/interfaces/entities/IProject';
import { Loader } from '@components/Loader';
import { StandardTableFooter } from '../../../StandardTableFooter';
import clsx from 'clsx';
import useStyles from './styles';

interface IProps {
  project: IProject;
  type: number;
}

export const UsedTypeCodesTab = ({ project, type }: IProps) => {
  const { classes } = useStyles();

  const [expandedRowIds, setExpandedRowIds] = useState<GridRowId[]>([]);
  const [adjustmentDefinitions, setAdjustmentDefinitions] = useState<IAdjustmentDefinition[]>([]);
  const [adjustments, setAdjustments] = useState<IAdjustment[]>([]);

  const { isTableExpanded, onToggleTableExpand } = useTableExpand();

  const {
    request: getAdjustmentDefinitionsRequest,
    data: getAdjustmentDefinitionsData,
    loading: getAdjustmentDefinitionsLoading,
  } = useApi(getAdjustmentDefinitions, null, { handleErrors: true });

  const {
    request: getAdjustmentsRequest,
    data: getAdjustmentsData,
    loading: getAdjustmentsLoading,
  } = useApi(getAdjustments, null, { handleErrors: true });

  const onRowExpansionChange = useCallback((ids: GridRowId[]) => {
    setExpandedRowIds(ids);
  }, []);

  const columns = useMemo<GridColDef[]>(() => {
    return [
      {
        field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
        headerName: '',
        width: 50,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const { rowNode, id } = params;

          if (rowNode.type === 'pinnedRow') return '';

          const isExpanded = expandedRowIds.includes(id);

          return (
            <Box
              className={clsx([classes.flex, classes.cursorPointer])}
              onClick={() => {
                const newExpandedRowIds = isExpanded
                  ? expandedRowIds.filter((rowId) => rowId !== id)
                  : [...expandedRowIds, id];

                onRowExpansionChange(newExpandedRowIds);
              }}
            >
              <IconButton size='small'>
                {isExpanded ? (
                  <img src={ArrowUpIcon} alt='Collapse row' />
                ) : (
                  <img src={ArrowDownIcon} alt='Expand row' />
                )}
              </IconButton>
            </Box>
          );
        },
      },
      {
        field: 'code',
        headerName: 'Type Code',
        type: 'string',
        flex: 2,
      },
      {
        field: 'description',
        headerName: 'Description',
        type: 'string',
        flex: 2,
      },
      {
        field: 'accounts',
        headerName: 'Account',
        type: 'string',
        flex: 2,
        sortable: false,
        filterable: false,
        renderCell: (params) =>
          !params.value ? '' : params.value.length > 1 ? 'Multiple' : params.value[0].accountName,
      },
      {
        field: 'totalSourceBalance',
        headerName: 'Balance',
        type: 'number',
        flex: 2,
        renderCell: (params) => formatBalance(params.value || 0),
      },
    ];
  }, [expandedRowIds, onRowExpansionChange]);

  useUpdateEffect(() => {
    if (getAdjustmentDefinitionsData) {
      setAdjustmentDefinitions(getAdjustmentDefinitionsData.items);
    }
  }, [getAdjustmentDefinitionsData]);

  useUpdateEffect(() => {
    if (getAdjustmentsData) {
      setAdjustments(getAdjustmentsData.items);
    }
  }, [getAdjustmentsData]);

  useEffect(() => {
    if (project.links[Actions.getAdjustmentDefinitions]) {
      getAdjustmentDefinitionsRequest(
        project.links[Actions.getAdjustmentDefinitions].href,
        AdjustmentOperation.Initial,
        undefined,
        type,
      );
    }
  }, [project.links[Actions.getAdjustmentDefinitions], type]);

  useEffect(() => {
    if (project.links[Actions.getAdjustments]) {
      getAdjustmentsRequest(
        project.links[Actions.getAdjustments].href,
        [AdjustmentOperation.Initial],
        undefined,
        undefined,
        type,
      );
    }
  }, [project.links[Actions.getAdjustments], type]);

  const dataItems = useMemo<IUsedTypeCodeDetails[]>(() => {
    const groupedDefinitions = Object.values(
      adjustmentDefinitions.reduce(
        (acc, x) => {
          if (!x.source.value?.id || !x.source.value?.title) {
            return acc;
          }

          const filteredAdjustments = adjustments.filter(
            (a: { adjustmentDefinitionId: string; amount: number }) =>
              a.adjustmentDefinitionId === x.id,
          );
          const [firstAdjustment] = filteredAdjustments;
          const totalSourceBalance = firstAdjustment?.sourceTotal || 0;

          const totalAmount = filteredAdjustments.reduce((sum, a) => sum + a.amount, 0);

          const currentDefinition = {
            id: x.id,
            code: x.source.value.title,
            description: x.source.value.description || '',
            tierLimits: x.tierLimits,
            accounts: x.targets.map(({ id, accountName }: { id: string; accountName: string }) => ({
              id,
              accountName,
            })),
            adjustment: totalAmount,
          };

          if (!acc[x.source.value.id]) {
            acc[x.source.value.id] = {
              code: x.source.value.title,
              description: x.source.value.description || '',
              accounts: new Map<string, string>(),
              totalAdjustment: 0,
              totalSourceBalance,
              adjustmentDefinitions: [],
            };
          }

          const group = acc[x.source.value.id];

          currentDefinition.accounts.forEach(({ id, accountName }) => {
            group.accounts.set(id, accountName);
          });

          group.totalAdjustment += totalAmount;
          group.adjustmentDefinitions.push(currentDefinition);

          return acc;
        },
        {} as Record<
          string,
          {
            code: string;
            description: string;
            accounts: Map<string, string>;
            totalAdjustment: number;
            totalSourceBalance: number;
            adjustmentDefinitions: {
              id: string;
              code: string;
              description: string;
              tierLimits: { name: string; lower: number; upper: number }[];
              accounts: { id: string; accountName: string }[];
              adjustment: number;
            }[];
          }
        >,
      ),
    ).map((group) => ({
      code: group.code,
      description: group.description,
      accounts: Array.from(group.accounts.entries()).map(([id, accountName]) => ({
        id,
        accountName,
      })),
      totalAdjustment: group.totalAdjustment,
      totalSourceBalance: group.totalSourceBalance,
      adjustmentDefinitions: group.adjustmentDefinitions,
    }));

    return groupedDefinitions;
  }, [adjustmentDefinitions, adjustments]);

  const showLoader = useLoader(getAdjustmentDefinitionsLoading, getAdjustmentsLoading);

  const getDetailPanelHeight = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelHeight']>
  >(() => 'auto' as const, []);

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelContent']>
  >(({ row }) => <UsedTypeCodeDetails details={row} />, []);

  return (
    <>
      <Box className={classes.root}>
        <DataGridPremium
          rows={dataItems}
          density='compact'
          columns={columns}
          className={clsx([classes.table, !isTableExpanded && classes.limitedHeightTable])}
          initialState={{
            sorting: {
              sortModel: [{ field: 'code', sort: 'asc' }],
            },
            aggregation: {
              model: {
                totalSourceBalance: 'sum',
              },
            },
          }}
          getRowId={(row) => row.code}
          slots={{
            footer: () => (
              <StandardTableFooter
                showTableExpandSwitch={dataItems.length > 10}
                isTableExpanded={isTableExpanded}
                onToggleTableExpand={onToggleTableExpand}
              />
            ),
          }}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
          detailPanelExpandedRowIds={expandedRowIds}
          onDetailPanelExpandedRowIdsChange={onRowExpansionChange}
        />
      </Box>
      <Loader show={showLoader} fixed={false} />
    </>
  );
};
