import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { CustomDialog } from '@components/CustomDialog';
import clsx from 'clsx';
import useStyles from './styles';

export interface IAccountOption {
  id: string;
  title: string;
  accounts?: {
    id: string;
    title: string;
  }[];
}

export interface IProps {
  open: boolean;
  title: string;
  subtitle: string;
  secondSubtitle?: string;
  accounts: IAccountOption[];
  selectedAccountId: string | null;
  onClose: (data?: IAccountOption) => void;
}

export const AccountsDialog = ({
  open,
  title,
  subtitle,
  secondSubtitle,
  accounts,
  selectedAccountId,
  onClose,
}: IProps) => {
  const { classes } = useStyles();
  const [accountId, setAccountId] = useState<string | null>(selectedAccountId);

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    if (accountId) {
      onClose(accounts.find((x) => x.id === accountId));
    }
  };

  const onAccountSelected = (accountId: string) => {
    setAccountId(accountId);
  };

  useEffect(() => {
    setAccountId(selectedAccountId);
  }, [selectedAccountId]);

  if (!open) return null;

  return (
    <CustomDialog
      title={title}
      onClose={onCancel}
      open={open}
      maxWidth='sm'
      fullWidth
      actions={
        <>
          <div />
          <Button
            size='medium'
            variant='contained'
            color='primary'
            onClick={onSave}
            disabled={!accountId}
          >
            Ok
          </Button>
        </>
      }
    >
      <Box className={classes.root}>
        <Box>
          <Typography variant='subtitle1' className={classes.subtitle}>
            {subtitle}
          </Typography>
          {secondSubtitle && (
            <Typography variant='body2' className={classes.subtitle}>
              {secondSubtitle}
            </Typography>
          )}
        </Box>
        <Box className={classes.accountsList}>
          {accounts.map((x) => (
            <Box
              key={x.id}
              className={clsx([classes.accountItem, x.id === accountId && classes.selected])}
              onClick={() => onAccountSelected(x.id)}
            >
              <Typography>{x.title}</Typography>
              {x.accounts &&
                x.accounts.map((a) => (
                  <Box className={classes.nestedAccountItem} key={`tier-group-account-${a.id}`}>
                    {a.title}
                  </Box>
                ))}
            </Box>
          ))}
        </Box>
      </Box>
    </CustomDialog>
  );
};
