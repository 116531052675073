import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';

import { Box } from '@mui/material';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { IAdjustmentDefinition } from '@models/interfaces/entities/IAdjustmentDefinition';
import { formatBalance } from '../../../../utils';
import { getTierLimitsLabel } from '../../../../common/TierLimits';
import { useMemo } from 'react';
import useStyles from './styles';

interface IProps {
  adjustmentDefinitions: IAdjustmentDefinition[];
  adjustments: IAdjustment[];
}

export const SourceBalancesTab = ({ adjustments, adjustmentDefinitions }: IProps) => {
  const { classes } = useStyles();

  const columns = useMemo(
    () =>
      [
        {
          field: 'sourceSubAccountId',
          headerName: 'Type Code',
          type: 'string',
          flex: 1,
        },
        {
          field: 'sourceSubAccountDescription',
          headerName: 'Description',
          type: 'string',
          flex: 1,
        },
        {
          field: 'tierLimits',
          headerName: 'Tier Limits',
          type: 'string',
          flex: 1,
          sortable: false,
          renderCell: (params) => (
            <Box
              dangerouslySetInnerHTML={{
                __html: getTierLimitsLabel(params.value),
              }}
            />
          ),
        },
        {
          field: 'sourceTotal',
          headerName: 'Type Code Balance',
          type: 'number',
          flex: 1,
          renderCell: (params) => (params.value !== undefined ? formatBalance(params.value) : ''),
        },
        {
          field: 'amount',
          headerName: 'Contribution to Account',
          type: 'number',
          flex: 1,
          renderCell: (params) => formatBalance(params.value || 0),
        },
      ] as GridColDef<IAdjustment>[],
    [],
  );

  const adjustmentItems = useMemo<
    (IAdjustment & {
      tierLimits: {
        name: string;
        lower: number;
        upper: number;
      }[];
    })[]
  >(
    () =>
      adjustments.map((x) => ({
        ...x,
        tierLimits:
          adjustmentDefinitions.find((ad) => ad.id === x.adjustmentDefinitionId)?.tierLimits || [],
      })),
    [adjustments, adjustmentDefinitions],
  );

  return (
    <>
      <Box className={classes.root}>
        <DataGridPremium
          rows={adjustmentItems}
          density='compact'
          columns={columns}
          className={classes.table}
          hideFooter
          initialState={{
            sorting: {
              sortModel: [{ field: 'sourceSubAccountId', sort: 'asc' }],
            },
            aggregation: {
              model: {
                amount: 'sum',
              },
            },
          }}
          getRowHeight={(params) => {
            const tierLimits =
              adjustmentItems.find((item) => item.id === params.id)?.tierLimits || [];
            return tierLimits.length > 1 ? 20 * tierLimits.length : undefined;
          }}
        />
      </Box>
    </>
  );
};
