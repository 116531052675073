import GroupRolesProvider from '@providers/GroupRolesProvider';
import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import { IRoleAssignment } from '@models/interfaces/entities/IRoleAssignment';
import { IRoleAssignmentDto } from '@models/interfaces/dtos/IRoleAssignmentDto';
import mapper from '@models/mapper';

const groupRolesProvider = new GroupRolesProvider();

export const getGroupRoles = async (url: string) => {
  const result = await groupRolesProvider.getGroupRoles(url);
  return {
    items: mapper.mapArray<IRoleAssignmentDto, IRoleAssignment>(
      result.items,
      'IRoleAssignmentDto',
      'IRoleAssignment',
    ),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};
