import { Box, Button } from '@mui/material';
import {
  IReportSectionLine,
  ReportSectionLineTrackingMode,
} from './components/ReportSection/components/ReportSectionLine';
import { getSimulations, getSimulationsData } from '@services/api/simulations';
import { useApi, useLoader, useUpdateEffect } from '@hooks';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { IClient } from '@models/interfaces/entities/IClient';
import { ILink } from '@models/interfaces/entities/ILink';
import { IProject } from '@models/interfaces/entities/IProject';
import { IReportSectionHeader } from './components/ReportSection/components/ReportSectionHeader';
import { ISimulation } from '@models/interfaces/entities/ISimulation';
import { ISimulationAbbreviation } from '@models/interfaces/entities/ISimulationAbbreviation';
import { Loader } from '@components/Loader';
import { ReportSection } from './components/ReportSection';
import { ReportSectionLineValueFormat } from './components/ReportSection/components/ReportSectionLineValue';
import { SimulationsDialog } from './components/SimulationsDialog';
import { getClient } from '@services/api';
import { useReactToPrint } from 'react-to-print';
import useStyles from './styles';

const simulationProps = [
  'name',
  'currentScenario',
  'includeNEV',
  'includeSBS',
  'beginningNW',
  'beginningRBC',
  'beginningROA',
  'clientMinNW',
  'clientMinRBC',
  'afs',
  'borrowA',
  'all',
  'cash',
  'cda',
  'coB',
  'coCD',
  'cof',
  'coNMD',
  'foi',
  'fxdInvestA',
  'fxdLoanA',
  'ltoA',
  'nonEarnA',
  'oa',
  'ol',
  'opExp',
  'pll',
  'yoa',
  'yoi',
  'yol',
  'firstNegROAScenario',
  'fallsBelowACNW',
  'fallsBelowACRBC',
  'fallsBelowMinNW',
  'fallsBelowMinRBC',
  'fallsBelowWCNW',
  'fallsBelowWCRBC',
];

const scenarioProps = [
  'averageROA',
  'averageNIM',
  'nbroaReq',
  'nevPercent',
  'nevVolatility',
  'nwAtRisk',
  'nwNotAtRisk',
  'rbcAtRisk',
  'rbcNotAtRisk',
  'sbsnii',
];

const yearlyScenarioProps = ['oldEarnings', 'oldNIM', 'remainingAssets', 'remainingCommitments'];

const simulationYearProps = ['beginAssetsTarget', 'endAssetsTarget', 'targetNW'];

const fixedDeltas = [
  '-0505',
  '-0404',
  '-0303',
  '-0202',
  '-0101',
  '+0000',
  '+0101',
  '+0202',
  '+0303',
  '+0404',
  '+0505',
];

const fixedSimulationYears = [0, 1, 2, 3];

const percentProps = [
  'beginningNW',
  'beginningRBC',
  'beginningROA',
  'clientMinNW',
  'clientMinRBC',
  'borrowA',
  'cda',
  'coB',
  'coCD',
  'cof',
  'coNMD',
  'foi',
  'fxdInvestA',
  'fxdLoanA',
  'ltoA',
  'nonEarnA',
  'opExp',
  'pll',
  'yoa',
  'yoi',
  'yol',

  'averageROA',
  'averageNIM',
  'nbroaReq',
  'nevPercent',
  'nevVolatility',
  'nwAtRisk',
  'nwNotAtRisk',
  'rbcAtRisk',
  'rbcNotAtRisk',

  'targetNW',

  'remainingCommitments',
];

const dollarAmountProps = [
  'all',
  'cash',
  'oa',
  'ol',

  'sbsnii',

  'beginAssetsTarget',
  'endAssetsTarget',

  'oldEarnings',
  'oldNIM',
  'remainingAssets',
];

const nonConfigurableProps = [
  'name',
  'currentScenario',
  'includeNEV',
  'includeSBS',
  'firstNegROAScenario',
  'fallsBelowACNW',
  'fallsBelowACRBC',
  'fallsBelowMinNW',
  'fallsBelowMinRBC',
  'fallsBelowWCNW',
  'fallsBelowWCRBC',
];

const lowerIsBetterTrackingModeProps = ['nwAtRisk', 'rbcAtRisk'];

const simulationsCountLimit = 7;

interface IProps {
  project: IProject;
}

export const ReportsTab = ({ project }: IProps) => {
  const { classes } = useStyles();

  const [client, setClient] = useState<IClient>();
  const [simulations, setSimulations] = useState<ISimulationAbbreviation[]>([]);
  const [simulationsData, setSimulationsData] = useState<ISimulation[]>([]);
  const [selectedSimulationIds, setSelectedSimulationIds] = useState<string[]>([]);
  const [simulationsDataLink, setSimulationsDataLink] = useState<ILink>();
  const reportSectionRef = useRef<HTMLDivElement>(null);
  const [openSimulationsDialog, setOpenSimulationsDialog] = useState(false);

  const {
    request: getClientRequest,
    data: getClientData,
    loading: getClientLoading,
  } = useApi(getClient, null, { handleErrors: true });

  const { request: getSimulationsRequest, loading: getSimulationsLoading } = useApi(
    getSimulations,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          setSimulations(data.items);
          if (data.links[Actions.getData]) {
            const latestSimulationsIds = data.items
              .sort((a, b) => {
                if (b.balanceSheetYear !== a.balanceSheetYear) {
                  return b.balanceSheetYear - a.balanceSheetYear;
                }
                return b.balanceSheetMonth - a.balanceSheetMonth;
              })
              .slice(0, simulationsCountLimit)
              .map((item) => item.id);

            setSelectedSimulationIds(latestSimulationsIds);
            setSimulationsDataLink(data.links[Actions.getData]);
          }
        }
      },
    },
  );

  const {
    request: getSimulationsDataRequest,
    data: getSimulationsDataData,
    loading: getSimulationsDataLoading,
  } = useApi(getSimulationsData, null, { handleErrors: true });

  const reactToPrintFn = useReactToPrint({
    contentRef: reportSectionRef,
    documentTitle: `Report-${project.name}`,
    onAfterPrint: () => console.log('Print complete!'),
  });

  const onSelectSimulations = () => {
    setOpenSimulationsDialog(true);
  };

  const onCloseSimulationsDialog = (selectedSimulationsIds?: string[]) => {
    if (selectedSimulationsIds) {
      setSelectedSimulationIds(selectedSimulationsIds);
    }

    setOpenSimulationsDialog(false);
  };

  useEffect(() => {
    if (project.links[Actions.getClient]) {
      getClientRequest(project.links[Actions.getClient].href);
    }
  }, [project]);

  useEffect(() => {
    if (client?.links[Actions.getSimulations]) {
      getSimulationsRequest(client.links[Actions.getSimulations].href);
    }
  }, [client]);

  useUpdateEffect(() => {
    if (simulationsDataLink) {
      getSimulationsDataRequest(simulationsDataLink.href, selectedSimulationIds, fixedDeltas);
    }
  }, [selectedSimulationIds, simulationsDataLink]);

  useUpdateEffect(() => {
    if (getClientData) {
      setClient(getClientData);
    }
  }, [getClientData]);

  useUpdateEffect(() => {
    if (getSimulationsDataData) {
      const sortedSimulations = [...getSimulationsDataData.items].sort((a, b) => {
        if (b.bsYear !== a.bsYear) {
          return b.bsYear - a.bsYear;
        }
        return b.bsMonth - a.bsMonth;
      });

      setSimulationsData(sortedSimulations);
    }
  }, [getSimulationsDataData]);

  const getDeltaLabel = useCallback((delta: string): string => {
    if (delta === '+0000') return 'Current';

    const sign = delta.startsWith('-') ? '-' : '+';
    const absValue = delta.slice(1, 3);
    const numericPart = parseInt(absValue, 10) * 100;

    return `${sign}${numericPart}`;
  }, []);

  const getFormat = useCallback((propName: string) => {
    if (percentProps.includes(propName)) {
      return ReportSectionLineValueFormat.percentage;
    }

    if (dollarAmountProps.includes(propName)) {
      return ReportSectionLineValueFormat.amount;
    }

    return undefined;
  }, []);

  const getFormattedValue = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: any) => {
      if (typeof value === 'boolean') {
        return value ? 'Yes' : 'No';
      }

      return value;
    },
    [],
  );

  const generateSimulationBasedSectionLines = useCallback(
    (simulationsData: ISimulation[]): IReportSectionLine[] => {
      return simulationProps.map((propName): IReportSectionLine => {
        const format = getFormat(propName);
        return {
          title: { value: propName },
          subtitle: { value: '' },
          configuration: [{ value: '' }, { value: '' }, { value: '' }],
          rows: [
            {
              data: simulationsData.map((simulation) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const rawValue = (simulation as any)[propName];

                const formattedValue = getFormattedValue(rawValue);

                return { value: formattedValue };
              }),
              additional: { value: '' },
            },
          ],
          format,
          editableConfiguration: !nonConfigurableProps.includes(propName),
          trackingMode: lowerIsBetterTrackingModeProps.includes(propName)
            ? ReportSectionLineTrackingMode.lowerIsBetter
            : ReportSectionLineTrackingMode.higherIsBetter,
        };
      });
    },
    [getFormattedValue],
  );

  const generateSimulationYearBasedSectionLines = useCallback(
    (simulationsData: ISimulation[]): IReportSectionLine[] => {
      return simulationYearProps.map((propName): IReportSectionLine => {
        const format = getFormat(propName);
        return {
          title: { value: propName },
          subtitle: { value: '' },
          configuration: [{ value: '' }, { value: '' }, { value: '' }],
          rows: fixedSimulationYears.map((year) => ({
            data: simulationsData.map((simulation) => {
              const yearData = simulation.years.find((x) => x.simYear === year);
              if (!yearData) return { value: '' };

              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const rawValue = (yearData as any)[propName];

              const formattedValue = getFormattedValue(rawValue);

              return { value: formattedValue };
            }),
            additional: { value: '' },
          })),
          format,
          editableConfiguration: true,
          trackingMode: lowerIsBetterTrackingModeProps.includes(propName)
            ? ReportSectionLineTrackingMode.lowerIsBetter
            : ReportSectionLineTrackingMode.higherIsBetter,
        };
      });
    },
    [getFormattedValue],
  );

  const generateSectionHeader = useCallback(
    (title: string, simulationsData: ISimulation[]): IReportSectionHeader => {
      return {
        configurationHeader: title,
        dataHeaders: simulationsData.map(
          (simulation) =>
            `${simulation.type === 0 ? 'Base' : 'WIFV'}\n${simulation.bsMonth}/${
              simulation.bsYear
            }`,
        ),
        additionalHeader: '',
      };
    },
    [],
  );

  const generateScenarioBasedSections = useCallback(
    (simulationsData: ISimulation[]) => {
      return scenarioProps.map((propName) => {
        const header = generateSectionHeader(
          `Scenario based values (${propName})`,
          simulationsData,
        );

        const format = getFormat(propName);

        const lines = fixedDeltas.map((delta) => {
          const isCurrent = delta === '+0000';
          return {
            title: { value: propName, bolded: isCurrent },
            subtitle: { value: getDeltaLabel(delta), bolded: isCurrent },
            configuration: [{ value: '' }, { value: '' }, { value: '' }],
            rows: [
              {
                data: simulationsData.map((simulation) => {
                  const matchingScenario = simulation.scenarios.find(
                    (scenario) => scenario.delta === delta,
                  );

                  const rawValue = matchingScenario
                    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (matchingScenario as any)[propName]
                    : undefined;

                  const formattedValue = getFormattedValue(rawValue);

                  return { value: formattedValue, bolded: isCurrent };
                }),
                additional: { value: '' },
              },
            ],
            format,
            editableConfiguration: true,
            trackingMode: lowerIsBetterTrackingModeProps.includes(propName)
              ? ReportSectionLineTrackingMode.lowerIsBetter
              : ReportSectionLineTrackingMode.higherIsBetter,
          } as IReportSectionLine;
        });

        return {
          header,
          lines,
        };
      });
    },
    [getDeltaLabel, getFormattedValue],
  );

  const generateYearlyScenarioBasedSections = useCallback(
    (simulationsData: ISimulation[]) => {
      return yearlyScenarioProps.map((propName) => {
        const header = generateSectionHeader(
          `Yearly scenario based values (${propName})`,
          simulationsData,
        );

        const format = getFormat(propName);

        const lines = fixedDeltas.map((delta) => {
          const isCurrent = delta === '+0000';
          return {
            title: { value: propName, bolded: isCurrent },
            subtitle: { value: getDeltaLabel(delta), bolded: isCurrent },
            configuration: [{ value: '' }, { value: '' }, { value: '' }],
            rows: fixedSimulationYears.map((year) => ({
              data: simulationsData.map((simulation) => {
                const matchingScenario = simulation.scenarios.find(
                  (scenario) => scenario.delta === delta,
                );
                if (!matchingScenario) return { value: '' };

                const yearlyScenarioData = matchingScenario.yearlyScenarios.find(
                  (x) => x.simYear === year,
                );
                if (!yearlyScenarioData) return { value: '' };

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const rawValue = (yearlyScenarioData as any)[propName];

                const formattedValue = getFormattedValue(rawValue);

                return { value: formattedValue, bolded: isCurrent };
              }),
              additional: { value: '' },
            })),
            format,
            editableConfiguration: true,
            trackingMode: lowerIsBetterTrackingModeProps.includes(propName)
              ? ReportSectionLineTrackingMode.lowerIsBetter
              : ReportSectionLineTrackingMode.higherIsBetter,
          } as IReportSectionLine;
        });

        return {
          header,
          lines,
        };
      });
    },
    [getDeltaLabel, getFormattedValue],
  );

  const simulationBasedSectionHeader = generateSectionHeader(
    'Simulation based values',
    simulationsData,
  );
  const simulationBasedSectionLines = generateSimulationBasedSectionLines(simulationsData);

  const simulationYearBasedSectionHeader = generateSectionHeader(
    'Simulation year based values',
    simulationsData,
  );
  const simulationYearBasedSectionLines = generateSimulationYearBasedSectionLines(simulationsData);

  const scenarioSections = generateScenarioBasedSections(simulationsData);
  const yearlyScenarioSections = generateYearlyScenarioBasedSections(simulationsData);

  const showLoader = useLoader(getClientLoading, getSimulationsLoading, getSimulationsDataLoading);

  console.log(selectedSimulationIds);

  return (
    <Box className={classes.root}>
      <Box className={classes.controls}>
        <Button variant='contained' color='primary' onClick={() => reactToPrintFn()}>
          Print Report
        </Button>

        <Button variant='outlined' color='primary' onClick={onSelectSimulations}>
          Select Simulations
        </Button>
      </Box>
      {simulationsData.length > 0 && (
        <>
          <Box className={classes.printContainer} ref={reportSectionRef}>
            <ReportSection
              header={simulationBasedSectionHeader}
              lines={simulationBasedSectionLines}
              dataColumnsCount={simulationsData.length}
              hideAdditional
            />

            <Box className={classes.printSection}>
              <ReportSection
                header={simulationYearBasedSectionHeader}
                lines={simulationYearBasedSectionLines}
                dataColumnsCount={simulationsData.length}
                hideAdditional
              />
            </Box>

            {scenarioSections.map((section, idx) => (
              <Box className={classes.printSection} key={idx}>
                <ReportSection
                  header={section.header}
                  lines={section.lines}
                  dataColumnsCount={simulationsData.length}
                  hideAdditional
                />
              </Box>
            ))}

            {yearlyScenarioSections.map((section, idx) => (
              <Box className={classes.printSection} key={idx}>
                <ReportSection
                  header={section.header}
                  lines={section.lines}
                  dataColumnsCount={simulationsData.length}
                  hideAdditional
                />
              </Box>
            ))}
          </Box>
        </>
      )}

      <SimulationsDialog
        limit={simulationsCountLimit}
        simulations={simulations}
        selectedSimulationIds={selectedSimulationIds}
        onClose={onCloseSimulationsDialog}
        open={openSimulationsDialog}
      />

      <Loader show={showLoader} />
    </Box>
  );
};
