import { Mapper, createMap } from '@automapper/core';

import { IRoleAssignment } from '@models/interfaces/entities/IRoleAssignment';
import { IRoleAssignmentDto } from '@models/interfaces/dtos/IRoleAssignmentDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createRoleAssignmentMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IRoleAssignmentDto>('IRoleAssignmentDto', {
    role: String,
    groupId: String,
    clientIds: [String],
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<IRoleAssignment>('IRoleAssignment', {
    role: String,
    groupId: String,
    clientIds: [String],
    links: 'ILinks',
  });

  createMap<IRoleAssignmentDto, IRoleAssignment>(mapper, 'IRoleAssignmentDto', 'IRoleAssignment');
};
