import { Box, Collapse, IconButton, Tooltip, Typography } from '@mui/material';
import {
  createAdjustmentDefinition,
  deleteAdjustmentDefinition,
  updateAdjustmentDefinition,
} from '@services/api';
import { useApi, useLoader } from '@hooks';
import { useCallback, useMemo, useState } from 'react';

import { AccountsTable } from '../AccountsTable';
import { AdjustmentOperation } from '../../common/AdjustmentOperation';
import AlertCircleIcon from '@assets/icons/dashboard/alert-circle-filled.svg';
import AlertTriangleIcon from '@assets/icons/dashboard/alert-triangle-filled.svg';
import ChevronDownIcon from '@assets/icons/dashboard/chevron-down-gray.svg';
import ChevronUpIcon from '@assets/icons/dashboard/chevron-up-gray.svg';
import CircleFilledIcon from '@assets/icons/dashboard/circle-filled-orange.svg';
import { CollapsibleAdjustmentsDetails } from '../CollapsibleAdjustmentsDetails';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { ICreateAdjustmentDefinitionData } from '@models/interfaces/additional/ICreateAdjustmentDefinitionData';
import { IProject } from '@models/interfaces/entities/IProject';
import { IUpdateAdjustmentDefinitionData } from '@models/interfaces/additional/IUpdateAdjustmentDefinitionData';
import { Loader } from '@components/Loader';
import clsx from 'clsx';
import { formatBalance } from '../../utils';
import useStyles from './styles';

export enum AdjustmentDetailsViewMode {
  sourceBalances = 'sourceBalances',
  lineItems = 'lineItems',
  sectionAdjustments = 'sectionAdjustments',
  balanceSheet = 'balanceSheet',
  none = 'none',
}

interface BalanceRecord {
  label: string;
  amount: string;
  class: string;
  mode: AdjustmentDetailsViewMode;
  disabled: boolean;
  alert?: { message: string; icon: string };
  indicator: boolean;
}

export interface IBalancingItem {
  title: string;
  sectionAccountsTypes: number[];
  accountsType: number;
  balancingType: number;
  balancingAccountId?: string;
  sourceBalance: number;
  adjustments: number;
  sectionAdjustments: number;
  ownedSectionAdjustment: number;
  receivedSectionAdjustment: number;
  sentToOtherSectionAdjustment: number;
  totalBalance: number;
  hideSource?: boolean;
  hideBalanceSheet?: boolean;
  showFees?: boolean;
  showPremiums?: boolean;
  availableOperations?: AdjustmentOperation[];
  items?: IBalancingItem[];
}

export interface IProps {
  project: IProject;
  accounts: IAccount[];
  balanceAdjustments: IAdjustment[];
  main?: boolean;
  item: IBalancingItem;
  newTypeCodesCountMap: Record<number, number>;
  onNewTypeCodesChanged: (type: number) => void;
  onAccountsChanged: (account?: IAccount) => void;
  onAdjustmentsChanged: () => void;
}

export const BalancingItem = ({
  project,
  accounts,
  balanceAdjustments,
  main,
  item,
  newTypeCodesCountMap,
  onNewTypeCodesChanged,
  onAccountsChanged,
  onAdjustmentsChanged,
}: IProps) => {
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState(false);

  const [activeAdjustmentDetailsViewMode, setActiveAdjustmentDetailsViewMode] =
    useState<AdjustmentDetailsViewMode | null>(null);

  const {
    request: manageAdjustmentDefinitionsRequest,
    loading: manageAdjustmentDefinitionsLoading,
  } = useApi(
    async (
      create?: { url: string; data: ICreateAdjustmentDefinitionData[] },
      update?: { url: string; data: IUpdateAdjustmentDefinitionData }[],
      callback?: () => void,
    ) => {
      if (update?.length || create?.data.length) {
        if (create) {
          for (const item of create.data) {
            await createAdjustmentDefinition(create.url, item);
          }
        }
        if (update) {
          for (const item of update) {
            await updateAdjustmentDefinition(item.url, item.data);
          }
        }
        onAdjustmentsChanged();
        if (callback) {
          callback();
        }
      }
    },
    null,
    {
      handleErrors: true,
    },
  );

  const { request: deleteAdjustmentDefinitionRequest, loading: deleteAdjustmentDefinitionLoading } =
    useApi(
      async (url: string, callback?: () => void) => {
        await deleteAdjustmentDefinition(url);
        onAdjustmentsChanged();
        if (callback) {
          callback();
        }
      },
      null,
      {
        handleErrors: true,
      },
    );

  const onModeChanged = (mode: AdjustmentDetailsViewMode) => {
    setActiveAdjustmentDetailsViewMode((prev) => (prev === mode ? null : mode));
  };

  const onToggleExpanded = () => {
    setExpanded((val) => !val);
  };

  const onBalanceClick = (mode: AdjustmentDetailsViewMode) => {
    setActiveAdjustmentDetailsViewMode((prev) => (prev === mode ? null : mode));
    setExpanded(true);
  };

  const isBalanced = useMemo(() => {
    if (!item.totalBalance || item.hideBalanceSheet) {
      return true;
    }
    const accountsTotal =
      item.sourceBalance +
      item.adjustments +
      (item.items?.length || 0 > 0 ? item.sectionAdjustments : item.ownedSectionAdjustment);

    return Math.abs(Math.round((item.totalBalance - accountsTotal) * 100) / 100) <= 1;
  }, [item]);

  const newTypeCodesCount = useMemo(() => {
    if (item.accountsType === -1) {
      return item.sectionAccountsTypes.reduce((acc, type) => {
        return acc + (newTypeCodesCountMap[type] || 0);
      }, 0);
    }
    return newTypeCodesCountMap[item.accountsType] || 0;
  }, [newTypeCodesCountMap, item.accountsType, item.sectionAccountsTypes]);

  const balanceRecords: BalanceRecord[] = [
    {
      label: 'Source Balance',
      amount: item.hideSource ? 'N/A' : formatBalance(item.sourceBalance),
      class: item.hideSource ? 'gray' : 'blue',
      mode: AdjustmentDetailsViewMode.sourceBalances,
      disabled: item.hideSource || item.items?.length || false,
      indicator: newTypeCodesCount > 0,
    },
    {
      label: 'Line Item Adjustments',
      amount: formatBalance(item.adjustments),
      class: 'blue',
      mode: AdjustmentDetailsViewMode.lineItems,
      disabled: item.items?.length || false,
      indicator: false,
    },
    {
      label: 'Section Adjustments',
      amount: formatBalance(item.sectionAdjustments),
      class: 'blue',
      mode: AdjustmentDetailsViewMode.sectionAdjustments,
      alert: !isBalanced
        ? {
            message: `Since a Balance Sheet target total has been established, a section adjustment should be created to ensure 
                      the account totals balance with the Balance Sheet total. 
                      To achieve this, assign one or more accounts as targets for the section adjustment.`,
            icon: AlertCircleIcon,
          }
        : undefined,
      disabled: false,
      indicator: false,
    },
    !item.hideBalanceSheet
      ? {
          label: item.totalBalance !== 0 ? 'Balance Sheet Total' : 'Total',
          amount: formatBalance(
            item.totalBalance !== 0
              ? item.totalBalance +
                  item.receivedSectionAdjustment -
                  item.sentToOtherSectionAdjustment
              : item.sourceBalance + item.adjustments + item.sectionAdjustments,
          ),
          class: 'green',
          mode: AdjustmentDetailsViewMode.balanceSheet,
          alert:
            item.totalBalance === 0
              ? {
                  message: `A target Balance Sheet Total has not been established.
              The total account balance is shown instead.`,
                  icon: AlertTriangleIcon,
                }
              : undefined,
          disabled: false,
          indicator: false,
        }
      : {
          label: 'Total',
          amount: formatBalance(item.adjustments + item.sectionAdjustments),
          class: 'orange',
          mode: AdjustmentDetailsViewMode.none,
          disabled: true,
          indicator: false,
        },
  ].filter((x): x is BalanceRecord => !!x);

  const getAllAccountsTypes = useCallback((item: IBalancingItem) => {
    const accountsTypes: number[] = [item.accountsType];

    const addChildAccountsTypes = (items?: IBalancingItem[]) => {
      if (!items) return;

      items.forEach((child) => {
        accountsTypes.push(child.accountsType);
        addChildAccountsTypes(child.items);
      });
    };

    addChildAccountsTypes(item.items);
    return accountsTypes;
  }, []);

  const allAccountsTypes = useMemo(() => getAllAccountsTypes(item), [item]);

  const filteredBalanceAdjustments = useMemo(() => {
    const filteredAccountsIds = accounts
      .filter((x) => allAccountsTypes.includes(x.accountType.type))
      .map((x) => x.id);

    return balanceAdjustments.filter((x) => filteredAccountsIds.includes(x.accountId));
  }, [balanceAdjustments, allAccountsTypes, accounts]);

  const showLoader = useLoader(
    manageAdjustmentDefinitionsLoading,
    deleteAdjustmentDefinitionLoading,
  );

  return (
    <Box className={clsx([classes.root, (main || !expanded) && 'shadowed'])}>
      <Box className={classes.header}>
        <Typography variant='h6'>{item.title}</Typography>
        <Box className={classes.balancesContainer}>
          {balanceRecords.map((balance) => (
            <Box className={classes.balanceRecord} key={balance.label}>
              <Box className={clsx([classes.flex, classes.gap8])}>
                {balance.indicator && <img src={CircleFilledIcon} alt='circle' />}
                <Typography variant='subtitle2'>{balance.label}</Typography>
              </Box>
              <Box
                className={clsx([
                  classes.balanceCount,
                  balance.class,
                  activeAdjustmentDetailsViewMode === balance.mode && 'active',
                  balance.disabled && 'disabled',
                ])}
                onClick={() =>
                  !balance.disabled &&
                  balance.mode !== AdjustmentDetailsViewMode.none &&
                  onBalanceClick(balance.mode)
                }
              >
                {!balance.alert ? (
                  <Typography variant='subtitle2'>{balance.amount}</Typography>
                ) : (
                  <Tooltip title={balance.alert.message}>
                    <Box className={classes.flex}>
                      <Typography variant='subtitle2'>{balance.amount}</Typography>
                      <img src={balance.alert.icon} alt='alert' />
                    </Box>
                  </Tooltip>
                )}
              </Box>
            </Box>
          ))}
        </Box>
        <IconButton onClick={onToggleExpanded} className={classes.expandIcon}>
          {expanded ? (
            <img alt='arrow up' src={ChevronUpIcon} />
          ) : (
            <img alt='arrow down' src={ChevronDownIcon} />
          )}
        </IconButton>
      </Box>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        {item.items?.length ? (
          <Box className={classes.itemsListContent}>
            <Box className={classes.adjustmentsDetails}>
              <CollapsibleAdjustmentsDetails
                adjustments={[]}
                balanceAdjustments={filteredBalanceAdjustments}
                isBalanced={isBalanced}
                accounts={accounts}
                category={item.title}
                availableOperations={item.availableOperations}
                project={project}
                types={allAccountsTypes}
                balancingType={item.balancingType}
                accountsTotal={
                  item.sourceBalance +
                  item.adjustments +
                  item.sectionAdjustments -
                  item.receivedSectionAdjustment
                }
                receivedSectionAdjustment={item.receivedSectionAdjustment}
                sentToOtherSectionAdjustment={item.sentToOtherSectionAdjustment}
                balanceSheetTotal={item.totalBalance}
                showSectionAdjustments
                showBalanceSheet={!item.hideBalanceSheet}
                onModeChanged={onModeChanged}
                activeMode={activeAdjustmentDetailsViewMode}
                hasNewTypeCodes={newTypeCodesCount > 0}
                onNewTypeCodesChanged={onNewTypeCodesChanged}
                onAccountsChanged={onAccountsChanged}
                onAdjustmentsChanged={onAdjustmentsChanged}
                manageAdjustmentDefinitions={manageAdjustmentDefinitionsRequest}
                deleteAdjustmentDefinition={deleteAdjustmentDefinitionRequest}
              />
            </Box>
            {item.items.map((item) => (
              <BalancingItem
                key={item.title}
                project={project}
                accounts={accounts}
                balanceAdjustments={balanceAdjustments}
                item={item}
                newTypeCodesCountMap={newTypeCodesCountMap}
                onNewTypeCodesChanged={onNewTypeCodesChanged}
                onAccountsChanged={onAccountsChanged}
                onAdjustmentsChanged={onAdjustmentsChanged}
              />
            ))}
          </Box>
        ) : (
          <Box className={classes.itemContent}>
            <AccountsTable
              type={item.accountsType}
              balancingType={item.balancingType}
              project={project}
              accounts={accounts}
              category={item.title}
              availableOperations={item.availableOperations}
              showFees={item.showFees}
              showPremiums={item.showPremiums}
              showSourceBalance={!item.hideSource}
              showBalanceSheet={!item.hideBalanceSheet}
              accountsTotal={
                item.sourceBalance +
                item.adjustments +
                item.sectionAdjustments -
                item.receivedSectionAdjustment
              }
              receivedSectionAdjustment={item.receivedSectionAdjustment}
              sentToOtherSectionAdjustment={item.sentToOtherSectionAdjustment}
              balanceSheetTotal={item.totalBalance}
              isBalanced={isBalanced}
              onModeChanged={onModeChanged}
              activeMode={activeAdjustmentDetailsViewMode}
              hasNewTypeCodes={newTypeCodesCount > 0}
              onNewTypeCodesChanged={onNewTypeCodesChanged}
              onAccountsChanged={onAccountsChanged}
              onAdjustmentsChanged={onAdjustmentsChanged}
              manageAdjustmentDefinitions={manageAdjustmentDefinitionsRequest}
              deleteAdjustmentDefinition={deleteAdjustmentDefinitionRequest}
            />
          </Box>
        )}
      </Collapse>
      <Loader show={showLoader} />
    </Box>
  );
};
