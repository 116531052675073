import { Box, Button, Typography } from '@mui/material';
import { DataGridPremium, GridColDef, GridRowParams } from '@mui/x-data-grid-premium';
import { SearchInput, SearchInputColors } from '@components/SearchInput';
import { getTypeCodes, updateNewTypeCode } from '@services/api';
import { useApi, useDebounce, useInput, useLoader } from '@hooks';
import { useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { ButtonsSelector } from '@components/ButtonsSelector';
import { CustomDialog } from '@components/CustomDialog';
import { ILink } from '@models/interfaces/entities/ILink';
import { IProject } from '@models/interfaces/entities/IProject';
import { ITypeCode } from '@models/interfaces/entities/ITypeCode';
import { Loader } from '@components/Loader';
import clsx from 'clsx';
import { formatBalance } from '../../../../utils';
import useStyles from './styles';

enum LineItemStatus {
  new = 'new',
  inUse = 'inUse',
  unused = 'unused',
}

export interface IProps {
  project: IProject;
  existingTypeCodes: string[];
  open: boolean;
  onClose: (typeCode?: ITypeCode) => void;
}

export const AddLineItemsDialog = ({ open, onClose, project, existingTypeCodes }: IProps) => {
  const { classes } = useStyles();
  const searchInput = useInput<string>('');

  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([LineItemStatus.new]);

  const [newTypeCodes, setNewTypeCodes] = useState<ITypeCode[]>([]);
  const [usedTypeCodes, setUsedTypeCodes] = useState<ITypeCode[]>([]);
  const [unusedTypeCodes, setUnusedTypeCodes] = useState<ITypeCode[]>([]);

  const [selectedTypeCode, setSelectedTypeCode] = useState<ITypeCode | null>(null);

  const debouncedSelectedStatuses = useDebounce<string[]>(selectedStatuses, 1000);

  const { request: updateNewTypeCodeRequest, loading: updateNewTypeCodeLoading } = useApi(
    updateNewTypeCode,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          onClose(data);
        }
      },
    },
  );

  const {
    request: getNewTypeCodesRequest,
    data: getNewTypeCodesData,
    loading: getNewTypeCodesLoading,
  } = useApi(
    (link: ILink) => {
      return getTypeCodes(link.href, undefined, -99, true);
    },
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        setNewTypeCodes(data?.items || []);
      },
    },
  );

  const { request: getUsedTypeCodesRequest, loading: getUsedTypeCodesLoading } = useApi(
    (link: ILink) => {
      return getTypeCodes(link.href, undefined, -99, undefined, true, true);
    },
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        setUsedTypeCodes(data?.items || []);
      },
    },
  );

  const { request: getUnusedTypeCodesRequest, loading: getUnusedTypeCodesLoading } = useApi(
    (link: ILink) => {
      return getTypeCodes(link.href, undefined, -99, false, true, false);
    },
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        setUnusedTypeCodes(data?.items || []);
      },
    },
  );

  const updateNewTypeCodeLink = useMemo(
    () => getNewTypeCodesData?.links[Actions.updateNewTypeCode]?.href,
    [getNewTypeCodesData],
  );

  const onStatusesChange = (value: string | string[]) => {
    setSelectedStatuses(Array.isArray(value) ? value : [value]);
  };

  const onCancel = () => {
    onClose();
  };

  const isNewFilterSelected = useMemo(
    () => debouncedSelectedStatuses.includes(LineItemStatus.new),
    [debouncedSelectedStatuses],
  );
  const isUsedFilterSelected = useMemo(
    () => debouncedSelectedStatuses.includes(LineItemStatus.inUse),
    [debouncedSelectedStatuses],
  );
  const isUnusedFilterSelected = useMemo(
    () => debouncedSelectedStatuses.includes(LineItemStatus.unused),
    [debouncedSelectedStatuses],
  );

  useEffect(() => {
    if (project.links[Actions.getTypeCodes] && open && isNewFilterSelected) {
      getNewTypeCodesRequest(project.links[Actions.getTypeCodes]);
    } else {
      setNewTypeCodes([]);
    }
  }, [project.links[Actions.getTypeCodes], isNewFilterSelected, open]);

  useEffect(() => {
    if (project.links[Actions.getTypeCodes] && open && isUsedFilterSelected) {
      getUsedTypeCodesRequest(project.links[Actions.getTypeCodes]);
    } else {
      setUsedTypeCodes([]);
    }
  }, [project.links[Actions.getTypeCodes], isUsedFilterSelected, open]);

  useEffect(() => {
    if (project.links[Actions.getTypeCodes] && open && isUnusedFilterSelected) {
      getUnusedTypeCodesRequest(project.links[Actions.getTypeCodes]);
    } else {
      setUnusedTypeCodes([]);
    }
  }, [project.links[Actions.getTypeCodes], isUnusedFilterSelected, open]);

  useEffect(() => {
    setSelectedStatuses([LineItemStatus.new]);
    setSelectedTypeCode(null);
    searchInput.set('');
  }, [open]);

  const filteredTypeCodes = useMemo(() => {
    const mergedCodes = [...newTypeCodes, ...usedTypeCodes, ...unusedTypeCodes];

    const allCodes = mergedCodes.filter(
      (code, index, self) => index === self.findIndex((c) => c.typeCode === code.typeCode),
    );

    const notAlreadyUsedTypeCodes = allCodes.filter(
      (x) =>
        !existingTypeCodes.some((etc) => etc === x.typeCode) &&
        (!selectedTypeCode || selectedTypeCode.typeCode !== x.typeCode),
    );

    if (!searchInput.value) {
      return notAlreadyUsedTypeCodes;
    }

    const filterValue = searchInput.value.toLowerCase();

    return notAlreadyUsedTypeCodes.filter(
      (code) =>
        code.typeCode.toLowerCase().includes(filterValue) ||
        code.description?.toLowerCase().includes(filterValue),
    );
  }, [newTypeCodes, usedTypeCodes, unusedTypeCodes, selectedTypeCode, searchInput.value]);

  const columns = useMemo(
    () =>
      [
        {
          field: 'typeCode',
          headerName: 'Balance Sheet Line Item',
          type: 'string',
          flex: 2,
        },
        {
          field: 'description',
          headerName: 'Description',
          type: 'string',
          flex: 2,
        },
        {
          field: 'balance',
          headerName: 'Balance',
          type: 'number',
          flex: 2,
          renderCell: (params) => formatBalance(params.value || 0),
        },
      ] as GridColDef<ITypeCode>[],
    [],
  );

  const onTableRowClick = (params: GridRowParams<ITypeCode>) => {
    const clickedRow = params.row;

    setSelectedTypeCode((prevSelected) => {
      const isSelected = prevSelected?.typeCode === clickedRow.typeCode;

      if (isSelected) {
        return null;
      }

      return clickedRow;
    });
  };

  const onSave = () => {
    if (!selectedTypeCode) return;
    if (!selectedTypeCode.id) {
      if (!updateNewTypeCodeLink) return;
      updateNewTypeCodeRequest(updateNewTypeCodeLink, {
        code: selectedTypeCode.typeCode,
        description: selectedTypeCode.description,
        ignoreReason: '',
        isUsed: false,
      });
    } else {
      onClose(selectedTypeCode);
    }
  };

  const pinnedRows = useMemo(
    () => (selectedTypeCode ? [selectedTypeCode] : undefined),
    [selectedTypeCode],
  );

  const statusOptions = useMemo(
    () => [
      { value: LineItemStatus.new, label: 'New' },
      { value: LineItemStatus.inUse, label: 'In Use' },
      { value: LineItemStatus.unused, label: 'Unused' },
    ],
    [],
  );

  const showLoader = useLoader(
    getNewTypeCodesLoading,
    getUsedTypeCodesLoading,
    getUnusedTypeCodesLoading,
    updateNewTypeCodeLoading,
  );

  return (
    <CustomDialog
      title='Add line items'
      onClose={onCancel}
      open={open}
      maxWidth='md'
      fullWidth
      actions={
        <>
          <div />
          <Button
            variant='contained'
            size='large'
            color='secondary'
            onClick={onSave}
            disabled={!selectedTypeCode}
          >
            Ok
          </Button>
        </>
      }
    >
      <Box className={classes.root}>
        <Box className={clsx([classes.flex, classes.gap24])}>
          <Box className={classes.flex}>
            <Typography className={classes.noWrap}>Show line items:</Typography>
            <ButtonsSelector
              multiselect
              options={statusOptions}
              value={selectedStatuses}
              onChanged={onStatusesChange}
            />
          </Box>
          <Box className={classes.flex}>
            <SearchInput
              onChange={(value) => searchInput.onChange({ target: { value } })}
              color={SearchInputColors.grey}
              maxWidth={300}
              minWidth={300}
              id='search-line-items-input'
            />
          </Box>
        </Box>
        <DataGridPremium
          rows={filteredTypeCodes}
          density='compact'
          columns={columns}
          className={clsx([classes.table])}
          initialState={{
            sorting: {
              sortModel: [{ field: 'typeCode', sort: 'asc' }],
            },
          }}
          pinnedRows={{
            bottom: pinnedRows,
          }}
          hideFooter
          getRowId={(row) => row.typeCode}
          onRowClick={onTableRowClick}
        />
      </Box>

      <Loader show={showLoader} fixed={false} />
    </CustomDialog>
  );
};
