import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  simulationsList: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: t.palette.custom.dark[20],
    height: 300,
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: t.palette.custom.dark[67],
      borderRadius: 4,
    },
  },
  simulationItem: {
    paddingLeft: 16,
    paddingRight: 8,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,

    '&:hover': {
      backgroundColor: t.palette.custom.info[20],
      cursor: 'pointer',
    },
  },
  selected: {
    backgroundColor: t.palette.custom.info[50],

    '&:hover': {
      backgroundColor: t.palette.custom.info[50],
    },
  },
}));

export default useStyles;
