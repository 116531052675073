import {
  ISimulation,
  ISimulationClient,
  ISimulationSummaryScenario,
  ISimulationSummaryYear,
  ISimulationYearlyScenario,
} from '@models/interfaces/entities/ISimulation';
import {
  ISimulationClientDto,
  ISimulationDto,
  ISimulationSummaryScenarioDto,
  ISimulationSummaryYearDto,
  ISimulationYearlyScenarioDto,
} from '@models/interfaces/dtos/ISimulationDto';
import { Mapper, createMap } from '@automapper/core';

import { PojosMetadataMap } from '@automapper/pojos';

export const createSimulationMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<ISimulationClientDto>('ISimulationClientDto', {
    name: String,
    identifier: String,
  });

  PojosMetadataMap.create<ISimulationClient>('ISimulationClient', {
    name: String,
    identifier: String,
  });

  PojosMetadataMap.create<ISimulationYearlyScenarioDto>('ISimulationYearlyScenarioDto', {
    simYear: Number,
    oldEarnings: Number,
    oldNIM: Number,
    remainingAssets: Number,
    remainingCommitments: Number,
  });

  PojosMetadataMap.create<ISimulationYearlyScenario>('ISimulationYearlyScenario', {
    simYear: Number,
    oldEarnings: Number,
    oldNIM: Number,
    remainingAssets: Number,
    remainingCommitments: Number,
  });

  PojosMetadataMap.create<ISimulationSummaryScenarioDto>('ISimulationSummaryScenarioDto', {
    scenario: String,
    delta: String,
    averageROA: Number,
    averageNIM: Number,
    nbroaReq: Number,
    nevPercent: Number,
    nevVolatility: Number,
    nwAtRisk: Number,
    nwNotAtRisk: Number,
    rbcAtRisk: Number,
    rbcNotAtRisk: Number,
    sbsnii: Number,
    yearlyScenarios: ['ISimulationYearlyScenarioDto'],
  });

  PojosMetadataMap.create<ISimulationSummaryScenario>('ISimulationSummaryScenario', {
    scenario: String,
    delta: String,
    averageROA: Number,
    averageNIM: Number,
    nbroaReq: Number,
    nevPercent: Number,
    nevVolatility: Number,
    nwAtRisk: Number,
    nwNotAtRisk: Number,
    rbcAtRisk: Number,
    rbcNotAtRisk: Number,
    sbsnii: Number,
    yearlyScenarios: ['ISimulationYearlyScenario'],
  });

  PojosMetadataMap.create<ISimulationSummaryYearDto>('ISimulationSummaryYearDto', {
    simYear: Number,
    beginAssetsTarget: Number,
    endAssetsTarget: Number,
    targetNW: Number,
  });

  PojosMetadataMap.create<ISimulationSummaryYear>('ISimulationSummaryYear', {
    simYear: Number,
    beginAssetsTarget: Number,
    endAssetsTarget: Number,
    targetNW: Number,
  });

  PojosMetadataMap.create<ISimulationDto>('ISimulationDto', {
    id: String,
    client: 'ISimulationClientDto',
    type: Number,
    bsMonth: Number,
    bsYear: Number,
    currentScenario: String,
    scenarioInterest: String,
    name: String,
    additionalInfo: String,
    includeNEV: Boolean,
    includeSBS: Boolean,
    beginningNW: Number,
    beginningRBC: Number,
    beginningROA: Number,
    clientMinNW: Number,
    clientMinRBC: Number,
    afs: Number,
    borrowA: Number,
    all: Number,
    cash: Number,
    cda: Number,
    coB: Number,
    coCD: Number,
    cof: Number,
    coNMD: Number,
    foi: Number,
    fxdInvestA: Number,
    fxdLoanA: Number,
    ltoA: Number,
    nonEarnA: Number,
    oa: Number,
    ol: Number,
    opExp: Number,
    pll: Number,
    yoa: Number,
    yoi: Number,
    yol: Number,
    firstNegROAScenario: String,
    fallsBelowACNW: String,
    fallsBelowACRBC: String,
    fallsBelowMinNW: String,
    fallsBelowMinRBC: String,
    fallsBelowWCNW: String,
    fallsBelowWCRBC: String,
    scenarios: ['ISimulationSummaryScenarioDto'],
    years: ['ISimulationSummaryYearDto'],
  });

  PojosMetadataMap.create<ISimulation>('ISimulation', {
    id: String,
    client: 'ISimulationClient',
    type: Number,
    bsMonth: Number,
    bsYear: Number,
    currentScenario: String,
    scenarioInterest: String,
    name: String,
    additionalInfo: String,
    includeNEV: Boolean,
    includeSBS: Boolean,
    beginningNW: Number,
    beginningRBC: Number,
    beginningROA: Number,
    clientMinNW: Number,
    clientMinRBC: Number,
    afs: Number,
    borrowA: Number,
    all: Number,
    cash: Number,
    cda: Number,
    coB: Number,
    coCD: Number,
    cof: Number,
    coNMD: Number,
    foi: Number,
    fxdInvestA: Number,
    fxdLoanA: Number,
    ltoA: Number,
    nonEarnA: Number,
    oa: Number,
    ol: Number,
    opExp: Number,
    pll: Number,
    yoa: Number,
    yoi: Number,
    yol: Number,
    firstNegROAScenario: String,
    fallsBelowACNW: String,
    fallsBelowACRBC: String,
    fallsBelowMinNW: String,
    fallsBelowMinRBC: String,
    fallsBelowWCNW: String,
    fallsBelowWCRBC: String,
    scenarios: ['ISimulationSummaryScenario'],
    years: ['ISimulationSummaryYear'],
  });

  createMap<ISimulationDto, ISimulation>(mapper, 'ISimulationDto', 'ISimulation');
  createMap<ISimulationClientDto, ISimulationClient>(
    mapper,
    'ISimulationClientDto',
    'ISimulationClient',
  );
  createMap<ISimulationSummaryScenarioDto, ISimulationSummaryScenario>(
    mapper,
    'ISimulationSummaryScenarioDto',
    'ISimulationSummaryScenario',
  );
  createMap<ISimulationYearlyScenarioDto, ISimulationYearlyScenario>(
    mapper,
    'ISimulationYearlyScenarioDto',
    'ISimulationYearlyScenario',
  );
  createMap<ISimulationSummaryYearDto, ISimulationSummaryYear>(
    mapper,
    'ISimulationSummaryYearDto',
    'ISimulationSummaryYear',
  );
};
