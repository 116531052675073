import { Box, FormControlLabel, IconButton, Switch, Tooltip } from '@mui/material';

import ChevronDownIcon from '@assets/icons/dashboard/chevron-down-gray.svg';
import ChevronUpIcon from '@assets/icons/dashboard/chevron-up-gray.svg';
import useStyles from './styles';

interface IProps {
  unadjustedAccountsVisibility: boolean;
  onChangeUnadjustedAccountsVisibility: (value: boolean) => void;
  showTableExpandSwitch: boolean;
  isTableExpanded: boolean;
  onToggleTableExpand: () => void;
}

export const AccountsTableFooter = ({
  unadjustedAccountsVisibility,
  onChangeUnadjustedAccountsVisibility,
  showTableExpandSwitch,
  isTableExpanded,
  onToggleTableExpand,
}: IProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.controlsContainer}>
        <FormControlLabel
          control={
            <Switch
              inputProps={{ role: 'switch' }}
              checked={unadjustedAccountsVisibility}
              onChange={(_, val) => onChangeUnadjustedAccountsVisibility(val)}
            />
          }
          label='Show accounts without adjustments'
        />
      </Box>
      {showTableExpandSwitch && (
        <Box className={classes.controlsContainer}>
          {isTableExpanded ? (
            <Tooltip title='Click here to decrease the table height'>
              <IconButton onClick={onToggleTableExpand}>
                <img src={ChevronUpIcon} alt='arrow-up' />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title='Click here to increase the table height'>
              <IconButton onClick={onToggleTableExpand}>
                <img src={ChevronDownIcon} alt='arrow-down' />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )}
    </Box>
  );
};
