import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{
  buttonsGap: number;
  buttonsWidth: number | undefined;
  wrap: boolean | undefined;
}>()((t, { buttonsGap, buttonsWidth, wrap }) => {
  return {
    root: {
      display: 'flex',
      justifyItems: 'center',
      gap: buttonsGap,
      flexWrap: wrap ? 'wrap' : 'nowrap',
    },
    button: {
      width: buttonsWidth,
      textTransform: 'none',
      textWrap: 'nowrap',
      fontWeight: 400,
    },
    transparentBorderButton: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'transparent',
    },
  };
});

export default useStyles;
