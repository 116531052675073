import { AppRoles, ClientRoles } from '@models/enums/Roles';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';

import { FeatureCard } from './components/FeatureCard';
import { IClientFeature } from '@models/interfaces/entities/IClientFeature';
import { IFeature } from '@models/interfaces/entities/IFeature';
import InfoIcon from '@assets/icons/item-view/info.svg';
import { selectAuthFeatures } from '@reducers/authSlice';
import { useAppSelector } from '@hooks';
import useStyles from './styles';

const columnsMappingFeatureName = 'Columns mapping';
const subAccountMatchingFeatureName = 'Subaccount matching';

interface IProps {
  clientFeatures: IClientFeature[];
  isActivationEnabled: boolean;
  onActivateFeatures: (featureId: string, roles: string[]) => void;
  onDeactivateFeatures: (
    features: Array<
      IFeature & { role: string } & {
        clientFeature?: IClientFeature | undefined;
      }
    >,
  ) => void;
}

const roles = [
  AppRoles.applicationAdmin,
  ClientRoles.clientManager,
  ClientRoles.projectManager,
  ClientRoles.clientUser,
];

const RolesMap: Readonly<Record<string, string>> = Object.freeze({
  [AppRoles.applicationAdmin]: 'Application Admin',
  [ClientRoles.clientManager]: 'Client Manager',
  [ClientRoles.projectManager]: 'Project Manager',
  [ClientRoles.clientUser]: 'Client User',
});

export const FeaturesTabView = ({
  clientFeatures,
  isActivationEnabled,
  onActivateFeatures,
  onDeactivateFeatures,
}: IProps) => {
  const { classes } = useStyles();
  const features = useAppSelector(selectAuthFeatures);

  const clientFeatureItems = useMemo<
    (IFeature & { role: string } & { clientFeature?: IClientFeature })[]
  >(() => {
    if (!features.length || !roles.length) return [];

    const items = features.flatMap((feature) =>
      roles.map((role) => ({
        ...feature,
        role,
        clientFeature: clientFeatures.find((x) => feature.id === x.featureId && role === x.role),
      })),
    );

    return items;
  }, [features, clientFeatures, roles]);

  const getFeature = useCallback(
    (featureId: string, role: string) =>
      clientFeatureItems.find((x) => x.id === featureId && x.role === role),
    [clientFeatureItems],
  );

  const getColumnsMappingFeature = useCallback(
    (role: string) =>
      clientFeatureItems.find((x) => x.name === columnsMappingFeatureName && x.role === role),
    [clientFeatureItems],
  );

  const getSubAccountMatchingFeature = useCallback(
    (role: string) =>
      clientFeatureItems.find((x) => x.name === subAccountMatchingFeatureName && x.role === role),
    [clientFeatureItems],
  );

  const handleActivateFeature = useCallback(
    (featureId: string, role: string) => {
      if (role !== AppRoles.applicationAdmin) {
        const isEnabledAdminFeature = clientFeatureItems.some(
          (x) => !!x.clientFeature && x.id === featureId && x.role === AppRoles.applicationAdmin,
        );
        if (isEnabledAdminFeature) {
          onActivateFeatures(featureId, [role]);
        } else {
          onActivateFeatures(featureId, [role, AppRoles.applicationAdmin]);
        }
      } else {
        onActivateFeatures(featureId, [role]);
      }
    },
    [clientFeatureItems, onActivateFeatures],
  );

  const handleDeactivateFeature = useCallback(
    (feature: IFeature & { role: string } & { clientFeature?: IClientFeature | undefined }) => {
      if (feature.role === AppRoles.applicationAdmin) {
        const enabledFeatures = clientFeatureItems.filter(
          (x) => !!x.clientFeature && x.name === feature.name,
        );
        onDeactivateFeatures(enabledFeatures);
      } else {
        onDeactivateFeatures([feature]);
      }
    },
    [clientFeatureItems, onDeactivateFeatures],
  );

  return (
    <Box className={classes.root}>
      <Box className={classes.grid}>
        {!!features.length && (
          <>
            <Box />
            {roles.map((x) => (
              <Box key={x} className={classes.topHeaderCell}>
                <Typography variant='subtitle1'>{RolesMap[x]}</Typography>
              </Box>
            ))}

            {features.map((f) => (
              <>
                <Box key={f.id} className={classes.sideHeaderCell}>
                  <Typography variant='subtitle1'>{f.name}</Typography>
                  <Tooltip title={<Box className={classes.tooltip}>{f.description}</Box>}>
                    <IconButton>
                      <img src={InfoIcon} alt='info' />
                    </IconButton>
                  </Tooltip>
                </Box>
                {roles.map((x) => {
                  const item = getFeature(f.id, x);
                  const columnsMappingFeature = getColumnsMappingFeature(x);
                  const subAccountMatchingFeature = getSubAccountMatchingFeature(x);

                  return (
                    !!item && (
                      <Box className={classes.cell} key={`${f.id}-${x}`}>
                        <FeatureCard
                          feature={item}
                          onActivate={() => handleActivateFeature(f.id, x)}
                          onDeactivate={() => handleDeactivateFeature(item)}
                          isActivationEnabled={
                            isActivationEnabled &&
                            (item.name === subAccountMatchingFeatureName
                              ? !!columnsMappingFeature?.clientFeature
                              : true)
                          }
                          isDeactivationEnabled={
                            item.name === columnsMappingFeatureName
                              ? !subAccountMatchingFeature?.clientFeature
                              : true
                          }
                          info={
                            item.name === subAccountMatchingFeatureName &&
                            !columnsMappingFeature?.clientFeature
                              ? 'The column mapping feature must be enabled for this role first in order to enable this feature.'
                              : item.name === columnsMappingFeatureName &&
                                !!subAccountMatchingFeature?.clientFeature
                              ? 'The subaccount matching feature must be disabled for this role first in order to disable this feature.'
                              : undefined
                          }
                        />
                      </Box>
                    )
                  );
                })}
              </>
            ))}
          </>
        )}
      </Box>
    </Box>
  );
};
