import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  mt24: {
    marginTop: 24,
  },
  mt12: {
    marginTop: 12,
  },
  flex: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
  },
  label: {
    color: t.palette.custom.black[60],
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  redText: {
    color: t.palette.custom.error[100],
  },
}));

export default useStyles;
