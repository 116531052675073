import { Box, Button, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { CustomDialog } from '@components/CustomDialog';
import { ISimulationAbbreviation } from '@models/interfaces/entities/ISimulationAbbreviation';
import clsx from 'clsx';
import useStyles from './styles';

export interface IProps {
  limit: number;
  open: boolean;
  simulations: ISimulationAbbreviation[];
  selectedSimulationIds: string[];
  onClose: (simulationIds?: string[]) => void;
}

export const SimulationsDialog = ({
  limit,
  open,
  simulations,
  selectedSimulationIds,
  onClose,
}: IProps) => {
  const { classes } = useStyles();
  const [simulationIds, setSimulationIds] = useState<string[]>([]);

  const onSimulationToggled = useCallback((simulationId: string, value: boolean) => {
    setSimulationIds((prev) => {
      if (value) {
        if (prev.length >= limit) {
          return prev;
        }
        return [...prev, simulationId];
      } else {
        return prev.filter((id) => id !== simulationId);
      }
    });
  }, []);

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    if (simulationIds.length) {
      onClose(simulationIds);
    }
  };

  useEffect(() => {
    if (!open) {
      setSimulationIds([]);
    } else {
      setSimulationIds([...selectedSimulationIds]);
    }
  }, [open, selectedSimulationIds]);

  if (!open) return null;

  return (
    <CustomDialog
      title='Select Simulations'
      onClose={onCancel}
      open={open}
      maxWidth='sm'
      fullWidth
      actions={
        <>
          <div />
          <Button
            size='medium'
            variant='contained'
            color='primary'
            onClick={onSave}
            disabled={!simulationIds.length}
          >
            Ok
          </Button>
        </>
      }
    >
      <Box className={classes.root}>
        <Typography color={'info'}>You are able to select up to {limit} simulations.</Typography>
        <Box className={classes.simulationsList}>
          {simulations.map((x) => (
            <Box
              key={x.id}
              className={clsx([
                classes.simulationItem,
                simulationIds.includes(x.id) && classes.selected,
              ])}
              onClick={() => onSimulationToggled(x.id, !simulationIds.includes(x.id))}
            >
              <Typography>{x.name}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </CustomDialog>
  );
};
