import { AdjustmentDetails, IAdjustmentDetails } from '../../../AdjustmentDetails';
import { AdjustmentOperation, OperationMap } from '../../../../common/AdjustmentOperation';
import { Box, Button, Typography } from '@mui/material';
import { DataGridPremium, GridColDef, GridRowParams } from '@mui/x-data-grid-premium';
import { SearchInput, SearchInputColors } from '@components/SearchInput';
import { getTypeCodes, updateNewTypeCode } from '@services/api';
import { useApi, useDebounce, useInput, useLoader } from '@hooks';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { ButtonsSelector } from '@components/ButtonsSelector';
import { CustomDialog } from '@components/CustomDialog';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { ICreateAdjustmentDefinitionData } from '@models/interfaces/additional/ICreateAdjustmentDefinitionData';
import { ILink } from '@models/interfaces/entities/ILink';
import { IProject } from '@models/interfaces/entities/IProject';
import { ITypeCode } from '@models/interfaces/entities/ITypeCode';
import { IUpdateAdjustmentDefinitionData } from '@models/interfaces/additional/IUpdateAdjustmentDefinitionData';
import { IUpdateNewTypeCodeData } from '@models/interfaces/additional/IUpdateNewTypeCodeData';
import { Loader } from '@components/Loader';
import { MatchAdjustmentDetails } from '../../../MatchAdjustmentDetails';
import clsx from 'clsx';
import { formatBalance } from '../../../../utils';
import { toast } from 'react-toastify';
import useStyles from './styles';

enum LineItemStatus {
  new = 'new',
  inUse = 'inUse',
  unused = 'unused',
}

export interface IProps {
  project: IProject;
  accounts: IAccount[];
  types: number[];
  category: string;
  existingTypeCodes: string[];
  availableOperations?: AdjustmentOperation[];
  manageAdjustmentDefinitions: (
    create?: { url: string; data: ICreateAdjustmentDefinitionData[] },
    update?: { url: string; data: IUpdateAdjustmentDefinitionData }[],
    callback?: () => void,
  ) => void;
  open: boolean;
  onClose: () => void;
}

export const AddLineItemsDialog = ({
  open,
  onClose,
  project,
  accounts,
  types,
  availableOperations,
  category,
  existingTypeCodes,
  manageAdjustmentDefinitions,
}: IProps) => {
  const { classes } = useStyles();
  const searchInput = useInput<string>('');
  const [step, setStep] = useState(1);

  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([LineItemStatus.new]);
  const operationInput = useInput<string>(AdjustmentOperation.Add);

  const adjustmentDetailsRef = useRef<{
    save: (sources?: { type: string; id: string; typeCode: string }[]) => boolean;
  }>(null);

  const [newTypeCodes, setNewTypeCodes] = useState<ITypeCode[]>([]);
  const [usedTypeCodes, setUsedTypeCodes] = useState<ITypeCode[]>([]);
  const [unusedTypeCodes, setUnusedTypeCodes] = useState<ITypeCode[]>([]);

  const [selectedTypeCodes, setSelectedTypeCodes] = useState<ITypeCode[]>([]);

  const debouncedSelectedStatuses = useDebounce<string[]>(selectedStatuses, 1000);

  const { request: updateNewTypeCodesRequest, loading: updateNewTypeCodesLoading } = useApi(
    async (url: string, data: IUpdateNewTypeCodeData[], typeCodes: ITypeCode[]) => {
      const results = await Promise.all(data.map((item) => updateNewTypeCode(url, item)));

      if (
        adjustmentDetailsRef.current?.save(
          [...results, ...typeCodes].map((x) => ({
            type: 'typeCode',
            id: x.id || '',
            typeCode: x.typeCode,
          })),
        )
      ) {
        onClose();
      }
    },
    null,
    {
      handleErrors: true,
    },
  );

  const {
    request: getNewTypeCodesRequest,
    data: getNewTypeCodesData,
    loading: getNewTypeCodesLoading,
  } = useApi(
    (link: ILink) => {
      return getTypeCodes(link.href, undefined, -99, true);
    },
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        setNewTypeCodes(data?.items || []);
      },
    },
  );

  const { request: getUsedTypeCodesRequest, loading: getUsedTypeCodesLoading } = useApi(
    (link: ILink) => {
      return getTypeCodes(link.href, undefined, -99, undefined, true, true);
    },
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        setUsedTypeCodes(data?.items || []);
      },
    },
  );

  const { request: getUnusedTypeCodesRequest, loading: getUnusedTypeCodesLoading } = useApi(
    (link: ILink) => {
      return getTypeCodes(link.href, undefined, -99, false, true, false);
    },
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        setUnusedTypeCodes(data?.items || []);
      },
    },
  );

  const updateNewTypeCodeLink = useMemo(
    () => getNewTypeCodesData?.links[Actions.updateNewTypeCode]?.href,
    [getNewTypeCodesData],
  );

  const onStatusesChange = (value: string | string[]) => {
    setSelectedStatuses(Array.isArray(value) ? value : [value]);
  };

  const onCancel = () => {
    onClose();
  };

  const onManageAdjustmentDefinitions =
    manageAdjustmentDefinitions !== undefined
      ? (
          create?: { url: string; data: ICreateAdjustmentDefinitionData[] },
          update?: { url: string; data: IUpdateAdjustmentDefinitionData }[],
          callback?: () => void,
        ) => {
          manageAdjustmentDefinitions(create, update, () => {
            if (create?.data.length) {
              toast.success(
                `Adjustment${create?.data.length !== 1 ? 's' : ''} ha${
                  create?.data.length !== 1 ? 've' : 's'
                } been successfully created`,
              );
            }
            if (update?.length) {
              toast.success(
                `Adjustment${update?.length !== 1 ? 's' : ''} ha${
                  update?.length !== 1 ? 've' : 's'
                } been successfully updated`,
              );
            }
            if (callback) {
              callback();
            }
          });
        }
      : undefined;

  const isNewFilterSelected = useMemo(
    () => debouncedSelectedStatuses.includes(LineItemStatus.new),
    [debouncedSelectedStatuses],
  );
  const isUsedFilterSelected = useMemo(
    () => debouncedSelectedStatuses.includes(LineItemStatus.inUse),
    [debouncedSelectedStatuses],
  );
  const isUnusedFilterSelected = useMemo(
    () => debouncedSelectedStatuses.includes(LineItemStatus.unused),
    [debouncedSelectedStatuses],
  );

  useEffect(() => {
    if (project.links[Actions.getTypeCodes] && open && isNewFilterSelected) {
      getNewTypeCodesRequest(project.links[Actions.getTypeCodes]);
    } else {
      setNewTypeCodes([]);
    }
  }, [project.links[Actions.getTypeCodes], isNewFilterSelected, open]);

  useEffect(() => {
    if (project.links[Actions.getTypeCodes] && open && isUsedFilterSelected) {
      getUsedTypeCodesRequest(project.links[Actions.getTypeCodes]);
    } else {
      setUsedTypeCodes([]);
    }
  }, [project.links[Actions.getTypeCodes], isUsedFilterSelected, open]);

  useEffect(() => {
    if (project.links[Actions.getTypeCodes] && open && isUnusedFilterSelected) {
      getUnusedTypeCodesRequest(project.links[Actions.getTypeCodes]);
    } else {
      setUnusedTypeCodes([]);
    }
  }, [project.links[Actions.getTypeCodes], isUnusedFilterSelected, open]);

  useEffect(() => {
    setStep(1);
    setSelectedTypeCodes([]);
    setSelectedStatuses([LineItemStatus.new]);
    operationInput.set(AdjustmentOperation.Add);
    searchInput.set('');
  }, [open]);

  useEffect(() => {
    if (operationInput.value !== AdjustmentOperation.Match) {
      setSelectedTypeCodes((prev) => (prev.length > 1 ? [] : prev));
    }
  }, [operationInput.value]);

  const filteredTypeCodes = useMemo(() => {
    const mergedCodes = [...newTypeCodes, ...usedTypeCodes, ...unusedTypeCodes];

    const allCodes = mergedCodes.filter(
      (code, index, self) => index === self.findIndex((c) => c.typeCode === code.typeCode),
    );

    const notAlreadyUsedTypeCodes = allCodes.filter(
      (x) =>
        !existingTypeCodes.some((etc) => etc === x.typeCode) &&
        !selectedTypeCodes.some((stc) => stc.typeCode === x.typeCode),
    );

    if (!searchInput.value) {
      return notAlreadyUsedTypeCodes;
    }

    const filterValue = searchInput.value.toLowerCase();

    return notAlreadyUsedTypeCodes.filter(
      (code) =>
        code.typeCode.toLowerCase().includes(filterValue) ||
        code.description?.toLowerCase().includes(filterValue),
    );
  }, [newTypeCodes, usedTypeCodes, unusedTypeCodes, selectedTypeCodes, searchInput.value]);

  const columns = useMemo(
    () =>
      [
        {
          field: 'typeCode',
          headerName: 'Balance Sheet Line Item',
          type: 'string',
          flex: 2,
        },
        {
          field: 'description',
          headerName: 'Description',
          type: 'string',
          flex: 2,
        },
        {
          field: 'balance',
          headerName: 'Balance',
          type: 'number',
          flex: 2,
          renderCell: (params) => formatBalance(params.value || 0),
        },
      ] as GridColDef<ITypeCode>[],
    [],
  );

  const onSourceTableRowClick = (params: GridRowParams<ITypeCode>) => {
    const clickedRow = params.row;

    setSelectedTypeCodes((prev) => {
      if (operationInput.value === AdjustmentOperation.Match) {
        return [...prev, clickedRow];
      }

      return [clickedRow];
    });
  };

  const onTargetTableRowClick = (params: GridRowParams<ITypeCode>) => {
    const clickedRow = params.row;

    setSelectedTypeCodes((prev) => {
      return prev.filter((x) => x.typeCode !== clickedRow.typeCode);
    });
  };

  const onSave = () => {
    if (!selectedTypeCodes.length) return;
    if (selectedTypeCodes.some((x) => !x.id)) {
      if (!updateNewTypeCodeLink) {
        toast.error('Unable to update type code');
        return;
      }
      const notCreatedTypeCodes = selectedTypeCodes.filter((x) => !x.id);
      const createdTypeCodes = selectedTypeCodes.filter((x) => !!x.id);
      updateNewTypeCodesRequest(
        updateNewTypeCodeLink,
        notCreatedTypeCodes.map((x) => ({
          code: x.typeCode,
          description: x.description,
          ignoreReason: '',
          isUsed: false,
        })),
        createdTypeCodes,
      );
    } else {
      if (
        adjustmentDetailsRef.current?.save(
          selectedTypeCodes.map((x) => ({
            type: 'typeCode',
            id: x.id || '',
            typeCode: x.typeCode,
          })),
        )
      ) {
        onClose();
      }
    }
  };

  const onStepBack = () => {
    setStep((step) => step - 1);
  };

  const onStepNext = () => {
    setStep((step) => step + 1);
  };

  const operationOptions = useMemo(
    () =>
      (availableOperations || []).map((x) => ({
        value: x.toString(),
        label: OperationMap[x],
      })),
    [],
  );

  const adjustmentDetails = useMemo<IAdjustmentDetails[]>(
    () =>
      selectedTypeCodes.map((selectedTypeCode) => ({
        sourceSubAccountId: selectedTypeCode.typeCode,
        sourceSubAccountDescription: selectedTypeCode.description,
        operation: operationInput.value,
        sourceTotal: selectedTypeCode.balance,
        matchingBalance: Number.MIN_VALUE,
        sourceAdjustment:
          (operationInput.value !== AdjustmentOperation.Subtract ? 1 : -1) *
          selectedTypeCode.balance,
        adjustments: [],
        reverseBalance: false,
        groupSources: selectedTypeCodes.map((x) => ({
          reverseBalance: false,
          sourceTotal: x.balance,
          sourceSubAccountId: x.typeCode,
        })),
      })),
    [selectedTypeCodes, operationInput.value],
  );

  const firstAdjustmentDetails = useMemo<IAdjustmentDetails | null>(() => {
    if (!adjustmentDetails.length) return null;
    const [first] = adjustmentDetails;
    return first;
  }, [adjustmentDetails]);

  const statusOptions = useMemo(
    () => [
      { value: LineItemStatus.new, label: 'New' },
      { value: LineItemStatus.inUse, label: 'In Use' },
      { value: LineItemStatus.unused, label: 'Unused' },
    ],
    [],
  );

  const showLoader = useLoader(
    getNewTypeCodesLoading,
    getUsedTypeCodesLoading,
    getUnusedTypeCodesLoading,
    updateNewTypeCodesLoading,
  );

  return (
    <CustomDialog
      title={step === 1 ? 'Add line items' : 'Configure Adjustment'}
      onClose={onCancel}
      open={open}
      maxWidth='md'
      fullWidth
      steps={2}
      activeStep={step}
      actions={
        <>
          {step === 1 && (
            <>
              <div />
              <Button
                disabled={!selectedTypeCodes.length}
                variant='contained'
                color='secondary'
                className='narrow'
                onClick={onStepNext}
              >
                Next
              </Button>
            </>
          )}
          {step === 2 && (
            <>
              <Button variant='outlined' color='secondary' className='narrow' onClick={onStepBack}>
                Back
              </Button>
              <Button variant='contained' size='large' color='secondary' onClick={onSave}>
                Ok
              </Button>
            </>
          )}
        </>
      }
    >
      <Box className={classes.root}>
        {step === 1 && (
          <>
            <Box className={clsx([classes.flex, classes.gap24])}>
              <Box className={classes.flex}>
                <Typography className={classes.noWrap}>Show line items:</Typography>
                <ButtonsSelector
                  multiselect
                  options={statusOptions}
                  value={selectedStatuses}
                  onChanged={onStatusesChange}
                />
              </Box>
              <Box className={classes.flex}>
                <SearchInput
                  onChange={(value) => searchInput.onChange({ target: { value } })}
                  color={SearchInputColors.grey}
                  maxWidth={300}
                  minWidth={300}
                  id='search-line-items-input'
                />
              </Box>
            </Box>
            <Box className={classes.flex}>
              <Typography className={classes.noWrap}>Operation:</Typography>
              <ButtonsSelector
                options={operationOptions}
                value={operationInput.value}
                onChanged={(value) =>
                  operationInput.onChange({
                    target: { value: Array.isArray(value) ? value[0] : value },
                  })
                }
              />
            </Box>
            <Box>
              <Typography variant='subtitle1'>Source line items:</Typography>
              <DataGridPremium
                rows={filteredTypeCodes}
                density='compact'
                columns={columns}
                className={clsx([classes.table])}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'typeCode', sort: 'asc' }],
                  },
                }}
                hideFooter
                getRowId={(row) => row.typeCode}
                onRowClick={onSourceTableRowClick}
              />
            </Box>
            <Box>
              <Typography variant='subtitle1'>Selected line items:</Typography>
              <DataGridPremium
                rows={selectedTypeCodes}
                density='compact'
                columns={columns}
                className={clsx([classes.table])}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'typeCode', sort: 'asc' }],
                  },
                }}
                hideFooter
                getRowId={(row) => row.typeCode}
                onRowClick={onTargetTableRowClick}
              />
            </Box>
          </>
        )}
        {step === 2 && firstAdjustmentDetails && (
          <>
            {operationInput.value !== AdjustmentOperation.Match ? (
              <AdjustmentDetails
                ref={adjustmentDetailsRef}
                details={firstAdjustmentDetails}
                accounts={accounts}
                category={category}
                project={project}
                types={types}
                manageAdjustmentDefinitions={onManageAdjustmentDefinitions}
                hideControls
              />
            ) : (
              <MatchAdjustmentDetails
                ref={adjustmentDetailsRef}
                detailsArray={adjustmentDetails}
                accounts={accounts}
                category={category}
                project={project}
                types={types}
                manageAdjustmentDefinitions={onManageAdjustmentDefinitions}
                creationMode
              />
            )}
          </>
        )}
      </Box>

      <Loader show={showLoader} fixed={false} />
    </CustomDialog>
  );
};
