import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    fontSize: t.typography.body2.fontSize,
    fontWeight: t.typography.body2.fontWeight,
    letterSpacing: t.typography.body2.letterSpacing,
  },
  content: {
    backgroundColor: t.palette.custom.white[100],
    padding: 12,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: t.palette.divider,
  },
  tabPlaceholder: {
    padding: 24,
    backgroundColor: t.palette.custom.white[100],
    textAlign: 'center',
  },
  tabTitle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    gap: 8,
  },
}));

export default useStyles;
