import { AdjustmentOperation, OperationMap } from '../../common/AdjustmentOperation';
import { Box, IconButton, Switch, Tooltip } from '@mui/material';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { formatBalance, useTableExpand } from '../../utils';
import { getAdjustmentDefinitions, getAdjustments } from '@services/api';
import { useApi, useConfirm, useLoader } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { AddLineItemsDialog } from './components/AddLineItemsDialog';
import CheckIcon from '@assets/icons/dashboard/check-circle.svg';
import { Dropdown } from '@components/Dropdown';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { IAdjustmentDefinition } from '@models/interfaces/entities/IAdjustmentDefinition';
import { ICreateAdjustmentDefinitionData } from '@models/interfaces/additional/ICreateAdjustmentDefinitionData';
import { ILink } from '@models/interfaces/entities/ILink';
import { IProject } from '@models/interfaces/entities/IProject';
import { ITypeCode } from '@models/interfaces/entities/ITypeCode';
import { IUpdateAdjustmentDefinitionData } from '@models/interfaces/additional/IUpdateAdjustmentDefinitionData';
import { Loader } from '@components/Loader';
import MinusIcon from '@assets/icons/dashboard/minus.svg';
import PlusIcon from '@assets/icons/dashboard/plus-filled-green.svg';
import { StandardTableFooter } from '../StandardTableFooter';
import XIcon from '@assets/icons/dashboard/x-circle-red.svg';
import clsx from 'clsx';
import useStyles from './styles';

interface IBalanceSheetLineItem {
  id: string;
  sourceSubAccountId: string;
  sourceSubAccountDescription: string;
  balance?: number;
  operation: string;
  reverseBalance: boolean;
  contribution: number;
  deleteEnabled: boolean;
  updateEnabled: boolean;
  editModeEnabled: boolean;
  label?: string;
}

interface IAdjustmentOperation {
  id: string;
  operation: string;
}

interface IAdjustmentReverseBalance {
  id: string;
  value: boolean;
}

interface IProps {
  project: IProject;
  type: number;
  balancingType: number;
  accounts: IAccount[];
  manageAdjustmentDefinitions: (
    create?: { url: string; data: ICreateAdjustmentDefinitionData[] },
    update?: { url: string; data: IUpdateAdjustmentDefinitionData }[],
    callback?: () => void,
  ) => void;
  deleteAdjustmentDefinition?: (url: string, callback?: () => void) => void;
  receivedSectionAdjustment: number;
  sentToOtherSectionAdjustment: number;
}

export const BalanceSheetTable = ({
  project,
  type,
  balancingType,
  accounts,
  manageAdjustmentDefinitions,
  deleteAdjustmentDefinition,
  receivedSectionAdjustment,
  sentToOtherSectionAdjustment,
}: IProps) => {
  const { classes } = useStyles();
  const confirm = useConfirm();

  const [addAdjustments, setAddAdjustments] = useState<IAdjustment[]>([]);
  const [subtractAdjustments, setSubtractAdjustments] = useState<IAdjustment[]>([]);
  const [addAdjustmentDefinitions, setAddAdjustmentDefinitions] = useState<IAdjustmentDefinition[]>(
    [],
  );
  const [subtractAdjustmentDefinitions, setSubtractAdjustmentDefinitions] = useState<
    IAdjustmentDefinition[]
  >([]);

  const [adjustmentReverseBalances, setAdjustmentReverseBalances] = useState<
    IAdjustmentReverseBalance[]
  >([]);
  const [adjustmentOperations, setAdjustmentOperations] = useState<IAdjustmentOperation[]>([]);
  const { isTableExpanded, onToggleTableExpand } = useTableExpand();

  const [openAddLineItemsDialog, setOpenAddLineItemsDialog] = useState(false);

  const operationOptions = useMemo(
    () =>
      [AdjustmentOperation.AddBalance, AdjustmentOperation.SubtractBalance].map((x) => ({
        value: x.toString(),
        label: OperationMap[x],
      })),
    [],
  );

  const { request: getAdjustmentsRequest, loading: getAdjustmentsLoading } = useApi(
    getAdjustments,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          setAddAdjustments(
            data.items.filter((x) => x.operation === AdjustmentOperation.AddBalance),
          );
          setSubtractAdjustments(
            data.items.filter((x) => x.operation === AdjustmentOperation.SubtractBalance),
          );
        }
      },
    },
  );

  const {
    request: getAddAdjustmentDefinitionsRequest,
    loading: getAddAdjustmentDefinitionsLoading,
  } = useApi(
    (href: string, type: number) => {
      return getAdjustmentDefinitions(href, AdjustmentOperation.AddBalance, undefined, type);
    },
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          setAddAdjustmentDefinitions(data.items);
        }
      },
    },
  );

  const {
    request: getSubtractAdjustmentDefinitionsRequest,
    loading: getSubtractAdjustmentDefinitionsLoading,
  } = useApi(
    (href: string, type: number) => {
      return getAdjustmentDefinitions(href, AdjustmentOperation.SubtractBalance, undefined, type);
    },
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          setSubtractAdjustmentDefinitions(data.items);
        }
      },
    },
  );

  const balancingAccount = useMemo(
    () => accounts.find((x) => x.accountType.type === balancingType),
    [balancingType, accounts],
  );

  const deleteEnabled = useCallback(
    (adjustment: IAdjustment) => {
      return (
        deleteAdjustmentDefinition !== undefined && !!adjustment.links[Actions.deleteDefinition]
      );
    },
    [deleteAdjustmentDefinition],
  );

  const updateEnabled = useCallback(
    (adjustment: IAdjustment) => {
      return (
        manageAdjustmentDefinitions !== undefined && !!adjustment.links[Actions.updateDefinition]
      );
    },
    [manageAdjustmentDefinitions],
  );

  const createEnabled = useMemo(
    () =>
      manageAdjustmentDefinitions !== undefined &&
      !!project.links[Actions.createAdjustmentDefinition],
    [manageAdjustmentDefinitions, project.links[Actions.createAdjustmentDefinition]],
  );

  const reloadAdjustments = useCallback(
    (link: ILink | undefined) => {
      if (link) {
        getAdjustmentsRequest(
          link.href,
          [AdjustmentOperation.AddBalance, AdjustmentOperation.SubtractBalance],
          undefined,
          undefined,
          type,
        );
      }
    },
    [type],
  );

  const reloadAdjustmentDefinitions = useCallback(
    (link: ILink | undefined) => {
      if (link) {
        getAddAdjustmentDefinitionsRequest(link.href, type);
        getSubtractAdjustmentDefinitionsRequest(link.href, type);
      }
    },
    [type],
  );

  const onDelete = useCallback(
    async (id: string) => {
      const adjustment = [...addAdjustments, ...subtractAdjustments].find((x) => x.id === id);
      if (
        deleteAdjustmentDefinition !== undefined &&
        !!adjustment?.links[Actions.deleteDefinition]
      ) {
        await confirm({
          title: 'Delete adjustment',
          description: 'Are you sure you wish to delete this adjustment?',
          confirmationText: 'Confirm',
        });

        deleteAdjustmentDefinition(adjustment.links[Actions.deleteDefinition].href, () => {
          reloadAdjustments(project.links[Actions.getAdjustments]);
          reloadAdjustmentDefinitions(project.links[Actions.getAdjustmentDefinitions]);
        });
      }
    },
    [
      addAdjustments,
      subtractAdjustments,
      project.links[Actions.getAdjustments]?.href,
      project.links[Actions.getAdjustmentDefinitions]?.href,
      deleteAdjustmentDefinition,
    ],
  );

  const onOperationChanged = useCallback(async (id: string, operation: string) => {
    setAdjustmentOperations((prev) => {
      if (prev.findIndex((x) => x.id === id) !== -1) {
        return prev.map((x) => (x.id !== id ? x : { id, operation }));
      } else {
        return [...prev, { id, operation }];
      }
    });
  }, []);

  const onReverseBalanceChanged = useCallback(async (id: string, value: boolean) => {
    setAdjustmentReverseBalances((prev) => {
      if (prev.findIndex((x) => x.id === id) !== -1) {
        return prev.map((x) => (x.id !== id ? x : { id, value }));
      } else {
        return [...prev, { id, value }];
      }
    });
  }, []);

  const onAdd = useCallback(() => {
    setOpenAddLineItemsDialog(true);
  }, []);

  const onSave = useCallback(
    async (id: string) => {
      const adjustment = [...addAdjustments, ...subtractAdjustments].find((x) => x.id === id);

      if (
        manageAdjustmentDefinitions !== undefined &&
        !!adjustment?.links[Actions.updateDefinition]
      ) {
        const adjustmentDefinition = [
          ...addAdjustmentDefinitions,
          ...subtractAdjustmentDefinitions,
        ].find((x) => x.id === adjustment.adjustmentDefinitionId);

        if (adjustmentDefinition) {
          const adjustmentOperation = adjustmentOperations.find((x) => x.id === id);
          const adjustmentReverseBalance = adjustmentReverseBalances.find((x) => x.id === id);
          manageAdjustmentDefinitions(
            undefined,
            [
              {
                url: adjustment.links[Actions.updateDefinition].href,
                data: {
                  reverseBalance: adjustmentReverseBalance
                    ? adjustmentReverseBalance.value
                    : adjustment.reverseBalance,
                  source: {
                    type: adjustmentDefinition.source.type,
                    id: adjustmentDefinition.source.value.id,
                  },
                  targets: adjustmentDefinition.targets.map((x) => x.id),
                  operation: adjustmentOperation?.operation || adjustmentDefinition.operation,
                  baseline: [],
                  tierLimits: [],
                },
              },
            ],
            () => {
              reloadAdjustments(project.links[Actions.getAdjustments]);
              reloadAdjustmentDefinitions(project.links[Actions.getAdjustmentDefinitions]);
              setAdjustmentOperations((prev) => prev.filter((x) => x.id !== id));
            },
          );
        }
      }
    },
    [
      addAdjustments,
      subtractAdjustments,
      addAdjustmentDefinitions,
      subtractAdjustmentDefinitions,
      adjustmentOperations,
      adjustmentReverseBalances,
      project.links[Actions.getAdjustments]?.href,
      project.links[Actions.getAdjustmentDefinitions]?.href,
      manageAdjustmentDefinitions,
    ],
  );

  const onDiscard = useCallback(async (id: string) => {
    setAdjustmentOperations((prev) => prev.filter((x) => x.id !== id));
    setAdjustmentReverseBalances((prev) => prev.filter((x) => x.id !== id));
  }, []);

  const columns = useMemo(
    () =>
      [
        {
          field: 'sourceSubAccountId',
          headerName: 'Balance Sheet Line Item',
          type: 'string',
          flex: 2,
          renderCell: (params) => {
            if (params.rowNode.type === 'pinnedRow') {
              if (params.id !== 'add-new') {
                return '';
              } else {
                return (
                  <Box
                    className={clsx([classes.flex, classes.cursorPointer])}
                    onClick={() => onAdd()}
                  >
                    <IconButton size='small'>
                      <img src={PlusIcon} alt='+' />
                    </IconButton>
                    <Box>Add new line item adjustment</Box>
                  </Box>
                );
              }
            }

            return (
              <Box className={classes.flex}>
                {params.row.deleteEnabled && (
                  <Tooltip title='Remove line item adjustment'>
                    <IconButton onClick={() => onDelete(params.id.toString())} size='small'>
                      <img src={MinusIcon} alt='-' />
                    </IconButton>
                  </Tooltip>
                )}
                <Box>{params.value}</Box>
              </Box>
            );
          },
        },
        {
          field: 'sourceSubAccountDescription',
          headerName: 'Description',
          type: 'string',
          flex: 2,
          renderCell: (params) => {
            if (params.rowNode.type === 'pinnedRow') {
              return '';
            }
            return params.value;
          },
        },
        {
          field: 'operation',
          headerName: 'Operation',
          type: 'string',
          width: 135,
          renderCell: (params) => {
            if (params.rowNode.type === 'pinnedRow') {
              return '';
            }
            return (
              <>
                {params.row.updateEnabled ? (
                  <Box className={classes.flex}>
                    <Dropdown
                      value={params.value}
                      onChanged={(value) => onOperationChanged(params.id.toString(), value)}
                      options={operationOptions}
                      variant='table-control'
                    />
                  </Box>
                ) : (
                  OperationMap[params.value]
                )}
              </>
            );
          },
        },
        {
          field: 'reverseBalance',
          headerName: 'Reverse Balance',
          type: 'string',
          width: 180,
          renderCell: (params) => {
            if (params.rowNode.type === 'pinnedRow') {
              return '';
            }
            return (
              <>
                {params.row.updateEnabled ? (
                  <Box className={classes.flex}>
                    <Switch
                      inputProps={{ role: 'switch' }}
                      checked={params.value}
                      size='small'
                      onChange={(_, val) => onReverseBalanceChanged(params.id.toString(), val)}
                    />
                    {params.row.editModeEnabled && (
                      <Tooltip title='Accept changes'>
                        <IconButton onClick={() => onSave(params.id.toString())} size='small'>
                          <img src={CheckIcon} alt='check' />
                        </IconButton>
                      </Tooltip>
                    )}
                    {params.row.editModeEnabled && (
                      <Tooltip title='Discard changes'>
                        <IconButton onClick={() => onDiscard(params.id.toString())} size='small'>
                          <img src={XIcon} alt='x' />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                ) : (
                  <Switch
                    inputProps={{ role: 'switch' }}
                    checked={params.value}
                    disabled
                    size='small'
                  />
                )}
              </>
            );
          },
        },
        {
          field: 'balance',
          headerName: 'Balance',
          type: 'number',
          flex: 2,
          renderCell: (params) =>
            params.rowNode.type !== 'pinnedRow' ? (
              formatBalance(params.value || 0)
            ) : params.row.id !== 'add-new' ? (
              <Box className={classes.pinnedRowLabel}>{params.row.label}</Box>
            ) : (
              ''
            ),
        },
        {
          field: 'contribution',
          headerName: 'Contribution',
          type: 'number',
          flex: 2,
          renderCell: (params) => {
            return params.id !== 'add-new' ? formatBalance(params.value || 0) : '';
          },
        },
      ] as GridColDef<IBalanceSheetLineItem>[],
    [onDelete, onSave, onDiscard, onOperationChanged],
  );

  useEffect(() => {
    reloadAdjustments(project.links[Actions.getAdjustments]);
  }, [project.links[Actions.getAdjustments]?.href]);

  useEffect(() => {
    reloadAdjustmentDefinitions(project.links[Actions.getAdjustmentDefinitions]);
  }, [project.links[Actions.getAdjustmentDefinitions]?.href]);

  const onCloseAddLineItemsDialog = (typeCode?: ITypeCode) => {
    if (!typeCode || !typeCode.id) {
      setOpenAddLineItemsDialog(false);
    } else {
      setOpenAddLineItemsDialog(false);
      if (manageAdjustmentDefinitions && balancingAccount) {
        manageAdjustmentDefinitions(
          {
            url: project.links[Actions.createAdjustmentDefinition].href,
            data: [
              {
                reverseBalance: false,
                source: {
                  type: 'typeCode',
                  id: typeCode.id,
                },
                targets: [balancingAccount.id],
                operation: AdjustmentOperation.AddBalance,
                baseline: [],
                tierLimits: [],
                description: '',
              },
            ],
          },
          undefined,
          () => {
            reloadAdjustments(project.links[Actions.getAdjustments]);
            reloadAdjustmentDefinitions(project.links[Actions.getAdjustmentDefinitions]);
          },
        );
      }
    }
  };

  const existingBalanceSheetLineItems = useMemo<IBalanceSheetLineItem[]>(() => {
    return [...addAdjustments, ...subtractAdjustments].map((x) => {
      const adjustmentOperation = adjustmentOperations.find((o) => o.id === x.id);
      const adjustmentReverseBalance = adjustmentReverseBalances.find((o) => o.id === x.id);

      return {
        id: x.id,
        sourceSubAccountId: x.sourceSubAccountId || 'Unknown',
        sourceSubAccountDescription: x.sourceSubAccountDescription || '',
        balance: !adjustmentReverseBalance
          ? x.sourceAdjustment
          : x.sourceTotal * (adjustmentReverseBalance?.value ? -1 : 1),
        operation: adjustmentOperation?.operation || x.operation,
        reverseBalance: !adjustmentReverseBalance
          ? x.reverseBalance
          : adjustmentReverseBalance.value,
        contribution:
          !adjustmentOperation && !adjustmentReverseBalance
            ? x.amount
            : x.sourceTotal *
              ((adjustmentReverseBalance ? adjustmentReverseBalance.value : x.reverseBalance)
                ? -1
                : 1) *
              ((adjustmentOperation ? adjustmentOperation.operation : x.operation) ===
              AdjustmentOperation.SubtractBalance
                ? -1
                : 1),
        deleteEnabled: deleteEnabled(x),
        updateEnabled: updateEnabled(x),
        editModeEnabled: !!adjustmentOperation || !!adjustmentReverseBalance,
      } as IBalanceSheetLineItem;
    });
  }, [
    addAdjustments,
    subtractAdjustments,
    adjustmentOperations,
    adjustmentReverseBalances,
    deleteEnabled,
    updateEnabled,
  ]);

  const existingTypeCodes = useMemo<string[]>(
    () => [...addAdjustments, ...subtractAdjustments].map((x) => x.sourceSubAccountId || 'Unknown'),
    [addAdjustments, subtractAdjustments],
  );

  const subtotalItem = useMemo<IBalanceSheetLineItem>(
    () => ({
      id: 'subtotal',
      sourceSubAccountId: '',
      sourceSubAccountDescription: '',
      balance: undefined,
      operation: '',
      reverseBalance: false,
      contribution: existingBalanceSheetLineItems.reduce((sum, item) => sum + item.contribution, 0),
      deleteEnabled: false,
      updateEnabled: false,
      editModeEnabled: false,
      label: 'Subtotal',
    }),
    [existingBalanceSheetLineItems],
  );

  const receivedSectionAdjustmentItem = useMemo<IBalanceSheetLineItem>(
    () => ({
      id: 'received-section-adjustments',
      sourceSubAccountId: '',
      sourceSubAccountDescription: '',
      balance: undefined,
      operation: '',
      reverseBalance: false,
      contribution: receivedSectionAdjustment,
      deleteEnabled: false,
      updateEnabled: false,
      editModeEnabled: false,
      label: 'Section Adjustments from Other Sections',
    }),
    [receivedSectionAdjustment],
  );

  const sentSectionAdjustmentItem = useMemo<IBalanceSheetLineItem>(
    () => ({
      id: 'sent-section-adjustments',
      sourceSubAccountId: '',
      sourceSubAccountDescription: '',
      balance: undefined,
      operation: '',
      reverseBalance: false,
      contribution: sentToOtherSectionAdjustment * -1,
      deleteEnabled: false,
      updateEnabled: false,
      editModeEnabled: false,
      label: 'Section Adjustments to Other Sections',
    }),
    [receivedSectionAdjustment],
  );

  const totalItem = useMemo<IBalanceSheetLineItem>(
    () => ({
      id: 'total',
      sourceSubAccountId: '',
      sourceSubAccountDescription: '',
      balance: undefined,
      operation: '',
      reverseBalance: false,
      contribution:
        subtotalItem.contribution +
        receivedSectionAdjustmentItem.contribution +
        sentSectionAdjustmentItem.contribution,
      deleteEnabled: false,
      updateEnabled: false,
      editModeEnabled: false,
      label: 'Total',
    }),
    [subtotalItem, receivedSectionAdjustmentItem, sentSectionAdjustmentItem],
  );

  const topPinnedItems = useMemo<IBalanceSheetLineItem[] | undefined>(
    () =>
      createEnabled
        ? [
            {
              id: 'add-new',
              sourceSubAccountId: '',
              sourceSubAccountDescription: '',
              balance: undefined,
              operation: '',
              reverseBalance: false,
              contribution: 0,
              deleteEnabled: false,
              updateEnabled: false,
              editModeEnabled: false,
              label: 'Total',
            },
          ]
        : undefined,
    [createEnabled],
  );

  const bottomPinnedItems = useMemo<IBalanceSheetLineItem[]>(() => {
    const items: IBalanceSheetLineItem[] = [];

    if (receivedSectionAdjustment !== 0) {
      items.push(receivedSectionAdjustmentItem);
    }

    if (sentToOtherSectionAdjustment !== 0) {
      items.push(sentSectionAdjustmentItem);
    }

    if (items.length > 0) {
      items.unshift(subtotalItem);
    }

    items.push(totalItem);

    return items;
  }, [
    receivedSectionAdjustment,
    sentToOtherSectionAdjustment,
    subtotalItem,
    receivedSectionAdjustmentItem,
    sentSectionAdjustmentItem,
    totalItem,
  ]);

  const balanceSheetLineItems = useMemo<IBalanceSheetLineItem[]>(() => {
    return [...existingBalanceSheetLineItems, ...bottomPinnedItems];
  }, [existingBalanceSheetLineItems, bottomPinnedItems]);

  const showLoader = useLoader(
    getAdjustmentsLoading,
    getAddAdjustmentDefinitionsLoading,
    getSubtractAdjustmentDefinitionsLoading,
  );

  return (
    <>
      <Box className={classes.root}>
        <DataGridPremium
          rows={balanceSheetLineItems}
          density='compact'
          columns={columns}
          className={clsx([classes.table, !isTableExpanded && classes.limitedHeightTable])}
          initialState={{
            sorting: {
              sortModel: [{ field: 'sourceSubAccountId', sort: 'asc' }],
            },
          }}
          pinnedRows={{
            top: topPinnedItems,
            bottom: bottomPinnedItems,
          }}
          slots={{
            footer: () => (
              <StandardTableFooter
                showTableExpandSwitch={balanceSheetLineItems.length > 10}
                isTableExpanded={isTableExpanded}
                onToggleTableExpand={onToggleTableExpand}
              />
            ),
          }}
        />
      </Box>
      <AddLineItemsDialog
        project={project}
        existingTypeCodes={existingTypeCodes}
        open={openAddLineItemsDialog}
        onClose={onCloseAddLineItemsDialog}
      />
      <Loader show={showLoader} />
    </>
  );
};
