import { Box, IconButton, List } from '@mui/material';

import { Actions } from '@models/enums/Actions';
import ActivityFilledIcon from '@assets/icons/menu/activity-filled.svg';
import ActivityIcon from '@assets/icons/menu/activity.svg';
import ChevronLeftIcon from '@assets/icons/menu/chevron-left.svg';
import ChevronRightIcon from '@assets/icons/menu/chevron-right.svg';
import GridFilledIcon from '@assets/icons/menu/grid-filled.svg';
import GridIcon from '@assets/icons/menu/grid.svg';
import LayersFilledIcon from '@assets/icons/menu/layers-filled.svg';
import LayersIcon from '@assets/icons/menu/layers.svg';
import RowsFilledIcon from '@assets/icons/menu/rows-filled.svg';
import RowsIcon from '@assets/icons/menu/rows.svg';
import { SecureContainer } from '../SecureContainer';
import SideMenuItem from '../SideMenuItem';
import UserFilledIcon from '@assets/icons/menu/user-filled.svg';
import UserIcon from '@assets/icons/menu/user.svg';
import UsersFilledIcon from '@assets/icons/menu/users-filled.svg';
import UsersIcon from '@assets/icons/menu/users.svg';
import { useState } from 'react';
import useStyles from './styles';

export const SideMenu = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { classes } = useStyles({ collapsed });

  const onToggleCollapsed = () => {
    setCollapsed((prevValue) => !prevValue);
  };

  return (
    <Box className={classes.container}>
      <List component='nav'>
        <SecureContainer action={Actions.getClients}>
          <SideMenuItem
            href='/admin/clients'
            label='Clients'
            collapsed={collapsed}
            icon={{ default: UserIcon, active: UserFilledIcon }}
          />
        </SecureContainer>
        <SecureContainer action={Actions.getGroups}>
          <SideMenuItem
            href='/admin/groups'
            label='Groups'
            collapsed={collapsed}
            icon={{ default: GridIcon, active: GridFilledIcon }}
          />
        </SecureContainer>
        <SecureContainer action={Actions.getUsers}>
          <SideMenuItem
            href='/admin/users'
            label='Users'
            collapsed={collapsed}
            icon={{ default: UsersIcon, active: UsersFilledIcon }}
          />
        </SecureContainer>
        <SecureContainer action={Actions.getProducts}>
          <SideMenuItem
            href='/admin/products'
            label='Products'
            collapsed={collapsed}
            icon={{ default: LayersIcon, active: LayersFilledIcon }}
          />
        </SecureContainer>
        <SecureContainer action={Actions.getStandardPiiCategories}>
          <SecureContainer action={Actions.getPiiCategories}>
            <SideMenuItem
              href='/admin/pii-categories'
              label='Pii Categories'
              collapsed={collapsed}
              icon={{ default: RowsIcon, active: RowsFilledIcon }}
            />
          </SecureContainer>
        </SecureContainer>
        <SecureContainer action={Actions.getActivities}>
          <SideMenuItem
            href='/admin/activity'
            label='Activity'
            collapsed={collapsed}
            icon={{ default: ActivityIcon, active: ActivityFilledIcon }}
          />
        </SecureContainer>
      </List>
      <Box className={classes.controls}>
        <IconButton onClick={onToggleCollapsed}>
          {collapsed ? (
            <img src={ChevronRightIcon} alt='Show' />
          ) : (
            <img src={ChevronLeftIcon} alt='Hide' />
          )}
        </IconButton>
      </Box>
    </Box>
  );
};
